<template>
  <three-point-menu ref="menu" dense>
    <!-- Choose address -->
    <address-select-dialog
      v-if="addressSelection.availableAddresses.length > 0"
      ref="select-address-dialog"
      v-model="selectedAddressValue"
      :addresses="addressSelection.availableAddresses"
      :show-select-hint="showSelectHint"
      @cancel="closeMenu"
      @select="selectAddress"
    >
      <template #activator="{ props }">
        <v-list-item ref="select-address-button" v-bind="props">
          <v-list-item-title>{{ $t('chooseAddress') }}</v-list-item-title>
        </v-list-item>
      </template>
    </address-select-dialog>

    <!-- Add new address -->
    <address-form-dialog
      v-if="addressSelection.canAdd"
      ref="add-address-dialog"
      :address-data="createEmptyAddress()"
      :show-delete-button="false"
      :disable-default-billing-address-checkbox="!addressPermissions.canSetDefaultBillingAddress"
      :disable-default-delivery-address-checkbox="!addressPermissions.canSetDefaultDeliveryAddress"
      :title="$t('addAddress').toString()"
      show-postal-address-only
      @save="createAddress"
      @cancel="closeMenu"
    >
      <template #activator="{ props }">
        <v-list-item ref="add-address-button" v-bind="props">
          <v-list-item-title>{{ $t('addAddress') }}</v-list-item-title>
        </v-list-item>
      </template>
    </address-form-dialog>

    <!-- Edit address -->
    <address-form-dialog
      v-if="selectedAddress"
      ref="edit-address-dialog"
      :address-data="selectedAddress"
      :show-delete-button="false"
      :readonly="!selectedAddress.canEdit"
      :show-default-address-checkboxes="false"
      show-postal-address-only
      show-edit-hint
      :title="$t('editAddress')"
      @save="saveAddress"
      @cancel="closeMenu"
    >
      <template #activator="{ props }">
        <v-list-item ref="edit-address-button" v-bind="props">
          <v-list-item-title>{{ $t('editAddress') }}</v-list-item-title>
        </v-list-item>
      </template>
    </address-form-dialog>

    <!-- Edit Contact data -->
    <contact-data-form-dialog
      v-if="contactData && selectedAddress?.canEdit"
      ref="edit-contact-data-dialog"
      :contact="contactData"
      :show-delete-button="showDeleteContactDataButton"
      @delete="deleteContactData"
      @save="saveContactData"
      @cancel="closeMenu"
    >
      <template #activator="{ props }">
        <v-list-item ref="edit-contact-data-button" v-bind="props">
          <v-list-item-title>{{ $t('editContactData') }}</v-list-item-title>
        </v-list-item>
      </template>
    </contact-data-form-dialog>

    <!-- Add contact data -->
    <contact-data-form-dialog
      v-if="!contactData && selectedAddress?.canEdit"
      ref="add-contact-data-dialog"
      :contact="createEmptyContactData()"
      :show-delete-button="false"
      @save="saveContactData"
      @cancel="closeMenu"
    >
      <template #activator="{ props }">
        <v-list-item ref="add-contact-data-button" v-bind="props">
          <v-list-item-title>{{ $t('addContactData') }}</v-list-item-title>
        </v-list-item>
      </template>
    </contact-data-form-dialog>
  </three-point-menu>
</template>

<script lang="ts">
import ThreePointMenu from '@/components/three-point-menu/ThreePointMenu.vue'
import { AddressPermissions, AddressSelection, ContactData, ShopAddress } from '@/generatedTypes'
import AddressFormDialog from '@/modules/address/components/AddressFormDialog.vue'
import AddressSelectDialog from '@/modules/address/components/AddressSelectDialog.vue'
import { createEmptyAddress } from '@/modules/address/helpers'
import ContactDataFormDialog from '@/modules/contactData/components/ContactDataFormDialog.vue'
import { createEmptyContactData } from '@/modules/contactData/helpers'
import { AddressChangeEvents } from '@/modules/tracking/events/addresses'
import { trackEvent } from '@/modules/tracking/useTracking'
import { clone } from 'lodash-es'
import { PropType, defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'OrderAddressAndContactDataMenu',
  components: {
    ContactDataFormDialog,
    ThreePointMenu,
    AddressSelectDialog,
    AddressFormDialog
  },
  props: {
    events: {
      type: Object as PropType<AddressChangeEvents>,
      default: undefined
    },
    selectedAddress: {
      type: Object as PropType<ShopAddress | undefined>,
      required: true
    },
    addressSelection: {
      type: Object as PropType<AddressSelection>,
      required: true
    },
    addressPermissions: {
      type: Object as PropType<AddressPermissions>,
      required: true
    },
    contactData: {
      type: Object as PropType<ContactData>,
      default: undefined
    },
    showDeleteContactDataButton: {
      type: Boolean,
      required: true
    },
    showSelectHint: {
      type: Boolean,
      required: true
    }
  },
  emits: ['create', 'save', 'select', 'saveContactData', 'deleteContactData'],
  setup(props, { emit }) {
    const menu = ref<{ close: () => void }>()
    const selectedAddressValue = ref(clone(props.selectedAddress))

    const createAddress = (address: ShopAddress) => {
      if (props.events) {
        trackEvent(props.events.Added)
      }
      emit('create', address)
      closeMenu()
    }

    const selectAddress = () => {
      if (props.events) {
        trackEvent(props.events.Chosen)
      }
      emit('select', selectedAddressValue.value)
      closeMenu()
    }

    const saveAddress = (address: ShopAddress) => {
      emit('save', address)
      closeMenu()
    }

    const deleteContactData = () => {
      emit('deleteContactData')
      closeMenu()
    }

    const saveContactData = (updatedContactData: ContactData) => {
      emit('saveContactData', updatedContactData)
      closeMenu()
    }

    const closeMenu = () => {
      if (menu.value) {
        menu.value.close()
      }
    }

    return {
      menu,
      selectedAddressValue,
      createEmptyAddress,
      createEmptyContactData,
      createAddress,
      selectAddress,
      saveAddress,
      deleteContactData,
      saveContactData,
      closeMenu
    }
  }
})
</script>
