<template>
  <v-form v-model="valid" lazy-validation>
    <v-row dense>
      <v-col cols="12">
        <sf-text>{{ $t('companyRegistration') }}</sf-text>
      </v-col>
      <v-col cols="12">
        <sf-heading>{{ $t('personalData') }}</sf-heading>
      </v-col>
      <v-col cols="12">
        <sf-select
          ref="title"
          v-model="companyForm.person.contactTitle"
          :items="titleItems"
          :label="$t('user.contactTitle') + ' *'"
          hide-details="auto"
          item-title="translation"
          item-value="title"
          autocomplete="honorific-prefix"
        />
      </v-col>
      <v-col cols="12">
        <sf-text-field
          ref="firstName"
          v-model="companyForm.person.firstName"
          :label="$t('user.firstName') + ' *'"
          :rules="[rules.requiredRule, rules.nameRule]"
          hide-details="auto"
          autocomplete="given-name"
        />
      </v-col>
      <v-col cols="12">
        <sf-text-field
          ref="lastName"
          v-model="companyForm.person.lastName"
          :label="$t('user.lastName') + ' *'"
          :rules="[rules.requiredRule, rules.nameRule]"
          hide-details="auto"
          autocomplete="family-name"
        />
      </v-col>
      <v-col cols="12">
        <sf-heading>{{ $t('companyData') }}</sf-heading>
      </v-col>
      <v-col cols="12">
        <sf-text-field
          ref="companyName"
          v-model="companyForm.company.name"
          :label="$t('user.company') + ' *'"
          :rules="[rules.requiredRule]"
          hide-details="auto"
          autocomplete="organization"
        />
      </v-col>
      <v-col cols="12">
        <sf-text-field
          ref="street"
          v-model="companyForm.company.street"
          :label="$t('address.street') + ' *'"
          :rules="[rules.requiredRule]"
          hide-details="auto"
          autocomplete="street-address"
        />
      </v-col>
      <v-col cols="4">
        <sf-text-field
          ref="zipCode"
          v-model="companyForm.company.zipCode"
          :label="$t('address.zipCode') + ' *'"
          :rules="[rules.requiredRule]"
          hide-details="auto"
          autocomplete="postal-code"
        />
      </v-col>
      <v-col cols="8">
        <sf-text-field
          ref="city"
          v-model="companyForm.company.city"
          :label="$t('address.city') + ' *'"
          :rules="[rules.requiredRule]"
          hide-details="auto"
          autocomplete="address-level2"
        />
      </v-col>
      <v-col cols="12">
        <sf-select
          ref="country"
          v-model="companyForm.company.country"
          :label="$t('address.country') + ' *'"
          :items="getCountries($i18n.locale)"
          item-title="label"
          item-value="value"
          :rules="[rules.requiredRule]"
          hide-details="auto"
          autocomplete="country-name"
        />
      </v-col>
      <v-col cols="12">
        <sf-text-field
          ref="phone"
          v-model="companyForm.company.phone"
          :label="$t('address.phone') + ' *'"
          :rules="[rules.requiredRule, rules.phoneRule]"
          hide-details="auto"
          autocomplete="tel"
        />
      </v-col>
    </v-row>

    <sf-large-button :disabled="!valid" block class="mt-2" :loading="sending" @click="submit">
      {{ $t('requestRegistration') }}
    </sf-large-button>
  </v-form>
  <login-link />
</template>

<script setup lang="ts">
import SfLargeButton from '@/components/button/SfLargeButton.vue'
import SfSelect from '@/components/input/SfSelect.vue'
import SfTextField from '@/components/input/SfTextField.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { Title } from '@/generatedTypes'
import { getCountries } from '@/modules/address/helpers'
import LoginLink from '@/modules/authentication/components/LoginLink.vue'
import { CompanyRegistrationDTO } from '@/modules/authentication/types'
import useSelfRegistration from '@/modules/authentication/useSelfRegistration'
import { getContactTitles } from '@/modules/user/helpers'
import rules from '@/rules'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

const emit = defineEmits<{
  submit: [CompanyRegistrationDTO]
}>()
const valid = ref(true)
const { sending } = storeToRefs(useSelfRegistration())
const titleItems = getContactTitles()

const companyForm = ref<CompanyRegistrationDTO>({
  company: {
    name: '',
    street: '',
    zipCode: '',
    city: '',
    country: 'DEU',
    phone: ''
  },
  person: {
    firstName: '',
    lastName: '',
    contactTitle: Title.Unspecified
  }
})

const submit = async () => {
  if (valid.value) {
    emit('submit', companyForm.value)
  }
}
</script>
