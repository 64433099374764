<template>
  <three-point-menu dense>
    <v-list-item ref="add-to-cart" @click="addToCart">
      <v-list-item-title>{{ $t('addToCart') }}</v-list-item-title>
    </v-list-item>

    <v-list-item ref="archive-order" @click="toggleOrderArchivation">
      <v-list-item-title>
        {{ order.isArchived ? $t('common.dearchive') : $t('common.archive') }}
      </v-list-item-title>
    </v-list-item>

    <order-rma-dialog v-if="order.rmaInformation.rmaPossible" :order="order">
      <template #activator="{ props }">
        <v-list-item v-bind="props">
          <v-list-item-title>
            {{ $t('rma.return') }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </order-rma-dialog>

    <sf-delete-confirm-dialog
      v-if="isDeletable"
      ref="delete-order"
      :title="$t('order.deleteOrder').toString()"
      :description="$t('order.deleteDialog').toString()"
      @confirm="deleteOrder"
    >
      <template #activator="{ props }">
        <v-list-item v-bind="props">
          <v-list-item-title class="text-error">{{ $t('order.deleteOrder') }}</v-list-item-title>
        </v-list-item>
      </template>
    </sf-delete-confirm-dialog>
  </three-point-menu>
</template>

<script setup lang="ts">
import SfDeleteConfirmDialog from '@/components/dialog/SfDeleteConfirmDialog.vue'
import ThreePointMenu from '@/components/three-point-menu/ThreePointMenu.vue'
import { DetailedOrder } from '@/generatedTypes'
import OrderRmaDialog from '@/modules/order/components/OrderRmaDialog.vue'
import { deletableStatuses } from '@/modules/order/helpers'
import useOrder from '@/modules/order/useOrder'
import OrderEvent from '@/modules/tracking/events/order'
import { trackEvent } from '@/modules/tracking/useTracking'
import { computed, PropType } from 'vue'

const componentProps = defineProps({
  order: {
    type: Object as PropType<DetailedOrder>,
    required: true
  }
})

const isDeletable = computed(() => deletableStatuses.includes(componentProps.order.orderStatus))

const toggleOrderArchivation = () => {
  useOrder().archiveOrder(componentProps.order.id, !componentProps.order.isArchived)
  trackEvent(componentProps.order.isArchived ? OrderEvent.Dearchive : OrderEvent.Archive)
}

const deleteOrder = () => {
  useOrder().deleteOrder(componentProps.order.id)
}

const addToCart = () => {
  useOrder().addToCart(componentProps.order.id)
  trackEvent(OrderEvent.AddToCart)
}
</script>
