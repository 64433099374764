<template>
  <v-row>
    <v-col cols="12">
      <sf-title>{{ $t('myCompany') }}</sf-title>
      <v-tabs show-arrows bg-color="transparent" class="mt-1" color="primary">
        <v-tab
          v-if="showColleaguesPermissions"
          :to="{ name: 'CompanyEmployees' }"
          @click="track('employees')"
        >
          {{ $t('company.employees') }}
        </v-tab>
        <v-tab v-else :to="profileRoute">
          {{ $t('myProfile') }}
        </v-tab>
        <v-tab :to="{ name: 'CompanyAddresses' }" @click="track('addresses')">
          {{ $t('addresses') }}
        </v-tab>
        <v-tab
          v-if="showOrderLimits"
          :to="{ name: 'CompanyOrderLimit' }"
          @click="track('approval')"
        >
          {{ $t('orderLimits.orderApproval') }}
        </v-tab>
      </v-tabs>
      <v-divider />
      <router-view />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import SfTitle from '@/components/text/SfTitle.vue'
import { isQuotesPortal, useTitle } from '@/helpers'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import MyCompanyEvent from '@/modules/tracking/events/MyCompany'
import { trackEvent } from '@/modules/tracking/useTracking'
import useOwnUser from '@/modules/user/useOwnUser'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'CompanyPage',
  components: { SfTitle },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('myCompany')}`))
    const { portalSettings } = storeToRefs(usePortalSettings())
    const { ownUser } = storeToRefs(useOwnUser())

    const showColleaguesPermissions = computed<boolean>(
      () => !portalSettings.value.hideColleaguesPermissions
    )

    const showOrderLimits = computed<boolean>(
      () => !isQuotesPortal.value && portalSettings.value.showOrderLimitFunctionality
    )

    const profileRoute = computed(() => {
      return {
        name: 'CompanyEmployee',
        params: { id: ownUser.value.id }
      }
    })

    const track = (tab: 'employees' | 'addresses' | 'approval') => {
      let event
      switch (tab) {
        case 'addresses':
          event = MyCompanyEvent.Tabs.Addresses
          break
        case 'approval':
          event = MyCompanyEvent.Tabs.OrderApproval
          break
        case 'employees':
          event = MyCompanyEvent.Tabs.Employees
          break
        default:
          return
      }
      trackEvent(event)
    }

    return {
      track,
      showColleaguesPermissions,
      showOrderLimits,
      profileRoute
    }
  }
})
</script>

<style lang="scss" scoped>
/* Workaround to mark selected tab as active even when not correct path */
.v-tab--selected {
  color: rgb(var(--v-theme-primary));
}
</style>
