<template>
  <sf-icon-button
    v-if="!$vuetify.display.mobile && !showCategories"
    color="primary"
    :size="32"
    :icon="mdiFileTree"
    @click="setShowCategories"
  />
</template>

<script lang="ts">
import useCategories from '@/modules/categories/useCategories'
import { defineComponent } from 'vue'
import { storeToRefs } from 'pinia'
import SfIconButton from '@/components/button/SfIconButton.vue'
import { mdiFileTree } from '@mdi/js'

export default defineComponent({
  name: 'ShowCategoriesButton',
  components: { SfIconButton },
  setup() {
    const { showCategories } = storeToRefs(useCategories())

    const setShowCategories = () => (useCategories().showCategories = true)

    return {
      showCategories,
      setShowCategories,
      mdiFileTree
    }
  }
})
</script>
