<template>
  <v-menu
    v-if="menu.length !== 0"
    v-model="showMenu"
    :persistent="false"
    location="bottom right"
    start
    transition="slide-y-transition"
  >
    <template v-if="!isError" #activator="{ props }">
      <v-btn id="profile-toolbar-button" icon v-bind="props">
        <v-avatar
          size="44"
          :color="isInProfile && !$vuetify.display.smAndDown ? 'primary' : 'transparent'"
        >
          <v-img
            v-if="avatarUrl"
            :src="fitImage(avatarUrl, 80, 80, '&')"
            max-height="40"
            max-width="40"
            class="rounded-circle"
          />
          <vue-avatar v-if="!avatarUrl" :username="fullName" :size="40" />
        </v-avatar>
      </v-btn>
    </template>

    <v-list density="compact" nav>
      <v-list-item
        v-if="userId"
        :to="profileRoute"
        exact
        :prepend-icon="mdiAccountCircleOutline"
        @click="trackToProfile"
      >
        <v-list-item-title>{{ $t('myProfile') }}</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-for="(item, index) in menu"
        :key="index"
        :to="item.link"
        :exact="$route.name === profileRoute.name && $route.params.id === profileRoute.params.id"
        :prepend-icon="item.icon"
        @click="trackGenericMenuItems(item)"
      >
        <v-list-item-title>{{ $t(item.key) }}</v-list-item-title>
      </v-list-item>

      <template v-if="changeSubsidiary">
        <v-divider class="my-1" />
        <switch-subsidiary-dialog @close="closeMenu" />
      </template>

      <template
        v-if="
          !branding?.ssoSettings?.ssoLoginExclusive &&
          (isAdmin || (branding && !branding.isQuotesPortal && branding.isUserRegistration))
        "
      >
        <v-divider class="my-1" />
        <invitation-dialog :is-admin="isAdmin" @close="closeMenu" />
      </template>

      <template v-if="branding?.legalInfoUrl || branding?.termsUrl || branding?.privacyUrl">
        <v-divider class="my-1" />
        <v-list-item
          v-if="branding?.termsUrl"
          link
          :prepend-icon="mdiInformationOutline"
          :href="branding.termsUrl"
          target="_blank"
        >
          <v-list-item-title>{{ $t('terms') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="branding?.privacyUrl"
          link
          :prepend-icon="mdiInformationOutline"
          :href="branding.privacyUrl"
          target="_blank"
        >
          <v-list-item-title>{{ $t('privacy') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="branding?.legalInfoUrl"
          link
          :prepend-icon="mdiInformationOutline"
          :href="branding.legalInfoUrl"
          target="_blank"
        >
          <v-list-item-title>{{ $t('legalInfo') }}</v-list-item-title>
        </v-list-item>
      </template>

      <div v-if="hasLogout">
        <v-divider class="my-1" />
        <v-list-item link :prepend-icon="mdiLogoutVariant" @click="logout">
          <v-list-item-title>{{ $t('signOut') }}</v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
  <v-avatar
    v-else
    size="44"
    :color="isInProfile && !$vuetify.display.smAndDown ? 'primary' : 'transparent'"
  >
    <v-img
      v-if="avatarUrl"
      :src="fitImage(avatarUrl, 80, 80, '&')"
      max-height="40"
      max-width="40"
      class="rounded-circle"
    />
    <vue-avatar v-if="!avatarUrl" :username="fullName" :size="40" />
  </v-avatar>
</template>

<script lang="ts">
import { MenuItem } from '@/components/navigation/menu'
import { fitImage } from '@/helpers'
import { Branding } from '@/modules/portalSettings/types'
import SwitchSubsidiaryDialog from '@/modules/subsidiary/components/SwitchSubsidiaryDialog.vue'
import ApplicationHeaderEvent from '@/modules/tracking/events/applicationHeader'
import { trackEvent } from '@/modules/tracking/useTracking'
import InvitationDialog from '@/modules/user/components/InvitationDialog.vue'
import { mdiAccountCircleOutline, mdiInformationOutline, mdiLogoutVariant } from '@mdi/js'
import VueAvatar from '@webzlodimir/vue-avatar'
import '@webzlodimir/vue-avatar/dist/style.css'
import { computed, defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'ToolbarUser',
  components: {
    VueAvatar,
    InvitationDialog,
    SwitchSubsidiaryDialog
  },
  props: {
    isAdmin: {
      type: Boolean,
      required: false,
      default: false
    },
    hasLogout: {
      type: Boolean,
      required: false,
      default: true
    },
    changeSubsidiary: {
      type: Boolean,
      required: false,
      default: false
    },
    userId: {
      type: String,
      default: undefined
    },
    avatarUrl: {
      type: String,
      default: undefined
    },
    fullName: {
      type: String,
      required: true
    },
    menu: {
      type: Array as PropType<MenuItem[]>,
      default: () => []
    },
    isError: {
      type: Boolean,
      required: false,
      default: false
    },
    branding: {
      type: Object as PropType<Branding | undefined>,
      required: true
    }
  },
  emits: ['logout'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const currentRoute = useRoute()
    const showMenu = ref(false)

    const closeMenu = () => {
      showMenu.value = false
    }

    const isInProfile = computed(() => {
      return currentRoute.name && (currentRoute.name as string).includes('Company')
    })

    const profileRoute = computed(() => {
      return {
        name: 'CompanyEmployee',
        params: { id: props.userId }
      }
    })

    const trackGenericMenuItems = (item: MenuItem) => {
      if (item.link !== '/login') {
        trackEvent(ApplicationHeaderEvent.GenericMenuItem(t(item.key, 'de').toString()))
      }
    }

    const trackToProfile = () => {
      trackEvent(ApplicationHeaderEvent.Profile)
    }

    const logout = () => emit('logout')

    return {
      showMenu,
      closeMenu,
      fitImage,
      logout,
      trackToProfile,
      trackGenericMenuItems,
      profileRoute,
      isInProfile,
      mdiAccountCircleOutline,
      mdiInformationOutline,
      mdiLogoutVariant
    }
  }
})
</script>
