<template>
  <v-btn-toggle :model-value="currentView" density="compact" group color="primary" variant="text">
    <template v-for="view in views" :key="view.name">
      <view-toggle-item
        v-if="view.restriction() && (view.showOnMobile || $vuetify.display.mdAndUp)"
        :view="view"
        @changed="toggleView"
      />
    </template>
  </v-btn-toggle>
</template>

<script lang="ts">
import ViewToggleItem from '@/components/ViewToggleItem.vue'
import { Event } from '@/modules/tracking/types'
import { trackEvent } from '@/modules/tracking/useTracking'
import { ViewToggle } from '@/types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ViewToggle',
  components: { ViewToggleItem },
  props: {
    views: {
      type: Array as PropType<ViewToggle<unknown>[]>,
      required: true
    },
    currentView: {
      type: String,
      required: true
    },
    toggleEvent: {
      type: Function as PropType<(label: string) => Event>,
      default: undefined
    }
  },
  emits: ['changed'],
  setup(props, { emit }) {
    const toggleView = (view: ViewToggle<unknown>) => {
      if (view.name !== props.currentView) {
        if (props.toggleEvent) {
          trackEvent(props.toggleEvent(view.name as string))
        }

        emit('changed', view.name)
      }
    }

    return { toggleView }
  }
})
</script>
