<template>
  <v-badge :offset-y="6" :offset-x="6" dot :model-value="showBadge" color="primary">
    <v-btn
      elevation="0"
      rounded
      height="32"
      class="text-body-1 font-weight-bold text-medium-emphasis"
      :to="menuItem.link"
      :active="isActive"
      @click="track(menuItem.key)"
    >
      {{ $t(menuItem.key).toString().toUpperCase() }}
    </v-btn>
  </v-badge>
</template>

<script lang="ts">
import { MenuItem } from '@/components/navigation/menu'
import ApplicationHeaderEvent from '@/modules/tracking/events/applicationHeader'
import { trackEvent } from '@/modules/tracking/useTracking'
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'NavigationMenuItem',
  props: {
    menuItem: {
      type: Object as PropType<MenuItem>,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n()
    const currentRoute = useRoute()

    if (props.menuItem.count) {
      props.menuItem.count.loadFunction()
    }

    const showBadge = computed(
      () => props.menuItem.count != undefined && props.menuItem.count.getter() > 0
    )

    const track = (key: string): void => {
      trackEvent(ApplicationHeaderEvent.GenericMenuItem(t(key, 'de').toString()))
    }

    const isActive = computed<boolean>(() => {
      return currentRoute && currentRoute.path.includes(props.menuItem.link)
    })

    return {
      isActive,
      showBadge,
      track
    }
  }
})
</script>

<style lang="scss" scoped>
.v-btn--active.v-btn.v-btn--rounded {
  color: rgb(var(--v-theme-primary)) !important;
}
</style>
