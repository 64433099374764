import { BatchRuntimeRequest, RuntimeRequest } from '@/generatedTypes'
import { ApiError, handleApiError } from '@/handleApiError'
import api from '@/modules/product/api'
import useProduct from '@/modules/product/useProduct'
import useProductSearch from '@/modules/product/useProductSearch'
import useStartPage from '@/modules/startPage/useStartPage'
import useTopi from '@/modules/topi/useTopi'
import { defineStore } from 'pinia'

interface RealtimePricesState {
  loading: number[]
}

const initialState = (): RealtimePricesState => {
  return {
    loading: []
  }
}

const useRealtimePrices = defineStore('realtimePrices', {
  state: () => initialState(),
  actions: {
    async loadRealtimePrices(request: RuntimeRequest) {
      this.loading = [request.productId]

      return api
        .loadRealtimePrices(request)
        .then(({ data }) => {
          useProduct().updateProductPrices(data)
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            appearance: 'NONE'
          })
        })
        .finally(() => {
          this.loading = []
        })
    },
    async loadRealtimePricesBatch(request: BatchRuntimeRequest) {
      this.loading = request.productIds

      return api
        .loadRealtimePricesBatch(request)
        .then(({ data }) => {
          data.forEach((productPriceUpdate) => {
            useProductSearch().updateProduct(productPriceUpdate)
          })
          useTopi().setCartItems(useProductSearch().items)
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            appearance: 'NONE'
          })
        })
        .finally(() => {
          this.loading = []
        })
    },
    async loadRealtimePricesBatchForStartPage(request: BatchRuntimeRequest) {
      this.loading = request.productIds

      return api
        .loadRealtimePricesBatch(request)
        .then(({ data }) => {
          data.forEach((productPriceUpdate) => {
            useStartPage().updateProduct(productPriceUpdate)
          })
          useTopi().setCartItems(useStartPage().startPage.featuredProducts)
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            appearance: 'NONE'
          })
        })
        .finally(() => {
          this.loading = []
        })
    },
    async loadRealtimePricesForSearch(request: RuntimeRequest) {
      this.loading = [request.productId]

      return api
        .loadRealtimePrices(request)
        .then(({ data }) => {
          useProductSearch().updateProduct(data)
          useTopi().setCartItems(useProductSearch().items)
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            appearance: 'NONE'
          })
        })
        .finally(() => {
          this.loading = []
        })
    }
  }
})

export default useRealtimePrices
