<template>
  <quotes-portal-layout v-if="branding && branding.isQuotesPortal" />
  <portal-layout v-else />
</template>

<script lang="ts">
import useBranding from '@/modules/portalSettings/useBranding'
import { PageView } from '@/modules/tracking/events/pageView'
import { trackEvent } from '@/modules/tracking/useTracking'
import router from '@/router'
import { defineComponent, onMounted } from 'vue'
import PortalLayout from '@/layouts/PortalLayout.vue'
import QuotesPortalLayout from '@/layouts/QuotesPortalLayout.vue'

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    PortalLayout,
    QuotesPortalLayout
  },
  setup() {
    const { branding } = useBranding()

    router.afterEach((from, to) => {
      if (from.path !== to.path && to.matched.some((record) => record.meta.requiresAuth)) {
        trackEvent(PageView)
      }
    })

    onMounted(() => {
      trackEvent(PageView)
    })

    return {
      branding
    }
  }
})
</script>
