<template>
  <sf-alert-info class="mb-3" dense>
    <span class="text-body-2">{{ $t('order.editAddressHint') }}</span>
  </sf-alert-info>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SfAlertInfo from '@/components/alerts/SfAlertInfo.vue'

export default defineComponent({
  name: 'AddressEditHint',
  components: { SfAlertInfo }
})
</script>
