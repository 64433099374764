<template>
  <v-combobox
    :label="$t('recipients')"
    :model-value="model"
    color="primary"
    variant="outlined"
    density="compact"
    clearable
    chips
    closable-chips
    multiple
    :items="emails"
    :error="error != ''"
    :error-messages="error"
    append-icon=""
    :delimiters="[';', ' ']"
    @update:model-value="onInput"
  />
</template>

<script setup lang="ts">
import useUsers from '@/modules/user/useUsers'
import { mailRule } from '@/rules'
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'

const model = defineModel<string[]>()

const { users } = storeToRefs(useUsers())
const { portalSettings } = storeToRefs(usePortalSettings())
const error = ref<string>('')

if (!portalSettings.value.hideColleaguesPermissions && !portalSettings.value.hideProfile) {
  useUsers().getUsers()
}

const onInput = (input: string[]): void => {
  const validatedInput = validateInput(input)
  model.value = validatedInput
}

const emails = computed(() => users.value.map((user) => user.email))

const validateInput = (input: string[]): string[] => {
  const result = input
  if (input.length > 0) {
    const mail = input[input.length - 1]
    const validationResult = mailRule(mail)

    if (typeof validationResult === 'string') {
      error.value = validationResult
      result.pop()
    } else {
      error.value = ''
    }
  } else {
    error.value = ''
  }
  return result
}
</script>
