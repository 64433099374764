<template>
  <div class="pa-4">
    <v-row>
      <v-col cols="12" sm="12" md="5" lg="4" xl="3">
        <v-skeleton-loader type="image" />
      </v-col>
      <v-col cols="12" sm="12" md="7" lg="8" xl="9">
        <v-skeleton-loader type="heading" />
        <v-skeleton-loader class="mt-3" type="sentences" />
        <v-skeleton-loader class="mt-2" type="chip" />
        <v-skeleton-loader class="mt-3" type="heading" />
        <v-skeleton-loader class="mt-3" type="text" width="100px" />
        <div class="d-flex mt-1">
          <v-skeleton-loader type="button" />
          <v-skeleton-loader class="ml-1" type="button" />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader type="heading@4, table-tbody" width="100%" />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProductLoading'
})
</script>
