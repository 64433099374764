<template>
  <v-dialog model-value :width="500" persistent>
    <v-card>
      <v-card-item>
        <v-card-title>
          <sf-heading>{{ title }}</sf-heading>
        </v-card-title>
      </v-card-item>
      <v-card-text class="text-justify">
        <sf-text>{{ description }}</sf-text>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <sf-text-button :loading="loading" color="primary" @click="$emit('refresh')">
          {{ $t('refresh') }}
        </sf-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'

export default defineComponent({
  name: 'SfRefreshDialog',
  components: { SfHeading, SfTextButton, SfText },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['refresh']
})
</script>
