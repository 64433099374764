import { Money } from '@/generatedTypes'
import { TopiPdpItem } from '@/modules/topi/types'

const getTopiPdpItem = (
  productId: number,
  price: Money,
  isGrossPrice: boolean,
  vatRatePercent: number
): TopiPdpItem => {
  const vatRate = 1 + vatRatePercent / 100
  let grossPrice: number
  let netPrice: number

  if (isGrossPrice) {
    grossPrice = Math.round(price.value * 100)
    netPrice = Math.round((price.value / vatRate) * 100)
  } else {
    netPrice = Math.round(price.value * 100)
    grossPrice = Math.round(price.value * vatRate * 100)
  }

  return {
    price: {
      currency: price.currency,
      gross: grossPrice,
      net: netPrice
    },
    quantity: 1,
    sellerProductReference: {
      reference: productId.toString(),
      source: 'puid'
    }
  }
}

export { getTopiPdpItem }
