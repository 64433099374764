import { QuoteActivityType } from '@/generatedTypes'
import { Event } from '@/modules/tracking/types'
import { addressEvents } from '@/modules/tracking/events/addresses'
import {
  checkoutCommentEvents,
  checkoutOrderNumberEvents,
  checkoutPositionCommentEvents
} from '@/modules/tracking/events/checkout'

export default class QuoteEvent extends Event {
  constructor(action: string, label?: string) {
    super('Angebot', action, label ?? '')
  }

  static readonly Download = class {
    static readonly Quote = new QuoteEvent('Ein Angebot wurde heruntergeladen.')
  }

  static readonly ToCart = class {
    static readonly InHeader = new QuoteEvent('In den Warenkorb', 'Über Header Button')
    static readonly InBody = new QuoteEvent('In den Warenkorb', 'Über Button im Angebot')
  }

  static readonly CreateRequestFromCollection = new QuoteEvent(
    'Anfrage erstellt',
    'Anfrage aus Sammlung erstellt'
  )

  static readonly CreateRequest = new QuoteEvent(
    'Anfrage erstellt',
    'Anfrage aus der Angebotsliste erstellt'
  )

  static readonly SendRequest = new QuoteEvent('Anfrage abgesendet')
  static readonly RequestAgain = new QuoteEvent('Erneut angefragt')
  static readonly DeleteRequest = new QuoteEvent('Anfrage gelöscht')
  static readonly EditRequestTitle = new QuoteEvent('Titel der Anfrage bearbeitet')
  static readonly Decline = new QuoteEvent('Angebot abgelehnt')
  static readonly TextSearch = new QuoteEvent('Angebot suchen')
  static readonly Archive = new QuoteEvent('Archiviert')
  static readonly Dearchive = new QuoteEvent('Dearchiviert')
  static readonly AddMessage = new QuoteEvent('Nachricht geschrieben')
  static readonly Accept = new QuoteEvent('Angebot bestellt')

  static readonly ToggleView = (view: string): QuoteEvent => {
    if (view === QuoteActivityType.CHAT) {
      return new QuoteEvent('Aktivitätenansicht umgeschaltet', 'Kommentare')
    }
    if (view === QuoteActivityType.HISTORY) {
      return new QuoteEvent('Aktivitätenansicht umgeschaltet', 'Änderungen')
    }

    return new QuoteEvent('Aktivitätenansicht umgeschaltet', view)
  }
}

export class QuoteCheckoutEvent extends Event {
  constructor(action: string, label?: string) {
    super('Angebots-Checkout', action, label ?? '')
  }
  static readonly Address = class {
    static readonly Billing = addressEvents('Rechnungsadresse', QuoteCheckoutEvent)
    static readonly Shipping = addressEvents('Lieferadresse', QuoteCheckoutEvent)
  }
  static readonly Comment = {
    Position: checkoutPositionCommentEvents(QuoteCheckoutEvent),
    Checkout: checkoutCommentEvents(QuoteCheckoutEvent)
  }
  static readonly OrderNumber = checkoutOrderNumberEvents(QuoteCheckoutEvent)
  static readonly SendOrder = new QuoteCheckoutEvent('Bestellung gesendet')
}

export class GuestQuoteEvent extends Event {
  constructor(action: string, label?: string) {
    super('Gastangebot', action, label ?? '')
  }

  static readonly Accept = new GuestQuoteEvent('Angebot bestellt')
  static readonly Reject = new GuestQuoteEvent('Angebot abgelehnt')
  static readonly Print = new GuestQuoteEvent('Ein Angebot wurde heruntergeladen.')
  static readonly AddMessage = new GuestQuoteEvent('Nachricht geschrieben')

  static readonly ChangeAddress = addressEvents('Angebotsadresse', GuestQuoteEvent)
  static readonly ChangeContactData = new GuestQuoteEvent('Kontaktdaten geändert')

  static readonly ToggleView = (view: string): GuestQuoteEvent => {
    if (view === QuoteActivityType.CHAT) {
      return new GuestQuoteEvent('Aktivitätenansicht umgeschaltet', 'Kommentare')
    }
    if (view === QuoteActivityType.HISTORY) {
      return new GuestQuoteEvent('Aktivitätenansicht umgeschaltet', 'Änderungen')
    }

    return new GuestQuoteEvent('Aktivitätenansicht umgeschaltet', view)
  }

  static readonly Register = new GuestQuoteEvent('Benutzer hat sich registriert')
}
