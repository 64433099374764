<template>
  <span class="text-white" :class="hasLink ? 'cursor-pointer' : ''" @click="onClick">{{
    message.message
  }}</span>
</template>

<script lang="ts">
import { Message } from '@/modules/message/useMessaging'
import { computed, defineComponent, PropType } from 'vue'
import router from '@/router'

export default defineComponent({
  name: 'MessageText',
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true
    }
  },
  setup(props) {
    const hasLink = computed(() => {
      return props.message && props.message.link != undefined && props.message.link != ''
    })

    const onClick = () => {
      const link = props.message.link
      if (link) {
        router.push(link)
      }
    }
    return {
      onClick,
      hasLink
    }
  }
})
</script>
