<template>
  <v-row>
    <v-col>
      <v-tabs v-model="tab" hide-slider show-arrows align-tabs="center" height="200px">
        <v-tab
          v-for="bundleItem in bundleItems"
          :key="bundleItem.product.productId"
          height="200px"
          width="200px"
          class="bundle-item pa-1"
        >
          <template #default>
            <v-badge
              :model-value="bundleItem.amount > 1"
              color="primary"
              :offset-y="-10"
              :content="bundleItem.amount + 'x'"
            >
              <div class="d-flex flex-column justify-center align-center">
                <v-img
                  class="mb-1"
                  height="120px"
                  width="120px"
                  :src="
                    bundleItem.product.imageUrls[0]
                      ? fitCropImage(bundleItem.product.imageUrls[0], 240, 240)
                      : placeholderImage
                  "
                />
                <span class="d-inline-block item-text text-caption text-truncate">
                  {{ bundleItem.product.title }}
                </span>
              </div>
            </v-badge>
          </template>
        </v-tab>
      </v-tabs>
      <v-window v-model="tab">
        <v-window-item
          v-for="bundleItem in bundleItems"
          :key="bundleItem.product.productId"
          class="bundle-item-product"
        >
          <product-header
            :loading-realtime-prices="false"
            :product="bundleItem.product"
            :show-add-to-cart-widget="false"
          />
          <product-technical-details
            class="mt-1"
            show-title
            :technical-details="bundleItem.product.technicalDetails"
            :product-identifiers="getProductIdentifiers(bundleItem.product, showProductIds)"
          />
        </v-window-item>
      </v-window>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { fitCropImage, placeholderImage } from '@/helpers'
import { defineComponent, PropType, ref } from 'vue'
import { getProductIdentifiers } from '@/modules/product/helpers'
import { BundleReference } from '@/generatedTypes'
import ProductHeader from '@/modules/product/components/ProductHeader.vue'
import ProductTechnicalDetails from '@/modules/product/components/ProductTechnicalDetails.vue'

export default defineComponent({
  name: 'ProductBundleItems',
  components: { ProductHeader, ProductTechnicalDetails },
  props: {
    bundleItems: {
      type: Array as PropType<BundleReference[]>,
      required: true
    },
    showProductIds: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const tab = ref(0)

    return {
      tab,
      placeholderImage,
      fitCropImage,
      getProductIdentifiers
    }
  }
})
</script>

<style lang="scss" scoped>
.v-tab--selected {
  border: 1px solid rgb(var(--v-theme-primary));
}

.item-text {
  max-width: 150px;
}
</style>
