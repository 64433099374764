import { Title } from '@/generatedTypes'

enum Step {
  EMAIL = 'email',
  COMPANY = 'company',
  LINK_SENT = 'linkSent',
  REQUESTED = 'requested'
}

type LoginCredentials = {
  mail: string
  password: string
}

type CompanyRegistrationDTO = {
  company: {
    name: string
    street: string
    zipCode: string
    city: string
    country: string
    phone: string
  }
  person: {
    firstName: string
    lastName: string
    contactTitle: Title
  }
}

type EmailRegistrationDTO = {
  email: string
  nextStep: Step
}

enum LoginError {
  WRONG_CREDENTIALS = 'WRONG_CREDENTIALS',
  USER_DEACTIVATED = 'USER_DEACTIVATED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  NONE = 'NONE'
}

export { LoginCredentials, LoginError, Step, CompanyRegistrationDTO, EmailRegistrationDTO }
