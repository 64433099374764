<template>
  <v-card :class="[{ 'border--red': !addressValidation.valid }]" height="100%">
    <v-card-item>
      <v-card-title class="d-flex justify-space-between align-center">
        <sf-heading ref="title">{{ $t(titleKey) }}</sf-heading>
        <order-address-and-contact-data-menu
          v-if="!readonly && addressSelection"
          ref="menu"
          :address-selection="addressSelection"
          :address-permissions="portalSettings.addressPermissions"
          :selected-address="address"
          :contact-data="contactData"
          :show-delete-contact-data-button="addressType === AddressType.delivery"
          :show-select-hint="
            addressType === AddressType.delivery && portalSettings.useOwnDeliveryAddress
          "
          :events="events"
          @create="createAddress"
          @select="selectAddress"
          @save="saveAddress"
          @save-contact-data="updateContactData"
          @delete-contact-data="deleteContactData"
        />
      </v-card-title>
    </v-card-item>
    <v-card-text>
      <div class="d-flex flex-wrap">
        <order-address v-if="address" class="flex-grow-1" :address="address" />
        <order-contact-data v-if="contactData" :contact-data="contactData" />
      </div>
      <div v-if="!addressValidation.valid && addressValidation.error" class="mt-2">
        <sf-color-text color="red">{{ addressValidation.error }}</sf-color-text>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import SfColorText from '@/components/text/SfColorText.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import { AddressSelection, ContactData, ShopAddress } from '@/generatedTypes'
import { AddressType } from '@/modules/address/types'
import useAddress from '@/modules/address/useAddress'
import useAddressValidation from '@/modules/address/useAddressValidation'
import OrderAddress from '@/modules/order/components/OrderAddress.vue'
import OrderAddressAndContactDataMenu from '@/modules/order/components/OrderAddressAndContactDataMenu.vue'
import OrderContactData from '@/modules/order/components/OrderContactData.vue'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import { AddressChangeEvents } from '@/modules/tracking/events/addresses'
import { storeToRefs } from 'pinia'
import { PropType, computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'OrderAddressAndContactData',
  components: {
    OrderAddress,
    OrderAddressAndContactDataMenu,
    OrderContactData,
    SfHeading,
    SfColorText
  },
  props: {
    address: {
      type: Object as PropType<ShopAddress | undefined>,
      required: true
    },
    addressType: {
      type: String as PropType<AddressType>,
      required: true
    },
    addressSelection: {
      type: Object as PropType<AddressSelection>,
      default: undefined
    },
    contactData: {
      type: Object as PropType<ContactData>,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    },
    events: {
      type: Object as PropType<AddressChangeEvents>,
      default: undefined
    }
  },
  emits: ['selectAddress', 'updateContactData'],
  setup(props, { emit }) {
    const { portalSettings } = storeToRefs(usePortalSettings())
    const addressValidation = useAddressValidation()[props.addressType]

    const titleKey = computed(() => {
      return props.addressType === AddressType.billing ? 'billingAddress' : 'order.shippingAddress'
    })

    const selectAddress = (address: ShopAddress) => {
      emit('selectAddress', address)
    }

    const createAddress = (address: ShopAddress): void => {
      useAddress()
        .createAddress(address)
        .then(() => {
          emit('selectAddress', address)
        })
    }

    const saveAddress = (address: ShopAddress): void => {
      emit('selectAddress', address)
    }

    const updateContactData = (updatedContactData: ContactData): void => {
      emit('updateContactData', updatedContactData)
    }

    const deleteContactData = (): void => {
      emit('updateContactData')
    }

    return {
      titleKey,
      portalSettings,
      addressValidation,
      AddressType,
      selectAddress,
      createAddress,
      saveAddress,
      updateContactData,
      deleteContactData
    }
  }
})
</script>
