<template>
  <v-row>
    <v-col cols="12">
      <template v-if="$vuetify.display.smAndUp">
        <template v-if="count == 0">
          <show-categories-button />
          <list-empty :icon="mdiSwapHorizontalBold" :message="$t('productComparison.noProducts')" />
          <product-comparison-hint />
        </template>

        <div
          v-if="count > 0"
          ref="comparisonContainer"
          class="overflow-y-auto overflow-x-hidden scrollbar"
          :style="'height: calc(100vh - ' + offset + 'px)'"
        >
          <v-row v-if="count > 0">
            <v-col class="align-self-start mt-2 pl-4 d-flex height" cols="2">
              <show-categories-button class="mr-2" />
              <div>
                <sf-text> {{ $t('productComparison.highlightDifferences') }}</sf-text>
                <v-switch
                  v-model="highlightDifferences"
                  class="pl-1"
                  color="green"
                  inset
                  @update:model-value="trackHighlightDifferences"
                />
              </div>
            </v-col>
            <v-col cols="10" lg="9">
              <v-row>
                <template v-for="product in products" :key="product.productId">
                  <!-- spacers are used to emulate "space-evenly" -->
                  <v-spacer />
                  <v-col cols="4" class="px-2">
                    <product-comparison-card :product="product" />
                  </v-col>
                </template>
                <v-spacer />
              </v-row>
            </v-col>
          </v-row>
          <product-comparison-table
            v-if="count > 0"
            :products="products"
            :groups="groups"
            :highlight-differences="highlightDifferences"
          />
        </div>
      </template>
      <template v-else>
        <list-empty
          :icon="mdiSwapHorizontalBold"
          :message="$t('productComparison.noMobileVersion')"
        />
      </template>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import ShowCategoriesButton from '@/components/ShowCategoriesButton.vue'
import ListEmpty from '@/components/list/ListEmpty.vue'
import SfText from '@/components/text/SfText.vue'
import ProductComparisonCard from '@/modules/product/components/ProductComparisonCard.vue'
import ProductComparisonHint from '@/modules/product/components/ProductComparisonHint.vue'
import ProductComparisonTable from '@/modules/product/components/ProductComparisonTable.vue'
import { useProductComparison } from '@/modules/product/useProductComparison'
import { trackEvent } from '@/modules/tracking/useTracking'
import { containerHeight } from '@/utils/containerHeightHelper'
import { storeToRefs } from 'pinia'
import { ComponentPublicInstance, defineComponent, onBeforeMount, ref } from 'vue'

import ProductComparisonEvent from '@/modules/tracking/events/productComparison'
import { useTitle } from '@/helpers'
import { useI18n } from 'vue-i18n'
import { mdiSwapHorizontalBold } from '@mdi/js'

export default defineComponent({
  name: 'ProductComparison',
  components: {
    ShowCategoriesButton,
    ProductComparisonCard,
    ProductComparisonTable,
    ProductComparisonHint,
    ListEmpty,
    SfText
  },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('productComparison.pageTitle')}`))

    const { products, count, groups } = storeToRefs(useProductComparison())
    onBeforeMount(() => useProductComparison().loadProducts())
    const highlightDifferences = ref(false)

    const comparisonContainer = ref<ComponentPublicInstance<HTMLElement>>()
    const { offset } = containerHeight(comparisonContainer, 24)
    const trackHighlightDifferences = (newValue: boolean | null) => {
      trackEvent(
        newValue
          ? ProductComparisonEvent.HighlightDifferences.on
          : ProductComparisonEvent.HighlightDifferences.off
      )
    }

    return {
      count,
      products,
      groups,
      offset,
      highlightDifferences,
      comparisonContainer,
      trackHighlightDifferences,
      mdiSwapHorizontalBold
    }
  }
})
</script>
