<template>
  <sf-text>
    {{ $t('common.comment') + ': ' + (comment ? comment : '-') }}
  </sf-text>
</template>

<script lang="ts">
import SfText from '@/components/text/SfText.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ApprovalRequestInfoComment',
  components: {
    SfText
  },
  props: {
    comment: {
      type: String,
      default: undefined
    }
  }
})
</script>
