import { ApiError, handleApiError } from '@/handleApiError'
import useProductFilter from '@/modules/product/useProductFilter'
import router from '@/router'
import { defineStore } from 'pinia'
import useProductSearch from '@/modules/product/useProductSearch'
import api from '@/modules/categories/api'
import {
  getOpenCategories,
  getSelectableCategoryForSearchTerm,
  removeEmptyChildren
} from '@/modules/categories/helpers'
import { Category } from '@/generatedTypes'

interface CategoriesState {
  categories: Category[]
  selectedCategory: Category | undefined
  openCategories: Category[]
  showCategories: boolean
  loading: boolean
}

const initialState = (): CategoriesState => {
  return {
    categories: [],
    selectedCategory: undefined,
    openCategories: [],
    showCategories: true,
    loading: false
  }
}

const useCategories = defineStore('categories', {
  state: () => initialState(),
  actions: {
    async loadCategories() {
      this.loading = true

      return api
        .loadCategories()
        .then(({ data }) => {
          this.categories = removeEmptyChildren(data)
        })
        .catch(handleApiError)
        .finally(() => {
          this.loading = false
        })
    },
    async initCategories(
      categorySearchTerm: string | undefined,
      keyword: string | undefined,
      filters: string[]
    ) {
      this.loading = true

      return api
        .loadCategories()
        .then(({ data }) => {
          this.categories = removeEmptyChildren(data)
          this.getProductsForCategory(categorySearchTerm, keyword, filters)
        })
        .catch((error: ApiError) => {
          handleApiError(
            error,
            {
              appearance: 'CUSTOM',
              customHandler: 'Category'
            },
            {
              errorCode: 404,
              customMessageKey: 'error.categoriesNotFound'
            }
          )
        })
        .finally(() => {
          this.loading = false
        })
    },
    getProductsForCategory(
      categorySearchTerm: string | undefined,
      keyword: string | undefined,
      filters: string[]
    ) {
      this.selectedCategory = getSelectableCategoryForSearchTerm(
        this.categories,
        categorySearchTerm
      )

      if (this.selectedCategory) {
        if (categorySearchTerm !== this.selectedCategory.searchTerm) {
          router.replace({
            name: 'Category',
            params: { id: this.selectedCategory.searchTerm }
          })
        } else {
          this.openCategories = getOpenCategories(this.categories, this.selectedCategory)
          useProductFilter().category = this.selectedCategory.searchTerm

          useProductSearch().searchProducts({
            keyword,
            category: categorySearchTerm,
            filters,
            sort: useProductFilter().selection.sort
          })
          useProductFilter().getFilters({
            keyword,
            category: categorySearchTerm,
            filters
          })
        }
      }
    }
  }
})

export default useCategories
