import { Event } from '@/modules/tracking/types'
import AddToCartButtonEvents from '@/modules/tracking/events/helper/addToCartButton'

export default class ProductPageEvent extends Event {
  static readonly PageView = (productTitle: string): ProductPageEvent =>
    new ProductPageEvent('Seite angesehen', productTitle)
  static readonly Comparison = class {
    static readonly AddToComparison = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Zu Vergleich hinzugefügt').withProduct(productId)
    static readonly RemoveFromComparison = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Vom Vergleich entfernt').withProduct(productId)
  }

  static readonly Datasheet = class {
    static readonly Html = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Datenblatt aufgerufen', 'HTML').withProduct(productId)
    static readonly Pdf = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Datenblatt aufgerufen', 'PDF').withProduct(productId)
    static readonly Manufacturer = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Datenblatt aufgerufen', 'Hersteller').withProduct(productId)
  }
  static readonly CreateCollection = (productId: number): ProductPageEvent =>
    new ProductPageEvent('Sammlung Erstellt').withProduct(productId)

  static readonly AddToCart = class {
    static readonly Header: AddToCartButtonEvents = {
      addToCart: new ProductPageEvent('In den Warenkorb gelegt', 'Über Produktheader'),
      request: new ProductPageEvent('Produkt angefragt', 'Über Produktheader')
    }
    static readonly Source: AddToCartButtonEvents = {
      addToCart: new ProductPageEvent('In den Warenkorb gelegt', 'Über Bezugsquelle'),
      request: new ProductPageEvent('Produkt angefragt', 'Über Bezugsquelle')
    }
    static readonly Accessory: AddToCartButtonEvents = {
      addToCart: new ProductPageEvent('Accessoire in den Warenkorb gelegt'),
      request: new ProductPageEvent('Accessoire angefragt')
    }
  }
  static readonly AddProductToCollection = (productId: number): ProductPageEvent =>
    new ProductPageEvent('Produkt zu Sammlung hinzugefügt').withProduct(productId)
  static readonly ProductType = (productId: number): ProductPageEvent =>
    new ProductPageEvent('Produkttyp aufgerufen').withProduct(productId)

  static readonly AddProductToExistingQuote = (productId: number): ProductPageEvent =>
    new ProductPageEvent('Produkt zur Anfrage hinzugefügt', 'Über Produktheader').withProduct(
      productId
    )
  static readonly AddProductToNewQuote = (productId: number): ProductPageEvent =>
    new ProductPageEvent('Anfrage erstellt', 'Über Produktheader').withProduct(productId)

  static readonly Tabs = class {
    static readonly PartsList = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Tab angeklickt', 'Bundle Stückliste').withProduct(productId)
    static readonly Sources = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Tab angeklickt', 'Bezugsquellen').withProduct(productId)
    static readonly TechnicalData = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Tab angeklickt', 'Technische Daten').withProduct(productId)
    static readonly Accessories = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Tab angeklickt', 'Accessories').withProduct(productId)
    static readonly Description = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Tab angeklickt', 'Beschreibung').withProduct(productId)
  }

  constructor(action: string, label?: string) {
    super('Produktseite', action, label ?? '')
  }
}
