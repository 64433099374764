<template>
  <sf-icon-button v-bind="{ color: '', ...$attrs }" :icon="mdiTrashCanOutline" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SfIconButton from '@/components/button/SfIconButton.vue'
import { mdiTrashCanOutline } from '@mdi/js'

export default defineComponent({
  name: 'SfDeleteButton',
  components: { SfIconButton },
  inheritAttrs: false,
  setup() {
    return {
      mdiTrashCanOutline
    }
  }
})
</script>
