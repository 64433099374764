<template>
  <v-text-field
    color="primary"
    density="compact"
    variant="outlined"
    v-bind="$attrs"
    :type="showing ? 'text' : 'password'"
  >
    <template #append-inner>
      <v-icon v-if="!showing" :icon="mdiEye" tabindex="-1" @click="toggleShowing" />
      <v-icon v-if="showing" :icon="mdiEyeOff" tabindex="-1" @click="toggleShowing" />
    </template>
  </v-text-field>
</template>

<script lang="ts">
import { mdiEye, mdiEyeOff } from '@mdi/js'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'SfTextField',
  inheritAttrs: false,
  setup() {
    const showing = ref(false)

    const toggleShowing = () => (showing.value = !showing.value)

    return {
      showing,
      toggleShowing,
      mdiEye,
      mdiEyeOff
    }
  }
})
</script>
