<template>
  <v-btn
    icon
    color="primary"
    variant="text"
    density="comfortable"
    :class="showBadge ? 'mr-2' : ''"
    @click="$emit('toggle')"
  >
    <v-badge
      :model-value="showBadge"
      :content="activeFilters"
      :offset-x="-2"
      :offset-y="16"
      bordered
      color="primary"
    >
      <v-icon size="32" :icon="mdiFilterOutline" />
    </v-badge>
  </v-btn>
</template>

<script lang="ts">
import { mdiFilterOutline } from '@mdi/js'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'FilterButton',
  props: {
    activeFilters: {
      type: Number,
      required: true
    }
  },
  emits: ['toggle'],
  setup(props) {
    const showBadge = computed(() => props.activeFilters > 0)

    return {
      showBadge,
      mdiFilterOutline
    }
  }
})
</script>
