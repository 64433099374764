import { Event, EventContext, WebVitalsContext } from '@/modules/tracking/types'
import router from '@/router'
import { Metric } from 'web-vitals'

const pathRegex = new RegExp(
  '/products/|/collections/|/company/employees/|/cart/checkout/|/quotes/|/cto/modelconfiguration/|/cto/modelgroup/|/login/registration/|/login/reset/'
)

export function getIframeUrl() {
  if (window.self !== window.top) {
    return document.referrer
  } else {
    return undefined
  }
}

const createEventContext = (event: Event): EventContext => {
  return {
    event,
    path: router.currentRoute.value.path,
    userAgent: window.navigator.userAgent,
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    iframeUrl: getIframeUrl()
  }
}

const createWebVitalsContext = (metric: Metric): WebVitalsContext => {
  return {
    webVitalsMetric: metric,
    path: removeSpecificSubPageFromPath(router.currentRoute.value.path),
    userAgent: window.navigator.userAgent,
    screenHeight: window.screen.height,
    screenWidth: window.screen.width
  }
}

const removeSpecificSubPageFromPath = (path: string): string => {
  // Extra case for quote checkout
  let matchResult = /\/quotes\/.+\/checkout/.exec(path)
  if (matchResult?.index !== undefined) {
    for (let i = matchResult.index + 1; i < path.length; i++) {
      if (path[i] === '/') {
        const lastSlashIndex = path.lastIndexOf('/')
        return path.slice(0, i + 1) + path.slice(lastSlashIndex + 1, path.length)
      }
    }
  }

  // Extra case for orders
  matchResult = /\/orders\/.+\//.exec(path)
  if (matchResult?.index !== undefined) {
    for (let i = matchResult.index + 1; i < path.length; i++) {
      if (path[i] === '/') {
        const lastSlashIndex = path.lastIndexOf('/')
        return path.slice(0, i + 1) + path.slice(lastSlashIndex + 1, path.length)
      }
    }
  }

  matchResult = pathRegex.exec(path)
  if (matchResult?.index !== undefined) {
    for (let i = matchResult.index + 1; i < path.length; i++) {
      if (path[i] === '/') {
        // This is done so that in the case of /products/category/SET195 the regex only checks for /products/ but cuts the path to be /products/category/.
        // But in the case of /products/ the same regex should find it but not cut the path at all.
        const lastSlashIndex = path.lastIndexOf('/')
        if (lastSlashIndex > i) {
          i = lastSlashIndex
        }

        return path.slice(0, i + 1)
      }
    }
  }
  return path
}

export { createEventContext, createWebVitalsContext }
