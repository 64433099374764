import { post, Response } from '@/api'
import { EventContext, WebVitalsContext } from '@/modules/tracking/types'

export default {
  trackUserInteraction: (
    eventContext: EventContext,
    isGuest = false
  ): Promise<Response<string>> => {
    return post<string, EventContext>((isGuest ? '/guest' : '') + '/user-experience', eventContext)
  },
  trackWebVitals: (webVitalsContext: WebVitalsContext): Promise<Response<string>> => {
    return post<string, WebVitalsContext>('/web-vitals', webVitalsContext)
  }
}
