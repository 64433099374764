<template>
  <v-breadcrumbs class="pl-0" density="compact" :items="breadCrumbsItems">
    <template #divider>
      <v-icon color="grey" :size="20" :icon="mdiChevronRight" />
    </template>
  </v-breadcrumbs>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { Category } from '@/generatedTypes'
import { useRoute } from 'vue-router'
import { mdiChevronRight } from '@mdi/js'

export default defineComponent({
  name: 'CategoryBreadcrumbs',
  props: {
    openCategories: {
      type: Array as PropType<Category[]>,
      required: true
    }
  },
  setup(props) {
    const currentRoute = useRoute()

    const breadCrumbsItems = computed(() =>
      props.openCategories
        .slice()
        .reverse()
        .map((category) => ({
          title: category.name,
          disabled: !(category.isSelectable && currentRoute.params.id !== category.searchTerm),
          to: {
            name: 'Category',
            params: {
              id: category.searchTerm
            }
          }
        }))
    )

    return {
      breadCrumbsItems,
      mdiChevronRight
    }
  }
})
</script>

<style lang="scss">
:not(.v-breadcrumbs-item--disabled) > .v-breadcrumbs-item--link {
  color: rgb(var(--v-theme-primary));
  text-decoration: none !important;
}
</style>
