import { handleApiError } from '@/handleApiError'
import api from '@/modules/product/api'
import { defineStore } from 'pinia'
import { MediumProduct } from '@/generatedTypes'

interface InstantProductSearchState {
  items: MediumProduct[]
  loading: boolean
}

const initialState = (): InstantProductSearchState => {
  return {
    items: [],
    loading: false
  }
}

const useInstantProductSearch = defineStore('instantProductSearch', {
  state: () => initialState(),
  actions: {
    async instantSearch(searchTerm: string) {
      this.loading = true
      return api
        .searchProducts({ keyword: searchTerm, instantSearch: true, size: 5 })
        .then(({ data }) => {
          this.items = data.items
        })
        .catch(handleApiError)
        .finally(() => {
          this.loading = false
        })
    },
    clearItems() {
      this.items = []
    }
  }
})

export default useInstantProductSearch
