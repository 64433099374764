import { DetailedShopCollection, ShortUser } from '@/generatedTypes'
import { ApiError, handleApiError } from '@/handleApiError'
import useCartCount from '@/modules/cart/useCartCount'
import api from '@/modules/collection/api'
import helpers from '@/modules/message/helpers'
import { AddToCartSuccess } from '@/modules/order/types'
import useTopi from '@/modules/topi/useTopi'
import router from '@/router'
import { defineStore } from 'pinia'

interface CollectionState {
  collection: DetailedShopCollection | undefined
  loading: boolean
}

const initialState = (): CollectionState => {
  return {
    collection: undefined,
    loading: false
  }
}

const REMOVE_ITEM = (state: CollectionState, id: number) => {
  if (state.collection) {
    const index = state.collection.items.findIndex((item) => {
      return item.productId === id
    })
    if (index >= 0) {
      state.collection.items.splice(index, 1)
    }
  }
}

const SET_QUANTITY = (state: CollectionState, id: number, quantity: number) => {
  if (state.collection) {
    const item = state.collection.items.find((el) => el.productId === id)
    if (item) {
      item.quantity = quantity
    }
  }
}

const SET_ARCHIVED = (state: CollectionState, archived: boolean) => {
  if (state.collection) {
    state.collection.isArchived = archived
  }
}

const UPDATE_COMMENT = (state: CollectionState, id: number, comment: string) => {
  if (state.collection) {
    const item = state.collection.items.find((el) => el.productId === id)
    if (item) {
      item.comment = comment
    }
  }
}

const useCollection = defineStore('collection', {
  state: () => initialState(),
  actions: {
    async getCollection(id: string, background = false) {
      this.loading = !background
      return api
        .getCollection(id)
        .then(({ data }) => {
          this.collection = data
          useTopi().setCartItems(data.items)
        })
        .catch((error: ApiError) => {
          handleApiError(
            error,
            {
              appearance: 'CUSTOM',
              customHandler: 'CategoryLayout'
            },
            {
              errorCode: 403,
              logError: false,
              customMessageKey: 'error.collectionNotFound'
            },
            {
              errorCode: 404,
              logError: false,
              customMessageKey: 'error.collectionNotFound'
            }
          )
        })
        .finally(() => (this.loading = false))
    },
    async updateComment(comment: string | undefined) {
      if (this.collection) {
        return api
          .updateCollection(this.collection.id, {
            description: comment
          })
          .catch((error: ApiError) => {
            handleApiError(error, {
              customMessageKey: 'common.error.updateComment'
            })
          })
      }
    },
    async updateName(name: string) {
      if (this.collection) {
        this.collection.name = name

        return api
          .updateCollection(this.collection.id, {
            name: name
          })
          .catch((error: ApiError) => {
            handleApiError(error, {
              customMessageKey: 'common.error.updateName'
            })
          })
      }
    },
    async updateAssignee(assignee: ShortUser) {
      if (this.collection) {
        this.collection.assignee = assignee

        return api
          .updateCollection(this.collection.id, {
            assignedUserId: assignee.id
          })
          .catch((error: ApiError) => {
            handleApiError(error, {
              customMessageKey: 'common.error.updateAssignee'
            })
          })
          .finally(() => {
            if (this.collection) {
              this.getCollection(this.collection.id, true)
            }
          })
      }
    },
    async updateItemComment(itemId: number, comment: string) {
      UPDATE_COMMENT(this.$state, itemId, comment)

      if (this.collection) {
        return api
          .updateCollectionItem(this.collection.id, itemId, {
            comment: comment
          })
          .catch((error: ApiError) => {
            handleApiError(error, {
              customMessageKey: 'common.error.changeQuantity'
            })
          })
      }
    },
    async setQuantity(itemId: number, qty: number) {
      SET_QUANTITY(this.$state, itemId, qty)

      if (this.collection) {
        return api
          .updateCollectionItem(this.collection.id, itemId, {
            quantity: qty
          })
          .catch((error: ApiError) => {
            handleApiError(error, {
              customMessageKey: 'common.error.updateComment'
            })
          })
      }
    },
    async removeCollectionItem(itemId: number) {
      REMOVE_ITEM(this.$state, itemId)

      if (this.collection) {
        return api
          .deleteCollectionItem(this.collection.id, itemId)
          .then(() => {
            helpers.reportSuccess('collection.success.itemRemoved')
          })
          .catch((error: ApiError) => {
            handleApiError(
              error,
              {
                customMessageKey: 'common.error.removeItem'
              },
              {
                errorCode: 409,
                logError: false,
                appearance: 'NONE'
              }
            )
          })
      }
    },
    async addCollectionToCart(id: string) {
      return api
        .addCollectionToCart(id)
        .then(({ data }) => {
          switch (data) {
            case AddToCartSuccess.SUCCESS:
              helpers.reportSuccess('collection.addedToCartSuccess')
              break
            case AddToCartSuccess.PARTIAL_SUCCESS:
              helpers.reportWarning('collection.addedToCartPartialSuccess')
              break
            case AddToCartSuccess.FAILURE:
              helpers.reportError('collection.addedToCartFailure')
              break
          }
        })
        .catch(handleApiError)
        .finally(() => {
          useCartCount().loadCartCount()
        })
    },
    async requestQuote(id: string) {
      return api
        .createQuoteRequest(id)
        .then(({ data }) => {
          helpers.reportSuccess('quotes.quoteRequested')
          router.push({ name: 'Quote', params: { id: data } })
        })
        .catch(handleApiError)
    },
    archiveCollection() {
      SET_ARCHIVED(this.$state, true)

      const collectionId = this.collection?.id
      if (collectionId) {
        return api
          .archiveCollection(collectionId)
          .catch(handleApiError)
          .finally(() => {
            this.getCollection(collectionId, true)
          })
      }
    },
    dearchiveCollection() {
      SET_ARCHIVED(this.$state, false)

      const collectionId = this.collection?.id
      if (collectionId) {
        return api
          .dearchiveCollection(collectionId)
          .catch(handleApiError)
          .finally(() => {
            this.getCollection(collectionId, true)
          })
      }
    },
    async duplicateCollection(id: string) {
      return api
        .duplicateCollection(id)
        .then(({ data }) => {
          router.push({ name: 'Collection', params: { id: data } })
        })
        .catch(handleApiError)
    }
  }
})

export default useCollection
