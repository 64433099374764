<template>
  <v-chip size="x-small" color="primary">
    {{ $t('quotes.new') }}
  </v-chip>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SfNewTag'
})
</script>
