import { handleApiError } from '@/handleApiError'
import helpers from '@/modules/message/helpers'
import api from '@/modules/order/api'
import { OrderRmaReturnPosition } from '@/modules/order/types'
import useOrder from '@/modules/order/useOrder'
import i18n from '@/plugins/i18n'
import { defineStore } from 'pinia'

interface OrderRmaState {
  returnPositions: OrderRmaReturnPosition[]
  returnReasons: string[]
  loading: boolean
  sending: boolean
}

const initialState = (): OrderRmaState => {
  return {
    returnPositions: [],
    returnReasons: [],
    loading: false,
    sending: false
  }
}

const useOrderRma = defineStore('orderRma', {
  state: () => initialState(),
  getters: {
    hasAllReturnPositionAReason: (state): boolean => {
      return state.returnPositions.find((position) => !position.reason) == undefined
    }
  },
  actions: {
    async getOrderRma(id: string) {
      this.loading = true

      return api
        .getOrderRma(id)
        .then(({ data }) => {
          this.returnPositions = data.availableReturnPositions.map((position, index) => {
            return { ...position, selected: false, quantity: 1, positionNumber: index }
          })
          this.returnReasons = data.returnReasons
        })
        .catch(handleApiError)
        .finally(() => {
          this.loading = false
        })
    },
    async requestOrderRma(id: string, comment: string) {
      this.sending = true

      return api
        .requestOrderRma(id, {
          comment,
          positions: this.returnPositions.map((position) => {
            return {
              comment: position.reason || '',
              orderLineItemId: position.orderLineItemId
            }
          })
        })
        .then(() => {
          helpers.reportSuccessMessage(i18n.global.t('rma.requested'))
          useOrder().getOrder(id, true)
        })
        .catch(handleApiError)
        .finally(() => {
          this.sending = false
        })
    },
    updateQuantity(id: string, quantity: number) {
      this.returnPositions[
        this.returnPositions.findIndex((position) => position.orderLineItemId === id)
      ].quantity = quantity
    },
    updateSelected(id: string, selected: boolean) {
      this.returnPositions[
        this.returnPositions.findIndex((position) => position.orderLineItemId === id)
      ].selected = selected
    },
    mapReturnPositions() {
      this.returnPositions = this.returnPositions.filter((position) => position.selected)

      this.returnPositions.forEach((position) => {
        if (position.quantity > 1) {
          for (let i = 1; i < position.quantity; i++) {
            this.returnPositions.push(position)
          }
        }
      })

      this.returnPositions = this.returnPositions.sort(
        (a, b) => a.positionNumber - b.positionNumber
      )

      this.returnPositions = this.returnPositions.map((position, index) => {
        return { ...position, positionNumber: index }
      })
    },
    updateReason(positionNumber: number, reason: string) {
      this.returnPositions[
        this.returnPositions.findIndex((position) => position.positionNumber === positionNumber)
      ].reason = reason
    }
  }
})

export default useOrderRma
