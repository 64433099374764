import CategoryLayout from '@/layouts/CategoryLayout.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import LoginLayout from '@/layouts/LoginLayout.vue'
import OauthLoginRedirect from '@/modules/authentication/components/OauthLoginRedirect.vue'
import SamlLoginRedirect from '@/modules/authentication/components/SamlLoginRedirect.vue'
import SamlLogoutRedirect from '@/modules/authentication/components/SamlLogoutRedirect.vue'
import OrderDetails from '@/modules/order/components/OrderDetails.vue'
import OrderDocuments from '@/modules/order/components/OrderDocuments.vue'
import useBranding from '@/modules/portalSettings/useBranding'
import CheckoutPage from '@/pages/CheckoutPage.vue'
import CollectionPage from '@/pages/CollectionPage.vue'
import CollectionsPage from '@/pages/CollectionsPage.vue'
import ErrorPage from '@/pages/ErrorPage.vue'
import LoginPage from '@/pages/LoginPage.vue'
import LogoutPage from '@/pages/LogoutPage.vue'
import OrderPage from '@/pages/OrderPage.vue'
import OrdersPage from '@/pages/OrdersPage.vue'
import ProductComparisonPage from '@/pages/ProductComparisonPage.vue'
import ProductPage from '@/pages/ProductPage.vue'
import ProductsPage from '@/pages/ProductsPage.vue'
import RegistrationPage from '@/pages/RegistrationPage.vue'
import ResetPasswordPage from '@/pages/ResetPasswordPage.vue'
import SelfRegistrationPage from '@/pages/SelfRegistrationPage.vue'
import ShoppingCartPage from '@/pages/ShoppingCartPage.vue'
import StartPagePage from '@/pages/StartPage.vue'
import UiComponentsPage from '@/pages/UiComponentsPage.vue'
import { RouteLocation, RouteLocationRaw, RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/red/registeruser/:token',
    redirect: {
      name: 'Registration'
    }
  },
  {
    path: '/red/changepassword/:token',
    redirect: {
      name: 'ResetPassword'
    }
  },
  {
    path: '/home/portal/*',
    redirect: {
      name: 'StartPage'
    }
  },
  {
    path: '/ctofinder/page/*',
    redirect: {
      name: 'CtoProductClass'
    }
  },
  {
    path: '/search/portal/:term/products*',
    redirect: (to: RouteLocation): RouteLocationRaw => {
      return { name: 'Search', query: { keyword: to.params.term } }
    }
  },
  {
    path: '/products/page/:id/*',
    redirect: {
      name: 'Product'
    }
  },
  {
    path: '/deals/portal/*/*,w=TRACING',
    redirect: (): RouteLocationRaw => {
      return { name: 'Orders', query: { view: 'GRID' } }
    }
  },
  {
    path: '/deals/portal/*',
    redirect: {
      name: 'ShoppingCart'
    }
  },
  {
    path: '/search/portal/-/orders*',
    redirect: {
      name: 'Orders'
    }
  },
  {
    path: '/deals/page/:id/*',
    redirect: {
      name: 'OrderDetails'
    }
  },
  {
    path: '/search/portal/-/collections*',
    redirect: {
      name: 'Collections'
    }
  },
  {
    path: '/collections/page/:id/*',
    redirect: {
      name: 'Collection'
    }
  },
  {
    path: '/search/portal/-/quotes*',
    redirect: {
      name: 'Quotes'
    }
  },
  {
    path: '/quotes/page/:id/*',
    redirect: {
      name: 'Quote'
    }
  },
  {
    path: '/companies/page/*/tab-employees',
    redirect: {
      name: 'CompanyEmployees'
    }
  },
  {
    path: '/companies/page/*/tab-address',
    redirect: {
      name: 'CompanyAddresses'
    }
  },
  {
    path: '/companies/page/*/tab-orderlimits',
    redirect: {
      name: 'CompanyOrderLimit'
    }
  },
  {
    path: '/logout',
    component: LogoutPage
  },
  {
    path: '/login',
    component: LoginLayout,
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: '',
        name: 'Login',
        component: LoginPage
      },
      {
        path: 'reset/:token',
        name: 'ResetPassword',
        component: ResetPasswordPage
      },
      {
        path: 'registration/:token',
        name: 'Registration',
        component: RegistrationPage
      },
      {
        path: 'selfregistration/:step?',
        name: 'SelfRegistration',
        component: SelfRegistrationPage,
        beforeEnter: () => {
          if (!useBranding().branding.value?.isUserRegistration) {
            return '/login'
          }
        }
      }
    ]
  },
  {
    path: '/',
    component: DefaultLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        component: CategoryLayout,
        children: [
          {
            path: '',
            name: 'StartPage',
            component: StartPagePage
          },
          {
            path: 'products',
            name: 'Products',
            redirect: '/products/category',
            children: [
              {
                path: 'category/:id?',
                name: 'Category',
                component: ProductsPage,
                props: () => ({ mode: 'CATEGORY' })
              },
              {
                path: 'search',
                name: 'Search',
                component: ProductsPage,
                props: () => ({ mode: 'SEARCH' })
              },
              {
                path: ':id',
                name: 'Product',
                component: ProductPage
              }
            ]
          },
          {
            path: 'products/compare',
            name: 'ProductComparison',
            component: ProductComparisonPage
          },
          {
            path: 'cart',
            name: 'ShoppingCart',
            component: ShoppingCartPage
          },
          {
            path: 'collections',
            name: 'Collections',
            component: CollectionsPage
          },
          {
            path: 'collections/:id',
            name: 'Collection',
            component: CollectionPage
          },
          {
            path: 'orders',
            name: 'Orders',
            component: OrdersPage
          },
          {
            path: 'orders/topi',
            redirect: (to: RouteLocation): RouteLocationRaw => {
              return { path: 'orders/' + to.query.seller_offer_reference, query: {} }
            }
          },
          {
            path: 'orders/:id',
            component: OrderPage,
            children: [
              {
                path: '',
                name: 'OrderDetails',
                component: OrderDetails
              },
              {
                path: 'documents',
                name: 'OrderDocuments',
                component: OrderDocuments
              }
            ]
          }
        ]
      },
      {
        path: '/cart/checkout/:id?',
        name: 'Checkout',
        component: CheckoutPage
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'Error',
        component: ErrorPage
      }
    ]
  }
]

export const getRoutes = () => {
  if (import.meta.env.DEV) {
    routes.splice(0, 0, {
      path: '/ui-components',
      component: UiComponentsPage
    })

    routes.splice(
      18,
      0,
      {
        path: '/oauth2/*',
        component: OauthLoginRedirect
      },
      {
        path: '/saml/logout',
        component: SamlLogoutRedirect
      },
      {
        path: '/saml/login',
        component: SamlLoginRedirect
      }
    )
  }

  return routes
}
