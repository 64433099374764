import { ShopAddress } from '@/generatedTypes'
import api from '@/modules/address/api'
import { ApiError, handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import helpers from '@/modules/message/helpers'

interface AddressState {
  addresses: ShopAddress[]
  addressesFiltered: ShopAddress[]
  loading: boolean
}

const initialState = (): AddressState => {
  return {
    addresses: [],
    loading: false,
    addressesFiltered: []
  }
}
const UPDATE_ADDRESS = (state: AddressState, updatedAddress: ShopAddress) => {
  const updateId = updatedAddress.id
  const index = state.addresses.findIndex((item) => item.id === updateId)
  state.addresses = [
    ...state.addresses.slice(0, index),
    updatedAddress,
    ...state.addresses.slice(index + 1)
  ]
}

const DELETE_ADDRESS = (state: AddressState, id: string) => {
  state.addresses.splice(
    state.addresses.findIndex((item) => {
      return item.id === id
    }),
    1
  )
}
const ADD_ADDRESS = (state: AddressState, address: ShopAddress) => {
  state.addresses.push(address)
}

function customFilter(item: ShopAddress, queryText: string) {
  return (
    item.companyName?.toLowerCase().includes(queryText.toLowerCase()) ||
    item.zipCode?.toLowerCase().includes(queryText.toLowerCase()) ||
    item.city?.toLowerCase().includes(queryText.toLowerCase()) ||
    item.street?.toLowerCase().includes(queryText.toLowerCase())
  )
}

const useAddress = defineStore('address', {
  state: () => initialState(),
  actions: {
    searchAddresses(keyword: string, sourceAddresses?: ShopAddress[]) {
      this.addressesFiltered = sourceAddresses
        ? sourceAddresses.filter((address) => customFilter(address, keyword))
        : this.addresses.filter((address) => customFilter(address, keyword))
    },
    resetSearch() {
      this.addressesFiltered = this.addresses
    },
    async getAddresses(background = false) {
      this.loading = !background

      return api
        .getAddresses()
        .then(({ data }) => {
          this.addresses = data
          this.addressesFiltered = data
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            appearance: 'CUSTOM',
            customHandler: 'Addresses',
            customMessageKey: 'error.addressesNotFound'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async createAddress(address: ShopAddress) {
      ADD_ADDRESS(this, address)

      return api
        .createAddress(address)
        .then(({ data }) => {
          address.id = data.id
          helpers.reportSuccess('success.addressCreated')
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            customMessageKey: 'error.addressCreated'
          })
        })
        .finally(() => this.getAddresses(true))
    },
    async updateAddress(address: ShopAddress) {
      UPDATE_ADDRESS(this, address)

      return api
        .updateAddress(address)
        .then(() => {
          helpers.reportSuccess('success.addressSaved')
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            customMessageKey: 'error.addressSaved'
          })
        })
        .finally(() => this.getAddresses(true))
    },
    async deleteAddress(id: string) {
      DELETE_ADDRESS(this, id)

      return api
        .deleteAddress(id)
        .then(() => {
          helpers.reportSuccess('success.addressDeleted')
          this.getAddresses()
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            customMessageKey: 'error.addressDeleted'
          })
        })
        .finally(() => this.getAddresses(true))
    }
  }
})

export default useAddress
