import { Department, PreferedProductSearchView, SettingPatch, Title, User } from '@/generatedTypes'
import { handleApiError } from '@/handleApiError'
import useCategories from '@/modules/categories/useCategories'
import useTopi from '@/modules/topi/useTopi'
import api from '@/modules/user/api'
import i18n, { isSupportedLocale } from '@/plugins/i18n'
import { FALLBACK_LOCALE, Language, Locale } from '@/types'
import * as Sentry from '@sentry/vue'
import axios from 'axios'
import { defineStore } from 'pinia'
import { Ref } from 'vue'

interface OwnUserState {
  ownUser: User
  loading: boolean
}

const initialState = (): OwnUserState => {
  return {
    ownUser: {
      id: '',
      title: Title.Unspecified,
      firstName: '',
      lastName: '',
      email: '',
      avatarUrl: '',
      alpha2language: Language.de,
      companyName: '',
      department: Department.Other,
      phone: '',
      phoneMobile: '',
      permission: {
        submitOrder: true,
        shopAdmin: false,
        changeSubsidiary: false,
        isApprover: false
      },
      setting: {
        preferedProductSearchView: PreferedProductSearchView.CARDS
      }
    },
    loading: false
  }
}

const useOwnUser = defineStore('ownUser', {
  state: () => initialState(),
  getters: {
    isOwnUser: (state) => {
      return (id: string) => id === state.ownUser.id
    },
    isAdmin: (state) => {
      return () => state.ownUser.permission.shopAdmin
    },
    isApprover: (state) => {
      return () => state.ownUser.permission.isApprover
    },
    getLocaleOrFallback: (state) => {
      return (): Locale => {
        if (isSupportedLocale(state.ownUser.alpha2language)) {
          return state.ownUser.alpha2language
        } else {
          return FALLBACK_LOCALE
        }
      }
    }
  },
  actions: {
    updateOwnUser(update: User) {
      if (this.isOwnUser(update.id)) {
        Object.assign(this.ownUser, update)
        ;(i18n.global.locale as unknown as Ref<string>).value = this.getLocaleOrFallback()
        axios.defaults.headers.common['Accept-Language'] = this.getLocaleOrFallback()
        useCategories().$reset()
        if (useTopi().isInitialized()) {
          useTopi().initTopi(this.getLocaleOrFallback())
        }
      }
    },
    async initUserSession() {
      return api
        .getOwnUser()
        .then(({ data }) => {
          this.ownUser = data
          ;(i18n.global.locale as unknown as Ref<string>).value = this.getLocaleOrFallback()
          axios.defaults.headers.common['Accept-Language'] = this.getLocaleOrFallback()
          Sentry.getCurrentScope().setUser({
            email: data.email
          })
        })
        .catch(handleApiError)
    },
    async updateUserSettings(dto: SettingPatch) {
      return api.updateUserSettings(this.ownUser.id, dto).catch(handleApiError)
    }
  }
})

export default useOwnUser
