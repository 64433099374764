<template>
  <v-row dense class="pw-rma-available-position">
    <v-col cols="auto" class="d-flex align-center">
      <v-checkbox v-model="selected" hide-details color="primary" />
    </v-col>
    <v-col cols="auto" class="d-flex align-center">
      <product-image
        :class="{ 'opacity-50': !selected }"
        :size="50"
        :url="returnPosition.productImageUrl"
      />
    </v-col>
    <v-col cols="" class="d-flex align-center">
      <sf-text class="two-lines" :class="{ 'text-disabled': !selected }">{{
        returnPosition.productTitle
      }}</sf-text>
    </v-col>
    <v-col cols="12" sm="auto" class="d-flex align-center justify-end justify-sm-start">
      <max-quantity-stepper
        v-model="quantity"
        :max-quantity="returnPosition.maxReturnQuantity"
        :disabled="!selected"
      />
    </v-col>
  </v-row>
  <v-divider class="d-sm-none my-1" />
</template>

<script setup lang="ts">
import MaxQuantityStepper from '@/components/MaxQuantityStepper.vue'
import ProductImage from '@/components/ProductImage.vue'
import SfText from '@/components/text/SfText.vue'
import { AvailableReturnPosition } from '@/generatedTypes'
import useOrderRma from '@/modules/order/useOrderRma'
import { PropType, ref, watch } from 'vue'

const props = defineProps({
  returnPosition: {
    type: Object as PropType<AvailableReturnPosition>,
    required: true
  }
})

const selected = ref(false)
const quantity = ref(1)

watch(selected, (newValue) => {
  useOrderRma().updateSelected(props.returnPosition.orderLineItemId, newValue)
})

watch(quantity, (newValue) => {
  useOrderRma().updateQuantity(props.returnPosition.orderLineItemId, newValue)
})
</script>
