const QuoteSorts = {
  DATE_ASC: 'DATE_ASC',
  DATE_DESC: 'DATE_DESC',
  LAST_MODIFIED: 'LAST_MODIFIED'
} as const
type QuoteSort = (typeof QuoteSorts)[keyof typeof QuoteSorts]

const DEFAULT_FILTER_TERM_QUOTES = 'BARNOTARCHIVED'

export { DEFAULT_FILTER_TERM_QUOTES, QuoteSort, QuoteSorts }
