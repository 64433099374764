<template>
  <instant-product-search-input @product-selected="addProductToQuoteRequest" />
</template>

<script lang="ts">
import useQuoteRequest from '@/modules/quote/useQuoteRequest'
import InstantProductSearchInput from '@/modules/search/components/InstantProductSearchInput.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'QuoteRequestAddProductInput',
  components: { InstantProductSearchInput },
  setup() {
    const addProductToQuoteRequest = (productId: number) => {
      useQuoteRequest().addLineItem({ productId: productId }, true)
    }

    return {
      addProductToQuoteRequest
    }
  }
})
</script>
