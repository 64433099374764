<template>
  <v-card id="pw-header" class="d-flex pa-2">
    <avatar-image :user="user" :size="100" class="mr-2" />

    <v-row id="pw-header-user-info" no-gutters>
      <v-col class="d-flex flex-column justify-center mr-1 mr-md-2">
        <sf-heading ref="name" class="mb-1">
          {{ getFullName(user) }}
        </sf-heading>
        <sf-text ref="department">
          {{ $t('departments.' + user.department) }}
        </sf-text>
        <sf-text ref="email">{{ user.email }}</sf-text>
      </v-col>
    </v-row>
    <template v-if="!$vuetify.display.xs">
      <user-change-password-dialog v-if="isOwnUser" />
      <user-delete-button v-if="!isHidden" @delete="deleteEmployee" />
    </template>
    <three-point-menu v-if="$vuetify.display.xs">
      <user-change-password-dialog v-if="isOwnUser" />
      <user-delete-button v-if="!isHidden" @delete="deleteEmployee" />
    </three-point-menu>
  </v-card>
</template>

<script lang="ts">
import AvatarImage from '@/components/AvatarImage.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import ThreePointMenu from '@/components/three-point-menu/ThreePointMenu.vue'
import { getFullName } from '@/helpers'
import { trackingForUser } from '@/modules/tracking/events/helper/userTrackingHelper'
import { trackEvent } from '@/modules/tracking/useTracking'
import router from '@/router'
import { computed, defineComponent, PropType } from 'vue'
import { User } from '@/generatedTypes'
import useOwnUser from '@/modules/user/useOwnUser'
import useUsers from '@/modules/user/useUsers'
import UserChangePasswordDialog from '@/modules/user/components/UserChangePasswordDialog.vue'
import UserDeleteButton from '@/modules/user/components/UserDeleteButton.vue'
import { storeToRefs } from 'pinia'

export default defineComponent({
  name: 'UserHeader',
  components: {
    AvatarImage,
    UserChangePasswordDialog,
    ThreePointMenu,
    UserDeleteButton,
    SfHeading,
    SfText
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    }
  },
  setup(props) {
    const { ownUser } = storeToRefs(useOwnUser())

    const isOwnUser = computed(() => {
      return props.user.id == ownUser.value.id
    })

    const isHidden = computed(() => {
      return !ownUser.value.permission.shopAdmin || props.user.id == ownUser.value.id
    })

    const deleteEmployee = () => {
      trackEvent(trackingForUser(props.user.id).deleteEmployee)
      useUsers().deleteUser(props.user.id)
      router.push({
        name: 'CompanyEmployees'
      })
    }

    return {
      isHidden,
      isOwnUser,
      deleteEmployee,
      getFullName
    }
  }
})
</script>
