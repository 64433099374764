<template>
  <v-text-field v-debounce="onDebounce" variant="underlined" v-bind="$attrs" color="primary" />
</template>

<script lang="ts">
import { defineComponent, useAttrs } from 'vue'

export default defineComponent({
  name: 'SfDebouncedTextField',
  inheritAttrs: false,
  emits: ['debounce'],
  setup(_, { emit }) {
    const attrs = useAttrs()
    const onDebounce = () => {
      if (!attrs.readonly) emit('debounce')
    }
    return {
      onDebounce
    }
  }
})
</script>
