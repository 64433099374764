import { PathsStorefrontCollectionsGetParametersQuerySort, ShopCollection } from '@/generatedTypes'

const DEFAULT_COLLECTION_COMMENT_LENGTH = 4096

type ProductCollectionRelation = 'including' | 'excluding'

type PagedCollections = {
  collections: ShopCollection[]
  page: number
  pageCount: number
}

export {
  DEFAULT_COLLECTION_COMMENT_LENGTH,
  PagedCollections,
  PathsStorefrontCollectionsGetParametersQuerySort as CollectionsSortOrder,
  ProductCollectionRelation
}
