<template>
  <div>
    <template v-if="getGroups.length > 0">
      <v-row v-for="group in getGroups" :key="group.name">
        <v-col v-if="getCategories(group)" cols="12" class="d-flex flex-row align-center">
          <sf-sub-heading class="font-weight-bold mr-2 pw-group-name">{{
            group.name
          }}</sf-sub-heading>
          <v-divider />
        </v-col>

        <v-col cols="12">
          <v-row v-for="(cat, index) in getCategories(group)" :key="cat" no-gutters>
            <v-col cols="2" align-self="center" class="pr-1 pw-category-name">{{ cat }}</v-col>
            <v-col cols="10" lg="9">
              <v-card
                :class="{
                  'rounded-t shadow-trl': index == 0,
                  'rounded-b shadow-brl': index == getCategories(group).length - 1,
                  'shadow-rl': index > 0 && index < getCategories(group).length - 1
                }"
                :color="(index + 1) % 2 == 0 ? 'grey-lighten-3' : 'white'"
                class="rounded-0"
                flat
              >
                <v-card-text>
                  <v-row class="flex-nowrap pw-category-row">
                    <template v-for="product in products" :key="product.productId">
                      <v-spacer />
                      <v-col cols="4" class="px-6">
                        <sf-color-text
                          v-if="hasHilightableDifferences(products, group.name, cat)"
                          class="font-weight-bold"
                          color="primary"
                        >
                          {{ getTechnicalDetail(product, group.name, cat) || '-' }}
                        </sf-color-text>
                        <sf-text v-else>
                          {{ getTechnicalDetail(product, group.name, cat) || '-' }}
                        </sf-text>
                      </v-col>
                    </template>
                    <v-spacer />
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <list-empty :icon="mdiSwapHorizontal" :message="$t('productComparison.noData')" />
    </template>
  </div>
</template>

<script lang="ts">
import SfColorText from '@/components/text/SfColorText.vue'
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { FullProduct, TechnicalDetail } from '@/generatedTypes'
import { ComparisonGroup } from '@/modules/product/useProductComparison'
import { PropType, computed, defineComponent } from 'vue'
import ListEmpty from '@/components/list/ListEmpty.vue'
import { mdiSwapHorizontal } from '@mdi/js'

export default defineComponent({
  name: 'ProductComparisonTable',
  components: {
    SfColorText,
    SfText,
    ListEmpty,
    SfSubHeading
  },
  props: {
    highlightDifferences: {
      type: Boolean,
      required: true
    },
    products: {
      type: Array as PropType<FullProduct[]>,
      required: true
    },
    groups: {
      type: Array as PropType<ComparisonGroup[]>,
      required: true
    }
  },
  setup(props) {
    const hasHilightableDifferences = (
      products: FullProduct[],
      groupName: string,
      category: string
    ) => {
      const technicalDetails = products.map((product) =>
        getTechnicalDetail(product, groupName, category)
      )

      return (
        props.highlightDifferences &&
        technicalDetails.length > 0 &&
        !technicalDetails.every((technicalDetail) => technicalDetail === technicalDetails[0])
      )
    }

    const getCategories = (group: ComparisonGroup) =>
      // only return categories that have values
      group.categories.filter((category) =>
        props.products.some((product) => getTechnicalDetail(product, group.name, category))
      )

    const getGroups = computed(() => props.groups.filter(getCategories))

    const getTechnicalDetail = (product: FullProduct, groupName: string, category: string) =>
      product.technicalDetails.find(
        (elem: TechnicalDetail) => elem.group == groupName && elem.name == category
      )?.value

    return {
      getCategories,
      getTechnicalDetail,
      hasHilightableDifferences,
      getGroups,
      mdiSwapHorizontal
    }
  }
})
</script>

<style scoped>
.shadow-trl {
  box-shadow:
    0px 3px 10px -2px rgba(85, 85, 85, 0.08),
    0px 2px 20px 0px rgba(85, 85, 85, 0.06),
    0px 1px 30px 0px rgba(85, 85, 85, 0.03) !important;
  clip-path: inset(-30px -30px 0px -30px);
}

.shadow-brl {
  box-shadow:
    0px 3px 10px -2px rgba(85, 85, 85, 0.08),
    0px 2px 20px 0px rgba(85, 85, 85, 0.06),
    0px 1px 30px 0px rgba(85, 85, 85, 0.03) !important;
  clip-path: inset(0px -30px -30px -30px);
}

.shadow-rl {
  box-shadow:
    0px 3px 10px -2px rgba(85, 85, 85, 0.08),
    0px 2px 20px 0px rgba(85, 85, 85, 0.06),
    0px 1px 30px 0px rgba(85, 85, 85, 0.03) !important;
  clip-path: inset(0px -30px 0px -30px);
}
</style>
