<template>
  <v-card height="100%">
    <v-card-item>
      <v-card-title>
        <sf-heading>{{ $t('collection.data') }}</sf-heading>
      </v-card-title>
    </v-card-item>
    <v-card-text class="d-flex flex-column">
      <sf-text v-if="collection.createdDate">
        {{ $t('created') }}:
        {{ formatDate(collection.createdDate) }}
      </sf-text>
      <sf-text v-if="collection.lastModifiedDate">
        {{ $t('changed') }}:
        {{ formatDate(collection.lastModifiedDate) }}
      </sf-text>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { DetailedShopCollection } from '@/generatedTypes'
import { formatDate } from '@/utils/timeFormatHelpers'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'CollectionDataCard',
  components: { SfText, SfHeading },
  props: {
    collection: {
      type: Object as PropType<DetailedShopCollection>,
      required: true
    }
  },
  setup() {
    return { formatDate }
  }
})
</script>
