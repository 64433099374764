<template>
  <v-card
    elevation="1"
    class="py-1 px-2 mb-1 border-sm border-opacity-0"
    :class="[{ 'border--primary': isSelected }]"
  >
    <v-hover v-slot="{ isHovering, props }">
      <v-row no-gutters style="min-height: 96px" v-bind="props">
        <!-- Image -->
        <v-col cols="auto" class="d-flex align-center mr-2">
          <product-image :url="product.imageUrl" :size="60" />
        </v-col>

        <!-- Title -->
        <v-col cols="" class="d-flex flex-column justify-center mr-3">
          <sf-text ref="product-title" class="text-truncate">
            {{ product.title }}
          </sf-text>
          <div class="d-flex align-center my-half">
            <sf-condition-chip
              v-if="product.condition && product.condition !== Condition.NEW"
              :condition="product.condition"
              class="mr-1"
            />
            <product-identifiers
              :article-number="product.articleNumber"
              :product-id="product.productId"
            />
          </div>
          <product-short-description
            class="text-body-2 two-lines"
            :description="product.description"
          />
        </v-col>

        <!-- Price -->
        <v-col cols="auto" align-self="center">
          <v-row no-gutters justify="end" style="min-height: 36px">
            <v-col v-if="!isSelected && !showAddToCart" v-show="isHovering" cols="auto">
              <div class="d-flex align-center mr-2">
                <comparison-button :product="product" @click.stop />
                <cart-button
                  v-if="!isRequest"
                  ref="hover-cart-btn"
                  is-icon
                  :product-id="product.productId"
                  :quantity="1"
                  :supplier-row-id="supplierRowId"
                  :on-click-event="addToCartEvent"
                  @click.stop
                />
              </div>
            </v-col>

            <v-col
              ref="product-price"
              cols="auto"
              class="d-flex flex-column justify-center align-end text-primary mr-2"
            >
              <template v-if="product.price">
                <product-price
                  :price="product.price.resellPrice"
                  :product-id="product.productId"
                  :loading-realtime-prices="loadingRealtimePrices"
                />
                <billing-period
                  v-if="product.price.subscriptionModel"
                  class="text-body-1"
                  :subscription-model="product.price.subscriptionModel"
                />
                <packaging-info
                  v-if="product.price.packagingModel"
                  class="text-body-1"
                  :packaging-model="product.price.packagingModel"
                />
              </template>
            </v-col>

            <v-col cols="auto" class="d-flex justify-end align-center">
              <v-icon
                v-if="product.stock"
                size="medium"
                :color="getStatusColor(product.stock.status)"
                :icon="mdiCircle"
              />
              <v-icon v-else size="large" color="grey" :icon="mdiCircle" />
            </v-col>

            <v-col v-if="showAddToCart" cols="auto" class="d-flex align-center ml-2">
              <cart-button
                v-if="product.price"
                ref="cart-button"
                is-icon
                :is-request="product.price.resellPrice.value <= 0"
                :product-id="product.productId"
                :quantity="1"
                :supplier-row-id="product.price.supplierRowId"
                :on-click-event="addToCartEvent"
              />
            </v-col>
          </v-row>

          <v-row
            v-if="!showAddToCart && !loadingRealtimePrices && canBeRented(product.price)"
            no-gutters
          >
            <v-col class="mt-1 d-flex justify-end">
              <x-topi-cart-rental-summary-label
                ref="topi"
                mode="product"
                :reference="product.productId"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-hover>
  </v-card>
</template>

<script setup lang="ts">
import BillingPeriod from '@/components/BillingPeriod.vue'
import CartButton from '@/components/button/CartButton.vue'
import SfConditionChip from '@/components/chips/SfConditionChip.vue'
import PackagingInfo from '@/components/PackagingInfo.vue'
import ProductImage from '@/components/ProductImage.vue'
import SfText from '@/components/text/SfText.vue'
import { Condition, MediumProduct } from '@/generatedTypes'
import ComparisonButton from '@/modules/product/components/ComparisonButton.vue'
import ProductIdentifiers from '@/modules/product/components/ProductIdentifiers.vue'
import ProductPrice from '@/modules/product/components/ProductPrice.vue'
import ProductShortDescription from '@/modules/product/components/ProductShortDescription.vue'
import { canBeRented, getStatusColor } from '@/modules/product/helpers'
import AddToCartButtonEvents from '@/modules/tracking/events/helper/addToCartButton'
import { mdiCircle } from '@mdi/js'
import { computed, PropType } from 'vue'

const componentProps = defineProps({
  product: {
    type: Object as PropType<MediumProduct>,
    required: true
  },
  isSelected: {
    type: Boolean,
    default: false
  },
  showAddToCart: {
    type: Boolean,
    default: false
  },
  loadingRealtimePrices: {
    type: Boolean,
    required: true
  },
  addToCartEvent: {
    type: Object as PropType<AddToCartButtonEvents>,
    default: undefined
  }
})
const supplierRowId = computed(() => {
  return componentProps.product.price?.supplierRowId || undefined
})

const isRequest = computed(() => {
  return componentProps.product.price && componentProps.product.price.resellPrice.value > 0
    ? false
    : true
})
</script>
