<template>
  <v-row dense>
    <v-col class="d-flex flex-column align-center justify-center ma-8">
      <v-icon class="text-medium-emphasis" size="x-large">{{ icon }}</v-icon>
      <span ref="message" class="text-body-1 font-weight-bold text-center text-medium-emphasis">
        {{ message }}
      </span>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ListEmpty',
  props: {
    message: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  }
})
</script>
