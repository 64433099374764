<template>
  <v-dialog v-model="dialog" :width="500" @keydown.esc="close" @click:outside="close">
    <template #activator="{ props }">
      <v-list-item v-bind="props" :prepend-icon="mdiAccountMultiplePlusOutline">
        <v-list-item-title>
          {{ $t('inviteColleagues') }}
        </v-list-item-title>
      </v-list-item>
    </template>
    <v-card>
      <v-card-item>
        <v-card-title>
          <sf-heading>{{ $t('inviteColleagues') }}</sf-heading>
        </v-card-title>
      </v-card-item>
      <v-card-text class="text-justify">
        <sf-text>{{ $t('invitationHint') }}</sf-text>
        <v-combobox
          ref="input"
          v-model="mails"
          class="mt-2"
          variant="outlined"
          density="compact"
          color="primary"
          chips
          multiple
          closable-chips
          :error="error !== ''"
          :error-messages="error"
          append-icon=""
          :delimiters="[';', ' ']"
          @update:model-value="validateInput"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <sf-text-button
          ref="send-button"
          :disabled="mails.length <= 0"
          :loading="loading"
          @click="sendInvites"
        >
          {{ $t('sendInvitation') }}
        </sf-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import helpers from '@/modules/message/helpers'
import ApplicationHeaderEvent from '@/modules/tracking/events/applicationHeader'
import { trackEvent } from '@/modules/tracking/useTracking'
import { getEmailDomain } from '@/modules/user/helpers'
import { EmailStatus } from '@/modules/user/types'
import useUserInvitation from '@/modules/user/useUserInvitation'
import { mdiAccountMultiplePlusOutline } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'InvitationDialog',
  components: { SfTextButton, SfText, SfHeading },
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { t } = useI18n()

    const dialog = ref(false)
    const mails = ref([])
    const error = ref('')
    const { loading } = storeToRefs(useUserInvitation())

    const sendInvites = () => {
      useUserInvitation()
        .sendInvites(mails.value)
        .then(() => {
          mails.value = []
          error.value = ''
          trackEvent(ApplicationHeaderEvent.InviteColleagues)
          emit('close')
        })
    }

    const validateInput = (input: string[]) => {
      const inputLength = input.length
      if (inputLength > 0) {
        const mail = input[inputLength - 1]

        useUserInvitation()
          .validateMail(mail, props.isAdmin)
          .then((data) => {
            switch (data) {
              case EmailStatus.VALID:
                error.value = ''
                break
              case EmailStatus.INVALID:
                mails.value.pop()
                error.value = t('error.invalidEmail').toString()
                break
              case EmailStatus.REGISTERED:
                mails.value.pop()
                error.value = t('error.alreadyRegistered', [mail]).toString()
                break
              case EmailStatus.INVITED:
                mails.value.pop()
                error.value = t('error.alreadyInvited', [mail]).toString()
                break
              case EmailStatus.BLACKLISTED:
                mails.value.pop()
                error.value = t('error.mailBlacklisted', [getEmailDomain(mail)]).toString()
                break
              default:
                mails.value.pop()
                helpers.reportError('error.genericError')
                break
            }
          })
          .catch(() => {
            mails.value.pop()
            helpers.reportError('error.genericError')
          })
      }
    }

    const close = () => {
      dialog.value = false
      emit('close')
    }

    return {
      dialog,
      loading,
      mails,
      error,
      close,
      validateInput,
      sendInvites,
      mdiAccountMultiplePlusOutline
    }
  }
})
</script>
