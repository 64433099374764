<template>
  <sf-debounced-textarea
    v-model="commentValue"
    class="pw-cart-row-comment"
    :label="label"
    :rows="1"
    auto-grow
    hide-details="auto"
    density="compact"
    variant="outlined"
    :rules="commentField.required ? [requiredRule] : []"
    :maxlength="maxLength"
    :readonly="!editable"
    autocomplete="off"
    :hint="commentValue.length >= maxLength ? $t('maxLength', [maxLength]) : ''"
    persistent-hint
    @debounce="sendComment"
  />
</template>

<script lang="ts">
import SfDebouncedTextarea from '@/components/input/SfDebouncedTextarea.vue'
import { CommentField, SellerComments } from '@/generatedTypes'
import { requiredRule } from '@/rules'
import { PropType, computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'CartRowComment',
  components: { SfDebouncedTextarea },
  props: {
    comment: {
      type: String,
      default: ''
    },
    commentField: {
      type: Object as PropType<CommentField>,
      required: true
    },
    fieldIndex: {
      type: String as PropType<keyof SellerComments>,
      default: ''
    },
    maxLength: {
      type: Number,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    isSellerComment: {
      type: Boolean,
      default: true
    }
  },
  emits: ['commentChanged'],
  setup(props, { emit }) {
    const { t } = useI18n()

    const commentValue = ref(props.comment)

    const sendComment = () => {
      emit('commentChanged', commentValue.value, props.fieldIndex)
    }

    const label = computed(() => {
      let index = ''
      if (props.fieldIndex) {
        const fieldRegex = /field([1-6]+)/g
        index += ' ' + fieldRegex.exec(props.fieldIndex)?.[1]
      }

      let label = props.isSellerComment
        ? t('common.sellerComment') + index
        : t('cartPositionComment')

      if (props.commentField.label) {
        label = props.commentField.label
      }

      if (props.commentField.required) {
        label += ' *'
      }

      return label
    })

    return {
      commentValue,
      sendComment,
      requiredRule,
      label
    }
  }
})
</script>
