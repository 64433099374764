<template>
  <v-row dense>
    <v-col class="d-flex flex-column ga-1">
      <template v-for="cartMessage in cartMessages">
        <sf-alert-error v-if="cartMessage.type == CartMessageType.ERROR" :key="cartMessage.message">
          {{ cartMessage.message }}
        </sf-alert-error>
        <sf-alert-warning
          v-if="cartMessage.type == CartMessageType.WARNING"
          :key="cartMessage.message"
        >
          {{ cartMessage.message }}</sf-alert-warning
        >
        <sf-alert-info v-if="cartMessage.type == CartMessageType.INFO" :key="cartMessage.message">
          {{ cartMessage.message }}</sf-alert-info
        >
      </template>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import SfAlertError from '@/components/alerts/SfAlertError.vue'
import SfAlertWarning from '@/components/alerts/SfAlertWarning.vue'
import SfAlertInfo from '@/components/alerts/SfAlertInfo.vue'
import { CartMessage, CartMessageType } from '@/generatedTypes'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'CartMessages',
  components: { SfAlertWarning, SfAlertError, SfAlertInfo },
  props: {
    cartMessages: {
      type: Array as PropType<CartMessage[]>,
      required: true
    }
  },
  setup() {
    return {
      CartMessageType
    }
  }
})
</script>
