<template>
  <v-row
    ref="element"
    dense
    :style="
      !$vuetify.display.mobile ? 'max-height: calc(100vh - ' + (offset + extraOffset) + 'px)' : ''
    "
    :class="[{ 'overflow-y-auto scrollbar': !$vuetify.display.mobile }]"
  >
    <slot />
  </v-row>
</template>

<script lang="ts">
import { containerHeight } from '@/utils/containerHeightHelper'
import { ComponentPublicInstance, defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ListContent',
  props: {
    extraOffset: {
      type: Number,
      default: 0
    }
  },
  setup() {
    const element = ref<ComponentPublicInstance<HTMLElement>>()
    const { offset } = containerHeight(element)

    return {
      offset,
      element
    }
  }
})
</script>
