import { readonly, Ref, ref } from 'vue'
import axios from 'axios'
import { Version } from '@/modules/version/types'

export interface VersionState {
  currentHash: string
  hashChanged: boolean
  newHash: string
}

const state = ref<VersionState>({
  currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
  hashChanged: false,
  newHash: ''
})

type ReturnType = {
  state: Readonly<Ref<VersionState>>
  checkVersion: () => Promise<void>
}

export default function useVersion(): ReturnType {
  const checkVersion = async () => {
    return axios
      .create({
        baseURL: window.location.origin,
        headers: {
          'Content-type': 'application/json'
        }
      })
      .get<Version>('/version.json' + '?t=' + new Date().getTime())
      .then(({ data }) => {
        if (state.value.currentHash.startsWith('legacy-')) {
          state.value.newHash = data.legacyHash
        } else {
          state.value.newHash = data.hash
        }

        state.value.hashChanged = state.value.currentHash !== state.value.newHash
      })
      .catch(() => {
        return
      })
  }

  return {
    state: readonly(state),
    checkVersion
  }
}
