import i18n from '@/plugins/i18n'
import useMessaging from '@/modules/message/useMessaging'

function reportError(messageKey: string, args: string[] = []): void {
  useMessaging().addError(i18n.global.t(messageKey, args).toString())
}

function reportErrorMessage(message: string): void {
  useMessaging().addError(message)
}

function reportRefreshError(message: string): void {
  useMessaging().addError(message, undefined, true)
}

function reportSuccess(messageKey: string, args: string[] = [], link?: string): void {
  useMessaging().addSuccess(i18n.global.t(messageKey, args).toString(), link)
}

function reportSuccessMessage(message: string, link?: string): void {
  useMessaging().addSuccess(message, link)
}

function reportWarning(messageKey: string, args: string[] = []): void {
  useMessaging().addWarning(i18n.global.t(messageKey, args).toString())
}

export default {
  reportError,
  reportErrorMessage,
  reportSuccess,
  reportSuccessMessage,
  reportRefreshError,
  reportWarning
}
