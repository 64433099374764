<template>
  <span class="text-h5 text-high-emphasis">
    <slot />
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SfTitle'
})
</script>
