import { ApiError, handleApiError } from '@/handleApiError'
import api from '@/modules/shortUrl/api'
import { ResolveRequirement } from '@/modules/shortUrl/types'
import { defineStore } from 'pinia'

interface ShortUrlState {
  loading: boolean
  resolving: boolean
  requirement?: ResolveRequirement
  error: boolean
}

const initialState = (): ShortUrlState => {
  return {
    requirement: undefined,
    loading: false,
    resolving: false,
    error: false
  }
}

const useShortUrl = defineStore('shortUrl', {
  state: () => initialState(),
  actions: {
    async checkRequirements(url: string) {
      this.loading = true

      return api
        .checkRequirements({ value: url })
        .then(({ data }) => {
          if (data == ResolveRequirement.NO_REQUIREMENTS) {
            this.resolveUrl(url)
          } else {
            this.requirement = data
          }
        })
        .catch(handleApiError)
        .finally(() => {
          this.loading = false
        })
    },
    async resolveUrl(url: string, secret?: string) {
      this.resolving = true

      return api
        .resolveUrl({ secret, shortUrl: { value: url } })
        .then(({ data }) => {
          window.location.href = data
        })
        .catch((error: ApiError) => {
          if (error.code == 401) {
            this.error = true
          } else if (error.code == 404) {
            this.requirement = ResolveRequirement.NO_SHORT_FORM
          } else {
            handleApiError(error)
          }
        })
        .finally(() => {
          this.resolving = false
        })
    }
  }
})

export default useShortUrl
