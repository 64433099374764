import { ApiError, handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import { OrderStatus } from '@/generatedTypes'
import { OrderPreviewColumns, OrderPreviewColumnsKey, OrderSortOrder } from '@/modules/order/types'
import api from '@/modules/order/api'
import { errorStatuses } from '@/modules/order/helpers'

const PAGE_SIZE = 20

interface OrderPreviewsState {
  columns: OrderPreviewColumns
}

const initialState = (): OrderPreviewsState => {
  return {
    columns: {
      ARRIVED: {
        orderStatus: OrderStatus.ARRIVED,
        orderPreviews: [],
        count: 0,
        pages: 0,
        loading: false,
        loadingNextPage: false
      },
      PROCESSING: {
        orderStatus: OrderStatus.PROCESSING,
        orderPreviews: [],
        count: 0,
        pages: 0,
        loading: false,
        loadingNextPage: false
      },
      ADVISED: {
        orderStatus: OrderStatus.ADVISED,
        orderPreviews: [],
        count: 0,
        pages: 0,
        loading: false,
        loadingNextPage: false
      },
      INVOICED: {
        orderStatus: OrderStatus.INVOICED,
        orderPreviews: [],
        count: 0,
        pages: 0,
        loading: false,
        loadingNextPage: false
      },
      ERROR: {
        orderStatus: OrderStatus.ERROR,
        orderPreviews: [],
        count: 0,
        pages: 0,
        loading: false,
        loadingNextPage: false
      }
    }
  }
}

const useOrderPreviews = defineStore('orderPreviews', {
  state: () => initialState(),
  getters: {
    loadingNextPage: (state) => {
      return Object.values(state.columns).some((column) => column.loadingNextPage)
    }
  },
  actions: {
    async getOrderPreviews(status: OrderPreviewColumnsKey, page: number) {
      const column = this.columns[status]

      column.loading = true
      const statuses = status !== OrderStatus.ERROR ? [status as OrderStatus] : errorStatuses

      return api
        .getOrderPreviews(page, PAGE_SIZE, OrderSortOrder.LAST_MODIFIED, statuses)
        .then(({ data }) => {
          column.orderPreviews = data.items
          column.count = data.totalCount
          column.pages = data.pageCount
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            appearance: 'CUSTOM',
            customHandler: 'OrdersGrid',
            customMessageKey: 'error.ordersNotFound'
          })
        })
        .finally(() => {
          column.loading = false
        })
    },
    async getAdditionalPage(status: OrderPreviewColumnsKey, page: number) {
      const column = this.columns[status]

      if (column.pages >= page) {
        column.loadingNextPage = true
        const statuses = status !== OrderStatus.ERROR ? [status as OrderStatus] : errorStatuses

        return api
          .getOrderPreviews(page, PAGE_SIZE, OrderSortOrder.LAST_MODIFIED, statuses)
          .then(({ data }) => {
            column.orderPreviews.push(...data.items)
            column.count = data.totalCount
            column.pages = data.pageCount
          })
          .catch((error: ApiError) => {
            handleApiError(error, {
              appearance: 'CUSTOM',
              customHandler: 'OrdersGrid',
              customMessageKey: 'error.ordersNotFound'
            })
          })
          .finally(() => {
            column.loadingNextPage = false
          })
      }
    }
  }
})

export default useOrderPreviews
