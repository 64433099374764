<template>
  <v-card
    :ripple="user.userRegistered"
    :class="[{ 'cursor-pointer': user.userRegistered }, 'd-flex align-center px-2 py-2']"
    :to="user.userRegistered ? { name: 'CompanyEmployee', params: { id: user.id } } : ''"
  >
    <avatar-image :user="user" :size="40" class="mr-2" />
    <sf-text ref="name">{{ displayName }}</sf-text>
  </v-card>
</template>

<script lang="ts">
import AvatarImage from '@/components/AvatarImage.vue'
import SfText from '@/components/text/SfText.vue'
import { ShortUser } from '@/generatedTypes'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'UserCard',
  components: { AvatarImage, SfText },
  props: {
    user: {
      type: Object as PropType<ShortUser>,
      required: true
    }
  },
  setup(props) {
    const displayName = computed(() => {
      if (props.user.firstName && props.user.lastName) {
        return [props.user.firstName, props.user.lastName].join(' ')
      } else {
        return props.user.email
      }
    })

    return { displayName }
  }
})
</script>
