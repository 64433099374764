<template>
  <v-main>
    <v-container fluid class="py-15">
      <v-card class="pa-3 mx-auto" max-width="500">
        <v-card-title class="d-flex align-center">
          <v-divider class="my-1" />
          <div class="mx-2">{{ $t('serverOffline.title') }}</div>
          <v-divider class="my-1" />
        </v-card-title>
        <v-card-text class="text-center">
          <v-img class="mx-auto mb-1" :src="getImageUrl()" max-width="500" />
          <sf-text>{{ $t('serverOffline.description') }}</sf-text>
        </v-card-text>
      </v-card>
    </v-container>
  </v-main>
</template>

<script lang="ts">
import SfText from '@/components/text/SfText.vue'
import { useTitle } from '@/helpers'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ServerOfllinePage',
  components: { SfText },
  setup() {
    const { t } = useI18n()
    useTitle(ref(t('serverOffline.title')))
    const getImageUrl = () => {
      return new URL('@/assets/images/paused.png', import.meta.url).href
    }
    return { getImageUrl }
  }
})
</script>
