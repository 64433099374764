import useAuthentication from '@/modules/authentication/useAuthentication'
import { EventSourcePlus, EventSourcePlusOptions } from 'event-source-plus'

function getStream(path: string): EventSourcePlus {
  const config: EventSourcePlusOptions = {}
  const userStore = useAuthentication()
  if (path.startsWith('/guest')) {
    config.headers = { Authorization: 'Bearer ' + userStore.state.value.guestToken }
  } else if (userStore.hasValidAccessToken()) {
    config.headers = { Authorization: 'Bearer ' + userStore.state.value.accessToken }
  }
  if (userStore.isRedslave()) {
    config.headers = { ...config.headers, redslave: 'true' }
  }

  const eventSource = new EventSourcePlus(
    import.meta.env.VITE_STOREFRONT_API_BASE_URL + path,
    config
  )

  return eventSource
}

export default { getStream }
