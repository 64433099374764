<template>
  <feature-ad-box>
    <feature-ad-header @close="hideFeatureAd" />
    <v-spacer />
    <sf-text>
      {{ $t('featureAd.comparison.shortDescription') }}
    </sf-text>
    <v-spacer />
    <sf-button block variant="outlined" color="#43C59E" class="mt-1" @click="goToPage">{{
      $t('featureAd.tryItOut')
    }}</sf-button>
  </feature-ad-box>
</template>

<script lang="ts">
import SfButton from '@/components/button/SfButton.vue'
import SfText from '@/components/text/SfText.vue'
import useFeatureAd from '@/modules/featureAd/useFeatureAd'
import FeatureAdEvent from '@/modules/tracking/events/featureAd'
import { trackEvent } from '@/modules/tracking/useTracking'
import router from '@/router'
import { defineComponent } from 'vue'
import FeatureAdBox from '@/modules/featureAd/components/FeatureAdBox.vue'
import FeatureAdHeader from '@/modules/featureAd/components/FeatureAdHeader.vue'

export default defineComponent({
  name: 'FeatureAdStart',
  components: {
    SfButton,
    SfText,
    FeatureAdBox,
    FeatureAdHeader
  },
  setup() {
    const hideFeatureAd = () => {
      trackEvent(FeatureAdEvent.HideStart())
      useFeatureAd().hideFeatureAd()
    }

    const goToPage = () => {
      trackEvent(FeatureAdEvent.CallToAction())
      router.push({ name: 'ProductComparison' })
    }

    return {
      hideFeatureAd,
      goToPage
    }
  }
})
</script>
