import { Event } from '@/modules/tracking/types'
import AddToCartButtonEvents from '@/modules/tracking/events/helper/addToCartButton'

export default class CategoryEvent extends Event {
  static readonly PageView = (categoryTitle: string): CategoryEvent =>
    new CategoryEvent('Seite angesehen', categoryTitle)
  static readonly AddToCart: AddToCartButtonEvents = {
    addToCart: new CategoryEvent('Produkt zum Warenkorb hinzugefügt'),
    request: new CategoryEvent('Produkt angefragt')
  }
  static readonly Sort = (sortName: string): CategoryEvent =>
    new CategoryEvent('Sortiert', sortName)

  constructor(action: string, label?: string) {
    super('Kategorien', action, label ?? '')
  }
}
