<template>
  <div class="text-center mt-2 text-body-2 text-primary">
    <div class="d-inline cursor-pointer" @click="toLogin">
      {{ $t('login') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import router from '@/router'

const toLogin = () => {
  router.replace('/login')
}
</script>
