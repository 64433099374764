import { OrderLimitType, OrderLimitUserPatch, ShortUser } from '@/generatedTypes'
import useOrderLimits from '@/modules/orderLimits/useOrderLimits'
import MyCompanyEvent from '@/modules/tracking/events/MyCompany'
import { trackEvent } from '@/modules/tracking/useTracking'

export function trackOrderLimitDialogue(
  userId: string,
  orderLimitUserPatch: OrderLimitUserPatch
): void {
  const orderLimitUser = useOrderLimits().getOrderLimitUser(userId)
  if (orderLimitUser) {
    const previousApprovers = orderLimitUser.approvers.map((approver: ShortUser) => approver.id)
    const patchedApprovers = orderLimitUserPatch.approverIds

    if (!equal(previousApprovers, patchedApprovers)) {
      trackEvent(MyCompanyEvent.OrderLimit.Individual.Assignees)
    }
    const previousSubmitRight = orderLimitUser.submitOrder

    if (orderLimitUserPatch.submitOrder !== previousSubmitRight) {
      trackEvent(MyCompanyEvent.OrderLimit.Individual.OrderRight)
    }
    if (
      orderLimitUserPatch.orderLimitType !== orderLimitUser.orderLimitType ||
      (orderLimitUser.orderLimitType === OrderLimitType.USER &&
        orderLimitUser.userOrderLimit !== orderLimitUserPatch.userOrderLimit)
    ) {
      trackEvent(MyCompanyEvent.OrderLimit.Individual.Limit)
    }
  }
}
const equal = (a?: Array<string>, b?: Array<string>): boolean => {
  if (!a || !b) {
    return false
  }
  return a.length === b.length && a.every((value: string, index: number) => b[index] === value)
}
