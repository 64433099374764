import { ApiError, handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import helpers from '@/modules/message/helpers'
import api from '@/modules/user/api'

interface UserInvitationState {
  loading: boolean
}

const initialState = (): UserInvitationState => {
  return {
    loading: false
  }
}

const useUserInvitation = defineStore('userInvitation', {
  state: () => initialState(),
  actions: {
    async validateMail(mail: string, isAdmin: boolean) {
      return api
        .validateMail(mail, isAdmin)
        .then(({ data }) => {
          return data
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            customMessageKey: 'error.invitation'
          })
        })
    },
    async sendInvites(mails: string[]) {
      this.loading = true

      return api
        .sendInvites(mails)
        .then(({ data }) => {
          if (data.invalidEmails?.length != 0) {
            helpers.reportError('error.invalidEmail')
          } else if (data.isAlreadyInvited?.length != 0) {
            helpers.reportError('error.alreadyInvited')
          } else if (data.isAlreadyRegistered?.length != 0) {
            helpers.reportError('error.alreadyRegistered')
          } else {
            helpers.reportSuccess('success.invitationSent')
          }
        })
        .catch((error: ApiError) => {
          handleApiError(
            error,
            {
              customMessageKey: 'error.invitation'
            },
            {
              errorCode: 403,
              logError: false,
              customMessageKey: 'error.noAdmin'
            }
          )
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
})

export default useUserInvitation
