import { defineStore } from 'pinia'

interface DebounceState {
  timerStarted: boolean
}

const initialState = (): DebounceState => {
  return {
    timerStarted: false
  }
}

const useDebounce = defineStore('debounce', {
  state: () => initialState()
})

export default useDebounce
