<template>
  <v-avatar :start="left" :size="size">
    <v-img v-if="user.avatarUrl" :src="fitImage(user.avatarUrl, doubleSize, doubleSize, '&')" />
    <vue-avatar v-else :username="getFullName(user)" :size="size" />
  </v-avatar>
</template>

<script lang="ts">
import { fitImage, getFullName } from '@/helpers'
import { ContactUser, ShortUser, User, QuoteActivityUser, OrderLimitUser } from '@/generatedTypes'
import { PropType, computed, defineComponent } from 'vue'
import VueAvatar from '@webzlodimir/vue-avatar'
import '@webzlodimir/vue-avatar/dist/style.css'

export default defineComponent({
  name: 'AvatarImage',
  components: { VueAvatar },
  props: {
    user: {
      type: Object as PropType<User | ShortUser | OrderLimitUser | ContactUser | QuoteActivityUser>,
      required: true
    },
    size: {
      type: Number,
      required: true
    },
    left: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const doubleSize = computed(() => {
      return props.size * 2
    })

    return {
      doubleSize,
      getFullName,
      fitImage
    }
  }
})
</script>
