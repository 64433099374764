<template>
  <div class="d-flex flex-column mb-1">
    <sf-text ref="title" class="two-lines">
      {{ item.productTitle }}
    </sf-text>
    <div class="d-flex justify-space-between text-body-1 text-primary">
      <span ref="quantity">{{ `${item.quantity} x` }}</span>
      <span v-if="item.price" ref="price">
        {{ formatMoney(item.price, true, true) }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import SfText from '@/components/text/SfText.vue'
import { defineComponent, PropType } from 'vue'
import { OrderItem } from '@/generatedTypes'
import { formatMoney } from '@/modules/product/helpers'

export default defineComponent({
  name: 'OrdersGridCardItem',
  components: { SfText },
  props: {
    item: {
      type: Object as PropType<OrderItem>,
      required: true
    }
  },
  setup() {
    return {
      formatMoney
    }
  }
})
</script>
