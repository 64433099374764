<template>
  <v-row justify="center" dense>
    <v-col>
      <v-row dense class="flex-row-reverse">
        <v-col cols="auto">
          <v-skeleton-loader type="button" />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-skeleton-loader class="mb-1" type="list-item" />
          <v-skeleton-loader class="mb-1" type="list-item" />
          <v-skeleton-loader class="mb-1" type="list-item" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OrderDocumentsLoading'
})
</script>
