<template>
  <v-row>
    <v-col>
      <expandable-group
        v-for="category in productDescription"
        :key="category.contentCategory"
        :title="category.contentCategory"
      >
        <div class="my-1 mx-2 text-justify content text-body-2" v-html="category.value" />
      </expandable-group>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import ExpandableGroup from '@/components/ExpandableGroup.vue'
import { defineComponent, PropType } from 'vue'
import { ProductDescription } from '@/modules/product/types'

export default defineComponent({
  name: 'ProductDescription',
  components: { ExpandableGroup },
  props: {
    productDescription: {
      type: Array as PropType<ProductDescription[]>,
      required: true
    }
  }
})
</script>

<style scoped>
.content :deep(img) {
  max-width: 100%;
}

.content :deep(ul) {
  padding-left: 20px;
  margin: 8px 0;
}
</style>
