import { ContactData } from '@/generatedTypes'

const createEmptyContactData = (): ContactData => {
  return {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    phoneMobile: ''
  }
}

export { createEmptyContactData }
