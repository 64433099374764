import CategoryLayout from '@/layouts/CategoryLayout.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import AddressesPage from '@/pages/AddressesPage.vue'
import CheckoutQuotePage from '@/pages/CheckoutQuotePage.vue'
import CompanyPage from '@/pages/CompanyPage.vue'
import CtoModelConfigurationPage from '@/pages/CtoModelConfigurationPage.vue'
import CtoModelGroupPage from '@/pages/CtoModelGroupPage.vue'
import CtoProductClassPage from '@/pages/CtoProductClassPage.vue'
import OrderLimitPage from '@/pages/OrderLimitPage.vue'
import PromotionalProductsPage from '@/pages/PromotionalProductsPage.vue'
import QuotePage from '@/pages/QuotePage.vue'
import QuotesPage from '@/pages/QuotesPage.vue'
import UserPage from '@/pages/UserPage.vue'
import UsersPage from '@/pages/UsersPage.vue'
import { RouteRecordRaw } from 'vue-router'

const getCtoFinderRoutes = (): RouteRecordRaw[] => {
  return [
    {
      path: '/',
      component: DefaultLayout,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          component: CategoryLayout,
          children: [
            {
              path: 'cto',
              name: 'CtoProductClass',
              component: CtoProductClassPage
            },
            {
              path: 'cto/modelgroup/:id',
              name: 'CtoModelGroup',
              component: CtoModelGroupPage
            },
            {
              path: 'cto/modelconfiguration/:id',
              name: 'CtoModelConfiguration',
              component: CtoModelConfigurationPage
            }
          ]
        }
      ]
    }
  ]
}

const getQuotesRoutes = (): RouteRecordRaw[] => {
  return [
    {
      path: '/',
      component: DefaultLayout,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          component: CategoryLayout,
          children: [
            {
              path: 'quotes',
              name: 'Quotes',
              component: QuotesPage
            },
            {
              path: 'quotes/:id',
              name: 'Quote',
              component: QuotePage
            }
          ]
        },
        {
          path: '/quotes/:id/checkout',
          name: 'CheckoutQuote',
          component: CheckoutQuotePage
        }
      ]
    }
  ]
}

const getPromotionalProductsRoutes = (): RouteRecordRaw[] => {
  return [
    {
      path: '/',
      component: DefaultLayout,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          component: CategoryLayout,
          children: [
            {
              path: 'promotional-products',
              name: 'PromotionalProducts',
              component: PromotionalProductsPage
            }
          ]
        }
      ]
    }
  ]
}

const getCompanyRoutes = (): RouteRecordRaw[] => {
  return [
    {
      path: '/',
      component: DefaultLayout,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'company',
          name: 'Company',
          redirect: '/company/employees',
          component: CompanyPage,
          children: [
            {
              path: 'employees',
              name: 'CompanyEmployees',
              component: UsersPage
            },
            {
              path: 'employees/:id',
              name: 'CompanyEmployee',
              component: UserPage
            },
            {
              path: 'addresses',
              name: 'CompanyAddresses',
              component: AddressesPage
            },
            {
              path: 'order-limit',
              name: 'CompanyOrderLimit',
              component: OrderLimitPage
            }
          ]
        }
      ]
    }
  ]
}

export { getCtoFinderRoutes, getQuotesRoutes, getPromotionalProductsRoutes, getCompanyRoutes }
