<template>
  <v-dialog v-model="dialog" :width="500">
    <template #activator="{ props }">
      <sf-edit-button v-bind="props" />
    </template>
    <v-card>
      <v-card-item>
        <v-card-title>
          <sf-heading>{{ getFullName(user) }}</sf-heading>
        </v-card-title>
      </v-card-item>
      <v-card-text>
        <v-row>
          <v-col class="d-flex align-center" cols="12">
            <sf-sub-heading class="mr-2">{{ $t('orderLimits.orderRight') }}</sf-sub-heading>
            <v-switch
              v-model="orderLimitUserPatch.submitOrder"
              class="mt-0 pt-0"
              color="green"
              hide-details
              inset
            />
          </v-col>
        </v-row>
        <v-divider class="my-2" />
        <v-row no-gutters>
          <v-col class="mr-2" cols="12" sm="auto">
            <sf-sub-heading>{{ $t('orderLimits.orderLimit') }}</sf-sub-heading>
          </v-col>
          <v-col cols="12" sm="">
            <v-radio-group
              v-model="orderLimitUserPatch.orderLimitType"
              color="primary"
              class="mt-0"
              hide-details
              density="compact"
            >
              <v-radio :label="$t('orderLimits.defaultLimit')" :value="OrderLimitType.DEFAULT" />
              <v-radio :label="$t('orderLimits.unlimited')" :value="OrderLimitType.UNLIMITED" />
              <v-radio :value="OrderLimitType.USER" class="d-flex align-start">
                <template #label>
                  <div class="d-flex align-start">
                    <span class="mr-2">{{ $t('orderLimits.individual') }}</span>
                    <v-form v-model="valid" lazy-validation>
                      <sf-text-field
                        v-show="orderLimitUserPatch.orderLimitType === OrderLimitType.USER"
                        v-model.number="orderLimitUserPatch.userOrderLimit"
                        :rules="[requiredRule, positiveNumberRule]"
                        class="input-text-right"
                        hide-details="auto"
                        hide-spin-buttons
                        min="0"
                        suffix="€"
                        type="number"
                        autocomplete="off"
                        @keydown.enter.prevent="save"
                      />
                    </v-form>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-divider class="my-2" />
        <div>
          <sf-sub-heading>{{ $t('orderLimits.orderApprover') }}</sf-sub-heading>
          <order-limit-approvers-select
            v-model="orderLimitUserPatch.approverIds"
            :approvers="approvers"
          />
          <div class="mt-1">
            <sf-text v-if="orderLimitUserPatch.approverIds.length === 0">
              {{ $t('orderLimits.defaultApproversUsed') }}
            </sf-text>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <sf-text-button color="grey" class="text-medium-emphasis" @click="closeDialog">
          {{ $t('cancel') }}
        </sf-text-button>
        <sf-text-button
          :disabled="!valid && orderLimitUserPatch.orderLimitType === OrderLimitType.USER"
          @click="save"
        >
          {{ $t('save') }}
        </sf-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import SfEditButton from '@/components/button/SfEditButton.vue'
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfTextField from '@/components/input/SfTextField.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { OrderLimitType, OrderLimitUser, OrderLimitUserPatch, ShortUser } from '@/generatedTypes'
import { getFullName } from '@/helpers'
import OrderLimitApproversSelect from '@/modules/orderLimits/components/OrderLimitApproversSelect.vue'
import { trackOrderLimitDialogue } from '@/modules/orderLimits/trackingHelpers'
import useOrderLimits from '@/modules/orderLimits/useOrderLimits'
import { positiveNumberRule, requiredRule } from '@/rules'
import { PropType, defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'OrderLimitDialog',
  components: {
    OrderLimitApproversSelect,
    SfHeading,
    SfEditButton,
    SfSubHeading,
    SfText,
    SfTextButton,
    SfTextField
  },
  props: {
    user: {
      type: Object as PropType<OrderLimitUser>,
      required: true
    },
    approvers: {
      type: Array as PropType<ShortUser[]>,
      required: true
    }
  },
  setup(props) {
    const valid = ref(true)
    const dialog = ref(false)

    const data = () => {
      return {
        submitOrder: props.user.submitOrder,
        userOrderLimit: props.user.userOrderLimit || 0,
        orderLimitType: props.user.orderLimitType,
        approverIds: props.user.approvers.map((approver) => approver.id)
      }
    }

    const orderLimitUserPatch = ref<OrderLimitUserPatch>(data())

    // set correct data when dialog is reopened
    watch(dialog, (newValue) => {
      if (newValue === true) {
        orderLimitUserPatch.value = data()
      }
    })

    const save = () => {
      trackOrderLimitDialogue(props.user.id, orderLimitUserPatch.value)
      if (orderLimitUserPatch.value.orderLimitType !== OrderLimitType.USER) {
        delete orderLimitUserPatch.value['userOrderLimit']
      }
      useOrderLimits().updateOrderLimitUser(props.user.id, orderLimitUserPatch.value)
      dialog.value = false
    }

    const closeDialog = () => {
      dialog.value = false
    }

    return {
      dialog,
      valid,
      getFullName,
      requiredRule,
      positiveNumberRule,
      OrderLimitType,
      orderLimitUserPatch,
      save,
      closeDialog
    }
  }
})
</script>
