<template>
  <v-list nav density="compact">
    <side-menu-item
      v-if="portalSettings.backToPlatformLink"
      :menu-item="getBackToPlatformMenuItem(portalSettings.backToPlatformLink)"
      external-link
    />
    <side-menu-item
      v-for="item in filterMenuItems(menu, portalSettings)"
      :key="item.key"
      :menu-item="item"
    />
  </v-list>
</template>

<script lang="ts">
import { getBackToPlatformMenuItem, MenuItem } from '@/components/navigation/menu'
import SideMenuItem from '@/components/navigation/SideMenuItem.vue'
import { filterMenuItems } from '@/helpers'
import { PortalSettings } from '@/modules/portalSettings/types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'SideMenu',
  components: {
    SideMenuItem
  },
  props: {
    menu: {
      type: Array as PropType<MenuItem[]>,
      default: () => []
    },
    portalSettings: {
      type: Object as PropType<PortalSettings>,
      required: true
    }
  },
  setup() {
    return {
      filterMenuItems,
      getBackToPlatformMenuItem
    }
  }
})
</script>
