<template>
  <login-wrapper
    v-if="branding"
    :title="$t('resetPassword')"
    :loading="loading"
    :branding="branding"
  >
    <reset-password-form
      :sending="sending"
      :token-error="tokenError"
      @change-password="changePassword"
    />
  </login-wrapper>
</template>

<script lang="ts">
import LoginWrapper from '@/modules/authentication/components/LoginWrapper.vue'
import ResetPasswordForm from '@/modules/authentication/components/ResetPasswordForm.vue'
import usePasswordReset from '@/modules/authentication/usePasswordReset'
import useBranding from '@/modules/portalSettings/useBranding'
import { useStateManager } from '@/store'
import { useTitle } from '@/helpers'
import { storeToRefs } from 'pinia'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'ResetPasswordPage',
  components: { ResetPasswordForm, LoginWrapper },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('resetPassword')}`))
    useStateManager().reset()
    const token = useRoute().params.token as string
    usePasswordReset().checkPasswordToken({
      value: token
    })

    const { branding } = useBranding()
    const { tokenError, loading, sending } = storeToRefs(usePasswordReset())

    const changePassword = (password: string) => {
      usePasswordReset().changePassword({
        token: { value: token },
        password: password
      })
    }

    return {
      branding,
      loading,
      sending,
      tokenError,
      changePassword
    }
  }
})
</script>
