import { Event } from '@/modules/tracking/types'
import AddToCartButtonEvents from '@/modules/tracking/events/helper/addToCartButton'
export default class PromotionalProductsEvent extends Event {
  constructor(action: string, label?: string) {
    super('Aktionsprodukte', action, label ?? '')
  }

  static readonly AddToCart: AddToCartButtonEvents = {
    addToCart: new PromotionalProductsEvent('Aktionsprodukt zu Warenkorb hinzugefügt'),
    request: new PromotionalProductsEvent('Aktionsprodukt angefragt')
  }
}
