<template>
  <v-number-input
    v-model="model"
    class="field"
    :class="{ 'text-disabled': disabled }"
    hide-details
    inset
    control-variant="split"
    :disabled="disabled"
    :suffix="'/' + maxQuantity"
    type="number"
    hide-spin-buttons
    variant="outlined"
    density="compact"
    color="primary"
    :min="1"
    :max="maxQuantity"
    :max-width="170"
    @blur="onBlur()"
    @focus="[$event.target.select()]"
    @keydown.enter="$event.target.blur()"
  />
</template>

<script setup lang="ts">
defineProps({
  maxQuantity: {
    type: Number,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const model = defineModel<number>()

const onBlur = () => {
  if (!model.value) {
    model.value = 1
  }
}
</script>

<style lang="scss" scoped>
.field {
  &:deep(.v-text-field__suffix) {
    width: 40px;
  }
  &:deep(input) {
    text-align: right;
  }
}
</style>
