<template>
  <v-card
    :to="{
      name: 'CtoModelConfiguration',
      params: { id: baseModel.baseModel.manufacturerMaterialNo }
    }"
    height="100%"
    class="product-card"
    @click="trackClick"
  >
    <v-img
      aspect-ratio="1"
      :lazy-src="emptyPlaceholderImage"
      :src="
        baseModel.baseModel.baseModelPicUrl
          ? fitImage(baseModel.baseModel.baseModelPicUrl, 500, 500)
          : placeholderImage
      "
      :srcset="
        baseModel.baseModel.baseModelPicUrl
          ? getSrcset(baseModel.baseModel.baseModelPicUrl, 500, 500)
          : placeholderImage
      "
    />
    <v-card-text>
      <sf-sub-heading>{{ baseModel.baseModel.description }}</sf-sub-heading>
      <sf-text>
        <ul class="pl-4 mt-1">
          <li
            v-for="modelComponent in baseModel.selectedModelComponents"
            :key="modelComponent.componentId"
          >
            {{ modelComponent.description }}
          </li>
        </ul>
      </sf-text>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { emptyPlaceholderImage, fitImage, getSrcset, placeholderImage } from '@/helpers'
import { BaseModelResource } from '@/generatedTypes'
import CtoEvent from '@/modules/tracking/events/cto'
import { trackEvent } from '@/modules/tracking/useTracking'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseModelCard',
  components: { SfText, SfSubHeading },
  props: {
    baseModel: {
      type: Object as PropType<BaseModelResource>,
      required: true
    }
  },
  setup(props) {
    const trackClick = () => {
      trackEvent(CtoEvent.Choose.BaseModel(props.baseModel))
    }

    return {
      trackClick,
      fitImage,
      getSrcset,
      placeholderImage,
      emptyPlaceholderImage
    }
  }
})
</script>
