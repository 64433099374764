<template>
  <v-snackbar
    v-for="(message, index) in snackbarMessages"
    :key="message.message"
    v-model="message.isShown"
    location="bottom end"
    :color="message.color"
    :style="{ bottom: `${getOffset(index)}px` }"
    :timeout="message.timeout + message.count"
    :close-on-back="false"
    @update:model-value="onChanged($event, index)"
  >
    <v-badge
      v-if="message.count > 1"
      :ref="
        (el) => {
          refs[index] = el as ComponentPublicInstance<HTMLElement>
        }
      "
      :content="message.count"
      bordered
      color="transparent"
      text-color="white"
      light
      inline
    >
      <message-text class="mr-1" :message="message" />
    </v-badge>
    <message-text
      v-else
      :ref="
        (el) => {
          refs[index] = el as ComponentPublicInstance<HTMLElement>
        }
      "
      class="mr-1"
      :message="message"
    />

    <template #actions>
      <sf-icon-button
        v-if="message.showRefresh"
        light
        color="white"
        :icon="mdiRefresh"
        @click="refresh"
      />
      <sf-icon-button light color="white" :icon="mdiClose" @click="close(index)" />
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import SfIconButton from '@/components/button/SfIconButton.vue'
import MessageText from '@/modules/message/components/MessageText.vue'
import useMessaging from '@/modules/message/useMessaging'
import router from '@/router'
import { mdiClose, mdiRefresh } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { ComponentPublicInstance, defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'MessagingCenter',
  components: {
    MessageText,
    SfIconButton
  },
  setup() {
    const { snackbarMessages } = storeToRefs(useMessaging())
    const refs = ref<ComponentPublicInstance<HTMLElement>[]>([])

    const refresh = () => {
      router.go(0)
    }

    const close = (index: number) => {
      snackbarMessages.value.splice(index, 1)
    }

    const onChanged = (isShown: boolean, index: number) => {
      if (!isShown) {
        refs.value.splice(index, 1)
        close(index)
      }
    }

    const getOffset = (index: number): number => {
      let offset = 0

      for (let i = 0; i < index; i++) {
        const ref = refs.value[i]
        if (ref) {
          const element = ref.$el as Element
          const parent = element.parentElement
          if (!parent) {
            continue
          }
          const parentHeight = parent.offsetHeight
          offset += 8 + parentHeight
        }
      }

      return offset
    }

    return {
      close,
      refresh,
      snackbarMessages,
      onChanged,
      refs,
      getOffset,
      mdiClose,
      mdiRefresh
    }
  }
})
</script>
