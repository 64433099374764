<template>
  <error-component :message="message" :code="code" />
</template>

<script lang="ts">
import ErrorComponent from '@/modules/error/components/ErrorComponent.vue'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: { ErrorComponent },
  setup() {
    const { t } = useI18n()

    return {
      code: 404,
      message: t('error.pageNotFound')
    }
  }
})
</script>
