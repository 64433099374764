<template>
  <v-row v-if="loading" justify="center">
    <v-col cols="auto" class="mt-8">
      <v-progress-circular indeterminate :size="50" color="primary" />
    </v-col>
  </v-row>
  <list-content v-else>
    <v-col cols="12">
      <v-row v-if="startPage.htmlText" dense>
        <v-col ref="html" class="pa-2 pb-5" v-html="startPage.htmlText" />
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <template v-if="startPage.featuredProducts.length > 0">
            <v-row justify="center" dense>
              <v-col
                v-for="product in startPage.featuredProducts"
                :key="product.productId"
                cols="12"
                sm="6"
                md="3"
                xl="2"
              >
                <product-card
                  :product="product"
                  :show-product-identifiers="false"
                  small
                  :add-to-cart-event="StartPageEvent.AddToCart"
                  :loading-realtime-prices="loadingRealtimePrices"
                  show-realtime-loading-indicator
                />
              </v-col>
            </v-row>
            <v-row
              v-if="loadingProducts || startPage.featuredProducts.length === page * PAGE_SIZE"
              dense
              justify="center"
            >
              <v-col>
                <v-btn
                  :loading="loadingProducts ? 'primary' : false"
                  size="large"
                  block
                  @click="getMoreProducts"
                >
                  {{ $t('moreUppercase') + $t('ellipsis') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <v-row
            v-if="startPage.startPageType === StartPageType.categories && categories.length > 0"
            dense
          >
            <v-col
              v-for="category in categories"
              :key="category.id"
              cols="12"
              md="6"
              xl="4"
              class="pa-1"
            >
              <start-page-category :category="category" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </list-content>
</template>

<script setup lang="ts">
import ListContent from '@/components/list/ListContent.vue'
import { StartPageType } from '@/generatedTypes'
import { useTitle } from '@/helpers'
import useCategories from '@/modules/categories/useCategories'
import ProductCard from '@/modules/product/components/ProductCard.vue'
import useRealtimePrices from '@/modules/product/useRealtimePrices'
import StartPageCategory from '@/modules/startPage/components/StartPageCategory.vue'
import useStartPage from '@/modules/startPage/useStartPage'
import StartPageEvent from '@/modules/tracking/events/startPage'
import { PAGE_SIZE } from '@/types'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const currentRoute = useRoute()
const startPageSerial = currentRoute.query.startPageSerial as string

const { t } = useI18n()
useTitle(ref(`${t('windowTitle.StartPage')}`))
useStartPage().loadStartPage(1, startPageSerial)

const page = ref(1)
const { startPage, loading, loadingProducts } = storeToRefs(useStartPage())
const { categories } = storeToRefs(useCategories())
const { loading: loadingRealtimePrices } = storeToRefs(useRealtimePrices())
const getMoreProducts = () => {
  page.value++
  useStartPage().loadStartPageNextPage(page.value)
}
</script>
