import { defineStore } from 'pinia'
import { SimplePrice } from '@/generatedTypes'
import useRealtimePrices from '@/modules/product/useRealtimePrices'

interface ProductQuantityState {
  quantity: number
  initialized: boolean
  updatingQuantity: boolean
}

const initialState = (): ProductQuantityState => {
  return {
    quantity: 1,
    initialized: false,
    updatingQuantity: false
  }
}

const useProductQuantity = defineStore('productQuantity', {
  state: () => initialState(),
  actions: {
    initializeQuantity(price?: SimplePrice) {
      if (!this.initialized) {
        this.quantity = price?.packagingModel?.minQuantity || 1
        this.initialized = true
      }
    },
    updateQuantity(quantity: number, productId: number) {
      this.quantity = quantity
      useRealtimePrices().loadRealtimePrices({
        productId,
        scale: quantity
      })
    },
    setUpdatingQuantity(value: boolean) {
      this.updatingQuantity = value
    }
  }
})

export default useProductQuantity
