<template>
  <v-form v-model="valid" lazy-validation>
    <v-row dense>
      <v-col cols="12" class="d-flex flex-column ga-2">
        <sf-text>
          {{ t('emailForRegistration') }}
        </sf-text>
        <sf-text-field
          ref="mail"
          v-model="email"
          :label="$t('user.email') + ' *'"
          :rules="[rules.mailRule, rules.requiredRule]"
          :error-messages="customErrorMessage"
          autocomplete="email"
          @keydown.prevent.enter="submit"
          @input="customErrorMessage = ''"
        />
      </v-col>
    </v-row>
    <sf-large-button :disabled="!valid" block class="mt-2" :loading="sending" @click="submit">
      {{ t('checkEmail') }}
    </sf-large-button>
  </v-form>
  <login-link />
</template>

<script setup lang="ts">
import SfLargeButton from '@/components/button/SfLargeButton.vue'
import SfTextField from '@/components/input/SfTextField.vue'
import SfText from '@/components/text/SfText.vue'
import LoginLink from '@/modules/authentication/components/LoginLink.vue'
import { EmailRegistrationDTO, Step } from '@/modules/authentication/types'
import useSelfRegistration from '@/modules/authentication/useSelfRegistration'
import { EmailStatus } from '@/modules/user/types'
import rules from '@/rules'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { VForm } from 'vuetify/components'

const emit = defineEmits<{
  submit: [EmailRegistrationDTO]
}>()
const { t, locale } = useI18n()
const valid = ref(false)
const email = ref<string>('')
const customErrorMessage = ref('')
const { sending } = storeToRefs(useSelfRegistration())

const submit = () => {
  if (valid.value) {
    validateEmail(email.value).then(
      (step: Step) => {
        emit('submit', { email: email.value, nextStep: step })
      },
      (message) => {
        customErrorMessage.value = message
        valid.value = false
      }
    )
  }
}

const validateEmail = async (email: string): Promise<Step> => {
  return useSelfRegistration()
    .validateEmail(email)
    .then(async (emailStatus) => {
      switch (emailStatus) {
        case EmailStatus.BLACKLISTED:
          return Promise.resolve(Step.COMPANY)
        case EmailStatus.REGISTERED:
          return Promise.reject(t('error.emailAlreadyRegistered'))
        case EmailStatus.VALID:
          await useSelfRegistration().requestRegistrationEmail({
            email,
            alpha2language: locale.value
          })
          return Promise.resolve(Step.LINK_SENT)
        default:
          return Promise.reject(t('error.invalidEmail'))
      }
    })
}
</script>
