import { Event } from '@/modules/tracking/types'

export class PunchoutEvent extends Event {
  constructor(action: string) {
    super('Punchout', action, '')
  }

  static readonly OpenBackToSystem = new PunchoutEvent('Checkout Dialog geöffnet')
  static readonly ClickedPunchoutQuoteFormSubmit = new PunchoutEvent('Angebot zurückgegeben')
  static readonly ClickedPunchoutFormSubmit = new PunchoutEvent('Warenkorb zurückgegeben')
}
