<template>
  <v-card class="pa-3 mx-auto" max-width="500">
    <v-overlay :model-value="loading" contained class="align-center justify-center">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
    <v-img
      v-if="branding.logoUrl"
      class="mx-auto"
      :src="branding.logoUrl"
      :alt="branding.portalName"
      max-width="190"
      max-height="75"
    />
    <v-card-title
      v-if="!branding.logoUrl && branding.portalName"
      ref="no-logo-fallback"
      class="d-flex justify-center"
    >
      <sf-title class="font-weight-bold">{{ branding.portalName }}</sf-title>
    </v-card-title>
    <v-card-title ref="title" class="d-flex align-center">
      <v-divider class="my-1" />
      <div class="mx-2">{{ title }}</div>
      <v-divider class="my-1" />
    </v-card-title>
    <v-card-text>
      <!-- content -->
      <slot />
      <login-footer
        :owner-name="branding.ownerName"
        :terms-url="branding.termsUrl"
        :privacy-url="branding.privacyUrl"
        :legal-info-url="branding.legalInfoUrl"
      />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import SfTitle from '@/components/text/SfTitle.vue'
import { Branding } from '@/modules/portalSettings/types'
import { defineComponent, PropType } from 'vue'
import LoginFooter from '@/modules/authentication/components/LoginFooter.vue'

export default defineComponent({
  name: 'LoginWrapper',
  components: { LoginFooter, SfTitle },
  props: {
    branding: {
      type: Object as PropType<Branding>,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
})
</script>
