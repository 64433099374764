<template>
  <v-chip label variant="outlined" color="grey">
    {{ $t('common.archived') }}
  </v-chip>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SfArchivedChip'
})
</script>
