<template>
  <v-card height="100%">
    <v-card-item>
      <v-card-title>
        <sf-heading>{{ $t('paymentMethod') + ' *' }}</sf-heading>
      </v-card-title>
    </v-card-item>
    <v-card-text>
      <v-select
        v-model="selectedPaymentMethod"
        class="mt-1"
        :rules="[requiredRule]"
        density="compact"
        :items="getPaymentMethods()"
        :placeholder="$t('pleaseSelect')"
        item-title="translation"
        item-value="title"
        variant="underlined"
        autocomplete="off"
      />
      <div
        v-if="selectedPaymentMethod && selectedPaymentMethod == 'MOBEX360'"
        class="d-flex flex-row align-start"
      >
        <v-icon class="mr-1" :icon="mdiInformationOutline" />
        <sf-secondary-text>{{ $t('mobex360Info') }}</sf-secondary-text>
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import SfHeading from '@/components/text/SfHeading.vue'
import SfSecondaryText from '@/components/text/SfSecondaryText.vue'
import { getPaymentMethods } from '@/modules/user/helpers'
import { requiredRule } from '@/rules'
import { computed, defineComponent } from 'vue'
import useCheckout from '@/modules/order/useCheckout'
import { mdiInformationOutline } from '@mdi/js'

export default defineComponent({
  name: 'MobexPaymentMethod',
  components: { SfHeading, SfSecondaryText },
  setup() {
    const selectedPaymentMethod = computed({
      get() {
        return useCheckout().paymentMethod
      },
      set(value: string | undefined) {
        if (value) {
          useCheckout().paymentMethod = value
        }
      }
    })

    return {
      selectedPaymentMethod,
      getPaymentMethods,
      requiredRule,
      mdiInformationOutline
    }
  }
})
</script>
