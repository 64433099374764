<template>
  <sf-checkbox v-model="model" :rules="[rules.requiredRule]">
    <template #label>
      <span @click.stop v-html="customTerms" />
    </template>
  </sf-checkbox>
</template>

<script setup lang="ts">
import SfCheckbox from '@/components/input/SfCheckbox.vue'
import rules from '@/rules'

const model = defineModel<boolean>()

defineProps({
  customTerms: { type: String, required: true }
})
</script>
