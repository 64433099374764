<template>
  <!-- pending -->
  <sf-alert-info
    v-if="approvalRequest.status == ApprovalRequestStatus.Pending && isInitiator"
    ref="pending-info-initiator"
  >
    <sf-text>{{
      $t('orderApproval.pendingInformationRequester', [formatDate(approvalRequest.createdDate)])
    }}</sf-text>
    <approval-request-info-assignees :assignees="approvalRequest.assignees" />
    <approval-request-info-comment :comment="approvalRequest.requestComment" />
  </sf-alert-info>

  <sf-alert-info
    v-else-if="approvalRequest.status == ApprovalRequestStatus.Pending && isApprovableByMe"
    ref="pending-info-approver"
  >
    <sf-text>{{
      $t('orderApproval.pendingInformationApprover', [formatDate(approvalRequest.createdDate)])
    }}</sf-text>
    <br />
    <approval-request-info-comment :comment="approvalRequest.requestComment" />
  </sf-alert-info>

  <sf-alert-info
    v-else-if="
      approvalRequest.status == ApprovalRequestStatus.Pending && !isApprovableByMe && !isInitiator
    "
    ref="pending-info"
  >
    <sf-text>{{
      $t('orderApproval.pendingInformation', [
        formatDate(approvalRequest.createdDate),
        getFullName(initiator)
      ])
    }}</sf-text>
    <approval-request-info-assignees :assignees="approvalRequest.assignees" />
    <approval-request-info-comment :comment="approvalRequest.requestComment" />
  </sf-alert-info>

  <!-- approved -->
  <sf-alert-success v-else-if="approvalRequest.status == ApprovalRequestStatus.Approved">
    <sf-text v-if="approvalRequest.processedDate && approvalRequest.processor">
      {{
        $t('orderApproval.approvedInformation', [
          getFullName(approvalRequest.processor),
          formatDate(approvalRequest.processedDate)
        ])
      }}
    </sf-text>
  </sf-alert-success>

  <!-- rejected -->
  <sf-alert-error
    v-else-if="approvalRequest.status == ApprovalRequestStatus.Rejected"
    :icon="mdiCancel"
  >
    <sf-text v-if="approvalRequest.processor && approvalRequest.processedDate">
      {{
        $t('orderApproval.declinedInformation', [
          getFullName(approvalRequest.processor),
          formatDate(approvalRequest.processedDate)
        ])
      }}
    </sf-text>
    <br />
    <approval-request-info-decline-comment :comment="approvalRequest.processorComment" />
  </sf-alert-error>
</template>

<script lang="ts">
import SfAlertError from '@/components/alerts/SfAlertError.vue'
import SfAlertInfo from '@/components/alerts/SfAlertInfo.vue'
import SfAlertSuccess from '@/components/alerts/SfAlertSuccess.vue'
import SfText from '@/components/text/SfText.vue'
import { getFullName } from '@/helpers'
import { ApprovalRequest, ApprovalRequestStatus, ShortUser } from '@/generatedTypes'
import useOwnUser from '@/modules/user/useOwnUser'
import { formatDate } from '@/utils/timeFormatHelpers'
import { computed, defineComponent, PropType } from 'vue'
import { storeToRefs } from 'pinia'
import { isOrderApprovableByMe } from '@/modules/order/helpers'
import ApprovalRequestInfoAssignees from '@/modules/order/components/ApprovalRequestInfoAssignees.vue'
import ApprovalRequestInfoComment from '@/modules/order/components/ApprovalRequestInfoComment.vue'
import ApprovalRequestInfoDeclineComment from '@/modules/order/components/ApprovalRequestInfoDeclineComment.vue'
import { mdiCancel } from '@mdi/js'

export default defineComponent({
  name: 'ApprovalRequestInfo',
  components: {
    SfAlertInfo,
    SfText,
    SfAlertSuccess,
    SfAlertError,
    ApprovalRequestInfoAssignees,
    ApprovalRequestInfoComment,
    ApprovalRequestInfoDeclineComment
  },
  props: {
    initiator: {
      type: Object as PropType<ShortUser>,
      required: true
    },
    approvalRequest: {
      type: Object as PropType<ApprovalRequest>,
      required: true
    }
  },
  setup(props) {
    const { ownUser } = storeToRefs(useOwnUser())

    const isApprovableByMe = computed(() => {
      return isOrderApprovableByMe(props.approvalRequest)
    })

    const isInitiator = computed(() => {
      return props.initiator.id === ownUser.value.id
    })

    return {
      isApprovableByMe,
      isInitiator,
      ApprovalRequestStatus,
      getFullName,
      formatDate,
      mdiCancel
    }
  }
})
</script>
