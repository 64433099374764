<template>
  <v-card
    :to="{
      name: 'CtoModelGroup',
      params: { id: productClass.productClassId }
    }"
    height="100%"
    class="product-card"
    @click="trackClick"
  >
    <v-img
      aspect-ratio="1"
      :lazy-src="emptyPlaceholderImage"
      :src="fitImage(productClass.productClassPicUrl, 500, 500)"
      :srcset="getSrcset(productClass.productClassPicUrl, 500, 500)"
    />
    <v-card-item>
      <v-card-title>
        <sf-sub-heading>{{ productClass.productClassDescription }}</sf-sub-heading>
      </v-card-title>
    </v-card-item>
  </v-card>
</template>

<script lang="ts">
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import { emptyPlaceholderImage, fitImage, getSrcset } from '@/helpers'
import { ProductClass } from '@/generatedTypes'
import CtoEvent from '@/modules/tracking/events/cto'
import { trackEvent } from '@/modules/tracking/useTracking'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'ProductClassCard',
  components: { SfSubHeading },
  props: {
    productClass: {
      type: Object as PropType<ProductClass>,
      required: true
    }
  },
  setup(props) {
    const trackClick = () => {
      trackEvent(CtoEvent.Choose.Class(props.productClass))
    }

    return {
      trackClick,
      fitImage,
      getSrcset,
      emptyPlaceholderImage
    }
  }
})
</script>
