import { FALLBACK_LOCALE, Language, supportedLocales } from '@/types'
import { createI18n, IntlNumberFormat, IntlNumberFormats, Locale } from 'vue-i18n'
import en from '@/locales/en.json' assert { type: 'json' }
import de from '@/locales/de.json' assert { type: 'json' }
import fr from '@/locales/fr.json' assert { type: 'json' }
import es from '@/locales/es.json' assert { type: 'json' }
import nl from '@/locales/nl.json' assert { type: 'json' }
import it from '@/locales/it.json' assert { type: 'json' }
import pl from '@/locales/pl.json' assert { type: 'json' }

type MessageSchema = typeof en

const getNumberFormats = (): IntlNumberFormats<IntlNumberFormat, Language> => {
  const numberFormat: IntlNumberFormat = {
    currency: {
      style: 'currency',
      currency: 'EUR'
    },
    percentage: {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  }

  const numberFormats: IntlNumberFormats = {}

  supportedLocales.forEach((locale) => {
    numberFormats[locale] = numberFormat
  })

  return numberFormats
}

const getLocale = () => {
  return isSupportedLocale(navigator.language.substring(0, 2))
    ? navigator.language.substring(0, 2)
    : FALLBACK_LOCALE
}

const isSupportedLocale = (localeToCheck: string): localeToCheck is Locale => {
  const supportedLocale = supportedLocales.find((locale) => locale === localeToCheck)
  return supportedLocale != undefined
}

export default createI18n<[MessageSchema], Language>({
  locale: getLocale(),
  fallbackLocale: FALLBACK_LOCALE,
  messages: {
    en: en,
    de: de,
    fr: fr,
    es: es,
    nl: nl,
    it: it,
    pl: pl
  },
  numberFormats: getNumberFormats(),
  legacy: false
})

export { isSupportedLocale }
