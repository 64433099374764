<template>
  <span v-if="packagingModel.contentUnit && packagingModel.orderUnit">
    {{ $t('per') }} {{ orderUnitMessage }} x {{ contentUnitMessage }}
  </span>
  <span v-else-if="packagingModel.contentUnit && !packagingModel.orderUnit">
    {{ $t('per') }} {{ contentUnitMessage }}
  </span>
  <span v-else-if="!packagingModel.contentUnit && packagingModel.orderUnit">
    {{ $t('per') }} {{ orderUnitMessage }}
  </span>
</template>

<script lang="ts">
import { PackagingModel } from '@/generatedTypes'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PackagingInfo',
  props: {
    packagingModel: {
      type: Object as PropType<PackagingModel>,
      required: true
    }
  },
  setup(props) {
    const orderUnitMessage = computed(() => {
      return (
        (props.packagingModel.priceQuantity > 1 ? props.packagingModel.priceQuantity : '') +
        ' ' +
        props.packagingModel.orderUnit
      )
    })

    const contentUnitMessage = computed(() => {
      return (
        (props.packagingModel.numberContentUnitPerOrderUnit > 1
          ? props.packagingModel.numberContentUnitPerOrderUnit
          : '') +
        ' ' +
        props.packagingModel.contentUnit
      )
    })

    return { orderUnitMessage, contentUnitMessage }
  }
})
</script>
