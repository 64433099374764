<template>
  <sf-icon-button
    id="comparison-button"
    :color="inComparison(product.productId) ? 'green' : 'primary'"
    :icon="mdiSwapHorizontalBold"
    @click.prevent="inComparison(product.productId) ? removeFromComparison() : addToComparison()"
  />
</template>

<script lang="ts">
import SfIconButton from '@/components/button/SfIconButton.vue'
import { BundleItemProduct, MediumProduct, ShortProduct } from '@/generatedTypes'
import useProductComparison from '@/modules/product/useProductComparison'
import { storeToRefs } from 'pinia'
import { defineComponent, PropType } from 'vue'
import { trackEvent } from '@/modules/tracking/useTracking'
import { Event } from '@/modules/tracking/types'
import { mdiSwapHorizontalBold } from '@mdi/js'

export default defineComponent({
  name: 'ComparisonButton',
  components: { SfIconButton },
  props: {
    product: {
      type: Object as PropType<BundleItemProduct | MediumProduct | ShortProduct>,
      required: true
    },
    addToComparisonEvent: {
      type: Object as PropType<Event>,
      default: undefined
    },
    removeFromComparisonEvent: {
      type: Object as PropType<Event>,
      default: undefined
    }
  },
  setup(props) {
    const { inComparison } = storeToRefs(useProductComparison())
    const addToComparison = () => {
      useProductComparison().addProduct(props.product.productId, props.product.productType)
      if (props.addToComparisonEvent) {
        trackEvent(props.addToComparisonEvent)
      }
    }
    const removeFromComparison = () => {
      useProductComparison().removeProduct(props.product.productId)
      if (props.removeFromComparisonEvent) {
        trackEvent(props.removeFromComparisonEvent)
      }
    }

    return {
      addToComparison,
      removeFromComparison,
      inComparison,
      mdiSwapHorizontalBold
    }
  }
})
</script>
