<template>
  <v-card class="transparent-card pa-1 mb-1 pw-rma-return-position" variant="outlined">
    <v-row dense>
      <v-col cols="auto" class="d-flex align-center">
        <product-image :size="50" :url="returnPosition.productImageUrl" />
      </v-col>
      <v-col cols="" class="d-flex align-center">
        <sf-text class="two-lines">{{ returnPosition.productTitle }}</sf-text>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <sf-select
          v-model="reason"
          :label="$t('rma.returnReason') + ' *'"
          :placeholder="$t('rma.selectReturnReason')"
          hide-details
          :items="returnReasons"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import SfSelect from '@/components/input/SfSelect.vue'
import ProductImage from '@/components/ProductImage.vue'
import SfText from '@/components/text/SfText.vue'
import { OrderRmaReturnPosition } from '@/modules/order/types'
import useOrderRma from '@/modules/order/useOrderRma'
import { storeToRefs } from 'pinia'
import { PropType, ref, watch } from 'vue'

const props = defineProps({
  returnPosition: {
    type: Object as PropType<OrderRmaReturnPosition>,
    required: true
  }
})

const { returnReasons } = storeToRefs(useOrderRma())

const reason = ref<string | undefined>(undefined)

watch(reason, (newValue) => {
  if (newValue) {
    useOrderRma().updateReason(props.returnPosition.positionNumber, newValue)
  }
})
</script>
