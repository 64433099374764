<template>
  <v-dialog v-model="dialog" scrollable :width="500" @click:outside="cancel" @keydown.esc="cancel">
    <template #activator="slotData">
      <slot name="activator" v-bind="slotData" />
    </template>
    <v-card>
      <v-card-item>
        <v-card-title ref="title">
          <sf-heading>{{ title }}</sf-heading>
        </v-card-title>
      </v-card-item>
      <v-card-text class="pt-2">
        <address-edit-hint v-if="showEditHint" />
        <v-form ref="form" v-model="valid" lazy-validation>
          <sf-text-field
            ref="company-name"
            v-model="address.companyName"
            :label="$t('address.companyName') + ' *'"
            :rules="[rules.requiredRule]"
            :disabled="readonly"
            autocomplete="organization"
          />
          <sf-text-field
            ref="addition-1"
            v-model="address.addition1"
            :label="$t('address.addition')"
          />
          <sf-text-field
            ref="addition-2"
            v-model="address.addition2"
            :label="$t('address.addition')"
          />
          <sf-text-field
            ref="street"
            v-model="address.street"
            :label="$t('address.street') + ' *'"
            :rules="[rules.requiredRule]"
            :disabled="readonly"
            autocomplete="street-address"
          />
          <sf-text-field
            ref="zip-code"
            v-model="address.zipCode"
            :label="$t('address.zipCode') + ' *'"
            :rules="[rules.requiredRule]"
            :disabled="readonly"
            autocomplete="postal-code"
          />
          <sf-text-field
            ref="city"
            v-model="address.city"
            :label="$t('address.city') + ' *'"
            :rules="[rules.requiredRule]"
            :disabled="readonly"
            autocomplete="address-level2"
          />
          <sf-text-field
            ref="post-box"
            v-model="address.postBox"
            :label="$t('address.postBox')"
            :disabled="readonly"
          />
          <sf-select
            ref="country"
            v-model="address.iso3country"
            :items="getCountries($i18n.locale)"
            :label="$t('address.country') + ' *'"
            :rules="[rules.requiredRule]"
            item-title="label"
            item-value="value"
            :disabled="readonly"
            autocomplete="country-name"
          />
          <sf-text-field
            ref="client-number"
            v-model="address.clientNumber"
            :label="$t('address.clientNumber')"
            :disabled="readonly"
          />
          <template v-if="!showPostalAddressOnly">
            <sf-text-field
              ref="phone"
              v-model="address.phone"
              :label="$t('address.phone')"
              :rules="[rules.phoneRule]"
              :disabled="readonly"
              autocomplete="tel"
              @update:model-value="parsePhoneNumber($event)"
            />
            <sf-text-field
              ref="email"
              v-model="address.email"
              :label="$t('address.email')"
              :rules="[rules.mailRule]"
              :disabled="readonly"
              autocomplete="email"
            />
            <sf-text-field
              ref="url"
              v-model="address.url"
              :label="$t('address.url')"
              :rules="[rules.urlRuleHttpOptional]"
              :disabled="readonly"
              autocomplete="url"
            />
          </template>
          <template v-if="showDefaultAddressCheckboxes">
            <sf-checkbox
              ref="billing-address"
              v-model="address.isDefaultBillingAddress"
              :disabled="disableDefaultBillingAddressCheckbox"
              :label="$t('address.defaultBillingAddress')"
              class="mt-0"
            />
            <sf-checkbox
              ref="delivery-address"
              v-model="address.isDefaultDeliveryAddress"
              :disabled="disableDefaultDeliveryAddressCheckbox"
              :label="$t('address.defaultDeliveryAddress')"
              class="mt-0"
            />
          </template>
        </v-form>
      </v-card-text>
      <v-card-actions class="flex-wrap">
        <sf-delete-confirm-dialog
          v-if="showDeleteButton"
          ref="delete-address-button"
          :title="$t('deleteAddress')"
          :description="$t('deleteAddressDialog')"
          @confirm="$emit('delete')"
        >
          <template #activator="{ props }">
            <sf-text-button color="error" v-bind="props">
              {{ $t('deleteAddress') }}
            </sf-text-button>
          </template>
        </sf-delete-confirm-dialog>
        <v-spacer />
        <sf-text-button color="grey" class="text-medium-emphasis" @click="cancel">
          {{ $t('cancel') }}
        </sf-text-button>
        <sf-text-button ref="save-address-button" :disabled="!valid" @click="saveAddress">
          {{ $t('save') }}
        </sf-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfDeleteConfirmDialog from '@/components/dialog/SfDeleteConfirmDialog.vue'
import SfCheckbox from '@/components/input/SfCheckbox.vue'
import SfSelect from '@/components/input/SfSelect.vue'
import SfTextField from '@/components/input/SfTextField.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import { ShopAddress } from '@/generatedTypes'
import { getFullUrl } from '@/helpers'
import AddressEditHint from '@/modules/address/components/AddressEditHint.vue'
import { getCountries } from '@/modules/address/helpers'
import rules from '@/rules'
import { VForm } from '@/types'
import * as phoneUtil from 'libphonenumber-js'
import { clone } from 'lodash-es'
import { PropType, defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'AddressFormDialog',
  components: {
    AddressEditHint,
    SfCheckbox,
    SfDeleteConfirmDialog,
    SfHeading,
    SfSelect,
    SfTextButton,
    SfTextField
  },
  props: {
    addressData: {
      type: Object as PropType<ShopAddress>,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    showDeleteButton: {
      type: Boolean,
      default: true
    },
    showEditHint: {
      type: Boolean,
      default: false
    },
    showDefaultAddressCheckboxes: {
      type: Boolean,
      default: true
    },
    disableDefaultBillingAddressCheckbox: {
      type: Boolean,
      default: true
    },
    disableDefaultDeliveryAddressCheckbox: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      required: true
    },
    showPostalAddressOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['delete', 'save', 'cancel'],
  setup(props, { emit }) {
    const dialog = ref(false)
    const form = ref<VForm>()
    const valid = ref(false)
    const address = ref(clone(props.addressData))

    const resetForm = () => {
      if (form.value) {
        form.value.resetValidation()
      }
    }

    const cancel = () => {
      emit('cancel')
      dialog.value = false
    }

    watch(dialog, (newValue) => {
      if (newValue === true) {
        address.value = clone(props.addressData)
        resetForm()
      }
    })

    const saveAddress = () => {
      if (form.value && form.value.validate()) {
        let defaultDeliveryChanged: boolean
        let defaultBillingChanged: boolean
        if (!props.addressData.id) {
          //when new address is created
          defaultBillingChanged = address.value.isDefaultBillingAddress
          defaultDeliveryChanged = address.value.isDefaultDeliveryAddress
        } else {
          defaultDeliveryChanged =
            address.value.isDefaultDeliveryAddress !== props.addressData.isDefaultDeliveryAddress
          defaultBillingChanged =
            address.value.isDefaultBillingAddress !== props.addressData.isDefaultBillingAddress
        }

        emit(
          'save',
          {
            ...address.value,
            url: getFullUrl(address.value.url)
          },
          { defaultBillingChanged, defaultDeliveryChanged }
        )

        dialog.value = false
      }
    }

    const parsePhoneNumber = (number: string) => {
      if (number.charAt(0) === '+') {
        try {
          address.value.phone = phoneUtil.parsePhoneNumber(number).formatInternational()
        } catch (_error) {
          address.value.phone = number
        }
      }
    }

    return {
      dialog,
      address,
      rules,
      form,
      valid,
      cancel,
      getCountries,
      saveAddress,
      parsePhoneNumber
    }
  }
})
</script>
