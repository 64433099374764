<template>
  <v-card class="py-1 px-2 mb-1">
    <v-row no-gutters>
      <v-col cols="auto" class="d-flex align-center mr-2">
        <product-image ref="image" />
      </v-col>
      <v-col class="d-flex justify-center">
        <v-row no-gutters>
          <v-col ref="names" class="d-flex flex-column justify-center mr-1 mr-md-2">
            <v-text-field
              ref="input"
              v-model="label"
              variant="underlined"
              density="compact"
              color="primary"
              hide-details
              class="align-center pw-freeline-input max-w-600"
              autocomplete="off"
              @blur="submit"
              @keydown.enter="$event.target.blur()"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import ProductImage from '@/components/ProductImage.vue'
import { defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
  name: 'UnsavedFreeLineItem',
  components: {
    ProductImage
  },
  emits: ['submit'],
  setup(_, { emit }) {
    const input = ref<HTMLInputElement>()
    const label = ref('')
    onMounted(() => {
      if (input.value) {
        input.value?.focus()
      }
    })

    const submit = () => {
      emit('submit', label.value)
    }

    return {
      input,
      label,
      submit
    }
  }
})
</script>
