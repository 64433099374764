<template>
  <list-content>
    <v-col cols="12">
      <v-card>
        <product-loading v-if="loading" />
        <template v-if="!loading && product">
          <product-header
            :product="product"
            show-add-to-cart-widget
            :show-quote-button="portalSettings.useQuotes"
            :show-collection-button="portalSettings.useCollections"
            :loading-realtime-prices="realtimeLoading"
          />
          <product-details
            :product="product"
            :bundle-items="bundleItems"
            :loading-realtime-prices="realtimeLoading"
          />
        </template>
      </v-card>
    </v-col>
  </list-content>
</template>

<script lang="ts">
import ListContent from '@/components/list/ListContent.vue'
import { useTitle } from '@/helpers'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import ProductDetails from '@/modules/product/components/ProductDetails.vue'
import ProductHeader from '@/modules/product/components/ProductHeader.vue'
import ProductLoading from '@/modules/product/components/ProductLoading.vue'
import useAccessories from '@/modules/product/useAccessories'
import useBundleItems from '@/modules/product/useBundleItems'
import useProduct from '@/modules/product/useProduct'
import useRealtimePrices from '@/modules/product/useRealtimePrices'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'ProductPage',
  components: {
    ProductDetails,
    ProductHeader,
    ProductLoading,
    ListContent
  },
  setup() {
    const { t } = useI18n()
    const currentRoute = useRoute()

    watch(
      () => currentRoute.params.id,
      (newId) => {
        useProduct().loadProduct(parseInt(newId as string))
        useAccessories().loadAccessories(parseInt(newId as string))
      },
      { immediate: true }
    )

    const { portalSettings } = storeToRefs(usePortalSettings())
    const { product, loading: productLoading } = storeToRefs(useProduct())
    const { bundleItems, loading: bundleItemsLoading } = storeToRefs(useBundleItems())
    const { loading: realtimePricesLoading } = storeToRefs(useRealtimePrices())

    const pageTitle = computed(() => (product.value ? `${product.value.title}` : `${t('product')}`))
    useTitle(pageTitle)

    const loading = computed(() => productLoading.value || bundleItemsLoading.value)
    const realtimeLoading = computed(() => {
      if (product.value) {
        return realtimePricesLoading.value.includes(product.value?.productId)
      } else {
        return false
      }
    })

    return {
      portalSettings,
      product,
      bundleItems,
      loading,
      realtimeLoading
    }
  }
})
</script>
