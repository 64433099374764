import emptyPlaceholderImage from '@/assets/images/empty-placeholder.png'
import placeholderImage from '@/assets/images/placeholder.png'
import { MenuItem } from '@/components/navigation/menu'
import { PortalSettings } from '@/modules/portalSettings/types'
import useBranding from '@/modules/portalSettings/useBranding'
import {
  Money,
  SimpleStock,
  SimpleStockStatus,
  QuoteActivityUser,
  ContactUser,
  ShortUser,
  User,
  Attachment,
  ContentType,
  OrderLimitUser
} from '@/generatedTypes'
import { GuestUser } from '@/modules/user/types'
import router from '@/router'
import useRequestManager from '@/store/useRequestManager'
import { VForm } from '@/types'
import { useTitle as vueUseTitle } from '@vueuse/core'
import { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios'
import { saveAs } from 'file-saver'
import { ComponentPublicInstance, ComputedRef, Ref, computed, reactive, toRef } from 'vue'
import { useI18n } from 'vue-i18n'

const fitCropImage = (url: string, width: number, height: number, separator = '?'): string => {
  return url + `${separator}fitcrop=${width}x${height}dBOTH`
}

const fitImage = (url: string, width: number, height: number, separator = '?'): string => {
  return url + `${separator}fit=${width}x${height}`
}

const getSrcset = (url: string, width: number, height: number, separator = '?'): string => {
  return `${url}${separator}fit=${width}x${height} 1x, ${url}${separator}fit=${width * 2}x${
    height * 2
  } 2x`
}

const getFullUrl = (url: string | undefined): string | undefined => {
  if (url && !url.startsWith('https://') && !url.startsWith('http://')) {
    return 'https://' + url
  } else {
    return url
  }
}

const disableSort = <T>(items: T[]): T[] => {
  return items
}

const stringCompare = (a?: string, b?: string): number => {
  if (!a) {
    return 1
  } else if (!b) {
    return -1
  } else {
    return a.toLowerCase().localeCompare(b.toLowerCase())
  }
}

const numberCompare = (a?: number, b?: number): number => {
  if (!a) {
    return 1
  } else if (!b) {
    return -1
  } else {
    return a - b
  }
}

const moneyCompare = (a?: Money, b?: Money): number => {
  if (!a) {
    return 1
  } else if (!b) {
    return -1
  }

  if (!a.value) {
    return 1
  } else if (!b.value) {
    return -1
  } else {
    return a.value - b.value
  }
}

const statusCompare = (a?: SimpleStock, b?: SimpleStock): number => {
  if (!a) {
    return 1
  } else if (!b) {
    return -1
  } else {
    return (
      simpleStockStatusOrder[Object.keys(SimpleStockStatus).indexOf(a.status)] -
      simpleStockStatusOrder[Object.keys(SimpleStockStatus).indexOf(b.status)]
    )
  }
}

const simpleStockStatusOrder: number[] = [1, 5, 4, 0, 2, 6, 3]

const scrollToTop = (ref: ComponentPublicInstance<unknown> | undefined): void => {
  if (window.scrollTo) {
    window.scrollTo(0, 0)
  }
  if (ref != null) {
    ref.$el.scrollTo(0, 0)
  }
}

/**
 * General typesafe compare function for objects.
 * Can be used to sort nullable objects by a field which is given with the keyToSort parameter
 *
 * @param keyToSort
 * @param direction
 * @param sortNullsToEnd
 */
const compare = <T>(
  keyToSort: keyof T,
  direction: 'asc' | 'desc' = 'asc',
  sortNullsToEnd = true
): ((a: T | undefined, b: T | undefined) => number) => {
  const compare = (objectA: T | undefined, objectB: T | undefined) => {
    if (!objectA) {
      return sortNullsToEnd ? 1 : -1
    } else if (!objectB) {
      return sortNullsToEnd ? -1 : 1
    }
    const valueA = objectA[keyToSort]
    const valueB = objectB[keyToSort]
    if (!valueA) {
      return sortNullsToEnd ? 1 : -1
    } else if (!valueB) {
      return sortNullsToEnd ? -1 : 1
    }

    if (valueA === valueB) {
      return 0
    }

    if (valueA > valueB) {
      return direction === 'asc' ? 1 : -1
    } else {
      return direction === 'asc' ? -1 : 1
    }
  }
  return function (a: T | undefined, b: T | undefined) {
    return compare(a, b)
  }
}

/**
 * General not typesafe compare function for objects.
 * Can be used to sort nullable objects by a nested field which is given with the keyToSort parameter
 *
 * @param keyToSort
 * @param direction
 * @param sortNullsToEnd
 */
const compareByNestedProperty = <T>(
  keyToSort: string,
  direction: 'asc' | 'desc' = 'asc',
  sortNullsToEnd = true
): ((a: T | undefined, b: T | undefined) => number) => {
  const compare = (objectA: T | undefined, objectB: T | undefined) => {
    if (!objectA) {
      return sortNullsToEnd ? 1 : -1
    } else if (!objectB) {
      return sortNullsToEnd ? -1 : 1
    }
    const valueA = getNestedProperty(objectA, keyToSort)
    const valueB = getNestedProperty(objectB, keyToSort)
    if (!valueA) {
      return sortNullsToEnd ? 1 : -1
    } else if (!valueB) {
      return sortNullsToEnd ? -1 : 1
    }

    if (valueA === valueB) {
      return 0
    }

    if (valueA > valueB) {
      return direction === 'asc' ? 1 : -1
    } else {
      return direction === 'asc' ? -1 : 1
    }
  }
  return function (a: T | undefined, b: T | undefined) {
    return compare(a, b)
  }
}

const getNestedProperty = (
  /* eslint-disable @typescript-eslint/no-explicit-any */
  object: { [index: string]: any },
  keyInDotNotation: string
) => {
  const keyList = keyInDotNotation.split('.')
  let currentObject = object
  while (keyList.length > 0) {
    const currentKey = keyList.splice(0, 1)[0]
    currentObject = currentObject[currentKey]
    if (!currentObject) {
      return currentObject
    }
  }
  return currentObject
}

const isQuoteActivityUser = (user: unknown): user is QuoteActivityUser => {
  if ((user as QuoteActivityUser).fullName) {
    return true
  }
  return false
}

const getFullName = (
  user: ShortUser | User | OrderLimitUser | ContactUser | GuestUser | QuoteActivityUser
): string => {
  return isQuoteActivityUser(user) ? user.fullName : [user.firstName, user.lastName].join(' ')
}

const getFullNameAndCompany = (user: ShortUser | User): string => {
  return [user.firstName, user.lastName].join(' ') + ` (${user.companyName})`
}

const urltoFile = async (url: string, filename: string, mimeType: string): Promise<File> => {
  const res = await fetch(url, {
    headers: {
      'Content-Type': mimeType
    }
  })
  const buf = await res.arrayBuffer()
  return new File([buf], filename, { type: mimeType })
}

const saveAttachment = (attachment: Attachment): void => {
  let type: string
  switch (attachment.contentType) {
    case ContentType.XLS:
      type = 'application/vnd.ms-excel'
      break
    case ContentType.PDF:
      type = 'application/pdf'
      break
    default:
      type = 'application/octet-stream'
      break
  }

  urltoFile('data:' + type + ';base64,' + attachment.content, attachment.name, type).then(
    function (file) {
      saveAs(file)
    }
  )
}

const filterMenuItems = (menu: MenuItem[], portalSettings: PortalSettings): MenuItem[] => {
  return menu.filter((item) => {
    if (item.restriction) {
      if (portalSettings[item.restriction]) {
        return item
      }
    } else {
      return item
    }
  })
}

const parseFileName = (responseHeaders: RawAxiosResponseHeaders | AxiosResponseHeaders): string => {
  return responseHeaders['content-disposition'].split('filename="')[1].slice(0, -1)
}

const isGuest: ComputedRef<boolean> = computed(() =>
  toRef(reactive(router), 'currentRoute').value.path.startsWith('/guest')
)

const isQuotesPortal: ComputedRef<boolean> = computed(() => {
  const { branding } = useBranding()
  return branding.value?.isQuotesPortal != undefined && branding.value.isQuotesPortal
})

const isQuotesPortalOrGuest: ComputedRef<boolean> = computed(() => {
  return isQuotesPortal.value || isGuest.value
})

const useTitle = (pretext: Ref<string>) => {
  const requestManager = useRequestManager().state
  const { t } = useI18n()
  const { branding } = useBranding()

  const title = computed(() => {
    if (requestManager.value.serverOffline) {
      return `${t('serverOffline.title')}`
    } else {
      return branding.value?.portalName
        ? `${pretext.value} - ${branding.value.portalName}`
        : `${t('portal')}`
    }
  })

  vueUseTitle(title)
}

const validateForm = async (form: Ref<VForm | undefined>): Promise<boolean> => {
  if (!form.value) {
    return false
  }
  const result = await form.value.validate()
  const castedResult = result as unknown as { valid: boolean }
  return castedResult.valid
}

export {
  compare,
  compareByNestedProperty,
  disableSort,
  emptyPlaceholderImage,
  filterMenuItems,
  fitCropImage,
  fitImage,
  getFullName,
  getFullNameAndCompany,
  getFullUrl,
  getSrcset,
  isGuest,
  isQuoteActivityUser,
  isQuotesPortal,
  isQuotesPortalOrGuest,
  statusCompare,
  moneyCompare,
  numberCompare,
  parseFileName,
  placeholderImage,
  saveAttachment,
  scrollToTop,
  stringCompare,
  urltoFile,
  useTitle,
  validateForm
}
