type ShortUrl = {
  value: string
}

type Resolve = {
  shortUrl: ShortUrl
  secret?: string
}

enum ResolveRequirement {
  NO_SHORT_FORM = 'NO_SHORT_FORM',
  NO_REQUIREMENTS = 'NO_REQUIREMENTS',
  ZIP_CODE = 'ZIP_CODE'
}

export { ShortUrl, Resolve, ResolveRequirement }
