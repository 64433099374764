<template>
  <div>
    <list-header :title="$t('filter.filter')" hide-show-categories-button>
      <template v-if="!$vuetify.display.mobile" #filter>
        <v-icon :icon="mdiClose" @click="$emit('close')" />
      </template>
    </list-header>
    <div v-show="isLoading" class="mt-2">
      <slot name="skeleton" />
    </div>
    <div v-show="!isLoading">
      <div
        ref="filterContainer"
        class="overflow-y-auto scrollbar mt-2 pr-1"
        :style="'height: calc(100vh - ' + offset + 'px)'"
      >
        <slot name="filter" />
      </div>
      <div class="d-flex align-center justify-center mt-2">
        <sf-text-button :disabled="isResetDisabled" @click="$emit('resetFilter')">
          {{ $t('filter.resetFilter') }}
        </sf-text-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentPublicInstance, defineComponent, ref } from 'vue'
import ListHeader from '@/components/list/ListHeader.vue'
import { containerHeight } from '@/utils/containerHeightHelper'
import SfTextButton from '@/components/button/SfTextButton.vue'
import { mdiClose } from '@mdi/js'

export default defineComponent({
  name: 'FilterBase',
  components: { ListHeader, SfTextButton },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    isResetDisabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'resetFilter'],
  setup() {
    const bottomMargin = 77
    const filterContainer = ref<ComponentPublicInstance<HTMLElement>>()
    const { offset } = containerHeight(filterContainer, bottomMargin)
    return {
      offset,
      filterContainer,
      mdiClose
    }
  }
})
</script>
