<template>
  <v-card height="100%">
    <v-card-item>
      <v-card-title>
        <sf-heading>{{ $t('common.comment') }}</sf-heading>
      </v-card-title>
    </v-card-item>
    <v-card-text>
      <v-textarea
        ref="comment-input"
        v-model="model"
        v-debounce="onDebounce"
        :placeholder="$t('common.commentPlaceholder')"
        auto-grow
        density="compact"
        hide-details="auto"
        :readonly="!editable"
        variant="underlined"
        color="primary"
        rows="1"
        autocomplete="off"
        :maxlength="maxLength"
        :hint="model && model.length >= maxLength ? $t('maxLength', [maxLength]) : ''"
        persistent-hint
      />
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import SfHeading from '@/components/text/SfHeading.vue'

const props = defineProps({
  editable: {
    type: Boolean,
    default: true
  },
  maxLength: {
    type: Number,
    required: true
  }
})
const emit = defineEmits(['debounce'])
const model = defineModel<string>()

const onDebounce = (value: string) => {
  if (props.editable) {
    emit('debounce', value)
  }
}
</script>
