<template>
  <v-dialog v-model="dialog" :width="500">
    <template #activator="{ props }">
      <sf-edit-button v-bind="props" />
    </template>
    <v-card>
      <v-card-item>
        <v-card-title>
          <sf-heading>{{ $t('collection.assignee') }}</sf-heading>
        </v-card-title>
      </v-card-item>
      <v-card-text>
        <collection-assignee-select v-model="assignedUser" :users="users" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <sf-text-button color="grey" class="text-medium-emphasis" @click="closeDialog">
          {{ $t('cancel') }}
        </sf-text-button>
        <sf-text-button color="primary" :disabled="!assignedUser" @click="save">
          {{ $t('save') }}
        </sf-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import SfEditButton from '@/components/button/SfEditButton.vue'
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import { ShortUser } from '@/generatedTypes'
import { defineComponent, PropType, ref, watch } from 'vue'
import useCollection from '@/modules/collection/useCollection'
import CollectionAssigneeSelect from '@/modules/collection/components/CollectionAssigneeSelect.vue'

export default defineComponent({
  name: 'CollectionAssigneeDialog',
  components: {
    CollectionAssigneeSelect,
    SfHeading,
    SfEditButton,
    SfTextButton
  },
  props: {
    assignee: {
      type: Object as PropType<ShortUser | undefined>,
      required: true
    },
    users: {
      type: Array as PropType<ShortUser[]>,
      required: true
    }
  },
  setup(props) {
    const dialog = ref(false)
    const assignedUser = ref(props.assignee)

    // set correct data when dialog is reopened
    watch(dialog, (newValue) => {
      if (newValue) {
        assignedUser.value = props.assignee
      }
    })

    const closeDialog = () => {
      dialog.value = false
    }

    const save = () => {
      if (assignedUser.value != undefined) {
        useCollection().updateAssignee(assignedUser.value)
        dialog.value = false
      }
    }

    return { dialog, assignedUser, save, closeDialog }
  }
})
</script>
