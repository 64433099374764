<template>
  <filter-base
    :is-loading="loading"
    @close="$emit('close')"
    @reset-filter="
      resetFilter().then(() => {
        $emit('update')
      })
    "
  >
    <template #skeleton>
      <v-skeleton-loader v-for="index in 4" :key="index" class="mt-2" type="list-item" />
    </template>
    <template #filter>
      <search-filter-input
        v-for="filterNode in filters"
        :key="filterNode.name"
        :filter-node="filterNode"
        :get-selection-for-filter="getSelectionForFilter"
        :update-filter-selection="updateFilterSelection"
        :remove-item-from-filter-selection="removeItemFromFilterSelection"
        :filter-event="trackFilterFunction"
        :multiple="false"
        @update="$emit('update')"
      />
    </template>
  </filter-base>
</template>

<script lang="ts">
import FilterBase from '@/components/filter/FilterBase.vue'
import SearchFilterInput from '@/components/filter/SearchFilterInput.vue'
import useCollectionsFilter from '@/modules/collection/useCollectionsFilter'
import FilterEvent from '@/modules/tracking/events/filter'
import { storeToRefs } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CollectionsFilter',
  components: { SearchFilterInput, FilterBase },
  emits: ['close', 'update'],
  setup() {
    const { filters, loading } = storeToRefs(useCollectionsFilter())
    const resetFilter = useCollectionsFilter().resetFilter
    const getSelectionForFilter = useCollectionsFilter().getSelectionForFilter
    const updateFilterSelection = useCollectionsFilter().updateFilterSelection
    const removeItemFromFilterSelection = useCollectionsFilter().removeItemFromFilterSelection

    const trackFilterFunction = FilterEvent.GetFor('collections')('Default')

    return {
      filters,
      loading,
      resetFilter,
      getSelectionForFilter,
      updateFilterSelection,
      removeItemFromFilterSelection,
      trackFilterFunction
    }
  }
})
</script>
