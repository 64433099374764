import { ApiError, handleApiError } from '@/handleApiError'
import { isGuest, parseFileName } from '@/helpers'
import api from '@/modules/quote/api'
import FileSaver from 'file-saver'
import { defineStore } from 'pinia'

interface QuoteItemDatasheetState {
  loading: boolean
}

const initialState = (): QuoteItemDatasheetState => {
  return {
    loading: false
  }
}

const useProductDatasheet = defineStore('quoteItemDatasheet', {
  state: () => initialState(),
  actions: {
    async loadDatasheet(id: string, itemId: string) {
      this.loading = true

      return api
        .loadDatasheet(id, itemId, isGuest.value)
        .then((response) => {
          FileSaver.saveAs(response.data, parseFileName(response.headers))
        })
        .catch((error: ApiError) => {
          handleApiError(error, { customMessageKey: 'datasheetError' })
        })
        .finally(() => (this.loading = false))
    }
  }
})

export default useProductDatasheet
