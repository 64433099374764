<template>
  <v-card height="100%" class="d-flex flex-column justify-center pa-2">
    <v-row no-gutters class="d-flex flex-row align-center">
      <avatar-image ref="avatar" :user="sellerUser" :size="40" class="mr-1" />
      <v-col class="d-flex flex-column">
        <sf-text ref="name">{{ getFullName(sellerUser) }}</sf-text>
        <sf-link ref="ct-email" class="text-body-1" :href="'mailto:' + sellerUser.email">{{
          sellerUser.email
        }}</sf-link>
        <sf-text v-if="sellerUser.phoneMobile" ref="ct-phone-mobile">{{
          sellerUser.phoneMobile
        }}</sf-text>
        <sf-text v-if="sellerUser.phone" ref="ct-phone">{{ sellerUser.phone }}</sf-text>
      </v-col>
    </v-row>
    <v-divider v-if="createdDate || validDate" class="my-1" />
    <v-row no-gutters>
      <v-col class="d-flex flex-column">
        <sf-text v-if="createdDate" ref="ct-created-date">
          {{ $t('createdDate') }}:
          {{ formatDate(createdDate, false) }}
        </sf-text>
        <sf-text v-if="validDate" ref="ct-valid-date">
          {{ $t('validDate') }}:
          {{ formatDate(validDate, false) }}
        </sf-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import AvatarImage from '@/components/AvatarImage.vue'
import SfLink from '@/components/text/SfLink.vue'
import SfText from '@/components/text/SfText.vue'
import { formatDate } from '@/utils/timeFormatHelpers'
import { getFullName } from '@/helpers'
import { ContactUser } from '@/generatedTypes'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'QuoteSellerInfoCard',
  components: {
    AvatarImage,
    SfLink,
    SfText
  },
  props: {
    sellerUser: {
      type: Object as PropType<ContactUser>,
      required: true
    },
    createdDate: {
      type: String,
      default: undefined
    },
    validDate: {
      type: String,
      default: undefined
    }
  },
  setup() {
    return {
      getFullName,
      formatDate
    }
  }
})
</script>
