<template>
  <div class="d-flex flex-column align-center ga-2">
    <v-img ref="illustration" :src="image" width="400" height="auto" />
    <sf-heading>{{ $t('shortUrl.error') }}</sf-heading>
  </div>
</template>

<script setup lang="ts">
import image from '@/assets/images/quote_not_found.png'
import SfHeading from '@/components/text/SfHeading.vue'
import { useTitle } from '@/helpers'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const pageTitle = ref(`${t('shortUrl.error')}`)
useTitle(pageTitle)
</script>
