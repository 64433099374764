import { ThemeDefinition } from 'vuetify'

const StorefrontLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    textbase: '#222222',
    background: '#f2f5f8',
    surface: '#ffffff',
    primary: '#0096c7',
    secondary: '#2a4f66',
    accent: '#59bbbb',
    error: '#ef476f',
    info: '#2196F3',
    success: '#05c075',
    warning: '#ffd166'
  },
  variables: {
    'hover-opacity': 0.15
  }
}

export { StorefrontLightTheme }
