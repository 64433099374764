import { ApiError, handleApiError } from '@/handleApiError'
import api from '@/modules/portalSettings/api'
import { Branding } from '@/modules/portalSettings/types'
import Vuetify from '@/plugins/vuetify'
import { ComputedRef, computed, reactive } from 'vue'

interface BrandingState {
  branding: Branding | undefined
  loading: boolean
}

type ReturnType = {
  loading: ComputedRef<boolean>
  branding: ComputedRef<Branding | undefined>
  getBranding: () => Promise<'quotesportal' | 'defaultportal' | 'portalunavailable'>
  quotesPortalRegistrationAllowed: ComputedRef<boolean>
}

const state: BrandingState = reactive({
  branding: undefined,
  loading: false
})

export default function useBranding(): ReturnType {
  const getBranding = async () => {
    state.loading = true

    return api
      .getBranding()
      .then(({ data }) => {
        state.branding = data

        if (state.branding.primaryColorHexCode) {
          Vuetify.theme.themes.value.StorefrontLightTheme.colors.primary =
            state.branding.primaryColorHexCode
        }

        return data.isQuotesPortal ? ('quotesportal' as const) : ('defaultportal' as const)
      })
      .catch((error: ApiError) => {
        handleApiError(error, undefined, {
          errorCode: 400,
          logError: false,
          appearance: 'NONE'
        })
        return 'portalunavailable' as const
      })
      .finally(() => {
        state.loading = false
      })
  }

  return {
    loading: computed(() => state.loading),
    branding: computed(() => state.branding),
    getBranding,
    quotesPortalRegistrationAllowed: computed(() => {
      return (state.branding?.isQuotesPortal && state.branding?.isUserRegistration) ?? true
    })
  }
}
