<template>
  <v-dialog v-model="dialog" :width="500" @keydown.esc="closeDialog" @click:outside="closeDialog">
    <template #activator="{ props }">
      <sf-text-button v-bind="props">
        {{ $t('changePassword') }}
      </sf-text-button>
    </template>
    <v-card>
      <v-card-item>
        <v-card-title>
          <sf-heading>{{ $t('changePassword') }}</sf-heading>
        </v-card-title>
      </v-card-item>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <sf-password-field
            v-model="oldPassword"
            class="mb-2"
            :label="$t('oldPassword') + ' *'"
            :rules="[requiredRule]"
            required
            :error-messages="oldPasswordValid ? [] : [$t('error.oldPassword')]"
          />
          <sf-password-field
            v-model="newPassword"
            class="mb-2"
            :label="$t('newPassword') + ' *'"
            :rules="[passwordLengthRule, passwordRule]"
            required
          />
          <sf-password-field
            v-model="newPasswordRepeat"
            class="mb-2"
            :label="$t('newPasswordRepeat') + ' *'"
            :rules="[passwordMatchRule(newPassword)]"
            required
            @keydown.enter.prevent="changePassword"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <sf-text-button color="grey" class="text-medium-emphasis" @click="closeDialog">
          {{ $t('cancel') }}
        </sf-text-button>
        <sf-text-button
          color="primary"
          :disabled="!valid"
          :loading="sending"
          @click="changePassword"
        >
          {{ $t('save') }}
        </sf-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfPasswordField from '@/components/input/SfPasswordField.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import { MyProfileEvent } from '@/modules/tracking/events/user'
import { trackEvent } from '@/modules/tracking/useTracking'
import useUser from '@/modules/user/useUser'
import { passwordLengthRule, passwordMatchRule, passwordRule, requiredRule } from '@/rules'
import { storeToRefs } from 'pinia'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'UserChangePasswordDialog',
  components: { SfTextButton, SfHeading, SfPasswordField },
  setup() {
    const dialog = ref(false)
    const valid = ref(false)
    const oldPassword = ref<string>('')
    const newPassword = ref<string>('')
    const newPasswordRepeat = ref<string>('')
    const form = ref<HTMLFormElement | undefined>(undefined)
    const oldPasswordValid = ref(true)
    const { sending } = storeToRefs(useUser())
    const changePassword = async () => {
      if (!form.value?.validate()) return
      trackEvent(MyProfileEvent.changedPassword())
      const result = await useUser().changePassword({
        currentPassword: oldPassword.value,
        newPassword: newPassword.value
      })
      if (result === 'SUCCESS') {
        closeDialog()
      } else if (result === 'OLD_PASSWORD_INVALID') {
        oldPasswordValid.value = false
      }
    }

    watch(oldPassword, () => {
      oldPasswordValid.value = true
    })

    const closeDialog = () => {
      dialog.value = false
      resetForm()
    }

    const resetForm = () => {
      oldPassword.value = ''
      newPassword.value = ''
      newPasswordRepeat.value = ''
      form.value?.resetValidation()
    }

    return {
      dialog,
      valid,
      form,
      sending,
      oldPassword,
      oldPasswordValid,
      newPassword,
      newPasswordRepeat,
      passwordRule,
      passwordLengthRule,
      passwordMatchRule,
      requiredRule,
      changePassword,
      closeDialog
    }
  }
})
</script>
