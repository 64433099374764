<template>
  <v-row>
    <v-col cols>
      <list-header :title="$t('order.orders')">
        <template #search>
          <search-bar :value="keyword" @search="setKeyword" @clear="resetSearch" />
        </template>
        <template #filter>
          <filter-button :active-filters="activeFilters" @toggle="showFilter = !showFilter" />
        </template>
        <template #viewToggle>
          <view-toggle
            :views="getOrdersViews()"
            :current-view="view"
            :toggle-event="toggleEvent"
            @changed="changeView"
          />
        </template>
        <template #cols>
          <v-col class="d-flex align-center justify-start" cols="">
            <sf-text>{{ $t('title') }}</sf-text>
          </v-col>
          <v-col class="d-flex align-center justify-end" cols="2">
            <sort-button
              :sort="sortDirectionDate"
              :active="activeSortButton === 'date'"
              @click="toggleSortByDate"
            >
              {{ $t('order.orderDate') }}
            </sort-button>
          </v-col>
          <v-col class="d-flex align-center justify-end" cols="2">
            <sf-text>{{ $t('costs') }}</sf-text>
          </v-col>
          <v-col class="d-flex align-center justify-end" cols="2">
            <sort-button
              :active="activeSortButton === 'status'"
              :sort="sortDirectionStatus"
              @click="toggleSortByStatus"
            >
              {{ $t('status') }}
            </sort-button>
          </v-col>
        </template>
      </list-header>

      <error-component
        v-if="mustHandleError"
        :message="error.message"
        :code="error.code"
        transparent-background
      />
      <list-loading v-else-if="loading" />
      <list-empty
        v-else-if="orders.length === 0"
        :message="$t('noOrdersFound')"
        :icon="mdiInboxArrowUp"
      />

      <list-content v-else ref="listContent">
        <v-col cols="12">
          <order-row v-for="order in orders" :key="order.id" :order="order" />
        </v-col>
        <v-col v-if="pages > 1" cols="12">
          <v-pagination
            :length="pages"
            :model-value="page"
            rounded
            total-visible="5"
            variant="flat"
            density="comfortable"
            active-color="primary"
            @update:model-value="goToPage"
          />
        </v-col>
      </list-content>
    </v-col>

    <mobile-side-menu
      v-if="$vuetify.display.mobile"
      id="pw-mobile-filter"
      :show="showFilter"
      @close="showFilter = false"
    >
      <order-filter
        :show-approvables="showApprovables"
        @close="showFilter = false"
        @update="searchOrders"
      />
    </mobile-side-menu>

    <v-col v-if="!$vuetify.display.mobile" v-show="showFilter" cols="3">
      <order-filter
        :show-approvables="showApprovables"
        @close="showFilter = false"
        @update="searchOrders"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import MobileSideMenu from '@/components/MobileSideMenu.vue'
import SearchBar from '@/components/SearchBar.vue'
import SortButton from '@/components/SortButton.vue'
import ViewToggle from '@/components/ViewToggle.vue'
import FilterButton from '@/components/filter/FilterButton.vue'
import ListContent from '@/components/list/ListContent.vue'
import ListEmpty from '@/components/list/ListEmpty.vue'
import ListHeader from '@/components/list/ListHeader.vue'
import ListLoading from '@/components/list/ListLoading.vue'
import SfText from '@/components/text/SfText.vue'
import ErrorComponent from '@/modules/error/components/ErrorComponent.vue'
import { customErrorHandling } from '@/modules/error/helpers'
import OrderFilter from '@/modules/order/components/OrderFilter.vue'
import OrderRow from '@/modules/order/components/OrderRow.vue'
import { getOrdersViews } from '@/modules/order/helpers'
import { OrderSortOrder, OrdersView, SortDirection } from '@/modules/order/types'
import useOrderFilter from '@/modules/order/useOrderFilter'
import useOrders from '@/modules/order/useOrders'
import OrderEvent from '@/modules/tracking/events/order'
import { trackEvent } from '@/modules/tracking/useTracking'
import { mdiInboxArrowUp } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { PropType, computed, defineComponent, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'OrdersList',
  components: {
    FilterButton,
    ListContent,
    ListEmpty,
    ListHeader,
    ListLoading,
    MobileSideMenu,
    OrderFilter,
    OrderRow,
    SearchBar,
    SfText,
    SortButton,
    ViewToggle,
    ErrorComponent
  },
  props: {
    view: {
      type: String as PropType<OrdersView>,
      required: true
    }
  },
  setup(props) {
    const { error, mustHandleError } = customErrorHandling()
    const router = useRouter()
    const currentRoute = useRoute()
    const { orders, pages, loading } = storeToRefs(useOrders())
    const { selection, initialized } = storeToRefs(useOrderFilter())
    const showFilter = ref(false)
    const listContent = ref()
    const page = computed(() => {
      return parseInt(currentRoute.query.page as string) || 1
    })
    const keyword = computed(() => {
      return currentRoute.query.keyword as string
    })
    const showApprovables = computed(() => {
      return props.view == OrdersView.APPROVALS
    })

    if (initialized.value) {
      useOrders().getOrders(page.value, showApprovables.value, keyword.value)
    } else {
      useOrders().initOrders(page.value, showApprovables.value, keyword.value)
    }

    const activeFilters = computed(() => {
      return useOrderFilter().getActiveFilters()
    })
    const sortDirectionDate = computed(() => {
      return selection.value.sort === OrderSortOrder.SENT_DESC
        ? SortDirection.DESC
        : selection.value.sort === OrderSortOrder.SENT_ASC
          ? SortDirection.ASC
          : SortDirection.NONE
    })
    const sortDirectionStatus = computed(() => {
      return selection.value.sort === OrderSortOrder.STATUS_DESC
        ? SortDirection.DESC
        : selection.value.sort === OrderSortOrder.STATUS_ASC
          ? SortDirection.ASC
          : SortDirection.NONE
    })
    const activeSortButton = computed(() => {
      const sort = selection.value.sort
      if (sort === OrderSortOrder.SENT_ASC || sort === OrderSortOrder.SENT_DESC) {
        return 'date'
      } else if (sort === OrderSortOrder.STATUS_ASC || sort === OrderSortOrder.STATUS_DESC) {
        return 'status'
      } else {
        return undefined
      }
    })

    watch(currentRoute, () => {
      useOrders().getOrders(page.value, showApprovables.value, keyword.value)
    })

    const setKeyword = (keyword: string) => {
      trackEvent(OrderEvent.TextSearch)
      router.push({
        name: 'Orders',
        query: {
          ...currentRoute.query,
          keyword,
          page: '1'
        }
      })
    }

    const resetSearch = () => {
      router.push({
        name: 'Orders',
        query: {
          ...currentRoute.query,
          keyword: undefined,
          page: '1'
        }
      })
    }

    const searchOrders = () => {
      goToPage(1)
      useOrders().getOrders(page.value, showApprovables.value, keyword.value)
    }

    const changeView = (view: OrdersView) => {
      router.push({ query: { view: view } })
    }

    const goToPage = (newPage: number) => {
      if (page.value !== newPage) {
        router.push({
          name: 'Orders',
          query: { ...currentRoute.query, page: newPage.toString() }
        })
      }
    }

    const toggleSortByStatus = () => {
      if (selection.value.sort === OrderSortOrder.STATUS_ASC) {
        useOrderFilter().selection.sort = OrderSortOrder.STATUS_DESC
        trackEvent(OrderEvent.Sort.Status.Descending)
      } else {
        useOrderFilter().selection.sort = OrderSortOrder.STATUS_ASC
        trackEvent(OrderEvent.Sort.Status.Ascending)
      }
      searchOrders()
    }

    const toggleSortByDate = () => {
      if (selection.value.sort === OrderSortOrder.SENT_DESC) {
        useOrderFilter().selection.sort = OrderSortOrder.SENT_ASC
        trackEvent(OrderEvent.Sort.Date.Ascending)
      } else {
        useOrderFilter().selection.sort = OrderSortOrder.SENT_DESC
        trackEvent(OrderEvent.Sort.Date.Descending)
      }
      searchOrders()
    }

    return {
      activeFilters,
      activeSortButton,
      changeView,
      error,
      getOrdersViews,
      goToPage,
      keyword,
      listContent,
      loading,
      mustHandleError,
      orders,
      page,
      pages,
      resetSearch,
      searchOrders,
      setKeyword,
      showApprovables,
      showFilter,
      sortDirectionDate,
      sortDirectionStatus,
      toggleEvent: OrderEvent.ToggleView,
      toggleSortByDate,
      toggleSortByStatus,
      mdiInboxArrowUp
    }
  }
})
</script>
