<template>
  <v-dialog v-model="dialog" :width="500">
    <template #activator="slotData">
      <slot name="activator" v-bind="slotData" />
    </template>
    <v-card>
      <v-card-item>
        <v-card-title>
          <sf-heading>{{ title }}</sf-heading>
        </v-card-title>
      </v-card-item>
      <v-card-text class="text-justify">
        <sf-text>{{ description }}</sf-text>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <sf-text-button color="grey" class="text-medium-emphasis" @click="close">
          {{ $t('cancel') }}
        </sf-text-button>
        <sf-text-button color="error" :loading="loading" @click="$emit('confirm')">
          {{ $t('delete') }}
        </sf-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'

export default defineComponent({
  name: 'SfDeleteConfirmDialog',
  components: { SfHeading, SfTextButton, SfText },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['confirm'],
  setup() {
    const dialog = ref(false)

    const close = () => {
      dialog.value = false
    }

    return {
      dialog,
      close
    }
  }
})
</script>
