<template>
  <div>
    <v-list-item v-if="!isArchived" @click="archiveCollection">
      <v-list-item-title>{{ $t('common.archive') }}</v-list-item-title>
    </v-list-item>

    <v-list-item v-if="isArchived" @click="dearchiveCollection">
      <v-list-item-title>{{ $t('common.dearchive') }}</v-list-item-title>
    </v-list-item>
  </div>
</template>

<script lang="ts">
import useCollection from '@/modules/collection/useCollection'
import CollectionEvent from '@/modules/tracking/events/collection'
import { trackEvent } from '@/modules/tracking/useTracking'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CollectionArchiveButton',
  props: {
    isArchived: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const archiveCollection = () => {
      useCollection().archiveCollection()
      trackEvent(CollectionEvent.Archive)
    }

    const dearchiveCollection = () => {
      useCollection().dearchiveCollection()
      trackEvent(CollectionEvent.Dearchive)
    }

    return {
      archiveCollection,
      dearchiveCollection
    }
  }
})
</script>
