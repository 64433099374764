<template>
  <div class="mt-2">
    <template v-if="loading">
      <v-skeleton-loader ref="userLoading" class="mb-2" type="list-item-avatar-three-line" />
      <v-skeleton-loader class="mb-2" type="article" />
    </template>
    <template v-if="!loading && user">
      <user-header :user="user" />
      <user-permissions
        v-if="showUserPermissions"
        class="mt-2"
        :user="user"
        :is-shop-admin="ownUser.permission.shopAdmin"
        @permissions-changed="changePermissions"
      />
      <user-data
        class="mt-2"
        :user="user"
        :is-shop-admin="ownUser.permission.shopAdmin"
        :is-own-user="isOwnUser(user.id)"
        :max-file-upload-size="portalSettings.maxFileUploadSize"
        @user-changed="changeUser"
        @language-changed="changeLanguage"
        @avatar-changed="changeAvatar"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { isQuotesPortal, useTitle } from '@/helpers'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import UserData from '@/modules/user/components/UserData.vue'
import UserHeader from '@/modules/user/components/UserHeader.vue'
import UserPermissions from '@/modules/user/components/UserPermissions.vue'
import { User, PermissionPatch } from '@/generatedTypes'
import useOwnUser from '@/modules/user/useOwnUser'
import useUser from '@/modules/user/useUser'
import { Language } from '@/types'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'UserPage',
  components: { UserData, UserPermissions, UserHeader },
  setup() {
    const { t } = useI18n()

    const currentRoute = useRoute()

    watch(
      () => currentRoute.params.id,
      (newId) => {
        useUser().getUser(newId as string)
      },
      { immediate: true }
    )

    const { user, loading } = storeToRefs(useUser())
    const { ownUser, isOwnUser } = storeToRefs(useOwnUser())
    const { portalSettings } = storeToRefs(usePortalSettings())

    const showUserPermissions = ref(!isQuotesPortal.value)

    const changeUser = (user: User) => {
      useUser().updateUser(user)
    }

    const changeLanguage = (lng: Language) => {
      useUser().updateLanguage(lng)
    }

    const changePermissions = (permissions: PermissionPatch) => {
      if (user.value) {
        useUser().updatePermissions(user.value.id, permissions)
      }
    }

    const changeAvatar = (file: File) => {
      const form = new FormData()
      form.append('image', file)

      if (user.value) {
        useUser().updateAvatar(user.value.id, form)
      }
    }
    const pageTitle = computed(() =>
      user.value
        ? `${t(`company.employee`)} ${user.value.firstName} ${user.value.lastName}`
        : `${t(`company.employee`)}`
    )
    useTitle(pageTitle)

    return {
      user,
      portalSettings,
      loading,
      ownUser,
      isOwnUser,
      changeUser,
      changePermissions,
      changeAvatar,
      changeLanguage,
      showUserPermissions
    }
  }
})
</script>
