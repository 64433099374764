<template>
  <v-card class="py-1 px-2 mb-1" :to="to">
    <v-row dense>
      <v-col v-if="$slots.avatar" cols="auto" class="d-flex justify-center align-center">
        <slot name="avatar" />
      </v-col>
      <v-col cols="" class="d-flex flex-column justify-center">
        <slot name="title" />
      </v-col>
      <v-col cols="12" md="2" class="d-flex align-center justify-start justify-md-end">
        <slot name="date" />
      </v-col>
      <v-col cols="6" md="2" order="2" order-md="1" class="d-flex align-center justify-end">
        <slot name="costs" />
      </v-col>
      <v-col
        cols="6"
        md="2"
        order="1"
        order-md="2"
        class="d-flex align-center justify-start justify-md-end text-md-right"
      >
        <slot name="status" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  name: 'ListRow',
  props: {
    to: {
      type: Object as PropType<RouteLocationRaw>,
      required: true
    }
  }
})
</script>
