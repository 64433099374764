import { RemovableRef, useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'

interface FeatureAdState {
  show: RemovableRef<boolean>
  showUntil: Date
  startPages: string[]
  detailPages: string[]
}

const initialState = (): FeatureAdState => {
  return {
    show: useStorage('showComparisonFeatureAd', true),
    showUntil: new Date('2024-03-01'),
    startPages: ['/'],
    detailPages: []
  }
}

const useFeatureAd = defineStore('featureAd', {
  state: () => initialState(),
  getters: {
    showFeatureAd: (state) => () => {
      return state.showUntil > new Date() && state.show
    }
  },
  actions: {
    hideFeatureAd() {
      this.show = false
    }
  }
})

export default useFeatureAd
