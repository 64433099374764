<template>
  <v-autocomplete
    :custom-filter="customShortUserFilter"
    :items="approvers"
    :model-value="modelValue"
    color="primary"
    chips
    closable-chips
    variant="filled"
    hide-details
    item-value="id"
    multiple
    :no-data-text="$t('orderLimits.noEmployeeFound')"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template #chip="{ props, item }">
      <v-chip v-bind="props" :text="getFullNameAndCompany(item.raw)">
        <template #prepend>
          <avatar-image ref="avatar" :size="20" :user="item.raw" left />
        </template>
      </v-chip>
    </template>

    <template #item="{ props, item }">
      <v-list-item v-bind="props" :title="getFullNameAndCompany(item.raw)">
        <template #prepend>
          <avatar-image ref="avatar" :size="30" :user="item.raw" left />
        </template>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import AvatarImage from '@/components/AvatarImage.vue'
import { getFullNameAndCompany } from '@/helpers'
import { customShortUserFilter } from '@/modules/user/helpers'
import { ShortUser } from '@/generatedTypes'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'OrderLimitApproversSelect',
  components: { AvatarImage },
  props: {
    modelValue: {
      type: Array as PropType<string[]>,
      required: true
    },
    approvers: {
      type: Array as PropType<ShortUser[]>,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup() {
    return {
      getFullNameAndCompany,
      customShortUserFilter
    }
  }
})
</script>
