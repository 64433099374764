<template>
  <div class="d-flex align-center justify-center">
    <span
      v-if="!branding.logoUrl"
      class="text-h5 font-weight-bold text-primary"
      style="user-select: none"
    >
      {{ branding.portalName }}
    </span>
    <img
      v-else
      :src="fitCropImage(branding.logoUrl, 304, 120, '&')"
      :alt="branding.portalName"
      style="max-height: 60px; max-width: 152px"
    />
  </div>
</template>

<script lang="ts">
import { fitCropImage } from '@/helpers'
import { Branding } from '@/modules/portalSettings/types'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'BrandingLogo',
  props: {
    branding: {
      type: Object as PropType<Branding>,
      required: true
    }
  },
  setup() {
    return { fitCropImage }
  }
})
</script>
