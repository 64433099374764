import * as Sentry from '@sentry/vue'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import vueDebounce from 'vue-debounce'
import VueEasyLightbox from 'vue-easy-lightbox'
import App from '@/App.vue'
import i18n from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'
import router from '@/router'

const app = createApp(App)

window.addEventListener('unhandledrejection', (event) => {
  Sentry.captureException(event.reason)
})

Sentry.init({
  app,
  dsn: import.meta.env.VITE_STOREFRONT_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllInputs: false,
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  normalizeDepth: 10,
  logErrors: true,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: import.meta.env.VITE_STOREFRONT_SENTRY_TRACES_SAMPLE_RATE ?? 1.0,
  environment: import.meta.env.MODE,
  release:
    import.meta.env.MODE == 'production'
      ? import.meta.env.PACKAGE_VERSION
      : import.meta.env.VITE_COMMIT_SHA
})

app.use(createPinia())
app.use(router)
app.use(vuetify)
app.use(i18n)
app.use(VueEasyLightbox)

app.directive('debounce', vueDebounce({ defaultTime: '1000ms', trim: true }))

app.mount('#app')
