<template>
  <v-btn variant="text" density="comfortable" color="primary" icon v-bind="$attrs">
    <v-icon :size="size"><slot /></v-icon>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SfIconButton',
  inheritAttrs: false,
  props: {
    size: {
      type: Number,
      default: undefined
    }
  }
})
</script>
