<template>
  <div class="d-flex flex-row align-center ga-1 my-1">
    <positive-integer-text-field v-model.number.lazy="stockCount" @change="checkStockCount" />
  </div>
</template>

<script setup lang="ts">
import { isPositiveInteger } from '@/components/filter/helpers'
import PositiveIntegerTextField from '@/components/filter/PositiveIntegerTextField.vue'
import helpers from '@/modules/message/helpers'
import { ref, watch } from 'vue'

const props = defineProps({
  stokCountFilter: {
    type: Number,
    default: undefined
  }
})

const stockCount = ref<number | undefined>()
const emit = defineEmits<{
  update: [value: number | undefined]
}>()

watch(
  () => props.stokCountFilter,
  () => {
    stockCount.value = props.stokCountFilter
  }
)

const emitUpdate = () => {
  emit('update', stockCount.value)
}

const checkStockCount = () => {
  if (stockCount.value && !isPositiveInteger(stockCount.value)) {
    helpers.reportError('onlyWholePositiveNumbers')
    stockCount.value = undefined
  }

  emitUpdate()
}
</script>
