import { VNode, h } from 'vue'

const VNodes = (props: { vnodes: VNode[] | string }) => {
  if (Array.isArray(props.vnodes)) {
    return props.vnodes
  } else {
    return h('span', props.vnodes)
  }
}

export default VNodes
