import { Event } from '@/modules/tracking/types'
import AddToCartButtonEvents from '@/modules/tracking/events/helper/addToCartButton'

export default class StartPageEvent extends Event {
  static readonly AddToCart: AddToCartButtonEvents = {
    addToCart: new StartPageEvent('Produkt zum Warenkorb hinzugefügt'),
    request: new StartPageEvent('Produkt angefragt')
  }

  constructor(action: string, label?: string) {
    super('Startseite', action, label ?? '')
  }
}
