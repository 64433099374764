<template>
  <v-list-item class="d-flex" v-bind="$attrs" title="">
    <template #prepend>
      <v-avatar rounded="0">
        <v-img
          :src="item.imageUrl ? fitCropImage(item.imageUrl, 80, 80) : placeholderImage"
          :lazy-src="emptyPlaceholderImage"
        />
      </v-avatar>
    </template>

    <v-list-item-title>
      <sf-text class="text-truncate">
        <v-nodes :vnodes="highlightResult(item.title, searchTerm)" />
      </sf-text>
    </v-list-item-title>

    <v-list-item-subtitle>
      <span class="text-body-2 text-medium-emphasis">
        <span v-if="item.articleNumber.ean">
          <v-nodes :vnodes="highlightResult(item.articleNumber.ean, searchTerm)" />
        </span>
        <span v-if="item.articleNumber.ean && item.articleNumber.manufacturerProductId">
          &nbsp;-&nbsp;
        </span>
        <span v-if="item.articleNumber.manufacturerProductId">
          <v-nodes
            :vnodes="highlightResult(item.articleNumber.manufacturerProductId, searchTerm)"
          />
        </span>
        <span
          v-if="
            (item.productId && item.articleNumber.manufacturerProductId) ||
            (item.productId && item.articleNumber.ean)
          "
        >
          &nbsp;-&nbsp;
        </span>
        <span v-if="item.productId">
          <v-nodes :vnodes="highlightResult(item.productId.toString(), searchTerm)" />
        </span>
      </span>
    </v-list-item-subtitle>
  </v-list-item>
</template>

<script lang="ts">
import { MediumProduct } from '@/generatedTypes'
import { defineComponent, PropType } from 'vue'
import { emptyPlaceholderImage, fitCropImage, placeholderImage } from '@/helpers'
import VNodes from '@/components/VNodes'
import SfText from '@/components/text/SfText.vue'
import { highlightResult } from '@/modules/search/helpers'

export default defineComponent({
  name: 'InstantSearchProductItem',
  components: { VNodes, SfText },
  inheritAttrs: false,
  props: {
    item: {
      required: true,
      type: Object as PropType<MediumProduct>
    },
    searchTerm: {
      type: String,
      default: undefined
    }
  },
  setup() {
    return { fitCropImage, emptyPlaceholderImage, placeholderImage, highlightResult }
  }
})
</script>
