<template>
  <v-row>
    <v-col cols="12">
      <collection-loading v-if="loading" />

      <template v-if="!loading && collection">
        <collection-header :collection="collection" :portal-settings="portalSettings" />
        <v-divider class="my-1" />

        <v-row dense>
          <v-col cols="12" sm="6" md="4">
            <collection-assignee-card :collection="collection" />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <collection-data-card :collection="collection" />
          </v-col>
          <v-col cols="12" md="4">
            <comment-card
              v-model="collection.description"
              :max-length="DEFAULT_COLLECTION_COMMENT_LENGTH"
              @debounce="updateCollectionComment"
            />
          </v-col>
        </v-row>

        <v-divider class="my-2" />

        <v-row dense>
          <v-col>
            <collection-add-product-input :collection="collection" />
          </v-col>
        </v-row>

        <v-divider class="my-2" />

        <list-empty
          v-if="collection.items.length === 0"
          :message="$t('collection.emptyCollection')"
          :icon="mdiStarOutline"
        />

        <list-content v-else :extra-offset="4">
          <v-col>
            <collection-position
              v-for="item in collection.items"
              :key="item.productId"
              class="pw-collection-position"
              :collection-item="item"
              @change-quantity="changeQuantity"
              @comment-changed="updateComment"
              @remove="removeProduct"
            />
          </v-col>
        </list-content>
      </template>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import CommentCard from '@/components/CommentCard.vue'
import ListContent from '@/components/list/ListContent.vue'
import ListEmpty from '@/components/list/ListEmpty.vue'
import { useTitle } from '@/helpers'
import CollectionAddProductInput from '@/modules/collection/components/CollectionAddProductInput.vue'
import CollectionAssigneeCard from '@/modules/collection/components/CollectionAssigneeCard.vue'
import CollectionDataCard from '@/modules/collection/components/CollectionDataCard.vue'
import CollectionHeader from '@/modules/collection/components/CollectionHeader.vue'
import CollectionLoading from '@/modules/collection/components/CollectionLoading.vue'
import CollectionPosition from '@/modules/collection/components/CollectionPosition.vue'
import { DEFAULT_COLLECTION_COMMENT_LENGTH } from '@/modules/collection/types'
import useCollection from '@/modules/collection/useCollection'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import { mdiStarOutline } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const currentRoute = useRoute()

watch(
  () => currentRoute.params.id,
  (newId) => {
    useCollection().getCollection(newId as string)
  },
  { immediate: true }
)

const { collection, loading } = storeToRefs(useCollection())
const { portalSettings } = storeToRefs(usePortalSettings())

const pageTitle = computed(() => {
  return collection.value
    ? `${t(`windowTitle.Collection`)} ${collection.value.name}`
    : `${t(`windowTitle.Collection`)}`
})

useTitle(pageTitle)

const removeProduct = (id: number) => {
  useCollection().removeCollectionItem(id)
}

const changeQuantity = ({ id, quantity }: { id: number; quantity: number }) => {
  useCollection().setQuantity(id, quantity)
}

const updateComment = ({ id, comment }: { id: number; comment: string }) => {
  useCollection().updateItemComment(id, comment)
}

const updateCollectionComment = (comment: string) => {
  if (collection.value) {
    useCollection().updateComment(comment)
  }
}
</script>
