<template>
  <div class="d-flex flex-row justify-space-between align-start">
    <sf-title
      >{{ $t('featureAd.NEW') }}
      <span class="font-weight-medium feature--text">{{ $t('featureAd.comparison.title') }}</span>
      <v-icon color="#43C59E" :icon="mdiSwapHorizontalBold" />
    </sf-title>
    <sf-icon-button color="#43C59E" :icon="mdiClose" @click="$emit('close')" />
  </div>
</template>

<script lang="ts">
import SfIconButton from '@/components/button/SfIconButton.vue'
import SfTitle from '@/components/text/SfTitle.vue'
import { mdiClose, mdiSwapHorizontalBold } from '@mdi/js'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FeatureAdHeader',
  components: {
    SfIconButton,
    SfTitle
  },
  emits: ['close'],
  setup() {
    return {
      mdiClose,
      mdiSwapHorizontalBold
    }
  }
})
</script>
