<template>
  <v-row dense>
    <v-col cols="12" class="d-flex flex-wrap flex-gap align-center">
      <v-chip
        ref="type"
        :to="{ name: 'Category', params: { id: 'SET' + productType.id } }"
        @click="trackProductType"
      >
        {{ productType.name }}
      </v-chip>
      <sf-text-button ref="html" :loading="loadingHTML" @click="loadDatasheetHtml">
        {{ $t('datasheet') }}
      </sf-text-button>
      <sf-text-button ref="pdf" :loading="loadingPDF" @click="loadDatasheetPdf">
        {{ $t('datasheetPdf') }}
      </sf-text-button>
      <sf-text-button
        v-if="datasheet.manufacturerUrl"
        ref="manufacturer"
        :href="datasheet.manufacturerUrl"
        target="_blank"
        @click="trackDatasheetManufacturer"
      >
        {{ $t('datasheetManufacturer') }}
      </sf-text-button>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import SfTextButton from '@/components/button/SfTextButton.vue'
import { Datasheet, ProductType } from '@/generatedTypes'
import useProductDatasheet from '@/modules/product/useProductDatasheet'
import ProductPageEvent from '@/modules/tracking/events/productPage'
import { trackEvent } from '@/modules/tracking/useTracking'
import { storeToRefs } from 'pinia'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'ProductDatasheetLinks',
  components: { SfTextButton },
  props: {
    productType: {
      type: Object as PropType<ProductType>,
      required: true
    },
    datasheet: {
      type: Object as PropType<Datasheet>,
      required: true
    },
    productId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const { loadingPDF, loadingHTML } = storeToRefs(useProductDatasheet())

    const trackDatasheetManufacturer = () => {
      trackEvent(ProductPageEvent.Datasheet.Manufacturer(props.productId))
    }

    const trackProductType = () => {
      trackEvent(ProductPageEvent.ProductType(props.productId))
    }

    const loadDatasheetHtml = () => {
      useProductDatasheet().loadHTMLDatasheet(props.productId)
      trackEvent(ProductPageEvent.Datasheet.Html(props.productId))
    }

    const loadDatasheetPdf = () => {
      useProductDatasheet().loadPDFDatasheet(props.productId)
      trackEvent(ProductPageEvent.Datasheet.Pdf(props.productId))
    }

    return {
      trackDatasheetManufacturer,
      trackProductType,
      loadDatasheetHtml,
      loadDatasheetPdf,
      loadingHTML,
      loadingPDF
    }
  }
})
</script>
