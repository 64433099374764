import { Event } from '@/modules/tracking/types'

export default class ApplicationHeaderEvent extends Event {
  static readonly ProductComparison = new ApplicationHeaderEvent('Produktvergleich')
  static readonly Logo = new ApplicationHeaderEvent('Logo angeklickt')
  static readonly Cart = new ApplicationHeaderEvent('Einkaufswagen')
  static readonly Profile = new ApplicationHeaderEvent('Mein Profil')
  static readonly Logout = new ApplicationHeaderEvent('Ausloggen')
  static readonly GenericMenuItem: (germanName: string) => ApplicationHeaderEvent = (
    germanName: string
  ) => new ApplicationHeaderEvent(germanName)
  static readonly InviteColleagues = new ApplicationHeaderEvent('Kollegen eingeladen')

  constructor(action: string, label?: string) {
    super('Anwendungsheader', action, label ?? '')
  }
}
