<template>
  <v-dialog v-model="dialog" :width="500">
    <template #activator="{ props }">
      <sf-button v-if="!$vuetify.display.mobile" class="ml-1" v-bind="props">
        {{ title }}
      </sf-button>
      <sf-icon-button v-if="$vuetify.display.mobile" :size="32" v-bind="props" :icon="mdiPlus" />
    </template>
    <v-card>
      <v-card-item>
        <v-card-title>
          <sf-heading>{{ title }}</sf-heading>
        </v-card-title>
      </v-card-item>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <sf-text-field
            v-model="name"
            :label="$t('name') + ' *'"
            :rules="[rules.requiredRule]"
            autocomplete="off"
            @keydown.enter.prevent="create"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <sf-text-button color="grey" class="text-medium-emphasis" @click="close">
          {{ $t('cancel') }}
        </sf-text-button>
        <sf-text-button color="primary" :disabled="!valid" :loading="loading" @click="create">
          {{ $t('create') }}
        </sf-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import SfButton from '@/components/button/SfButton.vue'
import SfIconButton from '@/components/button/SfIconButton.vue'
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfTextField from '@/components/input/SfTextField.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import rules from '@/rules'
import { VForm } from '@/types'
import { mdiPlus } from '@mdi/js'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'CreateDialog',
  components: { SfHeading, SfButton, SfTextButton, SfIconButton, SfTextField },
  props: {
    title: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['create'],
  setup(_, { emit }) {
    const name = ref('')
    const dialog = ref(false)
    const valid = ref(false)
    const form = ref<VForm>()

    const create = () => {
      if (form.value?.validate()) {
        emit('create', name.value)
      }
    }

    const close = () => {
      dialog.value = false
      name.value = ''
      form.value?.resetValidation()
    }

    return {
      name,
      dialog,
      valid,
      form,
      rules,
      close,
      create,
      mdiPlus
    }
  }
})
</script>
