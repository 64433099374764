<template>
  <v-row dense>
    <v-col cols="12" class="d-flex justify-center">
      <v-card
        variant="outlined"
        width="500px"
        class="d-flex flex-column align-center pa-2 transparent-card"
      >
        <div class="d-flex align-center mb-2">
          <v-icon class="mr-1" :icon="mdiInformationOutline" />
          <sf-title>{{ $t('featureAd.requestFunction') }}</sf-title>
        </div>
        <sf-text>{{ $t('featureAd.descriptionStart') }}</sf-text>
        <div class="d-flex flex-column my-2">
          <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
          <sf-text>1. {{ $t('featureAd.step1') }}</sf-text>
          <sf-text>2. {{ $t('featureAd.step2') }}</sf-text>
          <sf-text>3. {{ $t('featureAd.step3') }}</sf-text>
          <!-- eslint-enable -->
        </div>
        <sf-text>{{ $t('featureAd.descriptionEnd') }}</sf-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import SfText from '@/components/text/SfText.vue'
import SfTitle from '@/components/text/SfTitle.vue'
import { mdiInformationOutline } from '@mdi/js'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CreateRequestHint',
  components: {
    SfText,
    SfTitle
  },
  setup() {
    return {
      mdiInformationOutline
    }
  }
})
</script>
