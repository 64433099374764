<template>
  <span class="text-body-1 text-medium-emphasis">
    {{
      $t('price.priceVatStar').toString() +
      (portalSettings.grossPortal
        ? $t('includingVatHint', [portalSettings.vatRatePercent])
        : $t('excludingVatHint', [portalSettings.vatRatePercent]))
    }}
  </span>
</template>

<script lang="ts">
import { PortalSettings } from '@/modules/portalSettings/types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'VatHint',
  props: {
    portalSettings: {
      type: Object as PropType<PortalSettings>,
      required: true
    }
  }
})
</script>
