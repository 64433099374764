import { Event } from '@/modules/tracking/types'

export default class MyCompanyEvent extends Event {
  static readonly ChangeSubsidiary = new MyCompanyEvent('Firma gewechselt')

  static readonly Tabs = class {
    static readonly Employees = new MyCompanyEvent('Tab angeklickt', 'Mitarbeiter')
    static readonly Addresses = new MyCompanyEvent('Tab angeklickt', 'Adressen')
    static readonly OrderApproval = new MyCompanyEvent('Tab angeklickt', 'Bestellfreigabe')
  }

  static readonly OrderLimit = class {
    static readonly Default = class {
      static readonly Activated = new MyCompanyEvent('Bestelllimits aktiviert')
      static readonly Deactivated = new MyCompanyEvent('Bestelllimits deaktiviert')
      static readonly Limit = new MyCompanyEvent('Allgemeines Limit geändert')
      static readonly Assignees = new MyCompanyEvent('Standardfreigabebearbeiter geändert')
    }
    static readonly Individual = class {
      static readonly Assignees = new MyCompanyEvent(
        'Individuelle Standardfreigabebearbeiter geändert'
      )
      static readonly OrderRight = new MyCompanyEvent('Individuelles Bestellrecht geändert')
      static readonly Limit = new MyCompanyEvent('Individuelles Bestelllimit geändert')
    }
  }

  private constructor(action: string, label?: string) {
    super('Meine Firma', action, label ?? '')
  }
}
