<template>
  <div class="text-overline text-center mt-3">
    <v-responsive max-width="280" class="mx-auto">
      © {{ new Date().getFullYear() }}
      <span>{{ ownerName }}</span>
      <span v-if="legalInfoUrl">
        |
        <sf-link :href="legalInfoUrl">{{ $t('legalInfo') }}</sf-link>
      </span>
      <span v-if="privacyUrl">
        |
        <sf-link :href="privacyUrl">{{ $t('privacy') }}</sf-link>
      </span>
      <span v-if="termsUrl">
        | <sf-link :href="termsUrl">{{ $t('terms') }}</sf-link>
      </span>
    </v-responsive>
  </div>
</template>

<script lang="ts">
import SfLink from '@/components/text/SfLink.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LoginFooter',
  components: { SfLink },
  props: {
    ownerName: {
      type: String,
      required: true
    },
    legalInfoUrl: {
      type: String,
      default: undefined
    },
    privacyUrl: {
      type: String,
      default: undefined
    },
    termsUrl: {
      type: String,
      default: undefined
    }
  }
})
</script>
