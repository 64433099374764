<template>
  <sf-delete-confirm-dialog
    :title="$t('company.deleteEmployee')"
    :description="$t('removeDialog')"
    @confirm="$emit('delete')"
  >
    <template #activator="{ props }">
      <v-btn id="pw-remove-employee" variant="text" color="error" v-bind="props">
        {{ $t('company.deleteEmployee') }}
      </v-btn>
    </template>
  </sf-delete-confirm-dialog>
</template>

<script lang="ts">
import SfDeleteConfirmDialog from '@/components/dialog/SfDeleteConfirmDialog.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UserDeleteButton',
  components: { SfDeleteConfirmDialog },
  emits: ['delete']
})
</script>
