<script lang="ts">
import useAuthentication from '@/modules/authentication/useAuthentication'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LogoutPage',
  setup() {
    useAuthentication().logout()
  }
})
</script>
