<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-tabs v-model="tab" show-arrows bg-color="transparent" color="primary">
          <v-tab v-if="product.isBundle" class="product-tab" @click="log('parts')">
            {{ $t('partsList') }}
          </v-tab>
          <v-tab v-if="product.suppliers.length > 0" class="product-tab" @click="log('sources')">
            {{ $t('sources') }}
          </v-tab>
          <v-tab class="product-tab" @click="log('technicalData')">
            {{ $t('technicalData') }}
          </v-tab>
          <v-tab
            v-if="product.productDescription.length > 0"
            class="product-tab"
            @click="log('description')"
          >
            {{ $t('productDescription') }}
          </v-tab>
          <v-tab
            v-if="accessories && accessories.length > 0"
            class="product-tab"
            @click="log('accessories')"
          >
            {{ $t('productAccessories') }}
          </v-tab>
        </v-tabs>
        <v-divider />
        <v-window v-model="tab">
          <v-window-item v-if="product.isBundle">
            <product-bundle-items
              :bundle-items="bundleItems"
              :show-product-ids="portalSettings.showProductIds"
              class="mt-1"
            />
          </v-window-item>
          <v-window-item v-if="product.suppliers.length > 0">
            <product-sources
              :product-id="product.productId"
              :sources="product.suppliers"
              :loading-realtime-prices="loadingRealtimePrices"
              class="mt-1"
            />
          </v-window-item>
          <v-window-item>
            <product-technical-details
              :product-identifiers="getProductIdentifiers(product, portalSettings.showProductIds)"
              :technical-details="product.technicalDetails"
              class="mt-1"
            />
          </v-window-item>
          <v-window-item v-if="product.productDescription.length > 0">
            <product-description :product-description="product.productDescription" class="mt-1" />
          </v-window-item>
          <v-window-item v-if="accessories && accessories.length > 0">
            <product-accessories :accessories="accessories" class="mt-1" />
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { BundleReference, FullProduct } from '@/generatedTypes'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import ProductAccessories from '@/modules/product/components/ProductAccessories.vue'
import ProductBundleItems from '@/modules/product/components/ProductBundleItems.vue'
import ProductDescription from '@/modules/product/components/ProductDescription.vue'
import ProductSources from '@/modules/product/components/ProductSources.vue'
import ProductTechnicalDetails from '@/modules/product/components/ProductTechnicalDetails.vue'
import { getProductIdentifiers } from '@/modules/product/helpers'
import useAccessories from '@/modules/product/useAccessories'
import ProductPageEvent from '@/modules/tracking/events/productPage'
import { trackEvent } from '@/modules/tracking/useTracking'
import { storeToRefs } from 'pinia'
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'ProductDetails',
  components: {
    ProductAccessories,
    ProductBundleItems,
    ProductDescription,
    ProductSources,
    ProductTechnicalDetails
  },
  props: {
    product: {
      type: Object as PropType<FullProduct>,
      required: true
    },
    bundleItems: {
      type: Array as PropType<BundleReference[]>,
      required: true
    },
    loadingRealtimePrices: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const tab = ref(0)
    const { portalSettings } = storeToRefs(usePortalSettings())
    const { accessories } = storeToRefs(useAccessories())

    const log = (value: 'parts' | 'sources' | 'technicalData' | 'description' | 'accessories') => {
      let trackingEvent

      switch (value) {
        case 'parts':
          trackingEvent = ProductPageEvent.Tabs.PartsList(props.product.productId)
          break
        case 'sources':
          trackingEvent = ProductPageEvent.Tabs.Sources(props.product.productId)
          break
        case 'technicalData':
          trackingEvent = ProductPageEvent.Tabs.TechnicalData(props.product.productId)
          break
        case 'description':
          trackingEvent = ProductPageEvent.Tabs.Description(props.product.productId)
          break
        case 'accessories':
          trackingEvent = ProductPageEvent.Tabs.Accessories(props.product.productId)
          break
      }

      if (trackingEvent) {
        trackEvent(trackingEvent)
      }
    }

    return {
      tab,
      portalSettings,
      accessories,
      log,
      getProductIdentifiers
    }
  }
})
</script>
