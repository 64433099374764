import { StorefrontError } from '@/modules/error/types'
import { Ref, ref, readonly } from 'vue'

interface ErrorState {
  error: StorefrontError
}

const initialState = (): ErrorState => {
  return {
    error: {
      code: undefined,
      message: '',
      customHandler: undefined
    }
  }
}

type ReturnType = {
  state: Readonly<Ref<ErrorState>>
  getError: () => StorefrontError
  setError: (error: StorefrontError) => void
  reset: () => void
}

const state = ref(initialState())

export default function useError(): ReturnType {
  const getError = () => {
    return state.value.error
  }

  const setError = (apiError: StorefrontError) => {
    state.value.error = apiError
  }

  const reset = () => {
    Object.assign(state.value, initialState())
  }

  return {
    state: readonly(state),
    getError,
    setError,
    reset
  }
}
