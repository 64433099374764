<template>
  <sf-delete-confirm-dialog
    :title="$t('collection.deleteAction')"
    :description="$t('collection.deleteDialog')"
    @confirm="deleteCollection"
  >
    <template #activator="{ props }">
      <v-list-item v-bind="props">
        <v-list-item-title class="text-error">
          {{ $t('collection.deleteAction') }}
        </v-list-item-title>
      </v-list-item>
    </template>
  </sf-delete-confirm-dialog>
</template>

<script lang="ts">
import SfDeleteConfirmDialog from '@/components/dialog/SfDeleteConfirmDialog.vue'
import useCollections from '@/modules/collection/useCollections'
import CollectionEvent from '@/modules/tracking/events/collection'
import { trackEvent } from '@/modules/tracking/useTracking'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CollectionDeleteButton',
  components: { SfDeleteConfirmDialog },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const deleteCollection = () => {
      useCollections().removeCollection(props.id)
      trackEvent(CollectionEvent.DeleteCollection)
    }

    return {
      deleteCollection
    }
  }
})
</script>
