import { useStorage } from '@vueuse/core'
import router from '@/router'
import { ApiError, handleApiError } from '@/handleApiError'
import { useStateManager } from '@/store'
import { Ref, ref, shallowReadonly } from 'vue'
import { Token } from '@/generatedTypes'
import { LoginCredentials, LoginError } from '@/modules/authentication/types'
import api from '@/modules/authentication/api'
import { getBasicAuthString } from '@/modules/authentication/helpers'
import { trackEvent } from '@/modules/tracking/useTracking'
import LoginEvent from '@/modules/tracking/events/login'
import { useJwt } from '@vueuse/integrations/useJwt'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings.ts'

export interface AuthState {
  accessToken: string
  refreshToken: string
  guestToken: string
  isImpersonateMode: boolean
  isPunchoutMode: boolean
  isRedslave: boolean
  isReadonly: boolean
  loginError: LoginError
  loading: boolean
}

const state = ref({
  accessToken: useStorage('accessToken', ''),
  refreshToken: useStorage('refreshToken', ''),
  guestToken: '',
  isImpersonateMode: useStorage('isImpersonateMode', false),
  isPunchoutMode: useStorage('isPunchoutMode', false),
  isRedslave: useStorage('isRedslave', false),
  isReadonly: false,
  loginError: LoginError.NONE,
  loading: false
})

type ReturnType = {
  autoLogin: (refreshToken: Token) => Promise<void>
  guestLogin: (token: Token) => void
  hasValidAccessToken: () => boolean
  hasValidGuestToken: () => boolean
  isRedslave: () => boolean
  login: (arg: LoginCredentials) => Promise<void>
  loginViaOauth: (refreshToken: Token) => Promise<void>
  loginViaPunchout: (refreshToken: Token) => Promise<void>
  loginWithAccessToken: (accessToken: Token) => void
  logout: () => void
  refreshAccessToken: (refreshToken: Token) => Promise<void>
  reset: (withRedslave?: boolean) => void
  setImpersonateMode: (isImpersonateMode: boolean) => void
  setLoginError: (loginError: LoginError) => void
  setPunchoutMode: (punchoutMode: boolean) => void
  setRedslave: (redslave: boolean) => void
  state: Readonly<Ref<AuthState>>
}

const onBasicAuthFailure = (error: ApiError) => {
  state.value.accessToken = ''
  if (error.code === 401) {
    // wrong credentials
    state.value.loginError = LoginError.WRONG_CREDENTIALS
  } else if (error.code === 403) {
    // user deactivated
    state.value.loginError = LoginError.USER_DEACTIVATED
  } else {
    // other error
    handleApiError(error)
  }
}

export default function useAuthentication(): ReturnType {
  const hasValidAccessToken = () => {
    return state.value.accessToken !== ''
  }

  const hasValidGuestToken = () => {
    return state.value.guestToken !== ''
  }

  const isRedslave = () => {
    return state.value.isRedslave
  }

  const loginWithAccessToken = (accessToken: Token) => {
    reset(false)

    state.value.accessToken = accessToken.value
  }

  const guestLogin = (token: Token) => {
    reset(false)

    state.value.guestToken = token.value

    const { payload } = useJwt<{ readonly: boolean }>(token.value)
    if (payload.value) {
      state.value.isReadonly = payload.value.readonly
    }
  }

  const loginViaPunchout = async (refreshToken: Token) => {
    reset()

    return await api
      .getAccessToken(refreshToken.value)
      .then(({ data }) => {
        state.value.accessToken = data.accessToken
        state.value.refreshToken = data.refreshToken
        state.value.isPunchoutMode = true
        trackEvent(LoginEvent.Punchout)
      })
      .catch(onBasicAuthFailure)
  }

  const loginViaOauth = async (refreshToken: Token) => {
    reset()

    return await api
      .getAccessToken(refreshToken.value)
      .then(({ data }) => {
        state.value.accessToken = data.accessToken
        state.value.refreshToken = data.refreshToken
        trackEvent(LoginEvent.OAuth)
      })
      .catch(onBasicAuthFailure)
  }

  const autoLogin = async (refreshToken: Token) => {
    reset()

    return api
      .getAccessToken(refreshToken.value)
      .then(({ data }) => {
        state.value.accessToken = data.accessToken
        state.value.refreshToken = data.refreshToken
      })
      .catch(onBasicAuthFailure)
  }

  const login = async (loginCredentials: LoginCredentials) => {
    state.value.loading = true
    state.value.guestToken = ''

    return api
      .getLoginRefreshToken(getBasicAuthString(loginCredentials))
      .then(async ({ data }) => {
        return api
          .getAccessToken(data)
          .then(({ data }) => {
            state.value.loginError = LoginError.NONE
            state.value.accessToken = data.accessToken
            state.value.refreshToken = data.refreshToken
            state.value.guestToken = ''

            router.push((router.currentRoute.value.query.redirect as string) || '/')
            trackEvent(LoginEvent.Login)
          })
          .catch(() => {
            state.value.loginError = LoginError.UNKNOWN_ERROR
          })
      })
      .catch(onBasicAuthFailure)
      .finally(() => {
        state.value.loading = false
      })
  }

  const refreshAccessToken = async (refreshToken: Token) => {
    return api
      .getAccessToken(refreshToken.value)
      .then(({ data }) => {
        state.value.accessToken = data.accessToken
        state.value.refreshToken = data.refreshToken
      })
      .catch(() => useAuthentication().logout())
  }

  const setImpersonateMode = (isImpersonateMode: boolean) => {
    state.value.isImpersonateMode = isImpersonateMode
  }

  const setLoginError = (loginError: LoginError) => {
    state.value.loginError = loginError
  }

  const setRedslave = (redslave: boolean) => {
    state.value.isRedslave = redslave
  }

  const setPunchoutMode = (punchoutMode: boolean) => {
    state.value.isPunchoutMode = punchoutMode
  }

  const logout = () => {
    const { portalSettings } = usePortalSettings()
    const backToPlatformLink = portalSettings.backToPlatformLink

    useStateManager().reset()

    if (!router.currentRoute.value.path.startsWith('/login')) {
      if (backToPlatformLink) {
        window.location.href = backToPlatformLink
      } else {
        router.push('/login')
      }
    }
  }

  const reset = (withRedslave = true) => {
    state.value.accessToken = ''
    state.value.refreshToken = ''
    state.value.guestToken = ''
    state.value.isImpersonateMode = false
    state.value.isPunchoutMode = false
    state.value.isReadonly = false
    state.value.loginError = LoginError.NONE
    state.value.loading = false
    if (withRedslave) {
      state.value.isRedslave = false
    }
  }

  return {
    autoLogin,
    guestLogin,
    hasValidAccessToken,
    hasValidGuestToken,
    isRedslave,
    login,
    loginViaOauth,
    loginViaPunchout,
    loginWithAccessToken,
    logout,
    refreshAccessToken,
    reset,
    setImpersonateMode,
    setLoginError,
    setPunchoutMode,
    setRedslave,
    state: shallowReadonly(state)
  }
}
