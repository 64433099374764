<template>
  <v-row>
    <v-col cols="12">
      <list-header :title="$t('shoppingCart')">
        <template #indicator>
          <realtime-prices-indicator :loading="loading.realtime" />
        </template>
        <template #action>
          <cart-menu :quotes-enabled="portalSettings.useQuotes" />
        </template>
      </list-header>

      <list-loading v-if="loading.cart" />

      <list-empty v-else-if="isEmpty" :message="$t('emptyCart')" :icon="mdiCart" />

      <template v-else-if="cart">
        <list-content>
          <v-col cols="12">
            <cart-optimizations v-if="portalSettings.showSuppliers" class="mb-1" />

            <supplier-cart
              v-for="supplierCart in cart.supplierCarts"
              :key="supplierCart.id"
              :supplier-cart="supplierCart"
              :is-draggable="cart.supplierCarts.length > 1"
              :hide-infos="!portalSettings.showSuppliers"
            />
            <template v-if="cart.canOrder != CanOrder.FORBIDDEN">
              <v-divider class="my-2" />

              <cart-messages v-if="cart.messages.length > 0" :cart-messages="cart.messages" />

              <v-row dense>
                <v-col cols="12">
                  <cart-info
                    :cart-info="cart.cartInfo"
                    :gross-portal="portalSettings.grossPortal"
                    :is-supplier-cart="false"
                    big-sum
                  >
                    <template #button>
                      <punchout-dialog v-if="isPunchoutMode" :disabled="orderDisabled" big-button />
                      <cart-order-button v-else :can-order="cart.canOrder" big-button />
                    </template>
                  </cart-info>
                </v-col>
              </v-row>
            </template>
          </v-col>
        </list-content>
      </template>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import RealtimePricesIndicator from '@/components/RealtimePricesIndicator.vue'
import ListContent from '@/components/list/ListContent.vue'
import ListEmpty from '@/components/list/ListEmpty.vue'
import ListHeader from '@/components/list/ListHeader.vue'
import ListLoading from '@/components/list/ListLoading.vue'
import { CanOrder } from '@/generatedTypes'
import { useTitle } from '@/helpers'
import useAuthentication from '@/modules/authentication/useAuthentication'
import CartInfo from '@/modules/cart/components/CartInfo.vue'
import CartMenu from '@/modules/cart/components/CartMenu.vue'
import CartMessages from '@/modules/cart/components/CartMessages.vue'
import CartOptimizations from '@/modules/cart/components/CartOptimizations.vue'
import CartOrderButton from '@/modules/cart/components/CartOrderButton.vue'
import SupplierCart from '@/modules/cart/components/SupplierCart.vue'
import useCart from '@/modules/cart/useCart'
import useCheckout from '@/modules/order/useCheckout'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import PunchoutDialog from '@/modules/punchout/components/PunchoutDialog.vue'
import { mdiCart } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
useTitle(ref(`${t('shoppingCart')}`))
useCart().loadCart(false)

const { portalSettings } = storeToRefs(usePortalSettings())
const { cart, loading, isEmpty } = storeToRefs(useCart())

const isPunchoutMode = computed(() => useAuthentication().state.value.isPunchoutMode)

const invalidComments = computed(() => {
  return !useCheckout().allCommentsValid
})

const orderDisabled = computed(() => {
  return (cart.value && cart.value.canOrder == CanOrder.DISABLED) || invalidComments.value
})
</script>
