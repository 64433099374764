import { ApiError, handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import { OrderTransaction } from '@/generatedTypes'
import api from '@/modules/order/api'
import FileSaver from 'file-saver'
import { parseFileName } from '@/helpers'

interface OrderTransactionsState {
  documents: OrderTransaction[]
  loading: {
    documents: boolean
    downloadAllDocuments: boolean
    downloadDocument: boolean
    documentRowId: string | undefined
  }
}

const initialState = (): OrderTransactionsState => {
  return {
    documents: [],
    loading: {
      documents: false,
      downloadAllDocuments: false,
      downloadDocument: false,
      documentRowId: undefined
    }
  }
}

const useOrderDocuments = defineStore('OrderTransactions', {
  state: () => initialState(),
  actions: {
    async getOrderTransactions(orderId: string) {
      this.loading.documents = true

      return api
        .getOrderTransactions(orderId)
        .then(({ data }) => {
          this.documents = data.transactions
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            appearance: 'CUSTOM',
            customHandler: 'OrderTransactions',
            customMessageKey: 'error.documentsNotFound'
          })
        })
        .finally(() => {
          this.loading.documents = false
        })
    },
    async loadPDFDocument(id: string, transactionId: string) {
      this.loading.downloadDocument = true
      this.loading.documentRowId = transactionId

      return api
        .loadPDFDocument(id, transactionId)
        .then((response) => {
          FileSaver.saveAs(response.data, parseFileName(response.headers))
        })
        .catch((error: ApiError) => {
          handleApiError(error, { customMessageKey: 'documentError' })
        })
        .finally(() => {
          this.loading.downloadDocument = false
          this.loading.documentRowId = undefined
        })
    },
    async loadAllPDFDocuments(id: string) {
      this.loading.downloadAllDocuments = true

      return api
        .loadAllPDFDocuments(id)
        .then((response) => {
          const fileName = id + '.zip'
          FileSaver.saveAs(response.data, fileName)
        })
        .catch((error: ApiError) => {
          handleApiError(error, { customMessageKey: 'documentError' })
        })
        .finally(() => (this.loading.downloadAllDocuments = false))
    }
  }
})

export default useOrderDocuments
