<template>
  <div>
    <!-- Title -->
    <list-header :title="$t('categories')" hide-show-categories-button>
      <template v-if="showHideButton" #action>
        <sf-icon-button
          v-if="showCategories"
          color=""
          :icon="mdiArrowCollapseLeft"
          @click="hideCategories"
        />
      </template>
      <template v-else-if="showCloseButton" #action>
        <sf-icon-button color="" :icon="mdiClose" @click="$emit('close')" />
      </template>
    </list-header>
    <!-- Loading -->
    <div v-if="loading" class="mt-2">
      <v-skeleton-loader v-for="index in 10" :key="index" class="mt-2" type="text" />
    </div>
    <!-- Categories -->
    <list-content
      v-else-if="categories.length > 0"
      class="d-flex flex-column overflow-y-auto scrollbar pr-1"
    >
      <v-list
        density="compact"
        hoverable
        open-strategy="multiple"
        select-strategy="single-leaf"
        :selected="selectedCategory !== undefined ? [selectedCategory.searchTerm] : []"
        :opened="openCategoriesSearchTerm"
        class="cursor-pointer scrollbar"
        :items="categories"
        item-title="name"
        item-value="searchTerm"
        item-props
        return-object
        :bg-color="$vuetify.display.mobile ? '' : 'background'"
        @update:opened="onOpen($event)"
        @update:selected="onSelect($event)"
      >
        <template #header="{ props }">
          <category-group
            :name="props.name"
            :search-term="props.searchTerm"
            :is-selectable="props.isSelectable"
            :click-callback="props.onClick"
            :has-children="props.value.children.length > 0"
            @update="selectCategory"
          />
        </template>
        <template #item="{ props }">
          <category-item :name="props.name" :search-term="props.searchTerm" />
        </template>
      </v-list>
    </list-content>
    <!-- Empty -->
    <list-empty
      v-else-if="categories.length === 0"
      :icon="mdiStore"
      :message="$t('noCategoriesFound')"
    />
  </div>
</template>

<script lang="ts">
import SfIconButton from '@/components/button/SfIconButton.vue'
import ListContent from '@/components/list/ListContent.vue'
import ListEmpty from '@/components/list/ListEmpty.vue'
import ListHeader from '@/components/list/ListHeader.vue'
import CategoryGroup from '@/modules/categories/components/CategoryGroup.vue'
import CategoryItem from '@/modules/categories/components/CategoryItem.vue'
import { defineComponent, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import useCategories from '@/modules/categories/useCategories'
import { trackEvent } from '@/modules/tracking/useTracking'
import CategoryEvent from '@/modules/tracking/events/category'
import { mdiArrowCollapseLeft, mdiClose, mdiStore } from '@mdi/js'

export default defineComponent({
  name: 'ProductCategories',
  components: { ListEmpty, ListHeader, ListContent, SfIconButton, CategoryGroup, CategoryItem },
  props: {
    showHideButton: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update', 'close'],
  setup(_, { emit }) {
    const { categories, loading, openCategories, selectedCategory, showCategories } =
      storeToRefs(useCategories())

    const selectCategory = (categorySearchTerm: string | undefined) => {
      if (categorySearchTerm) {
        emit('update', categorySearchTerm)
      }
    }

    const hideCategories = () => (useCategories().showCategories = false)

    const openCategoriesSearchTerm = ref<string[]>(
      openCategories.value?.map((category) => category.searchTerm)
    )

    watch(selectedCategory, (newValue) => {
      if (newValue) {
        trackEvent(CategoryEvent.PageView(newValue.name))
      }
    })

    watch(openCategories, (newValue) => {
      if (openCategoriesSearchTerm.value.length === 0) {
        openCategoriesSearchTerm.value = newValue.map((category) => category.searchTerm)
      }
    })

    const onOpen = (opened: string[]) => {
      openCategoriesSearchTerm.value = opened
    }
    const onSelect = (selected: string[]) => {
      if (selected.length > 0) {
        selectCategory(selected.pop())
      }
    }

    return {
      selectCategory,
      hideCategories,
      categories,
      selectedCategory,
      openCategoriesSearchTerm,
      showCategories,
      loading,
      onOpen,
      onSelect,
      mdiClose,
      mdiStore,
      mdiArrowCollapseLeft
    }
  }
})
</script>
