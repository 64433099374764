<template>
  <v-card
    ref="loadingSpinnerCard"
    class="d-flex flex-column align-center justify-center"
    :height="height"
    :flat="flat"
  >
    <v-progress-circular indeterminate :size="50" color="primary" />
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LoadingSpinner',
  props: {
    height: {
      type: String,
      default: '100%'
    },
    flat: {
      type: Boolean,
      default: false
    }
  }
})
</script>
