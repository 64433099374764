<template>
  <sf-text-field
    v-model.number.lazy="model"
    hide-details
    type="number"
    hide-spin-buttons
    :min="0"
    single-line
    @focus="$event.target.select()"
    @keydown.enter="$event.target.blur()"
  />
</template>

<script setup lang="ts">
import SfTextField from '@/components/input/SfTextField.vue'

const model = defineModel<number>({
  default: undefined,
  set(value) {
    if (value) {
      return value
    } else {
      return undefined
    }
  }
})
</script>
