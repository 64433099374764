import useOwnUser from '@/modules/user/useOwnUser'
import { CompanyUserEvent, MyProfileEvent, UserEvent } from '@/modules/tracking/events/user'

export function trackingForUser(userId: string): UserEvent {
  const ownUser = useOwnUser().ownUser
  if (userId == ownUser.id) {
    return MyProfileEvent
  } else {
    return CompanyUserEvent
  }
}
