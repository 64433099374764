<template>
  <span
    v-if="
      subscriptionModel &&
      subscriptionModel.subscriptionPeriod &&
      subscriptionModel.subscriptionPeriodUnit
    "
    >{{
      $t('price.priceSubscriptionPeriod', [
        formatDuration(
          subscriptionModel.subscriptionPeriod,
          subscriptionModel.subscriptionPeriodUnit
        )
      ])
    }}</span
  >
</template>

<script lang="ts">
import { formatDuration } from '@/utils/timeFormatHelpers'
import { SubscriptionModel } from '@/generatedTypes'
import { defineComponent, PropType } from 'vue'
export default defineComponent({
  name: 'SubscriptionPeriod',
  props: {
    subscriptionModel: {
      type: Object as PropType<SubscriptionModel | undefined>,
      required: true
    }
  },
  setup() {
    return {
      formatDuration
    }
  }
})
</script>
