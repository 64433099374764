import { del, get, patch, post, Response } from '@/api'
import { ShopAddress, CheckoutAddressValidation } from '@/generatedTypes'
import { AddressType } from '@/modules/address/types'

export default {
  getAddresses: (): Promise<Response<ShopAddress[]>> => {
    return get<ShopAddress[]>('/addresses')
  },
  createAddress: (address: ShopAddress): Promise<Response<ShopAddress>> => {
    return post<ShopAddress, ShopAddress>('/addresses', address)
  },
  updateAddress: (address: ShopAddress): Promise<Response<string>> => {
    return patch<string, ShopAddress>('/addresses/' + address.id, address)
  },
  deleteAddress: (id: string): Promise<Response<string>> => {
    return del<string>('/addresses/' + id)
  },
  validateCheckoutAddress: (
    type: AddressType,
    address?: ShopAddress
  ): Promise<Response<CheckoutAddressValidation>> => {
    return post<CheckoutAddressValidation, ShopAddress>('/addresses/validate/' + type, address)
  }
}
