<template>
  <v-btn :value="view.name" class="ma-0" @click="$emit('changed', view)">
    <v-icon :size="24">{{ view.icon }}</v-icon>
    <span v-if="view.label && $vuetify.display.mdAndUp" ref="label" class="ml-1">
      {{ $t(view.label) }}
    </span>
    <v-badge :offset-x="-10" :offset-y="-10" dot :model-value="showBadge" color="primary" />
  </v-btn>
</template>

<script lang="ts">
import { ViewToggle } from '@/types'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ViewToggleItem',
  props: {
    view: {
      type: Object as PropType<ViewToggle<unknown>>,
      required: true
    }
  },
  emits: ['changed'],
  setup(props) {
    if (props.view.restriction() && props.view.count) {
      props.view.count.loadFunction()
    }

    const showBadge = computed(() => props.view.count != undefined && props.view.count.getter())

    return { showBadge }
  }
})
</script>
