<template>
  <v-list-item
    :[linkAttribute]="menuItem.link"
    active-class="text-primary"
    link
    :prepend-icon="menuItem.icon"
    :title="$t(menuItem.key)"
    @click="track(menuItem.key)"
  >
    <template #append>
      <v-badge inline dot :model-value="showBadge" color="primary" />
    </template>
  </v-list-item>
</template>

<script lang="ts">
import { MenuItem } from '@/components/navigation/menu'
import ApplicationHeaderEvent from '@/modules/tracking/events/applicationHeader'
import { trackEvent } from '@/modules/tracking/useTracking'
import { computed, defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'SideMenuItem',
  props: {
    menuItem: {
      type: Object as PropType<MenuItem>,
      required: true
    },
    externalLink: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { t } = useI18n()

    if (props.menuItem.count) {
      props.menuItem.count.loadFunction()
    }

    const linkAttribute = computed(() => (props.externalLink ? 'href' : 'to'))

    const showBadge = computed(
      () => props.menuItem.count != undefined && props.menuItem.count.getter() > 0
    )

    const track = (key: string): void => {
      trackEvent(ApplicationHeaderEvent.GenericMenuItem(t(key, 'de').toString()))
    }

    return {
      showBadge,
      track,
      linkAttribute
    }
  }
})
</script>
