<template>
  <v-list-item :value="searchTerm" color="primary" rounded>
    <v-list-item-title class="text-wrap">
      {{ name }}
    </v-list-item-title>
  </v-list-item>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CategoryItem',
  props: {
    name: {
      type: String,
      required: true
    },
    searchTerm: {
      type: String,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>
.v-list-item {
  padding-inline-start: calc(var(--indent-padding) / 2 + 28px) !important;
}
</style>
