<template>
  <v-dialog model-value :width="500" persistent>
    <v-card>
      <v-card-item>
        <v-card-title>
          <sf-heading>{{ $t('shortUrl.enterZipCodeTitle') }}</sf-heading>
        </v-card-title>
      </v-card-item>
      <v-card-text class="d-flex flex-column ga-2">
        <sf-text>{{ $t('shortUrl.enterZipCode') }}</sf-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <sf-text-field
            v-model="secret"
            :label="$t('address.zipCodeLong') + ' *'"
            :rules="[rules.requiredRule]"
            hide-details
            autocomplete="off"
            @keydown.enter.prevent="submit"
          />
        </v-form>
        <sf-color-text v-if="error" id="pw-error-text" color="red">
          {{ $t('shortUrl.wrongZipCode') }}
        </sf-color-text>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <sf-text-button color="primary" :disabled="!valid" :loading="resolving" @click="submit">
          {{ $t('open') }}
        </sf-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfTextField from '@/components/input/SfTextField.vue'
import SfColorText from '@/components/text/SfColorText.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import useShortUrl from '@/modules/shortUrl/useShortUrl'
import rules from '@/rules'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { VForm } from 'vuetify/components'

const secret = ref('')
const valid = ref(false)
const form = ref<VForm>()

const { resolving, error } = storeToRefs(useShortUrl())

const submit = () => {
  if (form.value?.validate()) {
    useShortUrl().resolveUrl(window.location.href, secret.value)
  }
}
</script>
