import useAuthentication from '@/modules/authentication/useAuthentication'
import useError from '@/modules/error/useError'
import helpers from '@/modules/message/helpers'
import i18n from '@/plugins/i18n'
import useRequestManager from '@/store/useRequestManager'
import * as Sentry from '@sentry/vue'
import { AxiosError, HttpStatusCode } from 'axios'

type StorefrontApiError = {
  httpStatus: string
  timestamp: string
  detail: string
  message?: string
}

type ApiError = {
  code?: HttpStatusCode
  axiosError?: AxiosError<StorefrontApiError>
  isCancel: boolean
}

type ErrorAppearance = 'CUSTOM' | 'FULLPAGE' | 'MESSAGE' | 'NONE'

type ErrorHandlingConfig = {
  logError: boolean
  appearance: ErrorAppearance
  errorCode?: HttpStatusCode
  customMessageKey?: string
  customHandler?: string
  showRefreshButton: boolean
}

const logError = (error: ApiError): void => {
  if (error.axiosError) {
    if (error.axiosError.response?.data) {
      Sentry.setExtra('StorefrontApiError', error.axiosError.response.data)
    }
    Sentry.captureException(error.axiosError)
    // eslint-disable-next-line no-console
    console.error(error.axiosError)
  }
}

const getGenericErrorMessageKey = (errorCode?: HttpStatusCode) => {
  switch (errorCode) {
    case 404:
      return 'error.pageNotFound'
    case 408:
      return 'error.timeout'
    case 409:
      return 'error.refresh'
    case 500:
      return 'error.serverError'
    default:
      return 'error.unknownError'
  }
}

const handleApiError = (
  error: ApiError,
  defaultConfigParams?: Partial<ErrorHandlingConfig>,
  ...specificConfigParams: Array<Partial<ErrorHandlingConfig>>
): void => {
  // filter out cancel errors
  if (error.isCancel) {
    return
  }

  // server offline
  if (error.code === 503) {
    useRequestManager().setServerOffline(true)
    return
  }

  // logout user immediately on 401
  if (error.code === 401) {
    useAuthentication().logout()
    return
  }

  // merge config
  let defaultConfig: ErrorHandlingConfig = {
    logError: true,
    appearance: 'MESSAGE',
    showRefreshButton: error.code == 409
  }
  defaultConfig = { ...defaultConfig, ...defaultConfigParams }

  let specificConfig: Partial<ErrorHandlingConfig> = {}

  specificConfigParams.forEach((specificConfigParam) => {
    if (specificConfigParam.errorCode && specificConfigParam.errorCode === error.code) {
      specificConfig = specificConfigParam
    }
  })

  const config: ErrorHandlingConfig = { ...defaultConfig, ...specificConfig }

  // don't log timeout errors and conflict errors
  if (error.code === 408 || error.code === 409) {
    config.logError = false
  }

  // log error to sentry & console
  if (config.logError) {
    logError(error)
  }

  // priority for error message
  // 1. Custom message
  // 2. Message from backend
  // 3. Generic message

  const errorMessage = config.customMessageKey
    ? i18n.global.t(config.customMessageKey)
    : error.axiosError?.response?.data.message
      ? error.axiosError.response.data.message
      : i18n.global.t(getGenericErrorMessageKey(error.code))

  showError(config, errorMessage, error.code)
}

const showError = (config: ErrorHandlingConfig, message: string, errorCode?: HttpStatusCode) => {
  if (config.appearance === 'FULLPAGE' || config.appearance === 'CUSTOM') {
    useError().setError({
      message,
      code: errorCode,
      customHandler:
        config.appearance === 'CUSTOM' && config.customHandler ? config.customHandler : undefined
    })
  } else if (config.appearance === 'MESSAGE') {
    if (config.showRefreshButton) {
      helpers.reportRefreshError(message)
    } else {
      helpers.reportErrorMessage(message)
    }
  } else {
    return
  }
}

export { ApiError, ErrorHandlingConfig, StorefrontApiError, handleApiError }
