import '@/assets/scss/main.scss'
import { StorefrontLightTheme } from '@/configs/theme'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { VNumberInput } from 'vuetify/labs/VNumberInput'

export default createVuetify({
  theme: {
    defaultTheme: 'StorefrontLightTheme',
    themes: {
      StorefrontLightTheme
    }
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  },
  components: {
    VNumberInput
  }
})
