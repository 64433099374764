<template>
  <v-carousel
    ref="carousel"
    v-model="lightBoxIndex"
    height="auto"
    hide-delimiters
    :show-arrows="!hideArrows && images.length > 1"
  >
    <template #prev="{ props }">
      <v-btn variant="text" color="primary" :icon="mdiChevronLeft" @click="props.onClick" />
    </template>
    <template #next="{ props }">
      <v-btn variant="text" color="primary" :icon="mdiChevronRight" @click="props.onClick" />
    </template>
    <v-carousel-item
      v-for="(image, index) in images"
      :key="index"
      content-class="cursor-pointer"
      eager
      aspect-ratio="1"
      :width="`${size}px`"
      :lazy-src="emptyPlaceholderImage"
      :src="fitImage(image, size, size)"
      :srcset="getSrcset(image, size, size)"
      @click="showLightBox"
    >
      <v-fade-transition v-if="hoverEffect">
        <div>
          <v-overlay :model-value="isHovered" contained class="align-center justify-center">
            <v-icon :size="magnifySize" :icon="mdiMagnify" color="primary" />
          </v-overlay>
        </div>
      </v-fade-transition>
    </v-carousel-item>
    <v-carousel-item
      v-if="images.length === 0"
      :src="placeholderImage"
      :width="`${size}px`"
      aspect-ratio="1"
    />
    <teleport to="body">
      <vue-easy-lightbox
        :imgs="images"
        :index="lightBoxIndex"
        :scroll-disabled="false"
        :visible="lightBoxShow"
        move-disabled
        @hide="hideLightBox"
        @on-index-change="updateIndex"
      />
    </teleport>
  </v-carousel>
</template>

<script lang="ts">
import { emptyPlaceholderImage, fitImage, getSrcset, placeholderImage } from '@/helpers'
import { computed, defineComponent, PropType, ref } from 'vue'
import { useElementHover } from '@vueuse/core'
import { mdiChevronLeft, mdiChevronRight, mdiMagnify } from '@mdi/js'

export default defineComponent({
  name: 'ProductImageGallery',
  props: {
    images: {
      type: Array as PropType<string[]>,
      required: true
    },
    size: {
      type: Number,
      default: 600
    },
    hideArrows: {
      type: Boolean,
      default: false
    },
    hoverEffect: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const lightBoxShow = ref(false)
    const lightBoxIndex = ref(0)

    const showLightBox = () => {
      lightBoxShow.value = true
    }

    const hideLightBox = () => {
      lightBoxShow.value = false
      isHovered.value = false
    }

    const updateIndex = (_: number, newIndex: number) => {
      lightBoxIndex.value = newIndex
    }

    const carousel = ref()
    const isHovered = useElementHover(carousel)

    const magnifySize = computed(() => Math.max(32, props.size / 8))

    return {
      lightBoxShow,
      lightBoxIndex,
      showLightBox,
      hideLightBox,
      updateIndex,
      fitImage,
      getSrcset,
      placeholderImage,
      emptyPlaceholderImage,
      isHovered,
      carousel,
      magnifySize,
      mdiMagnify,
      mdiChevronLeft,
      mdiChevronRight
    }
  }
})
</script>
