import { del, get, patch, post, Request, Response } from '@/api'
import {
  Checkout,
  DetailedOrder,
  FilterIdentifier,
  Order,
  OrderPostDTO,
  OrderPreview,
  OrderResponse,
  OrderRma,
  OrderSearchModel,
  OrderStatus,
  OrderTransactions,
  ProposeOrderPostDTO,
  RejectionDTO,
  RequestOrderPostDTO,
  RmaRequest,
  UpdateOrderItemDTO,
  UpdateOrderPatchDTO,
  ValidateOrderNumberDTO
} from '@/generatedTypes'
import { applyFilters } from '@/modules/order/helpers'
import { AddToCartSuccess, OrderSortOrder } from '@/modules/order/types'
import { PageableResponse } from '@/types'

export default {
  declineOrder: (id: string, comment: RejectionDTO): Promise<Response<RejectionDTO>> => {
    return post<RejectionDTO, RejectionDTO>('/orders/' + id + '/reject', comment)
  },
  getOrderTransactions: (orderId: string): Promise<Response<OrderTransactions>> => {
    return get<OrderTransactions>('/orders/' + orderId + '/history')
  },
  getOrders: (
    page: number,
    size: number,
    sort: OrderSortOrder,
    filter: FilterIdentifier[] = [],
    keyword?: string
  ): Promise<Response<PageableResponse<Order>>> => {
    const config: Request = {
      params: {
        page: page - 1,
        size,
        sort,
        keyword
      }
    }
    return get<PageableResponse<Order>>('/orders', applyFilters(config, filter))
  },
  getApprovableOrders: (
    page: number,
    size: number,
    sort: OrderSortOrder,
    filter: FilterIdentifier[] = [],
    keyword?: string
  ): Promise<Response<PageableResponse<Order>>> => {
    const config: Request = {
      params: {
        page: page - 1,
        size,
        sort,
        keyword
      }
    }
    return get<PageableResponse<Order>>('/orders/approvables', applyFilters(config, filter))
  },
  searchOrders: (term: string): Promise<Response<OrderSearchModel[]>> => {
    const config: Request = {
      params: {
        term
      }
    }
    return get<OrderSearchModel[]>('/orders/search', config)
  },
  getOrderPreviews: (
    page: number,
    size: number,
    sort: OrderSortOrder,
    status: OrderStatus[]
  ): Promise<Response<PageableResponse<OrderPreview>>> => {
    const config: Request = {
      params: {
        page: page - 1,
        size: size,
        sort: sort,
        status: status.join(',')
      }
    }
    return get<PageableResponse<OrderPreview>>('/orders/preview', config)
  },
  getOrderFilters: (
    filter: FilterIdentifier[] = [],
    keyword?: string
  ): Promise<Response<FilterIdentifier[]>> => {
    const config: Request = {
      params: {
        keyword
      }
    }
    return get<FilterIdentifier[]>('/orders/filters', applyFilters(config, filter))
  },
  getApprovableOrderFilters: (
    filter: FilterIdentifier[] = [],
    keyword?: string
  ): Promise<Response<FilterIdentifier[]>> => {
    const config: Request = {
      params: {
        keyword
      }
    }
    return get<FilterIdentifier[]>('/orders/approvables/filters', applyFilters(config, filter))
  },
  getOrder: (id: string): Promise<Response<DetailedOrder>> => {
    return get<DetailedOrder>('/orders/' + id)
  },
  validateOrderNumber: (orderNumber: string): Promise<Response<boolean>> => {
    return post<boolean, ValidateOrderNumberDTO>('/orders/validate', {
      orderNumber
    })
  },
  sendOrder: (order: OrderPostDTO, supplierCartId?: string): Promise<Response<OrderResponse>> => {
    return post<OrderResponse, OrderPostDTO>(
      '/order/actions/send-cart' + (supplierCartId ? `?supplierCartId=${supplierCartId}` : ''),
      order
    )
  },
  sendOrderAndPay: (order: OrderPostDTO, supplierCartId?: string): Promise<Response<string>> => {
    return post<string, OrderPostDTO>(
      '/order/actions/send-cart-sofort' +
        (supplierCartId ? `?supplierCartId=${supplierCartId}` : ''),
      order
    )
  },
  sendOrderTopi: (order: OrderPostDTO, supplierCartId?: string): Promise<Response<string>> => {
    return post<string, OrderPostDTO>(
      '/order/actions/send-cart-topi' + (supplierCartId ? `?supplierCartId=${supplierCartId}` : ''),
      order
    )
  },
  sendQuoteOrder: (order: OrderPostDTO, quoteId: string): Promise<Response<OrderResponse>> => {
    return post<OrderResponse, OrderPostDTO>('/order/actions/send-quote?quoteId=' + quoteId, order)
  },
  requestOrder: (
    order: ProposeOrderPostDTO,
    supplierCartId?: string
  ): Promise<Response<OrderResponse>> => {
    return post<OrderResponse, ProposeOrderPostDTO>(
      '/order/actions/request-cart' + (supplierCartId ? `?supplierCartId=${supplierCartId}` : ''),
      order
    )
  },
  requestQuoteOrder: (
    order: ProposeOrderPostDTO,
    quoteId: string
  ): Promise<Response<OrderResponse>> => {
    return post<OrderResponse, ProposeOrderPostDTO>(
      '/order/actions/request-quote?quoteId=' + quoteId,
      order
    )
  },
  requestExistingOrder: (id: string, comment: RequestOrderPostDTO): Promise<Response<string>> => {
    return post<string, RequestOrderPostDTO>('/orders/' + id + '/request', comment)
  },
  sendExistingOrder: (id: string, currentOrderVersion: string): Promise<Response<string>> => {
    return post<string, Record<string, unknown>>('/orders/' + id + '/send', {
      timestamp: currentOrderVersion
    })
  },
  sendExistingOrderAndPay: (id: string, orderVersion: string): Promise<Response<string>> => {
    return post<string, Record<string, unknown>>('/orders/' + id + '/send-sofort', {
      timestamp: orderVersion
    })
  },
  updateOrder: (orderId: string, dto: UpdateOrderPatchDTO): Promise<Response<string>> => {
    return patch<string, UpdateOrderPatchDTO>('/orders/' + orderId, dto)
  },
  deleteOrder: (orderId: string): Promise<Response<string>> => {
    return del<string>('/orders/' + orderId)
  },
  updateOrderItem: (
    orderId: string,
    orderItemId: string,
    dto: UpdateOrderItemDTO
  ): Promise<Response<string>> => {
    return patch<string, UpdateOrderItemDTO>('/orders/' + orderId + '/items/' + orderItemId, dto)
  },
  removeOrderItem: (orderId: string, orderItemId: string): Promise<Response<string>> => {
    return del<string>('/orders/' + orderId + '/items/' + orderItemId)
  },
  loadCheckout: (supplierCartId?: string): Promise<Response<Checkout>> => {
    return get<Checkout>('/checkout' + (supplierCartId ? `/${supplierCartId}` : ''))
  },
  loadCheckoutTerms: (alpha2language: string): Promise<Response<string>> => {
    return get<string>('/checkout-terms', { params: { alpha2language } })
  },
  loadCheckoutForQuote: (quoteId: string): Promise<Response<Checkout>> => {
    return get<Checkout>(`/checkout/quote/${quoteId}`)
  },
  addToCart: (orderId: string): Promise<Response<AddToCartSuccess>> => {
    return post<AddToCartSuccess, Record<string, unknown>>('/orders/' + orderId + '/to-cart', {})
  },
  loadPDFDocument: (id: string, transactionId: string): Promise<Response<Blob>> => {
    return get<Blob>('/orders/' + id + '/documents/' + transactionId, {
      responseType: 'blob'
    })
  },
  loadAllPDFDocuments: (id: string): Promise<Response<Blob>> => {
    return get<Blob>('/orders/' + id + '/documents', {
      responseType: 'blob'
    })
  },
  getOrderRma: (id: string): Promise<Response<OrderRma>> => {
    return get<OrderRma>('/orders/' + id + '/rma')
  },
  requestOrderRma: (id: string, dto: RmaRequest): Promise<Response<string>> => {
    return post<string, RmaRequest>('/orders/' + id + '/rma', dto)
  }
}
