<template>
  <v-btn
    id="pw-comparison-toolbar-button"
    :to="{ name: 'ProductComparison' }"
    variant="text"
    rounded
    elevation="0"
    class="pa-0 text-medium-emphasis"
    height="32"
    @click="track"
  >
    <v-badge
      id="cart-toolbar-count"
      bordered
      :content="count"
      :offset-x="-8"
      :offset-y="12"
      color="primary"
    >
      <v-icon size="28" :icon="mdiSwapHorizontalBold" />
    </v-badge>
  </v-btn>
</template>

<script lang="ts">
import useProductComparison from '@/modules/product/useProductComparison'
import ApplicationHeaderEvent from '@/modules/tracking/events/applicationHeader'
import { trackEvent } from '@/modules/tracking/useTracking'
import { mdiSwapHorizontalBold } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ToolbarProductComparison',
  setup() {
    const { count } = storeToRefs(useProductComparison())
    const track = () => trackEvent(ApplicationHeaderEvent.ProductComparison)
    return {
      count,
      track,
      mdiSwapHorizontalBold
    }
  }
})
</script>

<style lang="scss" scoped>
.v-btn--active.v-btn.v-btn--rounded {
  color: rgb(var(--v-theme-primary)) !important;
}
</style>
