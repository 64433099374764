import { ApiError, handleApiError } from '@/handleApiError'
import { parseFileName } from '@/helpers'
import api from '@/modules/product/api'
import FileSaver from 'file-saver'
import { defineStore } from 'pinia'

interface ProductDatasheetState {
  loadingHTML: boolean
  loadingPDF: boolean
}

const initialState = (): ProductDatasheetState => {
  return {
    loadingHTML: false,
    loadingPDF: false
  }
}

const useProductDatasheet = defineStore('productDatasheet', {
  state: () => initialState(),
  actions: {
    async loadHTMLDatasheet(id: number) {
      this.loadingHTML = true

      return api
        .loadDatasheet(id, 'html')
        .then((response) => {
          window.open(URL.createObjectURL(response.data), '_blank')
        })
        .catch((error: ApiError) => {
          handleApiError(error, { customMessageKey: 'datasheetError' })
        })
        .finally(() => (this.loadingHTML = false))
    },
    async loadPDFDatasheet(id: number) {
      this.loadingPDF = true

      return api
        .loadDatasheet(id, 'pdf')
        .then((response) => {
          FileSaver.saveAs(response.data, parseFileName(response.headers))
        })
        .catch((error: ApiError) => {
          handleApiError(error, { customMessageKey: 'datasheetError' })
        })
        .finally(() => (this.loadingPDF = false))
    }
  }
})

export default useProductDatasheet
