import { OrderSearchModel, MediumProduct } from '@/generatedTypes'

type InstantSearchTitle = { type: InstantSearchType; key: string }

enum InstantSearchType {
  PRODUCT = 'PRODUCT',
  ORDER = 'ORDER'
}

type InstantSearchItem = MediumProduct | OrderSearchModel | InstantSearchTitle

export { InstantSearchItem, InstantSearchTitle, InstantSearchType }
