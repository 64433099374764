import { handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import helpers from '@/modules/message/helpers'
import api from '@/modules/user/api'
import { ShortUser } from '@/generatedTypes'
import { sortByName } from '@/modules/user/helpers'

interface UsersState {
  users: ShortUser[]
  loading: boolean
}

const initialState = (): UsersState => {
  return {
    users: [],
    loading: false
  }
}

const REMOVE_USER = (state: UsersState, id: string) => {
  state.users.splice(
    state.users.findIndex((item) => {
      return item.id === id
    }),
    1
  )
}

const useUsers = defineStore('users', {
  state: () => initialState(),
  getters: {
    getUsersByDepartment: (state) => {
      return (department: string) =>
        state.users.filter((user) => user.department === department).sort(sortByName)
    },
    getRegisteredUsers: (state) => {
      return () => state.users.filter((user) => user.userRegistered).sort(sortByName)
    }
  },
  actions: {
    async getUsers() {
      this.loading = true

      return api
        .getUsers()
        .then(({ data }) => {
          this.users = data
        })
        .catch(handleApiError)
        .finally(() => (this.loading = false))
    },
    async deleteUser(serial: string) {
      REMOVE_USER(this, serial)

      return api.deleteUser(serial).then(() => {
        helpers.reportSuccess('company.employeeDeleted')
      })
    }
  }
})

export default useUsers
