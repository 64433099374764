<template>
  <span class="text-body-1 text-high-emphasis">
    <slot />
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SfText'
})
</script>
