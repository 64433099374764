<template>
  <div class="d-flex flex-row align-center ga-1 my-1">
    <positive-integer-text-field
      v-model.number.lazy="priceRangeStart"
      suffix="€"
      @change="checkPriceRangeStart"
    />
    <sf-text>{{ $t('to') }}</sf-text>
    <positive-integer-text-field
      v-model.number.lazy="priceRangeEnd"
      suffix="€"
      @change="checkPriceRangeEnd"
    />
  </div>
</template>

<script setup lang="ts">
import { isPositiveInteger } from '@/components/filter/helpers'
import PositiveIntegerTextField from '@/components/filter/PositiveIntegerTextField.vue'
import { PriceRangeFilter } from '@/components/filter/types'
import SfText from '@/components/text/SfText.vue'
import helpers from '@/modules/message/helpers'
import { PropType, ref, watch } from 'vue'

const props = defineProps({
  priceRangeFilter: {
    type: Object as PropType<PriceRangeFilter>,
    required: true
  }
})

const priceRangeStart = ref<number | undefined>()
const priceRangeEnd = ref<number | undefined>()
const emit = defineEmits<{
  update: [value: PriceRangeFilter]
}>()

const emitUpdate = () => {
  emit('update', { start: priceRangeStart.value, end: priceRangeEnd.value })
}

watch(
  () => props.priceRangeFilter,
  () => {
    priceRangeStart.value = props.priceRangeFilter.start
    priceRangeEnd.value = props.priceRangeFilter.end
  }
)

const checkPriceRangeStart = () => {
  if (priceRangeStart.value && !isPositiveInteger(priceRangeStart.value)) {
    helpers.reportError('onlyWholePositiveNumbers')
    priceRangeStart.value = undefined
  }

  if (
    priceRangeEnd.value &&
    priceRangeStart.value &&
    priceRangeEnd.value <= priceRangeStart.value
  ) {
    helpers.reportError('priceRange.invalidPriceRange')
    priceRangeStart.value = undefined
  }

  emitUpdate()
}

const checkPriceRangeEnd = () => {
  if (priceRangeEnd.value && !isPositiveInteger(priceRangeEnd.value)) {
    helpers.reportError('onlyWholePositiveNumbers')
    priceRangeEnd.value = undefined
  }

  if (
    priceRangeEnd.value &&
    priceRangeStart.value &&
    priceRangeEnd.value <= priceRangeStart.value
  ) {
    helpers.reportError('priceRange.invalidPriceRange')
    priceRangeEnd.value = undefined
  }

  emitUpdate()
}
</script>
