<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <sf-select
        :model-value="selectedTitle"
        :items="titleItems"
        item-title="translation"
        :label="$t('user.contactTitle')"
        disabled
        return-object
        autocomplete="off"
      />
      <sf-text-field
        v-model="firstName"
        :label="$t('user.firstName')"
        :rules="[rules.nameRule]"
        required
        disabled
        autocomplete="off"
      />
      <sf-text-field
        v-model="lastName"
        :label="$t('user.lastName')"
        :rules="[rules.nameRule]"
        required
        disabled
        autocomplete="off"
      />
      <sf-text-field
        v-model="email"
        :label="$t('user.email')"
        :rules="[rules.mailRule]"
        class="mb-3"
        required
        disabled
        autocomplete="off"
      />
      <sf-password-field
        v-model="password"
        :label="$t('password') + ' *'"
        :rules="[rules.passwordLengthRule, rules.passwordRule]"
        required
        autocomplete="new-password"
      />
      <sf-password-field
        v-model="repeat"
        :label="$t('passwordRepeat') + ' *'"
        :rules="[rules.passwordMatchRule(password)]"
        required
        autocomplete="new-password"
      />
      <sf-checkbox
        v-model="consent"
        :label="$t('privacyConsent') + ' *'"
        :rules="[rules.requiredRule]"
        required
      />
      <sf-large-button block :loading="sending" :disabled="!valid" @click="sendRegistration">
        {{ $t('register') }}
      </sf-large-button>
    </v-form>
  </div>
</template>

<script lang="ts">
import SfLargeButton from '@/components/button/SfLargeButton.vue'
import SfCheckbox from '@/components/input/SfCheckbox.vue'
import SfPasswordField from '@/components/input/SfPasswordField.vue'
import SfSelect from '@/components/input/SfSelect.vue'
import SfTextField from '@/components/input/SfTextField.vue'
import { validateForm } from '@/helpers'
import { getContactTitles } from '@/modules/user/helpers'
import { Password } from '@/modules/user/types'
import useGuestUser from '@/modules/user/useGuestUser'
import rules from '@/rules'
import { VForm } from '@/types'
import { storeToRefs } from 'pinia'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'RegistrationForm',
  components: { SfLargeButton, SfPasswordField, SfSelect, SfTextField, SfCheckbox },
  props: {
    sending: {
      type: Boolean,
      required: true
    }
  },
  emits: ['finishRegistration'],
  setup(_, { emit }) {
    const { guestUser } = storeToRefs(useGuestUser())
    const titleItems = getContactTitles()
    const form = ref<VForm>()
    const password = ref('')
    const repeat = ref('')
    const selectedTitle = ref(titleItems[3])
    const firstName = guestUser.value.firstName
    const consent = ref(false)
    const lastName = guestUser.value.lastName
    const email = guestUser.value.email
    const valid = ref(false)

    const sendRegistration = async () => {
      const data: Password = {
        password: password.value
      }

      if (await validateForm(form)) {
        emit('finishRegistration', data)
      }
    }

    return {
      firstName,
      titleItems,
      selectedTitle,
      email,
      form,
      valid,
      rules,
      consent,
      password,
      lastName,
      repeat,
      sendRegistration
    }
  }
})
</script>
