<template>
  <sf-icon-button v-bind="$attrs" :icon="mdiPencil" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SfIconButton from '@/components/button/SfIconButton.vue'
import { mdiPencil } from '@mdi/js'

export default defineComponent({
  name: 'SfEditButton',
  components: { SfIconButton },
  inheritAttrs: false,
  setup() {
    return {
      mdiPencil
    }
  }
})
</script>
