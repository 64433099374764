<template>
  <sf-alert-error :icon="mdiCancel">
    <sf-text>
      {{
        $t('quotes.declineInformation', [
          getFullName(rejectionInfo.rejectedByUser),
          formatDate(rejectionInfo.date)
        ])
      }}
    </sf-text>
    <br />
    <sf-text ref="comment">
      {{
        $t('declineReason') +
        ': ' +
        (rejectionInfo.rejectionComment ? rejectionInfo.rejectionComment : $t('unknown'))
      }}
    </sf-text>
  </sf-alert-error>
</template>

<script lang="ts">
import SfAlertError from '@/components/alerts/SfAlertError.vue'
import SfText from '@/components/text/SfText.vue'
import { getFullName } from '@/helpers'
import { RejectionInformation } from '@/generatedTypes'
import { formatDate } from '@/utils/timeFormatHelpers'
import { mdiCancel } from '@mdi/js'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'QuoteRejectionInfo',
  components: {
    SfText,
    SfAlertError
  },
  props: {
    rejectionInfo: {
      type: Object as PropType<RejectionInformation>,
      required: true
    }
  },
  setup() {
    return {
      getFullName,
      formatDate,
      mdiCancel
    }
  }
})
</script>
