<template>
  <v-layout>
    <info-bar v-if="isRedslave" />
    <v-main
      class="background"
      :style="
        branding && branding.backgroundImageUrl
          ? {
              backgroundImage: 'url(' + fitImage(branding.backgroundImageUrl, 3840, 2160, '&') + ')'
            }
          : ''
      "
    >
      <v-container fluid class="py-15">
        <portal-paused v-if="branding && branding.isPaused" :branding="branding" />
        <router-view v-else />
      </v-container>
    </v-main>
  </v-layout>
</template>

<script lang="ts">
import InfoBar from '@/components/info-bar/InfoBar.vue'
import { fitImage } from '@/helpers'
import PortalPaused from '@/modules/authentication/components/PortalPaused.vue'
import useAuthentication from '@/modules/authentication/useAuthentication'
import useBranding from '@/modules/portalSettings/useBranding'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'LoginLayout',
  components: {
    PortalPaused,
    InfoBar
  },
  setup() {
    const { branding } = useBranding()
    const isRedslave = computed(() => useAuthentication().state.value.isRedslave)

    return {
      branding,
      isRedslave,
      fitImage
    }
  }
})
</script>

<style lang="scss" scoped>
.background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
