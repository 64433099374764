import { Event } from '@/modules/tracking/types'
import { addressEvents } from '@/modules/tracking/events/addresses'

interface CommentChangeEvents {
  CommentChanged: Event
  PartialShipment(activated: boolean): Event
}

interface PositionCommentChangeEvents {
  ToPortalOwner: Event
  ToDistributor: Event
}

const checkoutCommentEvents = (
  Event: new (action: string, label?: string) => Event
): CommentChangeEvents => {
  return {
    CommentChanged: new Event('Kommentar geändert'),
    PartialShipment: (activated: boolean) =>
      new Event('Teillieferung', activated ? 'Aktiviert' : 'Deaktiviert')
  }
}

const checkoutPositionCommentEvents = (
  Event: new (action: string, label?: string) => Event
): PositionCommentChangeEvents => {
  return {
    ToDistributor: new Event(
      'Positionsbasierter Kommentar hinzugefügt',
      'Kommentar an Lieferanten/Distributoren'
    ),
    ToPortalOwner: new Event(
      'Positionsbasierter Kommentar hinzugefügt',
      'Kommentar an den Portalbetreiber/Verkäufer'
    )
  }
}

const checkoutOrderNumberEvents = (
  Event: new (action: string, label?: string) => Event
): OrderNumberEvents => {
  return {
    OrderNumber: new Event('Bestellnummer angepasst'),
    CustomerOrderNumber: new Event('Endkundenbestellreferenz angepasst')
  }
}

export default class CheckoutEvent extends Event {
  constructor(action: string, label?: string) {
    super('Checkout', action, label ?? '')
  }

  static readonly Address = class {
    static readonly Billing = addressEvents('Rechnungsadresse', CheckoutEvent)
    static readonly Shipping = addressEvents('Lieferadresse', CheckoutEvent)
  }
  static readonly Comment = {
    Position: checkoutPositionCommentEvents(CheckoutEvent),
    Checkout: checkoutCommentEvents(CheckoutEvent)
  }
  static readonly OrderNumbers = checkoutOrderNumberEvents(CheckoutEvent)
  static readonly SendOrder = new CheckoutEvent('Bestellung gesendet')
}

interface OrderNumberEvents {
  OrderNumber: Event
  CustomerOrderNumber: Event
}

export {
  checkoutCommentEvents,
  CheckoutEvent,
  checkoutOrderNumberEvents,
  checkoutPositionCommentEvents,
  CommentChangeEvents,
  OrderNumberEvents,
  PositionCommentChangeEvents
}
