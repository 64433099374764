<template>
  <div v-if="preparedOrder && checkout">
    <v-row dense>
      <v-col cols="12" md="6" lg="">
        <order-address-and-contact-data
          :address-type="AddressType.billing"
          :address-selection="checkout.billingAddress"
          :address="preparedOrder.billingAddress"
          :contact-data="preparedOrder.billingContactData"
          :events="billingEvents"
          @select-address="selectBillingAddress"
          @update-contact-data="updateBillingContactData"
        />
      </v-col>
      <v-col cols="12" md="6" lg="">
        <order-address-and-contact-data
          :address-type="AddressType.delivery"
          :address-selection="checkout.deliveryAddress"
          :address="preparedOrder.deliveryAddress"
          :contact-data="preparedOrder.deliveryContactData"
          :events="shippingEvents"
          @select-address="selectDeliveryAddress"
          @update-contact-data="updateDeliveryContactData"
        />
      </v-col>
      <v-col cols="12" sm="">
        <order-comment
          :events="commentEvents"
          :max-length="orderingConditions.maxCommentLength"
          :order-comment="preparedOrder.orderComment"
          :partial-shipment-enabled="orderingConditions.isPartialShipmentEnabled"
          :partial-shipment-select="preparedOrder.usePartialShipment"
          editable
          @order-comment-changed="orderCommentChanged"
          @partial-shipment-selection-changed="partialShipmentSelectionChanged"
        />
      </v-col>
      <v-col v-if="portalSettings.showPaymentMethodSelection" cols="12" md="3" sm="6">
        <mobex-payment-method />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <order-numbers
          :customer-number="checkout.customerNumber"
          :customer-order-id-required="orderingConditions.customerOrderIdRequired"
          :events="orderNumberEvents"
          :order-number="preparedOrder.orderNumber"
          :show-order-number="isQuote || portalSettings.isCustomOrderNumberEnabled"
          :is-custom-order-number-enabled="portalSettings.isCustomOrderNumberEnabled"
          :show-customer-order-id="orderingConditions.showCustomerOrderId"
          :customer-order-id="preparedOrder.customerOrderId"
          @customer-order-id-changed="customerOrderIdChanged"
          @order-number-changed="orderNumberChanged"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { ShopAddress, ContactData } from '@/generatedTypes'
import { AddressType } from '@/modules/address/types'
import MobexPaymentMethod from '@/modules/order/components/MobexPaymentMethod.vue'
import OrderAddressAndContactData from '@/modules/order/components/OrderAddressAndContactData.vue'
import OrderComment from '@/modules/order/components/OrderComment.vue'
import OrderNumbers from '@/modules/order/components/OrderNumbers.vue'
import useCheckout from '@/modules/order/useCheckout'
import useOrderingConditions from '@/modules/portalSettings/useOrderingConditions'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import { AddressChangeEvents } from '@/modules/tracking/events/addresses'
import { CommentChangeEvents, OrderNumberEvents } from '@/modules/tracking/events/checkout'
import { defineComponent, PropType } from 'vue'
import { storeToRefs } from 'pinia'
import useCart from '@/modules/cart/useCart'

export default defineComponent({
  name: 'CheckoutHeader',
  components: {
    MobexPaymentMethod,
    OrderAddressAndContactData,
    OrderComment,
    OrderNumbers
  },
  props: {
    shippingEvents: {
      type: Object as PropType<AddressChangeEvents>,
      default: undefined
    },
    billingEvents: {
      type: Object as PropType<AddressChangeEvents>,
      default: undefined
    },
    commentEvents: {
      type: Object as PropType<CommentChangeEvents>,
      default: undefined
    },
    orderNumberEvents: {
      type: Object as PropType<OrderNumberEvents>,
      default: undefined
    },
    isQuote: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { portalSettings } = storeToRefs(usePortalSettings())
    const { orderingConditions } = storeToRefs(useOrderingConditions())
    const { checkout, preparedOrder } = storeToRefs(useCheckout())

    const selectBillingAddress = (address: ShopAddress) => {
      useCheckout().setBillingAddress(address)
    }

    const selectDeliveryAddress = (address: ShopAddress) => {
      useCheckout().setDeliveryAddress(address)
      useCart().changeVatCountry(address.iso3country)
    }

    const updateBillingContactData = (updatedContactData?: ContactData) => {
      useCheckout().setBillingContactData(updatedContactData)
    }

    const updateDeliveryContactData = (updatedContactData?: ContactData) => {
      useCheckout().setDeliveryContactData(updatedContactData)
    }

    const partialShipmentSelectionChanged = (selection: boolean) => {
      useCheckout().setPartialShipmentSelection(selection)
    }

    const orderNumberChanged = (orderNumber: string) => {
      useCheckout().setOrderNumber(orderNumber)
    }

    const customerOrderIdChanged = (customerOrderId: string) => {
      useCheckout().setCustomerOrderId(customerOrderId)
    }

    const orderCommentChanged = (comment: string) => {
      useCheckout().setOrderComment(comment)
    }

    return {
      portalSettings,
      orderingConditions,
      checkout,
      preparedOrder,
      AddressType,
      selectBillingAddress,
      selectDeliveryAddress,
      updateBillingContactData,
      updateDeliveryContactData,
      partialShipmentSelectionChanged,
      orderNumberChanged,
      customerOrderIdChanged,
      orderCommentChanged
    }
  }
})
</script>
