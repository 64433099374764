<template>
  <v-img
    aspect-ratio="1"
    :height="`${size}px`"
    :width="`${size}px`"
    :lazy-src="emptyPlaceholderImage"
    :src="url ? fitImage(url, size, size) : placeholderImage"
    :srcset="url ? getSrcset(url, size, size) : placeholderImage"
    class="rounded"
  />
</template>

<script lang="ts">
import { emptyPlaceholderImage, fitImage, getSrcset, placeholderImage } from '@/helpers'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProductImage',
  props: {
    url: {
      type: String,
      default: undefined
    },
    size: {
      type: Number,
      default: 100
    }
  },
  setup() {
    return {
      fitImage,
      getSrcset,
      placeholderImage,
      emptyPlaceholderImage
    }
  }
})
</script>
