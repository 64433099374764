<template>
  <sf-button size="x-large" v-bind="$attrs">
    <slot />
  </sf-button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SfButton from '@/components/button/SfButton.vue'

export default defineComponent({
  name: 'SfLargeButton',
  components: { SfButton },
  inheritAttrs: false
})
</script>
