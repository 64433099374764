<template>
  <sf-button :loading="requesting || scheduledRequest" @click="sendRequest">
    {{ $t('quoteRequest.sendRequest') }}
  </sf-button>
</template>

<script lang="ts">
import SfButton from '@/components/button/SfButton.vue'
import useQuoteRequest from '@/modules/quote/useQuoteRequest'
import QuoteEvent from '@/modules/tracking/events/quote'
import { trackEvent } from '@/modules/tracking/useTracking'
import useDebounce from '@/store/useDebounce'
import { storeToRefs } from 'pinia'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'RequestQuoteActionButton',
  components: { SfButton },
  setup() {
    const { requesting, updating } = storeToRefs(useQuoteRequest())
    const { timerStarted } = storeToRefs(useDebounce())
    const scheduledRequest = ref(false)

    const sendRequest = () => {
      if (timerStarted.value || updating.value) {
        scheduledRequest.value = true
        return
      }

      useQuoteRequest().sendRequest()
      trackEvent(QuoteEvent.SendRequest)
    }

    watch(updating, (newValue) => {
      if (!newValue && scheduledRequest.value) {
        scheduledRequest.value = false
        sendRequest()
      }
    })

    return {
      requesting,
      sendRequest,
      scheduledRequest
    }
  }
})
</script>
