<template>
  <v-dialog v-model="dialog" :width="800" max-height="90%" scrollable>
    <template #activator="slotData">
      <slot name="activator" v-bind="slotData" />
    </template>
    <v-card>
      <v-card-item>
        <v-card-title>
          <div class="d-flex flex-column ga-1">
            <sf-heading>{{ $t('rma.return') }}</sf-heading>
            <sf-sub-heading v-if="step == RmaStep.SELECT_POSITION">
              {{ $t('rma.selectPositions') }}
            </sf-sub-heading>
            <template v-if="step == RmaStep.SELECT_REASON">
              <order-rma-dialog-order-info :order="order" />
              <sf-text-field
                v-model="rmaComment"
                class="my-1"
                hide-details="auto"
                :label="$t('common.comment')"
                :maxlength="commentMaxLength"
                :hint="
                  rmaComment.length >= commentMaxLength ? $t('maxLength', [commentMaxLength]) : ''
                "
              />
              <sf-sub-heading>{{ $t('rma.selectedPositions') }}</sf-sub-heading>
            </template>
          </div>
        </v-card-title>
      </v-card-item>
      <v-card-text>
        <loading-spinner v-if="loading" class="fill-height" flat />
        <template v-else-if="returnPositions.length > 0">
          <!-- STEP 1: Selecting positions -->
          <template v-if="step == RmaStep.SELECT_POSITION">
            <order-rma-available-return-position
              v-for="availableReturnPosition in returnPositions"
              :key="availableReturnPosition.orderLineItemId"
              :return-position="availableReturnPosition"
            />
          </template>
          <!-- STEP 2: Add reason -->
          <template v-if="step == RmaStep.SELECT_REASON">
            <order-rma-return-position
              v-for="(returnPosition, index) in returnPositions"
              :key="index"
              :return-position="returnPosition"
            />
          </template>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <sf-text-button color="grey" class="text-medium-emphasis" @click="dialog = false">
          {{ $t('cancel') }}
        </sf-text-button>
        <sf-text-button
          v-if="step == RmaStep.SELECT_POSITION"
          ref="nextButton"
          color="primary"
          :disabled="returnPositions.filter((position) => position.selected).length == 0"
          @click="goToStep2()"
        >
          {{ $t('continue') }}
        </sf-text-button>
        <sf-text-button
          v-if="step == RmaStep.SELECT_REASON"
          ref="sendButton"
          color="primary"
          :disabled="!hasAllReturnPositionAReason"
          :loading="sending"
          @click="request"
        >
          {{ $t('send') }}
        </sf-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfTextField from '@/components/input/SfTextField.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import { DetailedOrder } from '@/generatedTypes'
import OrderRmaAvailableReturnPosition from '@/modules/order/components/OrderRmaAvailableReturnPosition.vue'
import OrderRmaDialogOrderInfo from '@/modules/order/components/OrderRmaDialogOrderInfo.vue'
import OrderRmaReturnPosition from '@/modules/order/components/OrderRmaReturnPosition.vue'
import { RmaStep } from '@/modules/order/types'
import useOrderRma from '@/modules/order/useOrderRma'
import OrderEvent from '@/modules/tracking/events/order'
import { trackEvent } from '@/modules/tracking/useTracking'
import { storeToRefs } from 'pinia'
import { PropType, ref, watch } from 'vue'

const props = defineProps({
  order: {
    type: Object as PropType<DetailedOrder>,
    required: true
  }
})

const commentMaxLength = ref(500)
const dialog = ref(false)
const step = ref<RmaStep>(RmaStep.SELECT_POSITION)
const rmaComment = ref('')

const { loading, returnPositions, hasAllReturnPositionAReason, sending } =
  storeToRefs(useOrderRma())

const goToStep2 = () => {
  useOrderRma().mapReturnPositions()
  step.value = RmaStep.SELECT_REASON
}

const request = () => {
  useOrderRma()
    .requestOrderRma(props.order.id, rmaComment.value)
    .then(() => {
      trackEvent(OrderEvent.RequestRma)
      dialog.value = false
    })
}

watch(dialog, (newValue) => {
  if (newValue) {
    trackEvent(OrderEvent.OpenRmaDialog)
    useOrderRma().getOrderRma(props.order.id)
    step.value = RmaStep.SELECT_POSITION
    rmaComment.value = ''
  }
})
</script>
