<template>
  <template v-if="branding">
    <v-app-bar color="white">
      <v-row justify="center" no-gutters>
        <v-col cols="12" xxl="10" class="pa-xxl-0 px-1">
          <v-row no-gutters>
            <v-col cols="auto" class="d-flex justify-start align-center">
              <branding-logo :branding="branding" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-row justify="center" no-gutters>
          <v-col cols="12" xxl="10">
            <quote-loading v-if="loading || requirement === ResolveRequirement.ZIP_CODE" />
            <no-short-url-error v-if="requirement === ResolveRequirement.NO_SHORT_FORM" />
            <pin-input-dialog v-if="requirement === ResolveRequirement.ZIP_CODE" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </template>
</template>

<script setup lang="ts">
import BrandingLogo from '@/components/BrandingLogo.vue'
import useBranding from '@/modules/portalSettings/useBranding'
import QuoteLoading from '@/modules/quote/components/QuoteLoading.vue'
import NoShortUrlError from '@/modules/shortUrl/components/NoShortUrlError.vue'
import PinInputDialog from '@/modules/shortUrl/components/PinInputDialog.vue'
import { ResolveRequirement } from '@/modules/shortUrl/types'
import useShortUrl from '@/modules/shortUrl/useShortUrl'
import { storeToRefs } from 'pinia'

// remove redslave parameter before check for use with redslave node
useShortUrl().checkRequirements(window.location.href.replace('?redslave=true', ''))
const { branding } = useBranding()
const { requirement, loading } = storeToRefs(useShortUrl())
</script>
