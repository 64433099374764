<template>
  <sf-text>
    {{ $t('declineReason') + ': ' + (comment ? comment : $t('unknown')) }}
  </sf-text>
</template>

<script lang="ts">
import SfText from '@/components/text/SfText.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ApprovalRequestInfoDeclineComment',
  components: {
    SfText
  },
  props: {
    comment: {
      type: String,
      default: undefined
    }
  }
})
</script>
