<template>
  <div class="mt-2">
    <div v-if="isLoading">
      <v-skeleton-loader ref="usersLoading" class="mb-2" type="heading" />
      <v-skeleton-loader
        v-for="index in 6"
        :key="'1-' + index"
        class="mb-2"
        type="list-item-avatar"
      />
      <v-skeleton-loader class="mb-2" type="heading" />
      <v-skeleton-loader
        v-for="index in 6"
        :key="'2-' + index"
        class="mb-2"
        type="list-item-avatar"
      />
    </div>
    <template v-else>
      <div
        v-for="department in getSortedDepartments()"
        :id="department.title"
        :key="department.value"
      >
        <template v-if="getUsersByDepartment(department.title).length > 0">
          <sf-heading class="department">{{ department.translation }}</sf-heading>
          <user-card
            v-for="user in getUsersByDepartment(department.title)"
            :key="user.id"
            :user="user"
            class="my-1 user-card"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import SfHeading from '@/components/text/SfHeading.vue'
import { useTitle } from '@/helpers'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import UserCard from '@/modules/user/components/UserCard.vue'
import { getSortedDepartments } from '@/modules/user/helpers'
import useUsers from '@/modules/user/useUsers'
import router from '@/router'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'UsersPage',
  components: {
    UserCard,
    SfHeading
  },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('myCompany')} - ${t('company.employees')}`))

    // This is obviously ugly and stupid but there is only one customer using this crap and
    // when using the router the pinia store is not initialized yet so yeah (┛ಠ_ಠ)┛彡┻━┻
    const { portalSettings, loading: portalSettingsLoading } = storeToRefs(usePortalSettings())

    onMounted(() => {
      if (!portalSettingsLoading.value) {
        if (!portalSettings.value.hideColleaguesPermissions) {
          useUsers().getUsers()
        } else {
          router.push('addresses')
        }
      }
    })

    watch(portalSettingsLoading, (newValue) => {
      if (!newValue && portalSettings.value.hideColleaguesPermissions) {
        router.push('addresses')
      } else {
        useUsers().getUsers()
      }
    })

    const { getUsersByDepartment, loading } = storeToRefs(useUsers())
    const isLoading = computed(() => loading.value || portalSettingsLoading.value)

    return {
      isLoading,
      getUsersByDepartment,
      getSortedDepartments
    }
  }
})
</script>
