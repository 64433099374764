import { axiosSetup } from '@/configs/axios'
import useAuthentication from '@/modules/authentication/useAuthentication'
import useError from '@/modules/error/useError'
import useProductComparison from '@/modules/product/useProductComparison'
import useRequestManager from '@/store/useRequestManager'
import * as Sentry from '@sentry/vue'
import { createSentryPiniaPlugin } from '@sentry/vue'
import { createPinia, defineStore, getActivePinia } from 'pinia'

axiosSetup()

type ReturnType = {
  reset: (withRedslave?: boolean) => void
}

export function useStateManager(): ReturnType {
  const reset = (withRedslave = true) => {
    useRequestManager().cancelPendingRequests()

    Sentry.getCurrentScope().setUser(null)

    useAuthentication().reset(withRedslave)
    useError().reset()
    useProductComparison().reset()

    const activepinia = getActivePinia()
    if (activepinia) {
      Object.entries(activepinia.state.value).forEach(([storeName, state]) => {
        const storeDefinition = defineStore(storeName, state)
        const store = storeDefinition(activepinia)
        store.$reset()
      })
    }
  }

  return {
    reset
  }
}

const pinia = createPinia()

pinia.use(createSentryPiniaPlugin())

export default pinia
