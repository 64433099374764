<template>
  <login-wrapper v-if="branding" :branding="branding" :title="title">
    <div class="mt-2">
      <self-registration-email v-if="step === Step.EMAIL" @submit="handleEmailSubmit" />
      <self-registration-company
        v-else-if="step === Step.COMPANY"
        @submit="handleCompanyRequestSubmit"
      />
      <self-registration-hint
        v-else-if="step === Step.LINK_SENT"
        :text="t('registrationLinkSent')"
      />
      <self-registration-hint
        v-else-if="step === Step.REQUESTED"
        :text="t('registrationRequested')"
      />
    </div>
  </login-wrapper>
</template>

<script setup lang="ts">
import { SelfRegistration } from '@/generatedTypes'
import { useTitle } from '@/helpers'
import LoginWrapper from '@/modules/authentication/components/LoginWrapper.vue'
import SelfRegistrationCompany from '@/modules/authentication/components/SelfRegistrationCompany.vue'
import SelfRegistrationEmail from '@/modules/authentication/components/SelfRegistrationEmail.vue'
import SelfRegistrationHint from '@/modules/authentication/components/SelfRegistrationHint.vue'
import { CompanyRegistrationDTO, EmailRegistrationDTO, Step } from '@/modules/authentication/types'
import useSelfRegistration from '@/modules/authentication/useSelfRegistration'
import useBranding from '@/modules/portalSettings/useBranding'
import router from '@/router'
import { useStateManager } from '@/store'
import { computed, onBeforeMount, reactive, ref, toRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t, locale } = useI18n()
const { branding } = useBranding()
useTitle(ref(`${t('windowTitle.Registration')}`))
useStateManager().reset()

const step = ref(Step.EMAIL)
const email = ref('')

// user should be able to go back to EMAIL step but he must not go forward to COMPANY step
const route = toRef(reactive(router), 'currentRoute')
watch(
  () => route.value.params.step,
  (nextStep) => {
    if (nextStep === Step.EMAIL) {
      step.value = Step.EMAIL
    } else if (step.value !== nextStep) {
      resetSelfRegistration()
    }
  }
)

const title = computed(() => {
  switch (step.value) {
    case Step.EMAIL:
    case Step.LINK_SENT: {
      return t('registration')
    }
    case Step.COMPANY:
    case Step.REQUESTED: {
      return t('registerCompany')
    }
    default: {
      return ''
    }
  }
})

// if user reloads when he is not at EMAIL step, he will be redirected to EMAIL step
onBeforeMount(() => {
  const nextStep = router.currentRoute.value.params.step
  if (nextStep !== Step.EMAIL) {
    resetSelfRegistration()
  }
})

const resetSelfRegistration = () => {
  const routerStep = router.currentRoute.value.params.step
  if (routerStep !== Step.EMAIL) {
    router.replace({
      name: 'SelfRegistration',
      params: { step: Step.EMAIL }
    })
  } else {
    step.value = Step.EMAIL
  }
}

const handleEmailSubmit = (emailRegistrationDto: EmailRegistrationDTO) => {
  email.value = emailRegistrationDto.email
  router.push({ name: 'SelfRegistration', params: { step: emailRegistrationDto.nextStep } })
  step.value = emailRegistrationDto.nextStep
}

const handleCompanyRequestSubmit = (companyRegistrationDto: CompanyRegistrationDTO) => {
  const selfRegistration: SelfRegistration = {
    ...companyRegistrationDto,
    person: {
      ...companyRegistrationDto.person,
      email: email.value,
      alpha2language: locale.value
    }
  }

  useSelfRegistration().requestSelfRegistration(
    selfRegistration,
    (newStep) => (step.value = newStep)
  )
}
</script>
