<template>
  <v-system-bar
    :height="(isRedslave && impersonateMode) || (isRedslave && isPreview) ? 60 : 30"
    class="d-block pa-0"
  >
    <info-bar-text v-if="isRedslave" color="red" :text="$t('readOnlyMode')" />
    <info-bar-text
      v-if="impersonateMode"
      :text="branchMode ? $t('subsidiaryMode', [branchName]) : $t('impersonateMode', [branchName])"
    />
    <info-bar-text v-if="isPreview" :text="$t('quotePreview')" />
  </v-system-bar>
</template>

<script lang="ts">
import InfoBarText from '@/components/info-bar/InfoBarText.vue'
import useAuthentication from '@/modules/authentication/useAuthentication'
import { Relation } from '@/generatedTypes'
import useSubsidiary from '@/modules/subsidiary/useSubsidiary'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'InfoBar',
  components: { InfoBarText },
  setup() {
    const isRedslave = computed(() => useAuthentication().state.value.isRedslave)
    const isPreview = computed(() => useAuthentication().state.value.isReadonly)
    const impersonateMode = computed(() => useAuthentication().state.value.isImpersonateMode)
    const branchMode = computed(
      () => useSubsidiary().impersonationStatus.relation == Relation.BRANCH
    )
    const branchName = computed(() => useSubsidiary().impersonationStatus.customer)

    return { isRedslave, isPreview, impersonateMode, branchName, branchMode }
  }
})
</script>
