<template>
  <v-list-item :disabled="collection.items.length === 0" @click="requestQuote">
    <v-list-item-title>
      {{ $t('quotes.createRequest') }}
    </v-list-item-title>
  </v-list-item>
</template>

<script lang="ts">
import { DetailedShopCollection } from '@/generatedTypes'
import useCollection from '@/modules/collection/useCollection'
import QuoteEvent from '@/modules/tracking/events/quote'
import { trackEvent } from '@/modules/tracking/useTracking'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'CollectionRequestQuote',
  props: {
    collection: {
      type: Object as PropType<DetailedShopCollection>,
      required: true
    }
  },
  setup(props) {
    const requestQuote = () => {
      useCollection().requestQuote(props.collection.id)
      trackEvent(QuoteEvent.CreateRequestFromCollection)
    }

    return { requestQuote }
  }
})
</script>
