import { post, Response } from '@/api'
import { Resolve, ResolveRequirement, ShortUrl } from '@/modules/shortUrl/types'

export default {
  checkRequirements: (shortUrl: ShortUrl): Promise<Response<ResolveRequirement>> => {
    return post<ResolveRequirement, ShortUrl>('/shorturl/requirement', shortUrl)
  },
  resolveUrl: (resolve: Resolve): Promise<Response<string>> => {
    return post<string, Resolve>('/shorturl/resolve', resolve)
  }
}
