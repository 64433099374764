import { SelfInvite, SelfRegistration } from '@/generatedTypes'
import { ApiError, handleApiError } from '@/handleApiError'
import api from '@/modules/authentication/api'
import { Step } from '@/modules/authentication/types'
import { EmailStatus } from '@/modules/user/types'
import router from '@/router'
import { defineStore } from 'pinia'

interface SelfRegistrationState {
  sending: boolean
}

const initialState = (): SelfRegistrationState => {
  return {
    sending: false
  }
}

const useSelfRegistration = defineStore('selfRegistration', {
  state: () => initialState(),
  actions: {
    async validateEmail(email: string): Promise<EmailStatus | void> {
      this.sending = true

      return api
        .validateRegistrationEmail(email)
        .then(({ data }) => data)
        .catch((error: ApiError) => {
          handleApiError(error, {
            customMessageKey: 'error.invitation'
          })
        })
        .finally(() => (this.sending = false))
    },
    async requestRegistrationEmail(data: SelfInvite) {
      this.sending = true

      return api.selfInvite(data).finally(() => (this.sending = false))
    },
    async requestSelfRegistration(data: SelfRegistration, setStepCallback: (step: Step) => void) {
      this.sending = true

      return api
        .executeSelfRegistration(data)
        .then(() => {
          router.replace({
            name: 'SelfRegistration',
            params: { step: Step.REQUESTED }
          })
          setStepCallback(Step.REQUESTED)
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            customMessageKey: 'error.registration'
          })
        })
        .finally(() => (this.sending = false))
    }
  }
})

export default useSelfRegistration
