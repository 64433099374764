<template>
  <v-dialog v-model="dialog" :width="500" @keydown.esc="close">
    <template #activator="{ props }">
      <v-list-item v-bind="props" :prepend-icon="mdiAccountSwitchOutline">
        <v-list-item-title>
          {{ $t('switchSubsidiary') }}
        </v-list-item-title>
      </v-list-item>
    </template>
    <v-card :loading="loading">
      <v-card-item>
        <v-card-title>
          <sf-heading>{{ $t('switchSubsidiary') }}</sf-heading>
        </v-card-title>
      </v-card-item>
      <v-card-text>
        <sf-text>{{ $t('switchSubsidiaryHint') }}</sf-text>
        <v-autocomplete
          v-model="subsidiaryId"
          color="primary"
          class="mt-2"
          variant="outlined"
          density="compact"
          clearable
          hide-details
          :items="subsidiaries"
          item-title="name"
          item-value="id"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <sf-text-button ref="send-button" :disabled="!subsidiaryId" @click="switchToSubsidiary"
          >{{ $t('switchSubsidiary') }}
        </sf-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import useSubsidiary from '@/modules/subsidiary/useSubsidiary'
import MyCompanyEvent from '@/modules/tracking/events/MyCompany'
import { trackEvent } from '@/modules/tracking/useTracking'
import { mdiAccountSwitchOutline } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'SwitchSubsidiaryDialog',
  components: { SfTextButton, SfText, SfHeading },
  emits: ['close'],
  setup(_, { emit }) {
    const dialog = ref(false)
    const { subsidiaries, loading } = storeToRefs(useSubsidiary())
    const subsidiaryId = ref<string | undefined>(undefined)

    const switchToSubsidiary = () => {
      if (subsidiaryId.value) {
        trackEvent(MyCompanyEvent.ChangeSubsidiary)
        useSubsidiary().switchToSubsidiary(subsidiaryId.value)
      }
    }

    const close = () => {
      dialog.value = false
      emit('close')
    }

    return {
      dialog,
      subsidiaries,
      subsidiaryId,
      loading,
      close,
      switchToSubsidiary,
      mdiAccountSwitchOutline
    }
  }
})
</script>
