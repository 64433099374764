import { PortalSettings } from '@/modules/portalSettings/types'
import { Router, RouteRecordRaw } from 'vue-router'
import {
  getCompanyRoutes,
  getCtoFinderRoutes,
  getPromotionalProductsRoutes,
  getQuotesRoutes
} from '@/router/additionalRoutes'

const getRouteRedirect = (router: Router): string => {
  return router.currentRoute.value.query.redirect
    ? '?redirect=' + router.currentRoute.value.query.redirect
    : ''
}

const addRoutes = (routes: RouteRecordRaw[], router: Router) => {
  routes.forEach((route) => router.addRoute(route))
}

const removeRoutes = (routes: RouteRecordRaw[], router: Router) => {
  routes.forEach((route) => {
    if (route.name != null) {
      router.removeRoute(route.name)
    }
  })
}

const addOrRemoveAdditionalRoutes = (portalSettings: PortalSettings, router: Router) => {
  if (portalSettings.useAppleCtoFinder) {
    addRoutes(getCtoFinderRoutes(), router)
  } else {
    removeRoutes(getCtoFinderRoutes(), router)
  }

  if (portalSettings.useQuotes) {
    addRoutes(getQuotesRoutes(), router)
  } else {
    removeRoutes(getQuotesRoutes(), router)
  }

  if (portalSettings.usePromotionalProducts) {
    addRoutes(getPromotionalProductsRoutes(), router)
  } else {
    removeRoutes(getPromotionalProductsRoutes(), router)
  }

  if (!portalSettings.hideProfile) {
    addRoutes(getCompanyRoutes(), router)
  } else {
    removeRoutes(getCompanyRoutes(), router)
  }
}

export { getRouteRedirect, addOrRemoveAdditionalRoutes }
