<template>
  <v-card class="py-1 px-2 mb-1 pw-order-limit-user">
    <v-row no-gutters>
      <v-col cols="12" md="" class="d-flex align-center justify-start mb-2 mb-md-0">
        <avatar-image :user="user" :size="40" class="mr-2" />
        <sf-text ref="name">{{ getFullName(user) }}</sf-text>
        <template v-if="$vuetify.display.smAndDown && editable">
          <v-spacer />
          <order-limit-dialog :user="user" :approvers="approvers" />
        </template>
      </v-col>
      <v-col cols="12" md="" class="d-flex align-center justify-end mb-2 mb-md-0">
        <sf-sub-heading class="d-md-none mr-2">{{ $t('orderLimits.orderRight') }}</sf-sub-heading>
        <v-spacer />
        <div ref="order-right" class="pw-order-limit-user-right">
          <sf-color-text v-if="user.submitOrder" color="green">{{ $t('yes') }}</sf-color-text>
          <sf-color-text v-else color="red">{{ $t('no') }}</sf-color-text>
        </div>
      </v-col>
      <v-col cols="12" md="" class="d-flex align-center justify-end mb-2 mb-md-0">
        <sf-sub-heading class="d-md-none mr-2">{{ $t('orderLimits.orderLimit') }}</sf-sub-heading>
        <v-spacer />
        <div ref="order-limit" class="text-right pw-order-limit-user-limit">
          <sf-text
            v-if="user.orderLimitType === OrderLimitType.USER && user.userOrderLimit != undefined"
            >{{ $n(user.userOrderLimit, 'currency') }}</sf-text
          >
          <sf-text v-if="user.orderLimitType === OrderLimitType.UNLIMITED">
            {{ $t('orderLimits.unlimited') }}
          </sf-text>
          <sf-text v-if="user.orderLimitType === OrderLimitType.DEFAULT">
            {{ $t('orderLimits.defaultLimit') }}
          </sf-text>
        </div>
      </v-col>
      <v-col cols="12" md="" class="d-flex align-start align-md-center justify-end">
        <sf-sub-heading class="d-md-none mr-2">{{
          $t('orderLimits.orderApprover')
        }}</sf-sub-heading>
        <v-spacer />
        <div ref="approvers">
          <template v-if="user.approvers.length > 0">
            <v-row no-gutters>
              <v-col class="d-flex flex-wrap justify-end">
                <avatar-image
                  v-for="approver in user.approvers"
                  :key="approver.id"
                  :user="approver"
                  :size="40"
                  class="ml-1"
                />
              </v-col>
            </v-row>
          </template>
          <sf-text v-if="user.approvers.length === 0" class="pw-order-limit-user-approvers">{{
            $t('orderLimits.default')
          }}</sf-text>
        </div>
      </v-col>
      <v-col
        v-if="$vuetify.display.mdAndUp && editable"
        cols="auto"
        class="d-flex align-center justify-end mx-1"
      >
        <order-limit-dialog ref="edit" :user="user" :approvers="approvers" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import AvatarImage from '@/components/AvatarImage.vue'
import SfColorText from '@/components/text/SfColorText.vue'
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { OrderLimitType, OrderLimitUser, ShortUser } from '@/generatedTypes'
import { getFullName } from '@/helpers'
import OrderLimitDialog from '@/modules/orderLimits/components/OrderLimitDialog.vue'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'OrderLimitUserCard',
  components: {
    OrderLimitDialog,
    AvatarImage,
    SfText,
    SfColorText,
    SfSubHeading
  },
  props: {
    user: {
      type: Object as PropType<OrderLimitUser>,
      required: true
    },
    approvers: {
      type: Array as PropType<ShortUser[]>,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return { getFullName, OrderLimitType }
  }
})
</script>
