<template>
  <loading-spinner v-if="loading" />
  <template v-else>
    <template v-if="$vuetify.display.mdAndUp && showFeatureAd()">
      <feature-ad-start v-if="showFeatureAdStart()" />
    </template>

    <info-bar v-if="isRedslave || impersonateMode" />

    <v-navigation-drawer
      v-if="$vuetify.display.smAndDown"
      v-model="drawer"
      floating
      width="260"
      class="elevation-1"
    >
      <template v-if="branding" #prepend>
        <router-link
          class="text-decoration-none text-center"
          :to="{ name: 'StartPage' }"
          @click="trackNavigationHome"
        >
          <branding-logo class="pa-1" :branding="branding" />
        </router-link>
      </template>

      <side-menu :menu="menu()" :portal-settings="portalSettings" />

      <template #append>
        <vat-hint :portal-settings="portalSettings" class="d-flex pa-2 align-center" />
      </template>
    </v-navigation-drawer>

    <v-app-bar color="white">
      <v-row justify="center" no-gutters>
        <v-col cols="12" xxl="10" class="pa-xxl-0 px-1">
          <v-row no-gutters>
            <template v-if="!showSearch">
              <v-col cols="2" sm="2" md="3" xl="4" class="d-flex align-center">
                <router-link
                  v-if="!$vuetify.display.smAndDown && branding"
                  class="text-decoration-none"
                  :to="{ name: 'StartPage' }"
                  @click="trackNavigationHome"
                >
                  <branding-logo :branding="branding" />
                </router-link>

                <v-app-bar-nav-icon
                  v-if="$vuetify.display.smAndDown"
                  @click.stop="drawer = !drawer"
                />
              </v-col>
              <v-spacer />
              <v-col
                v-if="$vuetify.display.mdAndUp"
                ref="searchBar"
                sm="7"
                md="6"
                xl="4"
                class="d-flex align-center justify-center"
              >
                <instant-search :max-width="instantSearchWidth" />
              </v-col>
              <v-spacer />
              <v-col
                cols="10"
                sm="2"
                md="3"
                xl="4"
                class="d-flex flex-row align-center justify-end"
              >
                <v-btn
                  v-if="$vuetify.display.smAndDown"
                  id="pw-search-btn"
                  variant="text"
                  rounded
                  elevation="0"
                  class="pa-0 text-medium-emphasis"
                  height="32"
                  @click="showSearch = true"
                >
                  <v-icon :size="28" :icon="mdiMagnify" />
                </v-btn>

                <vat-hint
                  v-if="!$vuetify.display.smAndDown"
                  :portal-settings="portalSettings"
                  class="ml-1 mr-1 text-center"
                />
                <sf-text-button
                  v-if="portalSettings.backToPlatformLink"
                  :href="portalSettings.backToPlatformLink"
                >
                  {{ $t('backToPlatform') }}
                </sf-text-button>
                <toolbar-product-comparison v-if="$vuetify.display.sm" />

                <toolbar-shopping-cart v-if="$vuetify.display.smAndDown" class="mr-1" />

                <toolbar-user
                  v-if="ownUser && !portalSettings.hideProfile"
                  :is-admin="ownUser.permission.shopAdmin"
                  :has-logout="portalSettings.showLogout"
                  :change-subsidiary="ownUser.permission.changeSubsidiary && hasSubsidiaries"
                  :avatar-url="ownUser.avatarUrl ? ownUser.avatarUrl : ''"
                  :full-name="getFullName(ownUser)"
                  :user-id="ownUser.id"
                  :branding="branding"
                  :menu="toolbarUserMenu"
                  @logout="logout"
                />
              </v-col>
            </template>
            <!-- mobile search input -->
            <v-col
              v-if="showSearch"
              ref="searchBarMobile"
              cols="12"
              class="d-flex align-center justify-center"
            >
              <instant-search mobile @reset="showSearch = false" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <template v-if="!$vuetify.display.smAndDown" #extension>
        <v-container fluid class="pa-0">
          <v-row justify="center" no-gutters>
            <v-col cols="12" xxl="10">
              <v-divider />
              <navigation-menu :menu="menu()" :portal-settings="portalSettings" />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-row justify="center" no-gutters>
          <v-col cols="12" xxl="10">
            <error-component
              v-if="error.code && (!error.customHandler || mustHandleError)"
              :message="error.message"
              :code="error.code"
            />
            <router-view v-else />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </template>
</template>

<script lang="ts">
import BrandingLogo from '@/components/BrandingLogo.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import VatHint from '@/components/VatHint.vue'
import SfTextButton from '@/components/button/SfTextButton.vue'
import InfoBar from '@/components/info-bar/InfoBar.vue'
import NavigationMenu from '@/components/navigation/NavigationMenu.vue'
import SideMenu from '@/components/navigation/SideMenu.vue'
import { menu, toolbarUserMenu } from '@/components/navigation/menu'
import ToolbarProductComparison from '@/components/toolbar/ToolbarProductComparison.vue'
import ToolbarShoppingCart from '@/components/toolbar/ToolbarShoppingCart.vue'
import ToolbarUser from '@/components/toolbar/ToolbarUser.vue'
import { getFullName } from '@/helpers'
import useAuthentication from '@/modules/authentication/useAuthentication'
import ErrorComponent from '@/modules/error/components/ErrorComponent.vue'
import { customErrorHandling } from '@/modules/error/helpers'
import FeatureAdStart from '@/modules/featureAd/components/FeatureAdStart.vue'
import useFeatureAd from '@/modules/featureAd/useFeatureAd'
import useBranding from '@/modules/portalSettings/useBranding'
import useOrderingConditions from '@/modules/portalSettings/useOrderingConditions'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import InstantSearch from '@/modules/search/components/InstantSearch.vue'
import useSubsidiary from '@/modules/subsidiary/useSubsidiary'
import useTopi from '@/modules/topi/useTopi'
import ApplicationHeaderEvent from '@/modules/tracking/events/applicationHeader'
import { trackEvent } from '@/modules/tracking/useTracking'
import useOwnUser from '@/modules/user/useOwnUser'
import vuetify from '@/plugins/vuetify'
import { addOrRemoveAdditionalRoutes } from '@/router/helpers'
import { mdiMagnify } from '@mdi/js'
import { useScriptTag } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'PortalLayout',
  components: {
    BrandingLogo,
    ErrorComponent,
    FeatureAdStart,
    InfoBar,
    InstantSearch,
    LoadingSpinner,
    NavigationMenu,
    SfTextButton,
    SideMenu,
    ToolbarProductComparison,
    ToolbarShoppingCart,
    ToolbarUser,
    VatHint
  },
  setup() {
    const drawer = ref(!vuetify.display.mobile.value)
    const currentRoute = useRoute()
    const router = useRouter()
    const loading = ref(true)
    const { showFeatureAd, startPages } = storeToRefs(useFeatureAd())
    const { portalSettings } = storeToRefs(usePortalSettings())

    Promise.all([
      useOwnUser().initUserSession(),
      useOrderingConditions().getOrderingConditions(),
      usePortalSettings().getPortalSettings(),
      useSubsidiary().getImpersonationStatus(),
      useSubsidiary().loadSubsidiaries()
    ]).then(async () => {
      addOrRemoveAdditionalRoutes(portalSettings.value, router)
      await router.replace(router.currentRoute.value.fullPath)

      if (
        portalSettings.value.useTopi &&
        import.meta.env.VITE_STOREFRONT_TOPI_ELEMENTS_LIBRARY_URL &&
        import.meta.env.VITE_STOREFRONT_TOPI_ELEMENTS_WIDGET_ID
      ) {
        useScriptTag(import.meta.env.VITE_STOREFRONT_TOPI_ELEMENTS_LIBRARY_URL, () =>
          useTopi().initTopi(useOwnUser().ownUser.alpha2language)
        )
      }

      loading.value = false
    })

    const searchBarRef = ref<HTMLElement>()
    const showSearch = ref(false)
    // set maxWidth depending on clientWidth
    const instantSearchWidth = ref('480px')
    const getMaxWidth = () => {
      if (searchBarRef.value && searchBarRef.value.clientWidth) {
        instantSearchWidth.value = searchBarRef.value.clientWidth.toString()
      }
    }
    const windowSizeChanged = () => {
      getMaxWidth()
    }
    onMounted(() => {
      getMaxWidth()
      window.addEventListener('resize', windowSizeChanged)
    })
    onUnmounted(() => {
      window.removeEventListener('resize', windowSizeChanged)
    })
    const impersonateMode = computed(() => useAuthentication().state.value.isImpersonateMode)
    const isRedslave = computed(() => useAuthentication().state.value.isRedslave)

    const { ownUser } = storeToRefs(useOwnUser())
    const { branding } = useBranding()
    const { error, mustHandleError } = customErrorHandling()
    const hasSubsidiaries = computed(() => useSubsidiary().subsidiaries.length > 0)
    const logout = () => {
      trackEvent(ApplicationHeaderEvent.Logout).finally(() => useAuthentication().logout())
    }

    const trackNavigationHome = () => {
      trackEvent(ApplicationHeaderEvent.Logo)
    }
    const showFeatureAdStart = () => {
      return startPages.value.includes(currentRoute.path)
    }

    return {
      branding,
      drawer,
      error,
      showFeatureAd,
      hasSubsidiaries,
      impersonateMode,
      instantSearchWidth,
      isRedslave,
      loading,
      ownUser,
      portalSettings,
      searchBar: searchBarRef,
      showSearch,
      toolbarUserMenu,
      getFullName,
      logout,
      menu,
      showFeatureAdStart,
      trackNavigationHome,
      mustHandleError,
      mdiMagnify
    }
  }
})
</script>
