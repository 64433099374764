<template>
  <!-- Loading -->
  <template v-if="loading">
    <v-row dense>
      <v-col v-for="index in 12" :key="index" cols="12" sm="6" md="4" :lg="cardsLg" :xl="cardsXl">
        <v-skeleton-loader type="card" />
      </v-col>
    </v-row>
  </template>

  <list-content v-else-if="products.length > 0">
    <!-- Products -->
    <v-col
      v-for="(product, index) in products"
      :key="index"
      v-intersect.once="index === products.length - 2 ? loadNextPage : ''"
      cols="12"
      sm="6"
      md="4"
      :lg="cardsLg"
      :xl="cardsXl"
    >
      <product-card
        show-product-identifiers
        :add-to-cart-event="addToCartEvent"
        :loading-realtime-prices="loadingRealtimePrices"
        :product="product"
      />
    </v-col>
    <v-col v-if="loadingNextPage" cols="12" class="d-flex justify-center">
      <v-progress-circular indeterminate :size="50" color="primary" class="ma-1" />
    </v-col>
  </list-content>

  <!-- Empty Message -->
  <list-empty
    v-else-if="products.length === 0"
    :icon="mdiStore"
    :message="keywordTooShortError ? $t('error.keywordTooShort') : $t('noProductsFound')"
  />
</template>

<script setup lang="ts">
import ListContent from '@/components/list/ListContent.vue'
import ListEmpty from '@/components/list/ListEmpty.vue'
import { MediumProduct } from '@/generatedTypes'
import ProductCard from '@/modules/product/components/ProductCard.vue'
import useProductSearch from '@/modules/product/useProductSearch'
import useRealtimePrices from '@/modules/product/useRealtimePrices'
import AddToCartButtonEvents from '@/modules/tracking/events/helper/addToCartButton'
import { mdiStore } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { PropType } from 'vue'

defineProps({
  addToCartEvent: {
    type: Object as PropType<AddToCartButtonEvents>,
    default: undefined
  },
  products: {
    type: Array as PropType<MediumProduct[]>,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  cardsLg: {
    type: Number,
    default: 3
  },
  cardsXl: {
    type: Number,
    default: 2
  }
})
const { loadingNextPage, keywordTooShortError } = storeToRefs(useProductSearch())
const { loading: loadingRealtimePrices } = storeToRefs(useRealtimePrices())

const loadNextPage = (_entries: unknown, _observer: unknown, isIntersecting: boolean): void => {
  if (isIntersecting) {
    useProductSearch().loadNextPage()
  }
}
</script>
