<template>
  <v-autocomplete
    ref="searchInput"
    v-model="selectedItem"
    v-model:search="searchTerm"
    :items="getInstantSearchItems"
    :loading="loading"
    return-object
    :append-inner-icon="mobile ? mdiClose : ''"
    :placeholder="$t('search')"
    :prepend-inner-icon="mdiMagnify"
    hide-details
    hide-no-data
    hide-selected
    no-filter
    color="primary"
    :variant="mobile ? 'solo' : 'outlined'"
    :flat="mobile"
    :autofocus="mobile"
    :rounded="!mobile"
    :clearable="!mobile"
    :clear-icon="mdiClose"
    :density="mobile ? 'default' : 'compact'"
    :menu-props="mobile ? mobileMenuProps : menuProps"
    class="no-menu-icon"
    item-value="productId"
    @click:append-inner="$emit('reset')"
    @keydown.enter.prevent="search"
  >
    <template #item="{ item, props }">
      <!-- Title -->
      <v-list-item
        v-if="isSearchInstantTitle(item.raw)"
        class="d-flex flex-row align-center"
        v-bind="props"
        title=""
      >
        <sf-heading>
          {{ $t(item.raw.key) }}
        </sf-heading>
        <sf-link v-if="item.raw.type != InstantSearchType.PRODUCT" class="ml-1">
          {{ $t('searchAll') }}
        </sf-link>
      </v-list-item>

      <!-- Product -->
      <instant-search-product-item
        v-if="isProduct(item.raw)"
        :item="item.raw"
        :search-term="searchTerm"
        v-bind="props"
      />

      <!-- Order -->
      <v-list-item v-if="isOrder(item.raw)" v-bind="props" title="">
        <template #prepend>
          <v-avatar class="bg-grey-lighten-2">
            <v-icon color="white" :icon="mdiInboxArrowUp" />
          </v-avatar>
        </template>

        <v-list-item-title>
          <sf-text>
            <span>
              {{ $t('order.order') }}
              <v-nodes :vnodes="highlightResult(item.raw.orderNumber, searchTerm)" />
            </span>
          </sf-text>
        </v-list-item-title>

        <v-list-item-subtitle>
          <span class="text-body-2 text-medium-emphasis">
            <span>{{ item.raw.initiatorFullName }}</span>
            <span class="mx-1">-</span>
            <span>{{ $t('positions', item.raw.itemCount) }}</span>
            <span class="mx-1">-</span>
            <span>{{ formatDate(item.raw.lastUpdated, false) }}</span>
          </span>
        </v-list-item-subtitle>

        <template #append>
          <sf-color-text :color="getStatusColor(item.raw.orderStatus)">
            {{ $t('orderStatus.' + item.raw.orderStatus) }}
          </sf-color-text>
        </template>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import VNodes from '@/components/VNodes'
import SfColorText from '@/components/text/SfColorText.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfLink from '@/components/text/SfLink.vue'
import SfText from '@/components/text/SfText.vue'
import { MediumProduct, OrderSearchModel } from '@/generatedTypes'
import { getStatusColor } from '@/modules/order/helpers'
import { ProductSort } from '@/modules/product/types'
import useProductFilter from '@/modules/product/useProductFilter'
import InstantSearchProductItem from '@/modules/search/components/InstantSearchProductItem.vue'
import { highlightResult, isOrder, isProduct, isSearchInstantTitle } from '@/modules/search/helpers'
import { InstantSearchItem, InstantSearchType } from '@/modules/search/type'
import useInstantSearch from '@/modules/search/useInstantSearch'
import OrderEvent from '@/modules/tracking/events/order'
import ProductSearchEvent from '@/modules/tracking/events/search'
import { trackEvent } from '@/modules/tracking/useTracking'
import router from '@/router'
import { formatDate } from '@/utils/timeFormatHelpers'
import { mdiClose, mdiInboxArrowUp, mdiMagnify } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { ComponentPublicInstance, computed, defineComponent, reactive, ref, watch } from 'vue'
import { NavigationFailure, RouteLocationRaw } from 'vue-router'

export default defineComponent({
  name: 'InstantSearch',
  components: {
    VNodes,
    SfText,
    SfColorText,
    SfHeading,
    InstantSearchProductItem,
    SfLink
  },
  props: {
    maxWidth: {
      type: String,
      default: '480px'
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  emits: ['reset'],
  setup(props, { emit }) {
    const searchInput = ref<ComponentPublicInstance<HTMLElement>>()
    const searchTerm = ref<string | undefined>('')
    const selectedItem = ref<InstantSearchItem | undefined>()
    const { getInstantSearchItems, loading } = storeToRefs(useInstantSearch())
    // Workaround as searchTerm gets set to item title right before selectedItem watcher gets triggered. Therefore we cannot read it in there for the searchProduct and searchOrder calls.
    const searchTermBackup = ref<string | undefined>('')

    let blockFurtherSearchRequests = false

    const menuProps = computed(() => ({
      closeOnContentClick: true,
      closeOnClick: false,
      maxWidth: props.maxWidth,
      rounded: 'lg',
      maxHeight: window.innerHeight * 0.75
    }))

    const mobileMenuProps = reactive({
      closeOnContentClick: true,
      closeOnClick: false,
      minWidth: '100vw',
      maxHeight: window.innerHeight
    })

    const resetInput = async () => {
      emit('reset')
      searchTerm.value = undefined
      selectedItem.value = undefined
      useInstantSearch().clearItems()
      searchInput.value?.blur()
    }

    const searchProducts = (term: string) => {
      useProductFilter().resetFilter(ProductSort.RELEVANCE)
      trackEvent(ProductSearchEvent.TextSearch(term))
      navigate({
        name: 'Search',
        query: { keyword: term, page: '1' }
      })
    }

    const searchOrders = (term: string) => {
      trackEvent(OrderEvent.TextSearchInstantSearch)
      navigate({
        name: 'Orders',
        query: { keyword: term, page: '1' }
      })
    }

    const openProduct = (product: MediumProduct) => {
      trackEvent(ProductSearchEvent.InstantSearch)
      navigate({
        name: 'Product',
        params: { id: product.productId.toString() }
      })
    }

    const openOrder = (order: OrderSearchModel) => {
      trackEvent(OrderEvent.InstantSearch)
      navigate({
        name: 'OrderDetails',
        params: { id: order.id.toString() }
      })
    }

    const navigate = (location: RouteLocationRaw) => {
      router
        .push(location)
        .catch((error: NavigationFailure) => {
          if (
            error.name !== 'NavigationDuplicated' &&
            !error.message.includes('Avoided redundant navigation to current location')
          ) {
            /* eslint-disable-next-line no-console */
            console.log(error)
          }
        })
        .finally(() => (blockFurtherSearchRequests = false))
    }

    watch(selectedItem, (value) => {
      if (value && searchTermBackup.value && !blockFurtherSearchRequests) {
        blockFurtherSearchRequests = true

        if (isSearchInstantTitle(value)) {
          if (value.type === InstantSearchType.PRODUCT) {
            searchProducts(searchTermBackup.value)
          } else if (value.type === InstantSearchType.ORDER) {
            searchOrders(searchTermBackup.value)
          }
        } else if (isProduct(value)) {
          openProduct(value)
        } else if (isOrder(value)) {
          openOrder(value)
        }

        resetInput()
      }
    })

    const timerId = ref<ReturnType<typeof setTimeout>>()

    watch(searchTerm, (value) => {
      clearTimeout(timerId.value)
      searchTermBackup.value = value

      timerId.value = setTimeout(() => {
        if (value && value.length >= 2) {
          useInstantSearch().instantSearch(value)
        } else {
          useInstantSearch().clearItems()
        }
      }, 400)
    })

    const search = () => {
      if (searchTerm.value != undefined && searchTerm.value != '') {
        searchProducts(searchTerm.value)
        resetInput()
      }
    }

    return {
      formatDate,
      getInstantSearchItems,
      getStatusColor,
      isOrder,
      isProduct,
      isSearchInstantTitle,
      loading,
      menuProps,
      mobileMenuProps,
      searchInput,
      searchTerm,
      selectedItem,
      InstantSearchType,
      highlightResult,
      search,
      mdiInboxArrowUp,
      mdiClose,
      mdiMagnify
    }
  }
})
</script>
