import { BatchProductPriceUpdate, MediumProduct, ProductPriceUpdate } from '@/generatedTypes'
import { ApiError, handleApiError } from '@/handleApiError'
import api from '@/modules/product/api'
import { ProductSearchOptions } from '@/modules/product/types'
import useRealtimePrices from '@/modules/product/useRealtimePrices'
import { concat } from 'lodash-es'
import { defineStore } from 'pinia'

interface ProductSearchState {
  loading: number
  items: MediumProduct[]
  lastKey?: number
  hasNext: boolean
  options: ProductSearchOptions | undefined
  loadingNextPage: boolean
  keywordTooShortError: boolean
}

const initialState = (): ProductSearchState => {
  return {
    loading: 0,
    items: [],
    lastKey: undefined,
    hasNext: false,
    options: undefined,
    loadingNextPage: false,
    keywordTooShortError: false
  }
}

const useProductSearch = defineStore('productSearch', {
  state: () => initialState(),
  actions: {
    async searchProducts(options: ProductSearchOptions) {
      if (!options.keyword || options.keyword.length > 1) {
        this.loading++
        this.keywordTooShortError = false
        this.options = options

        return api
          .searchProducts(options)
          .then(({ data }) => {
            this.items = data.items
            this.hasNext = data.hasNext
            this.lastKey = data.lastKey
            useRealtimePrices().loadRealtimePricesBatch({
              scale: 1,
              productIds: data.items.map((item) => item.productId)
            })
          })
          .catch((error: ApiError) => {
            this.items = []
            this.hasNext = false
            this.lastKey = undefined
            handleApiError(error)
          })
          .finally(() => {
            this.loading--
          })
      } else {
        this.keywordTooShortError = true
        this.items = []
        this.hasNext = false
        this.lastKey = undefined
      }
    },
    async loadNextPage() {
      if (this.options) {
        if (this.hasNext) {
          this.loadingNextPage = true

          return api
            .searchProducts(this.options, this.lastKey)
            .then(({ data }) => {
              this.items = concat(this.items, data.items)
              this.hasNext = data.hasNext
              this.lastKey = data.lastKey
              useRealtimePrices().loadRealtimePricesBatch({
                scale: 1,
                productIds: data.items.map((item) => item.productId)
              })
            })
            .catch((error: ApiError) => {
              this.items = []
              this.hasNext = false
              this.lastKey = undefined
              handleApiError(error)
            })
            .finally(() => {
              this.loadingNextPage = false
            })
        }
      }
    },
    updateProduct(update: ProductPriceUpdate | BatchProductPriceUpdate) {
      const index = this.items.findIndex((item) => item.productId === update.productId)
      if (index >= 0) {
        this.items[index].price = update.price
        this.items[index].stock = update.stock
        if ((update as ProductPriceUpdate).suppliers) {
          this.items[index].suppliers = (update as ProductPriceUpdate).suppliers
        }
      }
    }
  }
})

export default useProductSearch
