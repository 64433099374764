<template>
  <list-content v-if="order" class="mt-2">
    <v-col cols="12">
      <v-row v-if="orderStatus === OrderStatus.SENT" dense>
        <v-col>
          <sf-alert-success closable @click:close="removeQueryParams()">
            {{ $t('order.orderSent') }}
          </sf-alert-success>
        </v-col>
      </v-row>

      <v-row v-if="paymentStatus" dense>
        <v-col>
          <sf-alert-success
            v-if="paymentStatus === 'success'"
            closable
            @click:close="removeQueryParams()"
          >
            {{ $t('order.paymentStatusSuccess') }}
          </sf-alert-success>
          <sf-alert-warning
            v-else-if="paymentStatus === 'timeout'"
            closable
            @click:close="removeQueryParams()"
          >
            {{ $t('order.paymentStatusTimeout') }}
          </sf-alert-warning>
          <sf-alert-error
            v-else-if="paymentStatus === 'failure'"
            closable
            @click:close="removeQueryParams()"
          >
            {{ $t('order.paymentStatusError') }}
          </sf-alert-error>
        </v-col>
      </v-row>

      <v-row v-if="isTopiExpired" dense>
        <v-col>
          <sf-alert-warning>
            {{ $t('topiExpiredHint') }}
          </sf-alert-warning>
        </v-col>
      </v-row>

      <v-row v-if="order.approvalRequest" dense>
        <v-col>
          <approval-request-info
            :initiator="order.initiator"
            :approval-request="order.approvalRequest"
          />
        </v-col>
      </v-row>

      <v-form ref="form" v-model="validForm" lazy-validation>
        <v-row dense>
          <v-col cols="12" sm="">
            <order-address-and-contact-data
              ref="billing-address-and-contact-data"
              :address="order.billingAddress as ShopAddress"
              :address-type="AddressType.billing"
              :address-selection="order.billingAddressSelection"
              :contact-data="order.billingContactData"
              :readonly="!isPreparedOrder"
              @select-address="updateBillingAddress"
              @update-contact-data="updateBillingContactData"
            />
          </v-col>
          <v-col cols="12" sm="">
            <order-address-and-contact-data
              ref="delivery-address-and-contact-data"
              :address="order.deliveryAddress as ShopAddress"
              :address-type="AddressType.delivery"
              :address-selection="order.deliveryAddressSelection"
              :contact-data="order.deliveryContactData"
              :readonly="!isPreparedOrder"
              @select-address="updateDeliveryAddress"
              @update-contact-data="updateDeliveryContactData"
            />
          </v-col>
          <v-col cols="12" md="4">
            <order-comment
              :editable="isPreparedOrder"
              :max-length="orderingConditions.maxCommentLength"
              :order-comment="order.orderComment"
              :partial-shipment-enabled="orderingConditions.isPartialShipmentEnabled"
              :partial-shipment-select="order.hasPartialShipment"
              @order-comment-changed="updateOrderComment"
              @partial-shipment-selection-changed="updatePartialShipment"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <order-numbers
              :customer-number="order.customerNumber"
              :customer-order-id="order.customerOrderId"
              :customer-order-id-required="orderingConditions.customerOrderIdRequired"
              :order-number="order.orderNumber"
              :read-only="!isPreparedOrder"
              :is-custom-order-number-enabled="portalSettings.isCustomOrderNumberEnabled"
              :show-customer-order-id="
                (isPreparedOrder && orderingConditions.showCustomerOrderId) ||
                order.customerOrderId !== undefined
              "
              @customer-order-id-changed="updateCustomerOrderNumber"
              @order-number-changed="updateOrderNumber"
            />
          </v-col>
        </v-row>
      </v-form>

      <v-divider class="my-2" />

      <v-row dense>
        <v-col>
          <order-position
            v-for="item in order.items"
            :key="item.id"
            :editable="isPreparedOrder"
            :order-item="item"
            :last-item="onlyOneItem"
            :order-id="order.id"
            :show-topi-price="isWaitingForTopiCheckout"
          />
        </v-col>
      </v-row>

      <v-divider class="my-2" />

      <v-row v-if="order.isTopiOrder" dense>
        <v-col v-if="order.topiCheckoutUrl">
          <sf-large-button :block="$vuetify.display.xs" :href="order.topiCheckoutUrl">
            {{ $t('openTopiCheckout') }}
          </sf-large-button>
        </v-col>
        <v-col>
          <order-info
            :order-rejection-date="rejectionDate"
            :order-last-updated="order.lastUpdated"
            :order-sent-date="order.sentDate"
            :order-sum-information="order.orderSumInformation"
            :shipping-cost="order.shippingCost"
            :quantity-surcharge="order.quantitySurcharge"
            hide-prices
          />
        </v-col>
      </v-row>

      <v-row
        v-else-if="
          (isPreparedOrder && order.orderLimitExceedance == OrderLimitExceedance.KEPT) ||
          !isPreparedOrder
        "
        dense
        justify="space-between"
      >
        <v-col
          v-if="isPreparedOrder"
          ref="prepared-order-buttons"
          cols="12"
          md="6"
          class="d-flex flex-column"
        >
          <sf-text v-if="checkoutTerms" class="order-first mb-1">
            <div v-html="checkoutTerms" />
          </sf-text>
          <div class="d-flex flex-column flex-sm-row order-last order-md-first flex-wrap">
            <sf-large-button
              id="order-button"
              class="mr-2 mb-1 flex-grow-1 flex-md-grow-0"
              :block="$vuetify.display.xs"
              :disabled="!validForm || !addressValid || !allCommentsValid"
              :loading="sending"
              @click="orderingConditions.isSofortPaymentEnabled ? sendOrderAndPay() : sendOrder()"
              >{{
                orderingConditions.isSofortPaymentEnabled
                  ? $t('order.klarnaCheckoutCaption')
                  : $t('order.sendOrder')
              }}
            </sf-large-button>
            <rejection-dialog
              class="mr-0 mr-md-2 mt-2 mt-md-0 order-last order-md-first"
              :rejecting="rejecting"
              :type="'ORDER'"
              @reject="declineOrder"
            />
          </div>
        </v-col>
        <v-col>
          <order-info
            :order-rejection-date="rejectionDate"
            :order-last-updated="order.lastUpdated"
            :order-sent-date="order.sentDate"
            :order-sum-information="order.orderSumInformation"
            :shipping-cost="order.shippingCost"
            :quantity-surcharge="order.quantitySurcharge"
            class="flex-grow-1 order-first"
          />
        </v-col>
      </v-row>

      <v-row
        v-else-if="isPreparedOrder && order.orderLimitExceedance != OrderLimitExceedance.KEPT"
        no-gutters
      >
        <v-col class="d-flex flex-column">
          <order-limit-warning
            :no-order-right="order.orderLimitExceedance == OrderLimitExceedance.NO_ORDER_RIGHT"
            :approvers="order.orderLimitApprovers || []"
            :costs="order.orderSumInformation.grossSum"
            :order-limit-value="order.orderLimitValue"
            :loading="sending"
            :disabled="!validForm || !addressValid || !allCommentsValid"
            @request-order="requestOrder"
          >
            <order-info
              class="flex-grow-1"
              :order-rejection-date="rejectionDate"
              :order-sent-date="order.sentDate"
              :order-last-updated="order.lastUpdated"
              :shipping-cost="order.shippingCost"
              :quantity-surcharge="order.quantitySurcharge"
              :order-sum-information="order.orderSumInformation"
            />
          </order-limit-warning>
        </v-col>
      </v-row>
    </v-col>
    <sf-refresh-dialog
      v-if="orderOutdated"
      :title="$t('orderOutdated.title')"
      :description="$t('orderOutdated.description')"
      :loading="loading"
      @refresh="refreshOrder"
    />
  </list-content>
</template>

<script setup lang="ts">
import OrderLimitWarning from '@/components/OrderLimitWarning.vue'
import RejectionDialog from '@/components/RejectionDialog.vue'
import SfAlertError from '@/components/alerts/SfAlertError.vue'
import SfAlertSuccess from '@/components/alerts/SfAlertSuccess.vue'
import SfAlertWarning from '@/components/alerts/SfAlertWarning.vue'
import SfLargeButton from '@/components/button/SfLargeButton.vue'
import SfRefreshDialog from '@/components/dialog/SfRefreshDialog.vue'
import ListContent from '@/components/list/ListContent.vue'
import SfText from '@/components/text/SfText.vue'
import {
  ApprovalRequestStatus,
  ContactData,
  OrderLimitExceedance,
  OrderStatus,
  ShopAddress
} from '@/generatedTypes'
import { AddressType } from '@/modules/address/types'
import useAddressValidation from '@/modules/address/useAddressValidation'
import ApprovalRequestInfo from '@/modules/order/components/ApprovalRequestInfo.vue'
import OrderAddressAndContactData from '@/modules/order/components/OrderAddressAndContactData.vue'
import OrderComment from '@/modules/order/components/OrderComment.vue'
import OrderInfo from '@/modules/order/components/OrderInfo.vue'
import OrderNumbers from '@/modules/order/components/OrderNumbers.vue'
import OrderPosition from '@/modules/order/components/OrderPosition.vue'
import useCheckout from '@/modules/order/useCheckout'
import useOrder from '@/modules/order/useOrder'
import useOrderingConditions from '@/modules/portalSettings/useOrderingConditions'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import OrderEvent from '@/modules/tracking/events/order'
import { trackEvent } from '@/modules/tracking/useTracking'
import router from '@/router'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

const form = ref<HTMLFormElement>()
const validForm = ref(false)
const currentRoute = useRoute()

const paymentStatus = currentRoute.query.paymentStatus
const orderStatus = currentRoute.query.orderStatus
useCheckout().loadCheckoutTerms()

const { portalSettings } = storeToRefs(usePortalSettings())
const { orderingConditions } = storeToRefs(useOrderingConditions())
const { order, sending, rejecting, orderOutdated, loading, allCommentsValid } =
  storeToRefs(useOrder())
const { delivery, billing } = storeToRefs(useAddressValidation())
const { checkoutTerms } = storeToRefs(useCheckout())

const addressValid = computed(() => delivery.value.valid && billing.value.valid)
const isPreparedOrder = computed(() => order.value?.orderStatus === OrderStatus.PREPARING)
const isWaitingForTopiCheckout = computed(
  () => order.value?.orderStatus === OrderStatus.IN_TOPI_CHECKOUT
)
const isTopiExpired = computed(() => order.value?.orderStatus === OrderStatus.TOPI_EXPIRED)
const onlyOneItem = computed(() => order.value?.items.length === 1)
const rejectionDate = computed(() =>
  order.value?.approvalRequest?.status === ApprovalRequestStatus.Rejected
    ? order.value?.approvalRequest?.processedDate
    : undefined
)

const removeQueryParams = () => {
  router.replace({ query: {} })
}

const sendOrder = () => {
  if (order.value && form.value && form.value.validate()) {
    if (order.value.approvalRequest) {
      trackEvent(OrderEvent.Approve)
    } else {
      trackEvent(OrderEvent.Send)
    }
    useOrder().sendExistingOrder(order.value.id)
  }
}

const sendOrderAndPay = () => {
  if (order.value && form.value && form.value.validate()) {
    trackEvent(OrderEvent.SendAndPay)
    useOrder().sendExistingOrderAndPay(order.value.id)
  }
}

const requestOrder = (comment: string): void => {
  if (order.value) {
    if (order.value.approvalRequest) {
      trackEvent(OrderEvent.RequestApproval)
    } else {
      trackEvent(OrderEvent.Request)
    }
    useOrder().requestExistingOrder(order.value.id, comment)
  }
}

const declineOrder = (declineReason: string) => {
  if (order.value) {
    if (order.value.approvalRequest) {
      trackEvent(OrderEvent.DeclineApproval)
    } else {
      trackEvent(OrderEvent.Decline)
    }
    useOrder().declineOrder(order.value?.id, declineReason)
  }
}

const updateOrderNumber = (orderNumber: string) => {
  if (order.value) {
    useOrder().updateOrder(order.value.id, { orderNumber, timestamp: order.value.timestamp })
  }
}

const updateCustomerOrderNumber = (customerOrderId: string) => {
  if (order.value) {
    useOrder().updateOrder(order.value.id, {
      customerOrderId,
      timestamp: order.value.timestamp
    })
  }
}

const updateOrderComment = (comment: string) => {
  if (order.value) {
    useOrder().updateOrder(order.value.id, { comment, timestamp: order.value.timestamp })
  }
}

const updatePartialShipment = (hasPartialShipment: boolean) => {
  if (order.value) {
    useOrder().updateOrder(order.value.id, {
      hasPartialShipment,
      timestamp: order.value.timestamp
    })
  }
}

const updateBillingAddress = (billingAddress: ShopAddress) => {
  if (order.value) {
    order.value.billingAddress = billingAddress
    useOrder().updateOrder(order.value.id, { billingAddress, timestamp: order.value.timestamp })
  }
}

const updateDeliveryAddress = (deliveryAddress: ShopAddress) => {
  if (order.value) {
    order.value.deliveryAddress = deliveryAddress
    useOrder().updateOrder(order.value.id, {
      deliveryAddress,
      timestamp: order.value.timestamp
    })
  }
}

const updateBillingContactData = (billingContactData: ContactData) => {
  if (order.value) {
    order.value.billingContactData = billingContactData
    useOrder().updateOrder(order.value?.id, {
      billingContactData,
      timestamp: order.value.timestamp
    })
  }
}

const updateDeliveryContactData = (deliveryContactData: ContactData) => {
  if (order.value) {
    order.value.deliveryContactData = deliveryContactData
    useOrder().updateOrder(order.value?.id, {
      deliveryContactData,
      timestamp: order.value.timestamp
    })
  }
}

const refreshOrder = () => {
  useOrder().getOrder(currentRoute.params.id as string)
}
</script>
