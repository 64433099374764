import { get, patch, Response } from '@/api'
import {
  DefaultOrderLimit,
  DefaultOrderLimitPatch,
  OrderLimitUser,
  OrderLimitUserPatch,
  ShortUser
} from '@/generatedTypes'

export default {
  getDefaultOrderLimit: (): Promise<Response<DefaultOrderLimit>> => {
    return get<DefaultOrderLimit>('/orders/limits/company')
  },
  getOrderLimitUsers: (): Promise<Response<OrderLimitUser[]>> => {
    return get<OrderLimitUser[]>('/orders/limits/users')
  },
  getApprovers: (): Promise<Response<ShortUser[]>> => {
    return get<ShortUser[]>('/orders/limits/approvers')
  },
  updateDefaultOrderLimit: (dto: DefaultOrderLimitPatch): Promise<Response<string>> => {
    return patch<string, DefaultOrderLimitPatch>('/orders/limits/company', dto)
  },
  updateOrderLimitUser: (id: string, dto: OrderLimitUserPatch): Promise<Response<string>> => {
    return patch<string, OrderLimitUserPatch>('/orders/limits/users/' + id, dto)
  }
}
