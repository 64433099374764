<template>
  <span class="text-body-1 font-weight-medium" :class="'text-' + color">
    <slot />
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SfColorText',
  props: {
    color: {
      type: String,
      required: true
    }
  }
})
</script>
