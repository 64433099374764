<template>
  <div class="d-flex justify-center align-center px-1" :class="'bg-' + color" style="height: 30px">
    <span class="text-white">
      {{ text }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InfoBarText',
  props: {
    text: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'primary'
    }
  }
})
</script>
