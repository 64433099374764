<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <v-skeleton-loader type="heading" />
      </v-col>
    </v-row>
    <v-divider class="my-2" />
    <v-row dense>
      <v-col cols="12" md="4">
        <v-skeleton-loader type="article" />
      </v-col>
      <v-col cols="12" md="4">
        <v-skeleton-loader type="article" />
      </v-col>
      <v-col cols="12" md="4">
        <v-skeleton-loader type="article" />
      </v-col>
    </v-row>
    <v-divider class="my-2" />
    <list-loading />
  </div>
</template>

<script lang="ts">
import ListLoading from '@/components/list/ListLoading.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CollectionLoading',
  components: { ListLoading }
})
</script>
