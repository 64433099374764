<template>
  <login-wrapper :branding="branding" :title="$t('portalPaused')">
    <v-img ref="visual" class="mx-auto mb-1" :src="getImageUrl()" max-width="500" />
    <p v-if="!branding.pausedText" class="text-justify">
      {{ $t('portalPausedDescription') }}
    </p>
    <p v-else class="text-justify">{{ branding.pausedText }}</p>
  </login-wrapper>
</template>

<script lang="ts">
import { Branding } from '@/modules/portalSettings/types'
import { defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import LoginWrapper from '@/modules/authentication/components/LoginWrapper.vue'
import { useTitle } from '@/helpers'

export default defineComponent({
  name: 'PortalPaused',
  components: { LoginWrapper },
  props: {
    branding: {
      type: Object as PropType<Branding>,
      required: true
    }
  },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('portalPaused')}`))
    const getImageUrl = () => {
      return new URL('@/assets/images/paused.png', import.meta.url).href
    }
    return { getImageUrl }
  }
})
</script>
