<template>
  <div class="d-flex flex-row flex-wrap-reverse mr-2">
    <div v-if="$slots.button" class="flex-grow-1">
      <slot name="button" />
    </div>
    <div class="flex-column flex-grow-1 align-self-end text-right pl-1 mb-1">
      <div class="d-flex flex-row justify-end">
        <div class="d-flex flex-column mr-4">
          <div v-if="quoteSumInformation.netDiscount" ref="subtotal-label" class="flex-grow-1">
            <sf-text>
              {{ $t('order.subTotal') }}
            </sf-text>
          </div>
          <div v-if="quoteSumInformation.netDiscount" ref="netdiscount-label" class="flex-grow-1">
            <sf-text>
              {{ $t('order.netDiscount') }}
            </sf-text>
          </div>
          <div class="flex-grow-1">
            <sf-text :class="isGrossQuote ? '' : 'font-weight-bold'">
              {{ $t('order.totalCostsNet') }}
            </sf-text>
          </div>
          <template v-for="(totalTax, index) in quoteSumInformation.totalTaxes" :key="index">
            <div v-if="totalTax.vatSum.value > 0" class="flex-grow-1">
              <sf-text>
                {{ $t('additionalTax', [totalTax.vatPercent]) }}
              </sf-text>
            </div>
          </template>
          <div v-if="quoteSumInformation.totalTaxes.length > 0" class="flex-grow-1">
            <sf-text :class="isGrossQuote ? 'font-weight-bold' : ''">{{
              $t('order.totalCostsGross')
            }}</sf-text>
          </div>
        </div>
        <div class="d-flex flex-column align-end">
          <div v-if="quoteSumInformation.netDiscount" ref="subtotal-value" class="flex-grow-1">
            <sf-text>
              {{ formatMoney(quoteSumInformation.netSumWithoutDiscount, false, true) }}
            </sf-text>
          </div>
          <div v-if="quoteSumInformation.netDiscount" ref="netdiscount-value" class="flex-grow-1">
            <sf-text>
              {{ formatMoney(quoteSumInformation.netDiscount, false, true) }}
            </sf-text>
          </div>
          <div ref="net-sum" class="flex-grow-1">
            <sf-text :class="isGrossQuote ? '' : 'font-weight-bold'">
              {{ formatMoney(quoteSumInformation.netSum, false, true) }}
            </sf-text>
          </div>
          <template v-for="(totalTax, index) in quoteSumInformation.totalTaxes" :key="index">
            <div v-if="totalTax.vatSum.value > 0" ref="tax-total" class="flex-grow-1">
              <sf-text>
                {{ formatMoney(totalTax.vatSum, false, true) }}
              </sf-text>
            </div>
          </template>
          <div v-if="quoteSumInformation.totalTaxes.length > 0" ref="gross-sum" class="flex-grow-1">
            <sf-text :class="isGrossQuote ? 'font-weight-bold' : ''">
              {{ formatMoney(quoteSumInformation.grossSum, false, true) }}
            </sf-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import SfText from '@/components/text/SfText.vue'
import { OrderSumInformation } from '@/generatedTypes'
import { formatMoney } from '@/modules/product/helpers'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'QuoteSumInfo',
  components: { SfText },
  props: {
    quoteSumInformation: {
      type: Object as PropType<OrderSumInformation>,
      required: true
    },
    isGrossQuote: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    return {
      formatMoney
    }
  }
})
</script>
