<template>
  <v-row no-gutters>
    <v-col cols="" style="min-width: 0">
      <v-slide-group show-arrows class="ml-1 my-1">
        <v-slide-group-item
          v-for="menuEntry in filterMenuItems(menu, portalSettings)"
          :key="menuEntry.key"
        >
          <navigation-menu-item :menu-item="menuEntry" class="mr-1" />
        </v-slide-group-item>
      </v-slide-group>
    </v-col>
    <v-col cols="auto" class="d-flex justify-end align-center">
      <toolbar-product-comparison class="my-1 ml-1" />
      <toolbar-shopping-cart class="ma-1" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { MenuItem } from '@/components/navigation/menu'
import NavigationMenuItem from '@/components/navigation/NavigationMenuItem.vue'
import ToolbarProductComparison from '@/components/toolbar/ToolbarProductComparison.vue'
import ToolbarShoppingCart from '@/components/toolbar/ToolbarShoppingCart.vue'
import { filterMenuItems } from '@/helpers'
import { PortalSettings } from '@/modules/portalSettings/types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'NavigationMenu',
  components: {
    NavigationMenuItem,
    ToolbarShoppingCart,
    ToolbarProductComparison
  },
  props: {
    menu: {
      type: Array as PropType<MenuItem[]>,
      default: () => []
    },
    portalSettings: {
      type: Object as PropType<PortalSettings>,
      required: true
    }
  },
  setup() {
    return {
      filterMenuItems
    }
  }
})
</script>
