<template>
  <v-menu
    ref="menu"
    v-model:model-value="isActive"
    location="bottom"
    :persistent="false"
    close-on-content-click
  >
    <template #activator="{ props }">
      <sf-icon-button :icon="mdiDotsVertical" v-bind="props" />
    </template>
    <v-list :density="dense ? 'compact' : 'default'">
      <slot @save="close" />
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { VMenu } from '@/types'
import { mdiDotsVertical } from '@mdi/js'
import { defineComponent, ref } from 'vue'
import SfIconButton from '@/components/button/SfIconButton.vue'

export default defineComponent({
  name: 'ThreePointMenu',
  components: { SfIconButton },
  props: {
    dense: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const menu = ref<VMenu>()
    const isActive = ref(false)

    const close = () => {
      isActive.value = false
    }

    return {
      close,
      menu,
      isActive,
      mdiDotsVertical
    }
  }
})
</script>
