<template>
  <div class="d-flex flex-row">
    <avatar-image :user="entry.user" :size="40" />
    <div class="d-flex flex-column ml-2">
      <sf-text>{{ entry.user.fullName }}:</sf-text>
      <sf-sub-heading v-html="entry.message" />
      <sf-secondary-text>{{ formatRelativeDate(entry.timestamp) }}</sf-secondary-text>
    </div>
  </div>
</template>

<script lang="ts">
import AvatarImage from '@/components/AvatarImage.vue'
import SfSecondaryText from '@/components/text/SfSecondaryText.vue'
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { QuoteActivity } from '@/generatedTypes'
import { formatRelativeDate } from '@/utils/timeFormatHelpers'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'QuoteActivityEntry',
  components: { AvatarImage, SfSubHeading, SfText, SfSecondaryText },
  props: {
    entry: {
      type: Object as PropType<QuoteActivity>,
      required: true
    }
  },
  setup() {
    return {
      formatRelativeDate
    }
  }
})
</script>
