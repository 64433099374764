<template>
  <iframe ref="iframe" height="500" :srcdoc="html" sandbox="" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'SfHtmlPreview',
  props: {
    previewText: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const html = computed(() => props.previewText.replace(/href=".*?"/g, ''))

    return {
      html
    }
  }
})
</script>

<style scoped>
iframe {
  border-width: 0;
}
</style>
