<template>
  <v-navigation-drawer
    :model-value="show"
    temporary
    location="right"
    :width="$vuetify.display.xs ? 300 : 400"
    class="pa-2"
    @update:model-value="inputChanged"
  >
    <slot />
  </v-navigation-drawer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MobileSideMenu',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close'],
  setup(_, { emit }) {
    const inputChanged = (value: boolean) => {
      if (!value) {
        emit('close')
      }
    }

    return {
      inputChanged
    }
  }
})
</script>
