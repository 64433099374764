import {
  getDefaultFilters,
  getSelectionForFilter,
  removeItemFromFilterSelection,
  updateFilterSelection
} from '@/components/filter/helpers'
import { FilterIdentifier, FilterValue } from '@/generatedTypes'
import { FilterSelection } from '@/components/filter/types'
import { ApiError, handleApiError } from '@/handleApiError'
import api from '@/modules/order/api'
import { OrderSortOrder } from '@/modules/order/types'
import { AxiosResponse } from 'axios'
import { defineStore } from 'pinia'
import i18n from '@/plugins/i18n'

interface OrderFilterState {
  filters: FilterIdentifier[]
  selection: FilterSelection<OrderSortOrder>
  loading: boolean
  initialized: boolean
}

const initSelection = (): FilterSelection<OrderSortOrder> => {
  return {
    sort: OrderSortOrder.SENT_DESC,
    filters: []
  }
}

const initialState = (): OrderFilterState => {
  return {
    filters: [],
    selection: initSelection(),
    loading: false,
    initialized: false
  }
}

const useOrderFilter = defineStore('orderFilter', {
  state: () => initialState(),
  actions: {
    async resetFilter(approvables: boolean) {
      await this.applyDefaultFilters(approvables)
    },
    getActiveFilters() {
      return this.selection.filters.filter((filter) => filter.values.length > 0).length
    },
    async getOrderFilters(filter: FilterIdentifier[], approvables: boolean, keyword?: string) {
      this.loading = true

      let getFilterPromise: (
        filter?: FilterIdentifier[],
        keyword?: string | undefined
      ) => Promise<AxiosResponse<FilterIdentifier[]>>

      if (approvables) {
        getFilterPromise = api.getApprovableOrderFilters
      } else {
        getFilterPromise = api.getOrderFilters
      }

      return getFilterPromise(filter, keyword)
        .then(({ data }) => {
          this.filters = data
          for (const filter of this.filters) {
            for (const value of filter.values) {
              if (value.term === 'DSTARRIVED') {
                value.name = i18n.global.t('orderStatus.ARRIVED')
              }
            }
          }
        })
        .catch((error: ApiError) => {
          handleApiError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async applyDefaultFilters(approvables: boolean) {
      await this.getOrderFilters([], approvables)
      this.selection.filters = getDefaultFilters(this.filters)
      this.initialized = true
    },
    getSelectionForFilter(name: string) {
      return getSelectionForFilter(this.selection.filters, name)
    },
    updateFilterSelection(name: string, items: FilterValue[] | undefined) {
      updateFilterSelection(this.selection.filters, name, items)
    },
    removeItemFromFilterSelection(name: string, item: FilterValue) {
      removeItemFromFilterSelection(this.selection.filters, name, item)
    }
  }
})

export default useOrderFilter
