<template>
  <sf-info-dialog :title="$t('deliveryTracking.deliveryTracking')">
    <template #activator="{ props }">
      <sf-text-button v-bind="props" small @click="trackClick">{{
        $t('deliveryTracking.deliveryTracking')
      }}</sf-text-button>
    </template>
    <template #content>
      <sf-sub-heading>{{ deliveryInfo.carrier }}</sf-sub-heading>
      <div
        v-for="(link, index) in deliveryInfo.trackingLinks"
        :key="index"
        class="d-flex flex-row align-center justify-space-between mt-1 ga-1"
      >
        <sf-text-field readonly hide-details :model-value="link.packageId" />
        <sf-text v-if="link.url">
          <sf-link :href="link.url" @click="trackLink">{{
            $t('deliveryTracking.trackPackage')
          }}</sf-link>
        </sf-text>
      </div>
    </template>
  </sf-info-dialog>
</template>

<script lang="ts">
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfTextField from '@/components/input/SfTextField.vue'
import SfInfoDialog from '@/components/dialog/SfInfoDialog.vue'
import SfLink from '@/components/text/SfLink.vue'
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { DeliveryInfo } from '@/generatedTypes'
import OrderEvent from '@/modules/tracking/events/order'
import { trackEvent } from '@/modules/tracking/useTracking'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'OrderItemDeliveryInfoDialog',
  components: {
    SfTextButton,
    SfText,
    SfTextField,
    SfLink,
    SfSubHeading,
    SfInfoDialog
  },
  props: {
    deliveryInfo: {
      type: Object as PropType<DeliveryInfo>,
      required: true
    }
  },
  setup() {
    const trackClick = () => {
      trackEvent(OrderEvent.OpenParcelTracking)
    }

    const trackLink = () => {
      trackEvent(OrderEvent.OpenParcelTrackingLink)
    }

    return {
      trackClick,
      trackLink
    }
  }
})
</script>
