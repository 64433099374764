<template>
  <div class="d-flex flex-row flex-wrap ga-1">
    <v-tooltip v-if="articleNumber && articleNumber.additionalId" location="bottom">
      <template #activator="{ props }">
        <v-chip
          v-if="articleNumber && articleNumber.additionalId"
          :size="large ? 'small' : 'x-small'"
          v-bind="props"
        >
          {{ articleNumber.additionalId }}
        </v-chip>
      </template>
      <span>{{ $t('additionalId') }}</span>
    </v-tooltip>
    <template v-if="portalSettings.showProductIds">
      <v-tooltip v-if="articleNumber && articleNumber.manufacturerProductId" location="bottom">
        <template #activator="{ props }">
          <v-chip
            v-if="articleNumber && articleNumber.manufacturerProductId"
            :size="large ? 'small' : 'x-small'"
            v-bind="props"
          >
            {{ articleNumber.manufacturerProductId }}
          </v-chip>
        </template>
        <span>{{ $t('manufacturerId') }}</span>
      </v-tooltip>
      <v-tooltip v-if="articleNumber && articleNumber.ean" location="bottom">
        <template #activator="{ props }">
          <v-chip
            v-if="articleNumber && articleNumber.ean"
            :size="large ? 'small' : 'x-small'"
            v-bind="props"
          >
            {{ articleNumber.ean }}
          </v-chip>
        </template>
        <span>{{ $t('ean') }}</span>
      </v-tooltip>
      <v-tooltip v-if="productId" location="bottom">
        <template #activator="{ props }">
          <v-chip :size="large ? 'small' : 'x-small'" v-bind="props">
            {{ productId }}
          </v-chip>
        </template>
        <span>{{ $t('productId') }}</span>
      </v-tooltip>
    </template>
  </div>
</template>

<script lang="ts">
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import { defineComponent, PropType } from 'vue'
import { storeToRefs } from 'pinia'
import { ArticleNumber } from '@/generatedTypes'

export default defineComponent({
  name: 'ProductIdentifiers',
  props: {
    articleNumber: {
      type: Object as PropType<ArticleNumber>,
      default: undefined
    },
    productId: {
      type: Number,
      default: undefined
    },
    large: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { portalSettings } = storeToRefs(usePortalSettings())

    return {
      portalSettings
    }
  }
})
</script>
