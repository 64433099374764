<template>
  <v-card style="min-height: 92px">
    <v-card-title ref="category-name" class="justify-center text-center pb-0">
      <sf-title>
        <template v-if="!category.isSelectable">{{ category.name }}</template>
        <router-link
          v-else
          class="text-decoration-none cursor-pointer text-high-emphasis"
          :to="{ name: 'Category', params: { id: category.searchTerm } }"
        >
          {{ category.name }}
        </router-link>
      </sf-title>
    </v-card-title>
    <template v-if="category.children && category.children.length > 0">
      <v-expand-transition>
        <v-card-text v-if="open" class="mt-2">
          <v-list
            density="compact"
            hoverable
            active-class="text-high-emphasis"
            item-title="name"
            item-value="searchTerm"
            :items="category.children"
            class="cursor-pointer"
            selectable
            select-strategy="single-leaf"
            item-props
            return-object
            @update:selected="openCategory(($event as string[]).pop())"
          >
            <template #header="{ props }">
              <category-group
                :name="props.name"
                :search-term="props.searchTerm"
                :is-selectable="props.isSelectable"
                :click-callback="props.onClick"
                :color-selected="false"
                :has-children="props.value.children.length > 0"
                @update="openCategory"
              />
            </template>
            <template #item="{ props }">
              <category-item :name="props.name" :search-term="props.searchTerm" />
            </template>
          </v-list>
        </v-card-text>
      </v-expand-transition>
      <v-card-actions>
        <v-btn block size="small" variant="text" @click="collapseCategory">
          <v-icon size="large" :icon="open ? mdiMenuUp : mdiMenuDown" />
        </v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script lang="ts">
import SfTitle from '@/components/text/SfTitle.vue'
import { Category } from '@/generatedTypes'
import router from '@/router'
import { mdiMenuDown, mdiMenuUp } from '@mdi/js'
import { defineComponent, PropType, ref } from 'vue'
import { findCategoryBySearchTerm } from '@/modules/categories/helpers'
import CategoryGroup from '@/modules/categories/components/CategoryGroup.vue'
import CategoryItem from '@/modules/categories/components/CategoryItem.vue'

export default defineComponent({
  name: 'StartPageCategory',
  components: { CategoryItem, CategoryGroup, SfTitle },
  props: {
    category: {
      type: Object as PropType<Category>,
      required: true
    }
  },
  setup(props) {
    const open = ref(false)

    const openCategory = (searchTerm: string | undefined) => {
      if (!searchTerm) {
        return
      }
      const category = props.category.children
        ? findCategoryBySearchTerm(props.category.children, searchTerm)
        : undefined
      if (!category) {
        return
      }
      if (category.isSelectable) {
        router.push({
          name: 'Category',
          params: { id: searchTerm }
        })
      }
    }

    const collapseCategory = () => {
      open.value = !open.value
    }

    return {
      open,
      openCategory,
      collapseCategory,
      mdiMenuDown,
      mdiMenuUp
    }
  }
})
</script>
