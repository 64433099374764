<template>
  <three-point-menu dense>
    <v-list-item v-if="quotesEnabled" ref="request-cart" :disabled="isEmpty" @click="requestCart">
      <v-list-item-title>
        {{ $t('quotes.createRequest') }}
      </v-list-item-title>
    </v-list-item>

    <v-list-item ref="empty-cart" :disabled="isEmpty" @click="emptyCart">
      <v-list-item-title class="text-error">
        {{ $t('cart.removeAll') }}
      </v-list-item-title>
    </v-list-item>
  </three-point-menu>
</template>

<script lang="ts">
import ThreePointMenu from '@/components/three-point-menu/ThreePointMenu.vue'
import useCart from '@/modules/cart/useCart'
import CartEvent from '@/modules/tracking/events/cart'
import { trackEvent } from '@/modules/tracking/useTracking'
import { storeToRefs } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CartMenu',
  components: { ThreePointMenu },
  props: {
    quotesEnabled: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const { isEmpty } = storeToRefs(useCart())

    const emptyCart = () => {
      useCart().emptyCart()
      trackEvent(CartEvent.emptyCart)
    }

    const requestCart = () => {
      useCart().requestCart()
      trackEvent(CartEvent.createQuoteRequest)
    }

    return { isEmpty, emptyCart, requestCart }
  }
})
</script>
