import { LoginCredentials } from '@/modules/authentication/types'

const getBasicAuthString = (credentials: LoginCredentials): string => {
  const auth = `${credentials.mail}:${credentials.password}`

  // Fixed because of UTF-8 encoding issues. Copied this implementation from https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
  const encoded = btoa(
    encodeURIComponent(auth).replace(/%([0-9A-F]{2})/g, function (_, p1) {
      return String.fromCharCode(parseInt(p1, 16))
    })
  )
  return `Basic ${encoded}`
}

export { getBasicAuthString }
