<template>
  <template v-if="column.loading">
    <v-skeleton-loader v-for="index in 10" :key="index" type="card" />
  </template>
  <orders-grid-card
    v-for="order in column.orderPreviews"
    v-else
    :key="order.id"
    :order-preview="order"
    :show-status="isErrorStatus(order.orderStatus)"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { isErrorStatus } from '@/modules/order/helpers'
import { OrderPreviewColumn } from '@/modules/order/types'
import OrdersGridCard from '@/modules/order/components/OrdersGridCard.vue'

export default defineComponent({
  name: 'OrdersGridColumn',
  components: {
    OrdersGridCard
  },
  props: {
    column: {
      type: Object as PropType<OrderPreviewColumn>,
      required: true
    }
  },
  setup() {
    return {
      isErrorStatus
    }
  }
})
</script>
