const UPDATE_TIMESTAMP = (state: Timestamped | undefined, newTimestamp: string): void => {
  if (!state) return
  if (state.timestamp === newTimestamp) return
  state.timestamp = newTimestamp
}

interface Timestamped {
  timestamp: string
}

export { UPDATE_TIMESTAMP, Timestamped }
