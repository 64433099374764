import { ApiError, handleApiError } from '@/handleApiError'
import api from '@/modules/cart/api'
import useCart from '@/modules/cart/useCart'
import useCartOptimizations from '@/modules/cart/useCartOptimizations'
import CartEvent from '@/modules/tracking/events/cart'
import { trackEvent } from '@/modules/tracking/useTracking'
import { defineStore } from 'pinia'

interface CartItemMoveState {
  availableSupplierCarts: Record<string, string[]>
  draggingItem: string | undefined
  draggingFrom: string | undefined
  loading: boolean
}

const initialState = (): CartItemMoveState => {
  return {
    availableSupplierCarts: {},
    draggingItem: undefined,
    draggingFrom: undefined,
    loading: false
  }
}

const useCartCount = defineStore('cartItemMove', {
  state: () => initialState(),
  actions: {
    async loadCartItemMoveInfo() {
      this.loading = true

      return api
        .loadCartItemMoveInfo()
        .then(({ data }) => {
          this.availableSupplierCarts = data.availableSupplierCarts
        })
        .catch(handleApiError)
        .finally(() => {
          this.loading = false
        })
    },
    async moveCartItem(cartItemId: string, toSupplierCartId: string) {
      return api
        .moveCartItem(cartItemId, {
          toSupplierCartId
        })
        .then(() => {
          trackEvent(CartEvent.PositionMoved)
        })
        .catch((error: ApiError) => {
          handleApiError(error, undefined, {
            errorCode: 409,
            logError: false,
            customMessageKey: 'error.productNotAvailableBySupplier',
            showRefreshButton: false
          })
        })
        .finally(() => {
          useCart().loadCart(true)
          useCartOptimizations().calculateOptimizations()
        })
    }
  }
})

export default useCartCount
