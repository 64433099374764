import { Ref, ref } from 'vue'

interface RequestState {
  serverOffline: boolean
  abortControllers: AbortController[]
}

const initialState = (): RequestState => {
  return {
    serverOffline: false,
    abortControllers: []
  }
}

type ReturnType = {
  state: Ref<RequestState>
  addAbortController: (abortController: AbortController) => void
  cancelPendingRequests: () => void
  setServerOffline: (offline: boolean) => void
}

const state = ref(initialState())

export default function useRequestManager(): ReturnType {
  const setServerOffline = (offline: boolean) => {
    state.value.serverOffline = offline
  }

  const addAbortController = (abortController: AbortController) => {
    state.value.abortControllers.push(abortController)
  }

  const cancelPendingRequests = () => {
    state.value.abortControllers.forEach((abortController) => {
      abortController.abort()
    })

    state.value.abortControllers = []
  }

  return {
    state,
    addAbortController,
    cancelPendingRequests,
    setServerOffline
  }
}
