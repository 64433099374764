import {
  CartItem,
  CollectionItem,
  FullProduct,
  MediumProduct,
  OrderItem,
  ShortProduct
} from '@/generatedTypes'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import { canBeRented } from '@/modules/product/helpers'
import { getTopiPdpItem } from '@/modules/topi/helpers'
import { TopiPdpItem } from '@/modules/topi/types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let TopiElements: any

interface TopiState {
  topi: typeof TopiElements
}

const state: TopiState = {
  topi: undefined
}

type ReturnType = {
  isInitialized: () => boolean
  initTopi: (locale: string) => void
  setPdpItem: (product: FullProduct | MediumProduct) => void
  setCartItems: (products: ShortProduct[] | MediumProduct[] | CollectionItem[] | CartItem[]) => void
  setCartItemsFromOrderItems: (products: OrderItem[]) => void
  setCartItemsWithQuantity: (products: CartItem[]) => void
}

export default function useTopi(): ReturnType {
  const initTopi = (locale: string) => {
    state.topi = new TopiElements({
      locale,
      widgetId: import.meta.env.VITE_STOREFRONT_TOPI_ELEMENTS_WIDGET_ID
    })
  }

  const setPdpItem = (product: FullProduct | MediumProduct) => {
    if (state.topi && product.price && canBeRented(product.price)) {
      const portalSettings = usePortalSettings().portalSettings
      state.topi.pdpItem = getTopiPdpItem(
        product.productId,
        product.price.resellPrice,
        portalSettings.grossPortal,
        portalSettings.vatRatePercent
      )
    }
  }

  const setCartItemsFromOrderItems = (products: OrderItem[]) => {
    if (state.topi && products.length > 0) {
      const portalSettings = usePortalSettings().portalSettings
      const items: TopiPdpItem[] = []
      products.forEach((product) => {
        if (product.productId && product.price) {
          items.push(
            getTopiPdpItem(
              product.productId,
              product.price,
              portalSettings.grossPortal,
              portalSettings.vatRatePercent
            )
          )
        }
      })
      if (items.length > 0) {
        state.topi.cartItems = items
      }
    }
  }

  const setCartItems = (
    products: ShortProduct[] | MediumProduct[] | CollectionItem[] | CartItem[]
  ) => {
    if (state.topi && products.length > 0) {
      const portalSettings = usePortalSettings().portalSettings
      const items: TopiPdpItem[] = []
      products.forEach((product) => {
        if (product.price && canBeRented(product.price)) {
          items.push(
            getTopiPdpItem(
              product.productId,
              product.price.resellPrice,
              portalSettings.grossPortal,
              portalSettings.vatRatePercent
            )
          )
        }
      })
      if (items.length > 0) {
        state.topi.cartItems = items
      }
    }
  }

  const setCartItemsWithQuantity = (products: CartItem[]) => {
    if (state.topi && products.length > 0) {
      const portalSettings = usePortalSettings().portalSettings
      const items: TopiPdpItem[] = []
      products.forEach((product) => {
        if (product.price && canBeRented(product.price)) {
          for (let index = 0; index < product.quantity; index++) {
            items.push(
              getTopiPdpItem(
                product.productId,
                product.price.resellPrice,
                portalSettings.grossPortal,
                portalSettings.vatRatePercent
              )
            )
          }
        }
      })
      if (items.length > 0) {
        state.topi.cartItems = items
      }
    }
  }

  return {
    isInitialized: () => state.topi !== undefined,
    initTopi,
    setPdpItem,
    setCartItems,
    setCartItemsFromOrderItems,
    setCartItemsWithQuantity
  }
}
