<template>
  <v-card
    class="d-flex flex-column rounded-lg feature--box bg-white pa-3"
    variant="outlined"
    :elevation="24"
    :width="250"
  >
    <slot />
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FeatureAdBox'
})
</script>
