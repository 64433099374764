import { get, Request, Response } from '@/api'
import { StartPage } from '@/generatedTypes'

export default {
  loadStartPage: (
    page: number,
    size: number,
    startPageSerial?: string
  ): Promise<Response<StartPage>> => {
    const config: Request = {
      params: {
        page: page - 1,
        size: size,
        startPageSerial: startPageSerial
      }
    }

    return get<StartPage>('/startpages', config)
  }
}
