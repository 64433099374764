import { isGuest } from '@/helpers'
import useAuthentication from '@/modules/authentication/useAuthentication'
import useError from '@/modules/error/useError'
import useBranding from '@/modules/portalSettings/useBranding'
import { trackWebVitals } from '@/modules/tracking/useTracking'
import useVersion from '@/modules/version/useVersion'
import StartPage from '@/pages/StartPage.vue'
import { quotesPortalRoutes } from '@/router/quotesPortalRoutes'
import { getRoutes } from '@/router/routes'
import useRequestManager from '@/store/useRequestManager'
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [{ path: '/:pathMatch(.*)*', component: StartPage, name: 'Placeholder' }]
})

const addRoutes = (): void => {
  const routes = getRoutes()
  routes.forEach((route) => router.addRoute(route))
}

const addQuotesPortalRoutes = (): void => {
  quotesPortalRoutes.forEach((route) => router.addRoute(route))
}

router.beforeEach(async (to, _from, next) => {
  if (useBranding().branding.value == undefined) {
    const portalType = await useBranding().getBranding()
    router.removeRoute('Placeholder')
    if (portalType === 'quotesportal') {
      addQuotesPortalRoutes()
    } else if (portalType === 'defaultportal') {
      addRoutes()
    } else if (portalType === 'portalunavailable') {
      return next()
    }
    return next({ path: to.fullPath, query: { ...to.query }, replace: true })
  }

  if (to.query['redslave']) {
    useAuthentication().setRedslave(true)
  }

  // remove red/cust/# from old links
  if (to.fullPath.includes('/red/cust#')) {
    return next(to.fullPath.replace('/red/cust#', ''))
  }

  // cancel pending requests
  useRequestManager().cancelPendingRequests()

  const redirectPath = to.query.redirect ? to.query.redirect.toString() : ''

  if (to.path === '/authToken' || to.path === '/subsidiarylogin') {
    if (to.query?.token) {
      useAuthentication().loginWithAccessToken({
        value: to.query.token.toString()
      })
      if (to.query.startPageSerial) {
        next(redirectPath + `?startPageSerial=${to.query.startPageSerial}`)
      }
      return next(redirectPath || '/')
    }
  } else if (to.path === '/punchoutlogin') {
    if (to.query?.token) {
      await useAuthentication().loginViaPunchout({
        value: to.query.token.toString()
      })
      return next(redirectPath || '/')
    }
  } else if (to.path === '/oauthlogin') {
    if (to.query?.token) {
      await useAuthentication().loginViaOauth({
        value: to.query.token.toString()
      })
      return next(redirectPath || '/')
    }
  } else if (to.path === '/autologin') {
    if (to.query?.token) {
      await useAuthentication().autoLogin({
        value: to.query.token.toString()
      })
      return next('/')
    }
  } else if (to.path.startsWith('/guest')) {
    if (to.query?.jwt) {
      useAuthentication().guestLogin({
        value: to.query.jwt.toString()
      })
    }
  }

  // check authentication
  const authentication = useAuthentication()
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (authentication.hasValidAccessToken()) {
      return next()
    } else if (to.path != '/') {
      return next({ name: 'Login', query: { redirect: to.fullPath } })
    } else {
      return next({ name: 'Login' })
    }
  } else if (to.path === '/login' && authentication.hasValidAccessToken()) {
    return next({ name: 'StartPage' })
  } else {
    return next()
  }
})

router.afterEach((to, from) => {
  // scroll to top
  // replaces scrollBehavior because of bug when route has //
  // https://github.com/vuejs/vue-router/issues/2593
  if (window.scrollTo) {
    window.scrollTo(0, 0)
  }

  // clear errors
  useError().reset()

  if (from.name !== to.name && !isGuest.value) {
    trackWebVitals()
  }
})

router.beforeResolve((to, from, next) => {
  // check app version & hash
  if (import.meta.env.PROD) {
    useVersion().checkVersion()
    if (useVersion().state.value.hashChanged) {
      window.location.reload()
    }
  }

  next()
})

export default router
