<template>
  <v-card class="py-1 px-2 mb-1 pw-quote-position">
    <v-row no-gutters>
      <v-col
        v-if="showOptionalCheckbox"
        cols="auto"
        class="d-flex align-center justify-center mr-1"
      >
        <v-row no-gutters>
          <sf-checkbox
            v-if="quoteItem.isOptional"
            v-model="isPickedOptional"
            class="my-1"
            :disabled="!editable || isReadonly"
          />
          <sf-checkbox v-else v-model="checked" class="my-1" disabled />
        </v-row>
      </v-col>
      <v-col class="mr-1 mr-md-2">
        <v-row no-gutters>
          <v-col cols="auto" class="d-flex align-center mr-2">
            <product-image-gallery
              :images="quoteItem.productImageUrls"
              :size="100"
              hide-arrows
              hover-effect
            />
          </v-col>
          <v-col class="d-flex flex-column justify-center">
            <span
              class="text-body-1"
              :class="{ 'text-disabled': !isQuoteItemSelected }"
              v-html="quoteItem.productTitle"
            />
            <div
              v-if="showSubscriptionText || showDatasheetButton"
              class="d-flex flex-row align-center"
            >
              <subscription-period
                v-if="showSubscriptionText && quoteItem.price"
                class="text-body-1 text-medium-emphasis"
                :class="{ 'text-disabled': !isQuoteItemSelected }"
                :subscription-model="quoteItem.price.subscriptionModel"
              />
              <sf-text-button
                v-if="showDatasheetButton"
                :class="showSubscriptionText ? 'ml-2' : 'ml-n2'"
                :loading="loading"
                :color="isQuoteItemSelected ? 'primary' : 'grey'"
                @click="loadDatasheet"
              >
                {{ $t('datasheet') }}
              </sf-text-button>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="hidden-md-and-up" cols="12">
        <v-divider class="my-1" />
      </v-col>
      <v-col cols="12" md="auto" align-self="center">
        <v-row no-gutters justify="end">
          <v-col
            cols="auto"
            class="d-flex justify-center align-center"
            :class="[{ 'mr-md-2': quoteItem.totalPrice }]"
          >
            <quantity-stepper
              v-if="editable && quoteItem.isOptional && quoteItem.isQuantityEditCustomer"
              class="mr-1 quantity-stepper"
              :quantity="quantity"
              :disabled="isReadonly"
              :class="{ 'text-disabled': !isQuoteItemSelected }"
              @change-quantity="onChangeQuantity"
            />
            <sf-heading v-else ref="quantity" :class="{ 'text-disabled': !isQuoteItemSelected }">{{
              quoteItem.quantity
            }}</sf-heading>
            <sf-heading :class="{ 'text-disabled': !isQuoteItemSelected }">x</sf-heading>
            <div v-if="quoteItem.price" class="d-flex flex-column align-end ml-2">
              <sf-heading ref="price" :class="{ 'text-disabled': !isQuoteItemSelected }">
                {{ formatMoney(quoteItem.price.resellPrice, false, true) }}
              </sf-heading>
              <template v-if="quoteItem.discount">
                <span
                  ref="discount"
                  class="text-medium-emphasis"
                  :class="{ 'text-disabled': !isQuoteItemSelected }"
                >
                  {{ formatDiscount(quoteItem.discount) }}
                </span>
              </template>
            </div>
          </v-col>
          <v-col
            v-if="quoteItem.totalPrice"
            cols="auto"
            style="width: 120px"
            class="d-flex flex-column justify-center align-end ml-3 ml-md-0 mr-2"
          >
            <sf-heading ref="total-price" :class="{ 'text-disabled': !isQuoteItemSelected }">
              {{ formatMoney(quoteItem.totalPrice, false, true) }}
            </sf-heading>
            <billing-period
              v-if="quoteItem.price && quoteItem.price.subscriptionModel"
              class="text-body-1 text-medium-emphasis"
              :class="{ 'text-disabled': !isQuoteItemSelected }"
              :subscription-model="quoteItem.price.subscriptionModel"
            />
            <span
              v-if="quoteItem.vatPercent != undefined && quoteItem.totalPrice.value > 0"
              class="text-body-1 text-medium-emphasis"
              :class="{ 'text-disabled': !isQuoteItemSelected }"
            >
              {{
                isGrossQuote
                  ? $t('includingVatShort', [quoteItem.vatPercent])
                  : $t('excludingVatShort', [quoteItem.vatPercent])
              }}
            </span>
          </v-col>
          <v-col class="d-flex justify-end">
            <sf-icon-button
              :icon="mdiOpenInNew"
              target="_blank"
              :color="showLinkToProductPage ? 'primary' : 'grey'"
              :disabled="!showLinkToProductPage"
              :to="
                showLinkToProductPage
                  ? {
                      name: 'Product',
                      params: { id: quoteItem.productId }
                    }
                  : undefined
              "
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import BillingPeriod from '@/components/BillingPeriod.vue'
import SfIconButton from '@/components/button/SfIconButton.vue'
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfCheckbox from '@/components/input/SfCheckbox.vue'
import QuantityStepper from '@/components/QuantityStepper.vue'
import SubscriptionPeriod from '@/components/SubscriptionPeriod.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import { QuoteItem } from '@/generatedTypes'
import { isQuotesPortal } from '@/helpers'
import useAuthentication from '@/modules/authentication/useAuthentication'
import ProductImageGallery from '@/modules/product/components/ProductImageGallery.vue'
import { formatMoney } from '@/modules/product/helpers'
import { formatDiscount } from '@/modules/quote/helpers'
import useQuote from '@/modules/quote/useQuote'
import useQuoteItemDatasheet from '@/modules/quote/useQuoteItemDatasheet'
import { mdiOpenInNew } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'QuotePosition',
  components: {
    BillingPeriod,
    ProductImageGallery,
    QuantityStepper,
    SfCheckbox,
    SfHeading,
    SfIconButton,
    SfTextButton,
    SubscriptionPeriod
  },
  props: {
    quoteItem: {
      type: Object as PropType<QuoteItem>,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    isGrossQuote: {
      type: Boolean,
      required: true
    },
    showOptionalCheckbox: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const currentRoute = useRoute()
    const { loading } = storeToRefs(useQuoteItemDatasheet())
    const isQuoteItemSelected = computed(
      () => !props.quoteItem.isOptional || (props.quoteItem.isOptional && isPickedOptional.value)
    )
    const isPickedOptional = ref(props.quoteItem.isPickedOptional)
    const showLinkToProductPage = computed(
      () => !isQuotesPortal.value && props.quoteItem.productId && props.quoteItem.isInShopAssortment
    )
    const showSubscriptionText = computed(
      () => props.quoteItem.price && props.quoteItem.price.subscriptionModel
    )
    const showDatasheetButton = computed(() => isQuotesPortal.value && props.quoteItem.hasDatasheet)
    const isReadonly = computed(() => useAuthentication().state.value.isReadonly)
    const checked = ref(true)

    watch(isPickedOptional, (newValue: boolean) => {
      useQuote().updateQuoteItemIsPickedOptional(props.quoteItem.id, newValue)
    })

    const quantity = ref(props.quoteItem.quantity)

    watch(
      () => props.quoteItem,
      (newValue) => {
        quantity.value = newValue.quantity
      }
    )

    const onChangeQuantity = (newQuantity: number) => {
      quantity.value = newQuantity
      useQuote().updateQuoteItemQuantity(props.quoteItem.id, newQuantity)
    }

    const loadDatasheet = () => {
      useQuoteItemDatasheet().loadDatasheet(currentRoute.params.id as string, props.quoteItem.id)
    }

    return {
      loading,
      isPickedOptional,
      isReadonly,
      showLinkToProductPage,
      showSubscriptionText,
      showDatasheetButton,
      quantity,
      isQuoteItemSelected,
      onChangeQuantity,
      loadDatasheet,
      formatMoney,
      formatDiscount,
      mdiOpenInNew,
      checked
    }
  }
})
</script>
