import { Event } from '@/modules/tracking/types'

export default class CartEvent extends Event {
  constructor(action: string, label?: string) {
    super('Warenkorb', action, label ?? '')
  }

  static readonly PositionMoved = new CartEvent('Position anderem Lieferanten zugeordnet')
  static readonly RemovePosition = new CartEvent('Position entfernt')

  static readonly emptyCart = new CartEvent('Warenkorb entleert')

  static readonly createQuoteRequest = new CartEvent('Anfrage aus Warenkorb erstellt')
  static readonly Comment = class {
    static readonly ToDistributor = new CartEvent(
      'Positionsbasierter Kommentar hinzugefügt',
      'Kommentar an Lieferanten/Distributoren'
    )
    static readonly ToPortalOwner = new CartEvent(
      'Positionsbasierter Kommentar hinzugefügt',
      'Kommentar an den Portalbetreiber/Verkäufer'
    )
    static readonly OnOrder = new CartEvent('Bestellkommentar hinzugefügt')
  }

  static readonly Optimization = class {
    static readonly MinPrice = new CartEvent('Optimierung nach Bester Preis')
    static readonly MinimalSuppliers = new CartEvent('Optimierung nach minimalen Quellen')
    static readonly Manual = new CartEvent('Manuelle Optimierung')
    static readonly Additional = new CartEvent('zusätzliche Optimierung')
  }
}
