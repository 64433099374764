<template>
  <v-btn :color="$attrs.disabled ? '' : 'primary'" variant="text" v-bind="$attrs">
    <slot />
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SfTextButton',
  inheritAttrs: false
})
</script>
