import api from '@/modules/eventStream/api'
import { LiveUpdateState } from '@/modules/eventStream/types'
import { SseMessage } from 'event-source-plus'

const parseData = <T>(message: SseMessage): T => {
  return JSON.parse(message.data) as T
}

export default {
  openStream: <T>(state: LiveUpdateState, path: string, updateFn: (update: T) => void): void => {
    if (state.updateStream) {
      state.updateStream.abort()
    }

    state.updateStream = api.getStream(path).listen({
      onMessage: (message) => {
        updateFn(parseData<T>(message))
      }
    })
  },

  closeStream: (state: LiveUpdateState) => {
    if (state.updateStream) {
      state.updateStream.abort()
    }
  }
}
