import i18n from '@/plugins/i18n'
import useError from '@/modules/error/useError'
import { Ref, computed, getCurrentInstance } from 'vue'
import { StorefrontError } from '@/modules/error/types'

function customErrorHandling(): {
  error: Ref<StorefrontError>
  mustHandleError: Ref<boolean>
} {
  const componentInstance = getCurrentInstance()
  const componentName = componentInstance?.type?.name as string
  const error = computed(() => useError().getError())
  const mustHandleError = computed(() =>
    Boolean(error.value.customHandler && error.value.customHandler === componentName)
  )
  return { error, mustHandleError }
}

const getErrorImageSource = (isQuotesPortal: boolean, code: number): string => {
  return isQuotesPortal && (code === 404 || code === 403)
    ? new URL('@/assets/images/quote_not_found.png', import.meta.url).href
    : new URL('@/assets/images/error.png', import.meta.url).href
}

const getErrorHeadline = (code: number): string => {
  return code === 404 || code === 403
    ? i18n.global.t('errorPageOops')
    : i18n.global.t('errorPageHeadline')
}

const getErrorMessage = (code: number, message: string): string => {
  return `${code && code !== 403 ? code + ' - ' : ''}${message}`
}

export { customErrorHandling, getErrorMessage, getErrorHeadline, getErrorImageSource }
