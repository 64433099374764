<template>
  <div>
    <v-row dense>
      <v-col v-if="!showTabs" cols="12">
        <v-skeleton-loader type="heading" />
      </v-col>
      <v-col v-else cols="12">
        <v-skeleton-loader type="heading@2" />
      </v-col>
    </v-row>
    <v-divider class="my-2" />
    <v-row dense>
      <v-col cols="12" md="6">
        <v-skeleton-loader type="article" />
      </v-col>
      <v-col cols="12" md="6">
        <v-skeleton-loader type="article" />
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader type="article" />
      </v-col>
    </v-row>
    <v-divider class="my-2" />
    <list-loading />
    <v-divider class="my-2" />
    <v-row>
      <v-col cols="3">
        <v-skeleton-loader type="sentences" />
      </v-col>
      <v-spacer />
      <v-col cols="3">
        <v-skeleton-loader type="paragraph" />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import ListLoading from '@/components/list/ListLoading.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OrderLoading',
  components: { ListLoading },
  props: {
    showTabs: {
      type: Boolean,
      default: true
    }
  }
})
</script>
