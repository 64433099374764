import { get, Response } from '@/api'
import { OrderingConditions } from '@/generatedTypes'
import { Branding, PortalSettings } from '@/modules/portalSettings/types'

export const BRANDING_ENDPOINT = '/branding'

export default {
  getBranding: (): Promise<Response<Branding>> => {
    return get<Branding>(BRANDING_ENDPOINT)
  },
  getPortalSettings: (): Promise<Response<PortalSettings>> => {
    return get<PortalSettings>('/portalsettings')
  },
  getOrderingConditions: (): Promise<Response<OrderingConditions>> => {
    return get<OrderingConditions>('/orders/conditions')
  }
}
