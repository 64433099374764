import { Event } from '@/modules/tracking/types'
import AddToCartButtonEvents from '@/modules/tracking/events/helper/addToCartButton'

export default class CollectionEvent extends Event {
  static readonly Sort = class {
    static readonly Title = class {
      static readonly Ascending = new CollectionEvent('Sortiert nach Titel', 'Aufsteigend')
      static readonly Descending = new CollectionEvent('Sortiert nach Titel', 'Absteigend')
    }
    static readonly Date = class {
      static readonly Ascending = new CollectionEvent('Sortiert nach Datum', 'Aufsteigend')
      static readonly Descending = new CollectionEvent('Sortiert nach Datum', 'Absteigend')
    }
    static readonly LastModified = new CollectionEvent('Sortiert nach letzte Änderung')
  }
  static readonly AddProductToCart: AddToCartButtonEvents = {
    addToCart: new CollectionEvent('Produkt zu Warenkorb hinzugefügt'),
    request: new CollectionEvent('Produkt angefragt')
  }
  static readonly AddProduct = new CollectionEvent('Position hinzugefügt')
  static readonly RemoveProduct = new CollectionEvent('Position entfernt')

  static readonly Create = new CollectionEvent('Erstellt')
  static readonly DuplicateCollection = new CollectionEvent(
    'Dupliziert',
    'Aus dem "Optionen"-Menu dupliziert'
  )
  static readonly BuyCollection = new CollectionEvent('Zu Warenkorb hinzugefügt')
  static readonly DeleteCollection = new CollectionEvent('Gelöscht')
  static readonly Archive = new CollectionEvent('Archivieren')
  static readonly Dearchive = new CollectionEvent('Dearchivieren')
  static readonly ShareCollection = new CollectionEvent('Per E-Mail teilen')

  constructor(action: string, label?: string) {
    super('Sammlung', action, label ?? '')
  }
}
