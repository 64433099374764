import { Metric } from 'web-vitals'

type EventContext = {
  event: Event
  path: string
  userAgent: string
  screenWidth: number
  screenHeight: number
  iframeUrl?: string
}

type WebVitalsContext = {
  webVitalsMetric: Metric
  path: string
  userAgent: string
  screenWidth: number
  screenHeight: number
}

enum PropertiesDomain {
  Product = 'Product'
}

type TrackingDomain = {
  domain: PropertiesDomain
  references: Record<string, string>
}

abstract class Event {
  category: string
  action: string
  label: string
  trackingDomains: TrackingDomain[]

  protected constructor(category: string, action: string, label: string) {
    this.category = category
    this.action = action
    this.label = label
    this.trackingDomains = []
  }

  withProduct(productId: number, sourceId?: number): this {
    let references: Record<string, string> = {
      productId: productId.toString()
    }
    if (typeof sourceId !== 'undefined') {
      references = {
        productId: productId.toString(),
        sourceId: sourceId.toString()
      }
    }
    this.trackingDomains = [
      {
        domain: PropertiesDomain.Product,
        references: references
      }
    ]
    return this
  }
}

export { EventContext, Event, WebVitalsContext }
