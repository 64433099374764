<template>
  <v-card height="100%">
    <v-card-item>
      <v-card-title class="d-flex justify-space-between align-center">
        <sf-heading>{{ $t('collection.assignee') }}</sf-heading>
        <collection-assignee-dialog
          v-if="
            !portalSettings.hideProfile &&
            ((!portalSettings.hideColleaguesPermissions &&
              !portalSettings.isUserSpecificCollectionsEnabled) ||
              ownUser.permission.shopAdmin)
          "
          :users="getRegisteredUsers()"
          :assignee="collection.assignee"
        />
      </v-card-title>
    </v-card-item>
    <v-card-text v-if="collection.assignee">
      <avatar-image ref="avatar" :size="40" :user="collection.assignee" class="mr-1" />
      <sf-text ref="name">
        {{ getFullName(collection.assignee) }}
      </sf-text>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { DetailedShopCollection } from '@/generatedTypes'
import { getFullName } from '@/helpers'
import AvatarImage from '@/components/AvatarImage.vue'
import CollectionAssigneeDialog from '@/modules/collection/components/CollectionAssigneeDialog.vue'
import { storeToRefs } from 'pinia'
import useUsers from '@/modules/user/useUsers'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import useOwnUser from '@/modules/user/useOwnUser'

export default defineComponent({
  name: 'CollectionAssigneeCard',
  components: { CollectionAssigneeDialog, AvatarImage, SfHeading, SfText },
  props: {
    collection: {
      type: Object as PropType<DetailedShopCollection>,
      required: true
    }
  },
  setup() {
    const { portalSettings } = storeToRefs(usePortalSettings())

    if (!portalSettings.value.hideColleaguesPermissions && !portalSettings.value.hideProfile) {
      useUsers().getUsers()
    }
    const { ownUser } = storeToRefs(useOwnUser())
    const { getRegisteredUsers } = storeToRefs(useUsers())

    return { getRegisteredUsers, portalSettings, ownUser, getFullName }
  }
})
</script>
