<template>
  <div class="d-flex flex-row align-center flex-nowrap justify-end">
    <sf-text class="mr-1">{{ $t('realtimePrices') }}</sf-text>
    <v-progress-circular v-if="props.loading" indeterminate size="24" color="primary" width="2" />
    <v-icon v-else color="primary" :icon="mdiCheck" />
  </div>
</template>

<script setup lang="ts">
import SfText from '@/components/text/SfText.vue'
import { mdiCheck } from '@mdi/js'

const props = defineProps({
  loading: {
    type: Boolean,
    required: true
  }
})
</script>
