import { handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import api from '@/modules/cart/api'

interface CartCountState {
  cartCount: number
  loading: boolean
}

const initialState = (): CartCountState => {
  return {
    cartCount: 0,
    loading: false
  }
}

const useCartCount = defineStore('cartCount', {
  state: () => initialState(),
  actions: {
    async loadCartCount() {
      this.loading = true

      return api
        .loadCartCount()
        .then(({ data }) => {
          this.cartCount = data.count
        })
        .catch(handleApiError)
        .finally(() => {
          this.loading = false
        })
    }
  }
})

export default useCartCount
