<template>
  <v-container fluid>
    <show-categories-button style="position: absolute; z-index: 999" />
    <v-row>
      <v-col cols="12" sm="12" md="5" lg="4" xl="3" class="d-flex flex-column justify-start">
        <v-row v-if="$vuetify.display.smAndDown" dense>
          <v-col>
            <quote-button
              v-if="showQuoteButton"
              :product-id="product.productId"
              :add-to-quote-event="ProductPageEvent.AddProductToExistingQuote(product.productId)"
              :create-quote-event="ProductPageEvent.AddProductToNewQuote(product.productId)"
            />
            <comparison-button
              v-if="$vuetify.display.sm"
              :product="product"
              :add-to-comparison-event="
                ProductPageEvent.Comparison.AddToComparison(product.productId)
              "
              :remove-from-comparison-event="
                ProductPageEvent.Comparison.RemoveFromComparison(product.productId)
              "
            />
            <collection-button
              v-if="showCollectionButton"
              :product-id="product.productId"
              :add-to-collection-event="ProductPageEvent.AddProductToCollection(product.productId)"
              :create-collection-event="ProductPageEvent.CreateCollection(product.productId)"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align-self="start">
            <product-image-gallery :images="product.imageUrls" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="7" lg="8" xl="9">
        <v-row dense>
          <v-col cols="">
            <sf-title
              id="pw-product-title"
              class="text-justify"
              :class="[{ 'two-lines': $vuetify.display.mdAndUp }]"
            >
              {{ product.manufacturer.name }} {{ product.title }}
            </sf-title>
          </v-col>

          <v-col v-if="$vuetify.display.mdAndUp" cols="auto" class="d-flex flex-row">
            <quote-button
              v-if="showQuoteButton"
              :product-id="product.productId"
              :add-to-quote-event="ProductPageEvent.AddProductToExistingQuote(product.productId)"
              :create-quote-event="ProductPageEvent.AddProductToNewQuote(product.productId)"
            />
            <comparison-button
              :product="product"
              :add-to-comparison-event="
                ProductPageEvent.Comparison.AddToComparison(product.productId)
              "
              :remove-from-comparison-event="
                ProductPageEvent.Comparison.RemoveFromComparison(product.productId)
              "
            />
            <collection-button
              v-if="showCollectionButton"
              class="ml-2"
              :product-id="product.productId"
              :add-to-collection-event="ProductPageEvent.AddProductToCollection(product.productId)"
              :create-collection-event="ProductPageEvent.CreateCollection(product.productId)"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <product-short-description :description="product.description" />
          </v-col>
        </v-row>
        <product-datasheet-links
          :product-type="product.productType"
          :datasheet="product.datasheet"
          :product-id="product.productId"
        />
        <product-header-price
          v-if="showAddToCartWidget && isProduct(product)"
          :product="product"
          :loading-realtime-prices="loadingRealtimePrices"
          :add-to-cart-event="ProductPageEvent.AddToCart.Header"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import ShowCategoriesButton from '@/components/ShowCategoriesButton.vue'
import SfTitle from '@/components/text/SfTitle.vue'
import { BundleItemProduct, FullProduct } from '@/generatedTypes'
import CollectionButton from '@/modules/collection/components/CollectionButton.vue'
import ComparisonButton from '@/modules/product/components/ComparisonButton.vue'
import ProductDatasheetLinks from '@/modules/product/components/ProductDatasheetLinks.vue'
import ProductHeaderPrice from '@/modules/product/components/ProductHeaderPrice.vue'
import ProductImageGallery from '@/modules/product/components/ProductImageGallery.vue'
import ProductShortDescription from '@/modules/product/components/ProductShortDescription.vue'
import QuoteButton from '@/modules/quote/components/QuoteButton.vue'
import ProductPageEvent from '@/modules/tracking/events/productPage'
import { PropType } from 'vue'

defineProps({
  product: {
    type: Object as PropType<FullProduct | BundleItemProduct>,
    required: true
  },
  showAddToCartWidget: {
    type: Boolean,
    default: false
  },
  showQuoteButton: {
    type: Boolean,
    default: false
  },
  showCollectionButton: {
    type: Boolean,
    default: false
  },
  loadingRealtimePrices: {
    type: Boolean,
    required: true
  }
})

const isProduct = (product: FullProduct | BundleItemProduct): product is FullProduct => {
  return 'price' in product
}
</script>
