<template>
  <a class="text-primary text-decoration-none" target="_blank">
    <slot />
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SfLink'
})
</script>
