<template>
  <div>
    <!-- price -->
    <v-row dense>
      <v-col class="d-flex flex-column my-4" cols="auto">
        <div class="d-flex align-center ga-1 text-primary">
          <span
            ref="price"
            class="text-h4 font-weight-bold"
            :class="[{ 'text-grey': loadingRealtimePrices }]"
          >
            {{ formatMoney(product.price ? product.price.resellPrice : undefined, true) }}
          </span>
          <v-progress-circular
            v-if="loadingRealtimePrices"
            indeterminate
            size="24"
            color="primary"
            width="2"
          />
        </div>
        <template v-if="!loadingRealtimePrices && canBeRented(product.price)">
          <x-topi-product-rental-summary-label ref="topi" />
        </template>
        <div v-if="product.price?.subscriptionModel" class="text-body-1 text-primary">
          <billing-period ref="billing" :subscription-model="product.price.subscriptionModel" />
          <template v-if="product.price.subscriptionModel.subscriptionPeriod"> - </template>
          <subscription-period
            ref="subscription"
            :subscription-model="product.price.subscriptionModel"
          />
        </div>
        <packaging-info
          v-if="product.price?.packagingModel"
          class="text-body-1 text-primary"
          :packaging-model="product.price.packagingModel"
        />
      </v-col>
      <v-col
        v-if="product.condition && product.condition !== Condition.NEW"
        class="d-flex align-center"
        cols="auto"
      >
        <sf-condition-chip
          :condition="product.condition"
          :condition-description="product.conditionDescription"
        />
      </v-col>
    </v-row>

    <!-- stock + add to cart -->
    <v-row dense>
      <v-col cols="12" class="d-flex flex-column ga-1">
        <product-source-stock
          v-if="product.stock"
          :stock="product.stock"
          :loading-realtime-prices="loadingRealtimePrices"
          show-stock-count
        />
        <div class="d-flex flex-wrap">
          <quantity-stepper
            v-if="product.stock"
            ref="quantity-stepper"
            class="mr-1 mb-1"
            :quantity="quantity"
            :stock="product.stock.count"
            :packaging="product.price ? product.price.packagingModel : undefined"
            @change-quantity="changeQuantity"
            @focus="setUpdatingQuantity(true)"
            @blur="setUpdatingQuantity(false)"
          />
          <cart-button
            :is-request="isRequest"
            :product-id="product.productId"
            :quantity="quantity"
            :on-click-event="addToCartEvent"
            :supplier-row-id="product.price ? product.price.supplierRowId : undefined"
            :updating-quantity="updatingQuantity"
          />
        </div>
      </v-col>
    </v-row>

    <!-- uvp + hek -->
    <v-row
      v-if="
        (isFullProduct(product) && product.manufacturerSuggestedRetailPrice) ||
        (product.price && product.price.purchasePrice)
      "
      dense
    >
      <v-col cols="12" class="d-flex flex-wrap text-body-1 text-medium-emphasis">
        <div v-if="isFullProduct(product) && product.manufacturerSuggestedRetailPrice" class="mr-3">
          <span class="mr-1">{{ $t('price.manufacturerSuggestedRetailPrice') }}</span>
          <span>{{ formatMoney(product.manufacturerSuggestedRetailPrice) }}</span>
        </div>
        <div v-if="product.price && product.price.purchasePrice">
          <span class="mr-1">{{ $t('price.pricePurchase') }}</span>
          <span>{{ formatMoney(product.price.purchasePrice) }}</span>
        </div>
      </v-col>
    </v-row>

    <v-row
      v-if="product.price?.packagingModel || product.suppliers.some((e) => e.scales.length > 0)"
      dense
    >
      <v-col cols="12" class="d-flex flex-wrap flex-gap">
        <product-packaging
          v-if="product.price?.packagingModel"
          :packaging="product.price.packagingModel"
        />
        <product-graduation
          v-if="product.suppliers.some((e) => e.scales.length > 0)"
          :suppliers="product.suppliers"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import BillingPeriod from '@/components/BillingPeriod.vue'
import PackagingInfo from '@/components/PackagingInfo.vue'
import QuantityStepper from '@/components/QuantityStepper.vue'
import SubscriptionPeriod from '@/components/SubscriptionPeriod.vue'
import CartButton from '@/components/button/CartButton.vue'
import SfConditionChip from '@/components/chips/SfConditionChip.vue'
import { Condition, FullProduct, MediumProduct } from '@/generatedTypes'
import ProductGraduation from '@/modules/product/components/ProductGraduation.vue'
import ProductPackaging from '@/modules/product/components/ProductPackaging.vue'
import ProductSourceStock from '@/modules/product/components/ProductSourceStock.vue'
import { canBeRented, formatMoney, isFullProduct } from '@/modules/product/helpers'
import useProductQuantity from '@/modules/product/useProductQuantity'
import AddToCartButtonEvents from '@/modules/tracking/events/helper/addToCartButton'
import { storeToRefs } from 'pinia'
import { PropType, computed } from 'vue'

const props = defineProps({
  product: {
    type: Object as PropType<FullProduct | MediumProduct>,
    required: true
  },
  loadingRealtimePrices: {
    type: Boolean,
    required: true
  },
  addToCartEvent: {
    type: Object as PropType<AddToCartButtonEvents>,
    default: undefined
  }
})

const { quantity, updatingQuantity } = storeToRefs(useProductQuantity())
const isRequest = computed(() => !props.product.price || props.product.price.resellPrice.value <= 0)

const changeQuantity = (quantity: number) => {
  useProductQuantity().updateQuantity(quantity, props.product.productId)
}

const setUpdatingQuantity = (value: boolean) => {
  useProductQuantity().setUpdatingQuantity(value)
}
</script>
