<template>
  <div
    id="ordersummary"
    class="d-flex flex-column flex-sm-row align-start ga-0 ga-sm-2"
    :class="{ 'justify-end': hidePrices, 'justify-space-between': !hidePrices }"
  >
    <div class="d-flex order-last order-sm-first align-self-end align-self-sm-start ga-2">
      <div class="d-flex flex-column">
        <sf-text v-if="!orderRejectionDate && orderSentDate">
          {{ $t('ordered') }}
        </sf-text>
        <sf-text v-if="!orderRejectionDate">{{ $t('updated') }}</sf-text>
        <sf-text v-if="orderRejectionDate">
          {{ $t('orderStatus.REJECTED') }}
        </sf-text>
      </div>
      <div class="d-flex flex-column">
        <sf-text v-if="!orderRejectionDate && orderSentDate" ref="sent-date" class="text-right">
          {{ formatDate(orderSentDate) }}
        </sf-text>
        <sf-text v-if="!orderRejectionDate" ref="last-update" class="text-right">
          {{ formatDate(orderLastUpdated) }}
        </sf-text>
        <sf-text v-if="orderRejectionDate" ref="rejected-update" class="text-right">
          {{ formatDate(orderRejectionDate) }}
        </sf-text>
      </div>
    </div>
    <template v-if="!hidePrices">
      <v-spacer />
      <order-info-gross-table
        v-if="isGrossPortal"
        :shipping-cost="shippingCost"
        :quantity-surcharge="quantitySurcharge"
        :order-sum-information="orderSumInformation"
      />
      <order-info-net-table
        v-else
        :shipping-cost="shippingCost"
        :quantity-surcharge="quantitySurcharge"
        :order-sum-information="orderSumInformation"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import SfText from '@/components/text/SfText.vue'
import { Money, OrderSumInformation } from '@/generatedTypes'
import OrderInfoGrossTable from '@/modules/order/components/OrderInfoGrossTable.vue'
import OrderInfoNetTable from '@/modules/order/components/OrderInfoNetTable.vue'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import { formatDate } from '@/utils/timeFormatHelpers'
import { storeToRefs } from 'pinia'
import { PropType, ref } from 'vue'

defineProps({
  orderRejectionDate: {
    type: String,
    default: undefined
  },
  orderSentDate: {
    type: String,
    default: undefined
  },
  orderLastUpdated: {
    type: String,
    required: true
  },
  shippingCost: {
    type: Object as PropType<Money>,
    required: true
  },
  quantitySurcharge: {
    type: Object as PropType<Money>,
    default: undefined
  },
  orderSumInformation: {
    type: Object as PropType<OrderSumInformation>,
    required: true
  },
  hidePrices: {
    type: Boolean,
    default: false
  }
})

const { portalSettings } = storeToRefs(usePortalSettings())
const isGrossPortal = ref<boolean>(portalSettings.value.grossPortal)
</script>
