<template>
  <div class="d-flex">
    <sf-icon-button :size="32" :icon="mdiMagnify" @click="showInput" />
    <v-expand-x-transition>
      <v-text-field
        v-show="inputShown"
        ref="input"
        :model-value="value"
        :style="small ? 'width: 200px' : 'width: 400px'"
        :append-inner-icon="value ? mdiClose : ''"
        hide-details
        density="compact"
        variant="underlined"
        color="primary"
        :placeholder="$t('search')"
        autocomplete="off"
        @click:append-inner="reset"
        @blur="hideInput"
        @keydown.enter="$emit('search', $event.target.value)"
      />
    </v-expand-x-transition>
  </div>
</template>

<script lang="ts">
import SfIconButton from '@/components/button/SfIconButton.vue'
import { defineComponent, nextTick, ref, watch } from 'vue'
import { mdiClose, mdiMagnify } from '@mdi/js'

export default defineComponent({
  name: 'SearchBar',
  components: { SfIconButton },
  props: {
    value: {
      type: String,
      default: undefined
    },
    small: {
      type: Boolean,
      default: false
    },
    alwaysExpanded: {
      type: Boolean,
      default: false
    }
  },
  emits: ['search', 'clear'],
  setup(props, { emit }) {
    const input = ref<HTMLInputElement>()
    const inputShown = ref<boolean>(props.alwaysExpanded || props.value != undefined)

    watch(
      () => props.value,
      () => {
        if (props.value === undefined) {
          inputShown.value = false
        }
      }
    )

    const showInput = () => {
      inputShown.value = true
      nextTick(() => {
        setTimeout(() => {
          input.value?.focus()
        })
      })
    }

    const hideInput = () => {
      if (!props.value) {
        inputShown.value = props.alwaysExpanded
      }
    }

    const reset = () => {
      inputShown.value = props.alwaysExpanded
      emit('clear')
    }

    return {
      input,
      inputShown,
      showInput,
      hideInput,
      reset,
      mdiClose,
      mdiMagnify
    }
  }
})
</script>
