<template>
  <v-row>
    <v-col cols="12">
      <order-loading v-if="loading" :show-tabs="false" />

      <template v-if="quote && quote.quote">
        <list-header :title="$t('order.toOrder')" hide-show-categories-button />
        <list-empty
          v-if="quote.quote.items.length === 0"
          :message="$t('emptyCart')"
          :icon="mdiCart"
        />
        <template v-if="checkout && preparedOrder">
          <v-form ref="form" v-model="validForm">
            <checkout-header
              :billing-events="billingEvents"
              :comment-events="commentEvents"
              :order-number-events="orderNumberEvents"
              :shipping-events="shippingEvents"
              is-quote
            />
          </v-form>
          <v-divider class="my-2" />
          <list-empty v-if="noLineItems" :message="$t('noItemsInQuote')" :icon="mdiCart" />
          <v-row>
            <v-col>
              <template v-for="item in quote.quote.items" :key="item.id">
                <quote-position
                  v-if="!item.isOptional || item.isPickedOptional"
                  :quote-item="item"
                  :editable="false"
                  :is-gross-quote="quote.quote.isGrossQuote"
                />
              </template>
              <v-divider class="my-2" />
              <order-limit-warning
                v-if="checkout.orderLimitExceedance != OrderLimitExceedance.KEPT"
                :approvers="checkout.orderLimitApprovers"
                :no-order-right="
                  checkout.orderLimitExceedance == OrderLimitExceedance.NO_ORDER_RIGHT
                "
                :costs="quote.quote.sumInformation.netSum"
                :order-limit-value="checkout.orderLimitValue"
                :loading="sending"
                :disabled="!validForm || !addressValid || noLineItems"
                @request-order="requestOrder"
              >
                <quote-sum-info
                  :quote-sum-information="quote.quote.sumInformation"
                  :is-gross-quote="quote.quote.isGrossQuote"
                  :quote-status="quote.quoteStatus"
                />
              </order-limit-warning>
              <quote-sum-info
                v-else
                :quote-sum-information="quote.quote.sumInformation"
                :is-gross-quote="quote.quote.isGrossQuote"
                :quote-status="quote.quoteStatus"
              >
                <template #button>
                  <sf-large-button
                    id="order-button"
                    :loading="sending"
                    :block="$vuetify.display.xs"
                    :disabled="!validForm || !addressValid || noLineItems"
                    @click="sendOrder()"
                    >{{ $t('order.sendOrder') }}</sf-large-button
                  >
                </template>
              </quote-sum-info>
            </v-col>
          </v-row>
        </template>
      </template>
    </v-col>
    <sf-refresh-dialog
      v-if="cartOutdated"
      :title="$t('quoteOutdated.title')"
      :description="$t('quoteOutdated.description')"
      :loading="quoteLoading"
      @refresh="refreshQuote"
    />
  </v-row>
</template>

<script lang="ts">
import OrderLimitWarning from '@/components/OrderLimitWarning.vue'
import SfLargeButton from '@/components/button/SfLargeButton.vue'
import SfRefreshDialog from '@/components/dialog/SfRefreshDialog.vue'
import ListEmpty from '@/components/list/ListEmpty.vue'
import ListHeader from '@/components/list/ListHeader.vue'
import { OrderLimitExceedance } from '@/generatedTypes'
import { useTitle } from '@/helpers'
import useAddress from '@/modules/address/useAddress'
import useAddressValidation from '@/modules/address/useAddressValidation'
import CheckoutHeader from '@/modules/order/components/CheckoutHeader.vue'
import OrderLoading from '@/modules/order/components/OrderLoading.vue'
import useCheckout from '@/modules/order/useCheckout'
import QuotePosition from '@/modules/quote/components/QuotePosition.vue'
import QuoteSumInfo from '@/modules/quote/components/QuoteSumInfo.vue'
import useQuote from '@/modules/quote/useQuote'
import { QuoteCheckoutEvent } from '@/modules/tracking/events/quote'
import { trackEvent } from '@/modules/tracking/useTracking'
import { VForm } from '@/types'
import { mdiCart } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'CheckoutQuotePage',
  components: {
    CheckoutHeader,
    ListEmpty,
    ListHeader,
    SfLargeButton,
    OrderLimitWarning,
    OrderLoading,
    QuotePosition,
    QuoteSumInfo,
    SfRefreshDialog
  },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('order.toOrder')}`))
    const currentRoute = useRoute()

    useAddress().getAddresses()
    useCheckout().loadCheckoutForQuote(currentRoute.params.id as string)

    const form = ref<VForm>()
    const validForm = ref(false)

    watch(form, () => {
      form.value?.validate()
    })

    const { quote, loading: quoteLoading } = storeToRefs(useQuote())
    const { loading, sending, preparedOrder, checkout, cartOutdated } = storeToRefs(useCheckout())

    const { delivery, billing } = storeToRefs(useAddressValidation())

    const addressValid = computed(() => {
      return delivery.value.valid && billing.value.valid
    })

    const sendOrder = () => {
      if (form.value && form.value.validate() && quote.value) {
        useCheckout().sendQuoteOrder(currentRoute.params.id as string, quote.value.timestamp)
        trackEvent(QuoteCheckoutEvent.SendOrder)
      }
    }

    const requestOrder = (commentToApprovers: string) => {
      if (form.value && form.value.validate() && quote.value) {
        useCheckout().requestQuoteOrder(
          commentToApprovers,
          currentRoute.params.id as string,
          quote.value.timestamp
        )
      }
    }

    const refreshQuote = (): void => {
      useQuote().getQuote(currentRoute.params.id as string)
    }

    const noLineItems = computed(
      () =>
        !quote.value?.quote?.items ||
        quote.value.quote.items.length == 0 ||
        quote.value.quote.items.every((item) => item.isOptional && !item.isPickedOptional)
    )

    return {
      quote,
      preparedOrder,
      checkout,
      loading,
      sending,
      cartOutdated,
      quoteLoading,
      form,
      validForm,
      addressValid,
      shippingEvents: QuoteCheckoutEvent.Address.Shipping,
      billingEvents: QuoteCheckoutEvent.Address.Billing,
      commentEvents: QuoteCheckoutEvent.Comment.Checkout,
      orderNumberEvents: QuoteCheckoutEvent.OrderNumber,
      noLineItems,
      sendOrder,
      requestOrder,
      refreshQuote,
      mdiCart,
      OrderLimitExceedance
    }
  }
})
</script>
