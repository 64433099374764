import { Cart, SupplierCart, SimpleStock, SimpleStockStatus } from '@/generatedTypes'

const isProductUnavailable = (stock: SimpleStock): boolean => {
  return (
    stock.status === SimpleStockStatus.NotAvailable || stock.status === SimpleStockStatus.Unknown
  )
}

const isSupplierCart = (cart: Cart | SupplierCart): cart is SupplierCart => {
  return !('supplierCarts' in (cart as SupplierCart))
}

export { isProductUnavailable, isSupplierCart }
