<template>
  <v-dialog v-model="dialog" scrollable :width="800">
    <template #activator="{ props }">
      <v-list-item v-bind="props" :disabled="emptyCollection">
        <v-list-item-title>
          {{ $t('collection.sendEmail') }}
        </v-list-item-title>
      </v-list-item>
    </template>
    <v-card>
      <loading-spinner v-if="loading" height="10vh" flat />
      <template v-if="!loading && item">
        <v-card-item>
          <v-card-title>
            <sf-heading>
              {{ $t('collection.shareCollection', [collection.name]) }}
            </sf-heading>
          </v-card-title>
        </v-card-item>
        <v-card-text class="d-flex flex-column pt-2">
          <email-input v-model="emails" />
          <sf-text-field
            v-model="subject"
            class="pt-1"
            :label="$t('collection.subject')"
            hide-details
            autocomplete="off"
          />
          <div class="d-flex align-center justify-start my-1">
            <sf-checkbox
              v-if="item.attachments.length > 0"
              v-model="withAttachment"
              class="mt-0 pt-0 mr-1 flex-grow-0"
              :label="$t('collection.addAttachment') + ': '"
            />
            <span
              class="text-primary text-decoration-none cursor-pointer"
              @click="downloadAttachment"
            >
              {{ item.attachments[0].name }}
            </span>
          </div>
          <sf-html-preview v-if="item.body" :preview-text="item.body" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <sf-text-button color="grey" class="text-medium-emphasis" @click="dialog = false">
            {{ $t('cancel') }}
          </sf-text-button>
          <sf-text-button
            :disabled="emails.length === 0 || subject.length === 0"
            :loading="sending"
            @click="sendEmail"
          >
            {{ $t('send') }}
          </sf-text-button>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfCheckbox from '@/components/input/SfCheckbox.vue'
import SfTextField from '@/components/input/SfTextField.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import SfHtmlPreview from '@/components/preview/SfHtmlPreview.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import { DetailedShopCollection } from '@/generatedTypes'
import { saveAttachment } from '@/helpers'
import useCollectionEmail from '@/modules/collection/useCollectionEmail'
import EmailInput from '@/modules/email/components/EmailInput.vue'
import CollectionEvent from '@/modules/tracking/events/collection'
import { trackEvent } from '@/modules/tracking/useTracking'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, PropType, ref, watch } from 'vue'

export default defineComponent({
  name: 'CollectionShareButton',
  components: {
    EmailInput,
    LoadingSpinner,
    SfCheckbox,
    SfHeading,
    SfHtmlPreview,
    SfTextButton,
    SfTextField
  },
  props: {
    collection: {
      type: Object as PropType<DetailedShopCollection>,
      required: true
    }
  },
  setup(props) {
    const dialog = ref(false)
    const emails = ref<string[]>([])
    const subject = ref<string>('')
    const withAttachment = ref(false)
    const { item, loading, sending } = storeToRefs(useCollectionEmail())

    const emptyCollection = computed(() => {
      return props.collection.items.length == 0
    })

    const downloadAttachment = () => {
      if (item.value) {
        saveAttachment(item.value.attachments[0])
      }
    }

    // set correct data when dialog is opened/reopened
    watch(dialog, (newValue) => {
      if (newValue) {
        emails.value = []
        withAttachment.value = false
        useCollectionEmail()
          .getEmailPreview(props.collection.id)
          .then(() => (subject.value = item.value?.subject ?? ''))
      }
    })

    const sendEmail = () => {
      useCollectionEmail().sendEmail(props.collection.id, {
        emails: emails.value,
        subject: subject.value,
        withAttachment: withAttachment.value
      })
      trackEvent(CollectionEvent.ShareCollection)
      dialog.value = false
    }

    return {
      dialog,
      emails,
      subject,
      withAttachment,
      item,
      loading,
      sending,
      emptyCollection,
      sendEmail,
      downloadAttachment
    }
  }
})
</script>
