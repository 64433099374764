<template>
  <v-row>
    <v-col cols>
      <list-header :title="$t('collection.collections')">
        <template #filter>
          <filter-button :active-filters="getActiveFilters" @toggle="showFilter = !showFilter" />
        </template>
        <template #action>
          <sf-create-dialog
            :title="$t('collection.addCollection')"
            :loading="creating"
            @create="createCollection"
          />
        </template>
        <template #cols>
          <v-col cols="">
            <sort-button
              :sort="sortDirectionTitle.title"
              :active="activeSortButton === 'title'"
              @click="toggleSortByTitle"
            >
              {{ $t('title') }}
            </sort-button>
          </v-col>
          <v-col cols="3" xl="2" class="d-flex justify-end">
            <sort-button
              :sort="SortDirection.DESC"
              :active="activeSortButton === 'lastModified'"
              @click="toggleSortByLastModified"
            >
              {{ $t('collection.lastModified') }}
            </sort-button>
          </v-col>
          <v-col cols="3" xl="2" class="d-flex justify-end">
            <sort-button
              :sort="sortDirectionDate.date"
              :active="activeSortButton === 'date'"
              @click="toggleSortByDate"
            >
              {{ $t('created') }}
            </sort-button>
          </v-col>
        </template>
      </list-header>

      <error-component
        v-if="mustHandleError"
        :message="error.message"
        :code="error.code"
        transparent-background
      />
      <list-loading v-else-if="loading" />

      <list-empty
        v-else-if="collections.length === 0"
        :message="$t('noCollectionsFound')"
        :icon="mdiStarOutline"
      />

      <list-content v-else ref="listContent">
        <v-col cols="12">
          <collection-row v-for="items in collections" :key="items.id" :collection="items" />
        </v-col>
        <v-col v-if="pages > 1" cols="12">
          <v-pagination
            :length="pages"
            :model-value="page"
            rounded
            total-visible="5"
            variant="flat"
            density="comfortable"
            active-color="primary"
            @update:model-value="goToPage"
          />
        </v-col>
      </list-content>
    </v-col>

    <mobile-side-menu v-if="$vuetify.display.mobile" :show="showFilter" @close="showFilter = false">
      <collections-filter
        ref="collections-filter-mobile"
        @close="showFilter = false"
        @update="searchCollections"
      />
    </mobile-side-menu>

    <v-col v-if="!$vuetify.display.mobile" v-show="showFilter" cols="3">
      <collections-filter
        ref="collections-filter"
        @close="showFilter = false"
        @update="searchCollections"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import MobileSideMenu from '@/components/MobileSideMenu.vue'
import SortButton from '@/components/SortButton.vue'
import SfCreateDialog from '@/components/dialog/SfCreateDialog.vue'
import FilterButton from '@/components/filter/FilterButton.vue'
import ListContent from '@/components/list/ListContent.vue'
import ListEmpty from '@/components/list/ListEmpty.vue'
import ListHeader from '@/components/list/ListHeader.vue'
import ListLoading from '@/components/list/ListLoading.vue'
import { useTitle } from '@/helpers'
import CollectionRow from '@/modules/collection/components/CollectionRow.vue'
import CollectionsFilter from '@/modules/collection/components/CollectionsFilter.vue'
import { CollectionsSortOrder } from '@/modules/collection/types'
import useCollections from '@/modules/collection/useCollections'
import useCollectionsFilter from '@/modules/collection/useCollectionsFilter'
import ErrorComponent from '@/modules/error/components/ErrorComponent.vue'
import { customErrorHandling } from '@/modules/error/helpers'
import CollectionEvent from '@/modules/tracking/events/collection'
import { trackEvent } from '@/modules/tracking/useTracking'
import router from '@/router'
import { SortDirection } from '@/types'
import { mdiStarOutline } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'CollectionsPage',
  components: {
    CollectionRow,
    CollectionsFilter,
    SfCreateDialog,
    FilterButton,
    ListContent,
    ListEmpty,
    ListHeader,
    ListLoading,
    MobileSideMenu,
    SortButton,
    ErrorComponent
  },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('collection.collections')}`))
    const { error, mustHandleError } = customErrorHandling()

    const currentRoute = useRoute()
    const { collections, pages, loading, creating } = storeToRefs(useCollections())
    const { selection, initialized, getActiveFilters } = storeToRefs(useCollectionsFilter())
    const showFilter = ref(false)
    const listContent = ref()
    const page = computed(() => {
      return parseInt(currentRoute.query.page as string) || 1
    })

    if (initialized.value) {
      useCollections().getCollections(page.value)
    } else {
      useCollections().initCollections(page.value)
    }

    const sortDirectionDate = computed(() => {
      return {
        date:
          selection.value.sort === CollectionsSortOrder.DATE_DESC
            ? SortDirection.DESC
            : SortDirection.ASC
      }
    })
    const sortDirectionTitle = computed(() => {
      return {
        title:
          selection.value.sort === CollectionsSortOrder.TITLE_DESC
            ? SortDirection.DESC
            : SortDirection.ASC
      }
    })
    const activeSortButton = computed((): 'title' | 'date' | 'lastModified' | undefined => {
      const sort = useCollectionsFilter().selection.sort
      if (sort === CollectionsSortOrder.LAST_MODIFIED) {
        return 'lastModified'
      } else if (
        sort === CollectionsSortOrder.DATE_ASC ||
        sort === CollectionsSortOrder.DATE_DESC
      ) {
        return 'date'
      } else if (
        sort === CollectionsSortOrder.TITLE_ASC ||
        sort === CollectionsSortOrder.TITLE_DESC
      ) {
        return 'title'
      } else {
        return undefined
      }
    })

    watch(currentRoute, () => {
      useCollections().getCollections(page.value)
    })

    const searchCollections = () => {
      goToPage(1)
      useCollections().getCollections(page.value)
    }

    const createCollection = (name: string) => {
      useCollections().createCollection(name)
      trackEvent(CollectionEvent.Create)
    }

    const goToPage = (newPage: number) => {
      if (page.value !== newPage) {
        router.push({
          name: 'Collections',
          query: { ...currentRoute.query, page: newPage.toString() }
        })
      }
    }

    const toggleSortByDate = () => {
      if (selection.value.sort === CollectionsSortOrder.DATE_DESC) {
        useCollectionsFilter().selection.sort = CollectionsSortOrder.DATE_ASC
        trackEvent(CollectionEvent.Sort.Date.Ascending)
      } else {
        useCollectionsFilter().selection.sort = CollectionsSortOrder.DATE_DESC
        trackEvent(CollectionEvent.Sort.Date.Descending)
      }
      searchCollections()
    }

    const toggleSortByLastModified = () => {
      if (selection.value.sort === CollectionsSortOrder.LAST_MODIFIED) {
        return
      }
      useCollectionsFilter().selection.sort = CollectionsSortOrder.LAST_MODIFIED
      trackEvent(CollectionEvent.Sort.LastModified)
      searchCollections()
    }

    const toggleSortByTitle = () => {
      if (selection.value.sort === CollectionsSortOrder.TITLE_DESC) {
        useCollectionsFilter().selection.sort = CollectionsSortOrder.TITLE_ASC
        trackEvent(CollectionEvent.Sort.Title.Ascending)
      } else {
        useCollectionsFilter().selection.sort = CollectionsSortOrder.TITLE_DESC
        trackEvent(CollectionEvent.Sort.Title.Descending)
      }
      searchCollections()
    }

    return {
      getActiveFilters,
      activeSortButton,
      collections,
      createCollection,
      creating,
      error,
      goToPage,
      listContent,
      loading,
      mustHandleError,
      page,
      pages,
      searchCollections,
      showFilter,
      SortDirection,
      sortDirectionDate,
      sortDirectionTitle,
      toggleSortByDate,
      toggleSortByLastModified,
      toggleSortByTitle,
      mdiStarOutline
    }
  }
})
</script>
