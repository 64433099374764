<template>
  <orders-list v-if="viewType == OrdersView.LIST" :view="OrdersView.LIST" />
  <orders-grid v-else-if="viewType == OrdersView.GRID" />
  <orders-list v-else-if="viewType == OrdersView.APPROVALS" :view="OrdersView.APPROVALS" />
</template>

<script lang="ts">
import OrdersGrid from '@/modules/order/components/OrdersGrid.vue'
import OrdersList from '@/modules/order/components/OrdersList.vue'
import { getOrdersViews } from '@/modules/order/helpers'
import { OrdersView } from '@/modules/order/types'
import vuetify from '@/plugins/vuetify'
import { useTitle } from '@/helpers'
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'OrdersPage',
  components: {
    OrdersList,
    OrdersGrid
  },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('order.orders')}`))
    const currentRoute = useRoute()
    const ordersViews = getOrdersViews()
    const view = computed(() => {
      return (currentRoute.query.view as string) || OrdersView.LIST
    })
    const viewType = computed(() => {
      const foundView = ordersViews.find((orderView) => orderView.name == view.value)

      if (foundView) {
        if (vuetify.display.mdAndUp) {
          return foundView.name
        } else {
          if (foundView.showOnMobile) {
            return foundView.name
          }
        }
      }

      return OrdersView.LIST
    })

    return {
      OrdersView,
      viewType
    }
  }
})
</script>
