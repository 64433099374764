import i18n from '@/plugins/i18n'
import { DateTime, DateTimeFormatOptions, Duration, Settings } from 'luxon'
import { Ref } from 'vue'

const dateTimeFormat: DateTimeFormatOptions = {
  ...DateTime.DATETIME_SHORT,
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit'
}

const dateFormat: DateTimeFormatOptions = {
  ...DateTime.DATE_SHORT,
  month: '2-digit',
  day: '2-digit'
}

const getDefaultLocale = () => (i18n.global.locale as unknown as Ref<string>).value || 'en'

const getPeriodFormat = (period: string): string => {
  const timeUnit = period.toLowerCase().slice(0, -1)
  return i18n.global.t('time.' + timeUnit).toString()
}

const getPeriodFormatShort = (period: string): string => {
  return (
    i18n.global
      .t('time.' + period.toLowerCase().slice(0, -1))
      .toString()
      .charAt(0) + '.'
  )
}

const dateNotOver = (
  date: string,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
): boolean => {
  return DateTime.now().startOf('day') <= DateTime.fromISO(date).setZone(timezone)
}

const formatDate = (
  date: string,
  withTime = true,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
): string => {
  Settings.defaultLocale = getDefaultLocale()
  const dt = DateTime.fromISO(date).setZone(timezone)
  return dt.toLocaleString(withTime ? dateTimeFormat : dateFormat)
}

const formatTime = (
  date: string,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
): string => {
  Settings.defaultLocale = getDefaultLocale()
  const dt = DateTime.fromISO(date).setZone(timezone)
  return dt.toLocaleString(DateTime.TIME_24_SIMPLE)
}

const formatRelativeDate = (
  dateString: string,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
): string => {
  const now = new Date()
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  const date = new Date(dateString)
  const difference = now.getTime() - date.getTime()
  const hoursMilli = 1000 * 60 * 60

  if (Math.abs(difference) < hoursMilli) {
    Settings.defaultLocale = getDefaultLocale()
    const dt = DateTime.fromJSDate(date).setZone(timezone)
    return dt.toRelative() || dt.diffNow().toHuman()
  } else if (now.toDateString() === date.toDateString()) {
    return `${i18n.global.t('today')} ${formatTime(dateString)}`
  } else if (yesterday.toDateString() === date.toDateString()) {
    return `${i18n.global.t('yesterday')} ${formatTime(dateString)}`
  } else {
    return formatDate(dateString)
  }
}

const formatDuration = (period: number, periodUnit: string): string => {
  Settings.defaultLocale = getDefaultLocale()
  const du = Duration.fromISO('P' + period + periodUnit[0])
  return du.toHuman()
}

const formatDurationWithoutNumber = (period: number, periodUnit: string): string => {
  Settings.defaultLocale = getDefaultLocale()
  if (period === 1) {
    return getPeriodFormat(periodUnit)
  } else {
    const du = Duration.fromISO('P' + period + periodUnit[0])
    return du.toHuman()
  }
}

const formatDurationShort = (period: number, periodUnit: string): string => {
  Settings.defaultLocale = getDefaultLocale()
  let periodFormat
  if (period === 1) {
    periodFormat = getPeriodFormatShort(periodUnit)
  } else {
    periodFormat = `${period} ${getPeriodFormatShort(periodUnit)}`
  }
  return periodFormat
}

export {
  dateNotOver,
  formatDate,
  formatDuration,
  formatDurationShort,
  formatDurationWithoutNumber,
  formatRelativeDate
}
