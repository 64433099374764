import { ApiError, handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import api from '@/modules/ctoFinder/api'
import {
  ModelConfiguration,
  ModelGroupResource,
  ProductClass,
  MediumProduct
} from '@/generatedTypes'
import { ModelComponent } from '@/modules/ctoFinder/types'

interface CtoFinderState {
  productClasses: ProductClass[]
  modelGroups: ModelGroupResource[]
  modelConfiguration: ModelConfiguration | undefined
  catalogProduct: MediumProduct | undefined
  loading: boolean
  loadingCatalogProduct: boolean
}

const initialState = (): CtoFinderState => {
  return {
    productClasses: [],
    modelGroups: [],
    modelConfiguration: undefined,
    catalogProduct: undefined,
    loading: false,
    loadingCatalogProduct: false
  }
}

const useCtoFinder = defineStore('ctoFinder', {
  state: () => initialState(),
  getters: {
    selectedModelComponents() {
      let selectedModelComponents: ModelComponent[] = []

      if (this.modelConfiguration) {
        this.modelConfiguration.modelComponentGroups.forEach((group) => {
          selectedModelComponents = selectedModelComponents.concat(
            group.modelComponents
              .filter((modelComponent) => modelComponent.selected === true)
              .map((component) => {
                return {
                  componentListPrice: component?.componentListPrice,
                  componentId: component?.componentId,
                  description: component?.description
                }
              })
          )
        })

        selectedModelComponents = selectedModelComponents.concat(
          this.modelConfiguration.fixedModelComponents
        )
      }

      return selectedModelComponents
    }
  },
  actions: {
    selectModelComponent(modelComponentGroupId: number, modelComponentId: number) {
      const modelGroup = this.modelConfiguration?.modelComponentGroups.find(
        (group) => group.groupId === modelComponentGroupId
      )
      const modelComponentToSelect = modelGroup?.modelComponents.find(
        (component) => component.componentId === modelComponentId
      )
      const modelComponentToUnselect = modelGroup?.modelComponents.find(
        (component) => component.selected === true
      )

      if (modelComponentToSelect && modelComponentToUnselect) {
        modelComponentToSelect.selected = true
        modelComponentToUnselect.selected = false
        this.getProductInformation()
      }
    },
    async getProductClasses() {
      this.loading = true

      return api
        .getProductClasses()
        .then(({ data }) => {
          this.productClasses = data
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            appearance: 'CUSTOM',
            customHandler: 'CategoryLayout'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getModelGroups(productClassId: string) {
      this.loading = true

      return api
        .getModelGroups(productClassId)
        .then(({ data }) => {
          this.modelGroups = data
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            appearance: 'CUSTOM',
            customHandler: 'CategoryLayout'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getModelConfiguration(manufacturerMaterialNo: string) {
      this.loading = true

      return api
        .getModelConfiguration(manufacturerMaterialNo)
        .then(({ data }) => {
          this.modelConfiguration = data
          this.getProductInformation()
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            appearance: 'CUSTOM',
            customHandler: 'CategoryLayout'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getProductInformation() {
      if (this.modelConfiguration) {
        this.loadingCatalogProduct = true

        return api
          .getProductInformation({
            baseModel: this.modelConfiguration.baseModel,
            modelComponents: this.selectedModelComponents
          })
          .then(({ data }) => {
            this.catalogProduct = data
          })
          .catch((error: ApiError) => {
            this.catalogProduct = undefined

            handleApiError(error, undefined, {
              errorCode: 404,
              appearance: 'NONE',
              logError: false
            })
          })
          .finally(() => {
            this.loadingCatalogProduct = false
          })
      }
    }
  }
})

export default useCtoFinder
