import { get, post, Response } from '@/api'
import {
  ModelConfiguration,
  ModelGroupResource,
  ProductClass,
  ProductClassesPostDTO,
  MediumProduct
} from '@/generatedTypes'

export default {
  getProductClasses: (): Promise<Response<ProductClass[]>> => {
    return get<ProductClass[]>('/cto/productclasses')
  },
  getModelGroups: (productClassId: string): Promise<Response<ModelGroupResource[]>> => {
    return get<ModelGroupResource[]>('/cto/modelgroup/' + productClassId)
  },
  getModelConfiguration: (
    manufacturerMaterialNo: string
  ): Promise<Response<ModelConfiguration>> => {
    return get<ModelConfiguration>('/cto/productclasses/' + manufacturerMaterialNo)
  },
  getProductInformation: (dto: ProductClassesPostDTO): Promise<Response<MediumProduct>> => {
    return post<MediumProduct, ProductClassesPostDTO>('/cto/productinformation', dto)
  }
}
