<template>
  <v-list-item @click="duplicateCollection">
    <v-list-item-title>{{ $t('collection.duplicate') }}</v-list-item-title>
  </v-list-item>
</template>

<script lang="ts">
import useCollection from '@/modules/collection/useCollection'
import CollectionEvent from '@/modules/tracking/events/collection'
import { trackEvent } from '@/modules/tracking/useTracking'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CollectionDuplicateButton',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const duplicateCollection = () => {
      useCollection().duplicateCollection(props.id)
      trackEvent(CollectionEvent.DuplicateCollection)
    }

    return {
      duplicateCollection
    }
  }
})
</script>
