<template>
  <ul class="pl-0">
    <li
      v-for="(item, index) in description"
      :key="item + '_' + index"
      class="d-inline desc-item"
      v-html="item"
    />
  </ul>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ProductShortDescription',
  props: {
    description: {
      type: Array as PropType<string[]>,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>
.desc-item {
  word-wrap: normal;
  &::after {
    content: '○';
    font-size: 0.9rem;
    padding: 0 5px 0 5px;
  }
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
    &::after {
      content: '';
    }
  }
}
</style>
