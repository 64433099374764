import { PreferedProductSearchView } from '@/generatedTypes'
import { Event } from '@/modules/tracking/types'
import AddToCartButtonEvents from '@/modules/tracking/events/helper/addToCartButton'

export default class ProductSearchEvent extends Event {
  static readonly TextSearch = (searchTerm: string): ProductSearchEvent =>
    new ProductSearchEvent('Produkte gesucht', searchTerm)
  static readonly CategorySearch = new ProductSearchEvent('Produkte in einer Kategorie gesucht')
  static readonly InstantSearch = new ProductSearchEvent('Produkt in Instant Search ausgewählt')
  static readonly AddToCart = class {
    static readonly Card: AddToCartButtonEvents = {
      addToCart: new ProductSearchEvent('Produkt zum Warenkorb hinzugefügt', 'Aus einer Kachel'),
      request: new ProductSearchEvent('Produkt angefragt', 'Aus einer Kachel')
    }
    static readonly List: AddToCartButtonEvents = {
      addToCart: new ProductSearchEvent(
        'Produkt zum Warenkorb hinzugefügt',
        'Aus einem Listenelement'
      ),
      request: new ProductSearchEvent('Produkt angefragt', 'Aus einem Listenelement')
    }
    static readonly Detail: AddToCartButtonEvents = {
      addToCart: new ProductSearchEvent('Produkt zum Warenkorb hinzugefügt', 'Aus Detailansicht'),
      request: new ProductSearchEvent('Produkt angefragt', 'Aus Detailansicht')
    }
  }
  static readonly GoToProductPage = class {
    static readonly Detail = new ProductSearchEvent('Produktseite aufgerufen', 'Aus Detailansicht')
    static readonly List = new ProductSearchEvent('Produktseite aufgerufen', 'Aus Listenansicht')
  }
  static readonly AddProductToExistingQuote = class {
    static readonly Detail = new ProductSearchEvent(
      'Produkt zur Anfrage hinzugefügt',
      'Aus Detailansicht'
    )
  }
  static readonly AddProductToNewQuote = class {
    static readonly Detail = new ProductSearchEvent('Anfrage erstellt', 'Aus Detailansicht')
  }
  static readonly AddToCollection = class {
    static readonly Detail = new ProductSearchEvent(
      'Produkt zu Sammlung hinzugefügt',
      'Aus Detailansicht'
    )
  }
  static readonly CreateCollection = class {
    static readonly Detail = new ProductSearchEvent('Sammlung erstellt', 'Aus Detailansicht')
  }
  static readonly Comparison = class {
    static readonly AddToComparison = (productId: number): ProductSearchEvent =>
      new ProductSearchEvent('Produkt zu Vergleich hinzugefügt', 'Aus Detailansicht').withProduct(
        productId
      )
    static readonly RemoveFromComparison = (productId: number): ProductSearchEvent =>
      new ProductSearchEvent('Produkt vom Vergleich entfernt', 'Aus Detailansicht').withProduct(
        productId
      )
  }
  static readonly Sort = (sortName: string): ProductSearchEvent =>
    new ProductSearchEvent('Sortiert', sortName)
  static readonly NextPage = new ProductSearchEvent('Produkte nachgeladen')
  static readonly ToggleView = (view: string): ProductSearchEvent => {
    if (view === PreferedProductSearchView.CARDS) {
      return new ProductSearchEvent('Darstellungsansicht umgeschaltet', 'Kachelansicht')
    }
    if (view === PreferedProductSearchView.LIST) {
      return new ProductSearchEvent('Darstellungsansicht umgeschaltet', 'Listenansicht')
    }
    return new ProductSearchEvent('Darstellungsansicht umgeschaltet', view)
  }
  private constructor(action: string, label?: string) {
    super('Produktsuche', action, label ?? '')
  }
}
