<template>
  <v-row>
    <v-col cols="12">
      <order-loading v-if="loading" />

      <template v-if="!loading && order">
        <div
          ref="order-title"
          class="d-flex justify-space-between ga-1 align-center"
          :style="!$vuetify.display.mobile ? 'height: 36px' : ''"
        >
          <div class="d-flex flex-row">
            <show-categories-button class="mr-1" />

            <div class="d-flex flex-wrap flex-lg-nowrap align-center ga-1">
              <sf-title
                :class="[
                  {
                    'text-disabled': order.isArchived,
                    'text-truncate': !$vuetify.display.mobile
                  }
                ]"
              >
                {{
                  order.distributorNames.length > 0
                    ? $t('orderBy', [formatDistributors(order.distributorNames)])
                    : $t('order.order') + ' ' + order.orderNumber
                }}
              </sf-title>
              <div class="d-flex flex-shrink-0 ga-1 align-center">
                <topi-badge v-if="order.isTopiOrder" />
                <sf-color-text
                  ref="status"
                  :color="getStatusColor(order.orderStatus)"
                  class="text-center"
                >
                  {{ $t('orderStatus.' + order.orderStatus) }}
                </sf-color-text>
                <sf-archived-chip v-if="order.isArchived" />
                <order-rma-already-requested
                  v-if="order.rmaInformation.rmaAlreadyRequested"
                  class="hidden-md-and-up"
                />
              </div>
            </div>
          </div>

          <div class="d-flex align-center flex-shrink-0 ga-1">
            <order-rma-already-requested
              v-if="order.rmaInformation.rmaAlreadyRequested"
              id="pw-order-rma-already-requested"
              class="hidden-sm-and-down"
            />
            <order-menu :order="order" />
          </div>
        </div>

        <v-tabs bg-color="transparent" color="primary" class="mt-1">
          <v-tab :to="{ name: 'OrderDetails', params: { id: order.id } }">
            {{ $t('order.details') }}
          </v-tab>
          <v-tab :to="{ name: 'OrderDocuments', params: { id: order.id } }">
            {{ $t('order.documents') }}
          </v-tab>
        </v-tabs>

        <v-divider />

        <router-view />
      </template>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import ShowCategoriesButton from '@/components/ShowCategoriesButton.vue'
import SfArchivedChip from '@/components/chips/SfArchivedChip.vue'
import SfColorText from '@/components/text/SfColorText.vue'
import SfTitle from '@/components/text/SfTitle.vue'
import { useTitle } from '@/helpers'
import useAddress from '@/modules/address/useAddress'
import OrderLoading from '@/modules/order/components/OrderLoading.vue'
import OrderMenu from '@/modules/order/components/OrderMenu.vue'
import OrderRmaAlreadyRequested from '@/modules/order/components/OrderRmaAlreadyRequested.vue'
import { formatDistributors, getStatusColor } from '@/modules/order/helpers'
import useOrder from '@/modules/order/useOrder'
import TopiBadge from '@/modules/topi/components/TopiBadge.vue'
import { storeToRefs } from 'pinia'
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const currentRoute = useRoute()

watch(
  () => currentRoute.params.id,
  (newId, oldId) => {
    if (oldId != newId) {
      useOrder().getOrder(newId as string)
    }
  },
  { immediate: true }
)

useAddress().getAddresses()

const { order, loading } = storeToRefs(useOrder())

const pageTitle = computed(() =>
  order.value ? `${t('order.order')} ${order.value.orderNumber}` : `${t('order.order')}`
)
useTitle(pageTitle)
</script>
