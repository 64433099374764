<template>
  <sf-icon-button :size="32" :icon="mdiFileTree" @click="$emit('toggle')" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SfIconButton from '@/components/button/SfIconButton.vue'
import { mdiFileTree } from '@mdi/js'

export default defineComponent({
  name: 'CategoriesButton',
  components: { SfIconButton },
  emits: ['toggle'],
  setup() {
    return {
      mdiFileTree
    }
  }
})
</script>
