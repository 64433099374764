<template>
  <div class="mt-2">
    <template v-if="loading">
      <v-skeleton-loader class="mb-2" type="heading" />
      <v-skeleton-loader class="mb-2" type="text" />
      <v-row dense>
        <v-col>
          <v-skeleton-loader class="mb-2" type="list-item-three-line" />
        </v-col>
        <v-col>
          <v-skeleton-loader class="mb-2" type="list-item-three-line" />
        </v-col>
      </v-row>
      <v-divider class="my-3" />
      <v-skeleton-loader class="mb-2" type="text" />
      <v-skeleton-loader v-for="index in 6" :key="index" class="mb-2" type="list-item-avatar" />
    </template>
    <template v-else>
      <v-row>
        <v-col cols="12">
          <div class="d-flex flex-row align-center mb-1">
            <sf-sub-heading class="mr-2">
              {{ $t('orderLimits.activateOrderLimits') }}
            </sf-sub-heading>
            <v-switch
              id="pw-activate-order-limits-switch"
              v-model="defaultOrderLimit.activated"
              :disabled="!ownUser.permission.shopAdmin"
              class="mt-0 pt-0"
              color="green"
              hide-details
              inset
              @update:model-value="orderLimitActivatedChanged"
            />
          </div>
          <div class="text-justify">
            <sf-text>{{ $t('orderLimits.description') }}</sf-text>
          </div>
        </v-col>
      </v-row>
      <template v-if="defaultOrderLimit.activated">
        <order-limit-default-settings :editable="ownUser.permission.shopAdmin" />
        <template v-if="showColleaguesPermissions">
          <v-divider class="my-3" />
          <v-row v-if="$vuetify.display.mdAndUp" class="px-2" no-gutters>
            <v-col class="d-flex align-center justify-start">
              <sf-sub-heading>{{ $t('company.employee') }}</sf-sub-heading>
            </v-col>
            <v-col class="d-flex align-center justify-end">
              <sf-sub-heading>{{ $t('orderLimits.orderRight') }}</sf-sub-heading>
            </v-col>
            <v-col class="d-flex align-center justify-end">
              <sf-sub-heading>{{ $t('orderLimits.orderLimit') }}</sf-sub-heading>
            </v-col>
            <v-col class="d-flex align-center justify-end">
              <sf-sub-heading>{{ $t('orderLimits.orderApprover') }}</sf-sub-heading>
            </v-col>
            <v-col
              v-if="ownUser.permission.shopAdmin"
              class="d-flex align-center justify-end mx-1"
              cols="auto"
            >
              <v-icon size="36" color="transparent" :icon="mdiPencil" />
            </v-col>
          </v-row>
          <div id="pw-order-limit-users">
            <order-limit-user-card
              v-for="user in orderLimitUsers"
              :key="user.id"
              :user="user"
              :approvers="approvers"
              :editable="ownUser.permission.shopAdmin"
            />
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import SfText from '@/components/text/SfText.vue'
import OrderLimitDefaultSettings from '@/modules/orderLimits/components/OrderLimitDefaultSettings.vue'
import OrderLimitUserCard from '@/modules/orderLimits/components/OrderLimitUserCard.vue'
import useOrderLimits from '@/modules/orderLimits/useOrderLimits'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import MyCompanyEvent from '@/modules/tracking/events/MyCompany'
import { trackEvent } from '@/modules/tracking/useTracking'
import useOwnUser from '@/modules/user/useOwnUser'
import { useTitle } from '@/helpers'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { mdiPencil } from '@mdi/js'

export default defineComponent({
  name: 'OrderLimitPage',
  components: {
    OrderLimitDefaultSettings,
    OrderLimitUserCard,
    SfSubHeading,
    SfText
  },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('myCompany')} - ${t('windowTitle.CompanyOrderLimit')}`))
    const ownUserStore = useOwnUser()
    const store = useOrderLimits()

    const { defaultOrderLimit, orderLimitUsers, approvers, loading } = storeToRefs(store)
    const { ownUser } = storeToRefs(ownUserStore)

    const { portalSettings, loading: portalSettingsLoading } = storeToRefs(usePortalSettings())

    watch(
      portalSettingsLoading,
      (newValue) => {
        if (!newValue) {
          store.loadOrderLimits()
          store.getApprovers()
        }
      },
      { immediate: true }
    )

    const showColleaguesPermissions = computed<boolean>(
      () => !portalSettings.value.hideColleaguesPermissions
    )

    const orderLimitActivatedChanged = () => {
      const trackingEvent = defaultOrderLimit.value.activated
        ? MyCompanyEvent.OrderLimit.Default.Activated
        : MyCompanyEvent.OrderLimit.Default.Deactivated
      trackEvent(trackingEvent)
      store.updateDefaultOrderLimit({
        activated: defaultOrderLimit.value.activated
      })
    }

    return {
      defaultOrderLimit,
      orderLimitUsers,
      approvers,
      loading,
      ownUser,
      showColleaguesPermissions,
      orderLimitActivatedChanged,
      mdiPencil
    }
  }
})
</script>
