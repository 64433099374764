<template>
  <v-row dense>
    <v-col>
      <v-skeleton-loader v-for="index in 6" :key="index" type="list-item" class="mb-1" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ListLoading'
})
</script>
