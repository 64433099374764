import { ApiError, StorefrontApiError } from '@/handleApiError'
import axios, { AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios'
import useAuthentication from '@/modules/authentication/useAuthentication'
import useRequestManager from '@/store/useRequestManager'
import { BRANDING_ENDPOINT } from '@/modules/portalSettings/api'

function axiosSetup(): void {
  let refreshTokenPromise: Promise<string | void> | undefined = undefined

  axios.defaults.baseURL = import.meta.env.VITE_STOREFRONT_API_BASE_URL
  axios.defaults.headers.common['Content-Type'] = 'application/json'
  axios.defaults.timeout = import.meta.env.VITE_STOREFRONT_AXIOS_TIMEOUT ?? 30000

  // request interceptor
  axios.interceptors.request.use(
    (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
      if (
        window.AbortController !== undefined &&
        config.method === 'get' &&
        !config.url?.endsWith(BRANDING_ENDPOINT)
      ) {
        const abortController = new AbortController()
        config.signal = abortController.signal
        useRequestManager().addAbortController(abortController)
      }

      // intercept request and add authorization
      const userStore = useAuthentication()
      if (config.headers && config.url?.startsWith('/guest')) {
        config.headers.Authorization = 'Bearer ' + userStore.state.value.guestToken
      } else if (
        config.headers &&
        userStore.hasValidAccessToken() &&
        config.url !== '/user/actions/access-token'
      ) {
        config.headers.Authorization = 'Bearer ' + userStore.state.value.accessToken
      }
      if (config.headers && userStore.isRedslave()) {
        config.headers['redslave'] = true
      }
      return config
    },
    (error: AxiosError): Promise<AxiosError> => {
      return Promise.reject(error)
    }
  )

  // response interceptor
  axios.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => response,
    async (error: AxiosError<StorefrontApiError>): Promise<ApiError> => {
      if (error.code === AxiosError.ERR_CANCELED) {
        return Promise.reject({
          isCancel: true
        } as ApiError)
      }

      if (error.code === AxiosError.ERR_NETWORK) {
        return Promise.reject({
          code: 503,
          isCancel: false
        } as ApiError)
      }

      if (error.code === AxiosError.ECONNABORTED) {
        return Promise.reject({
          code: 408,
          isCancel: false
        } as ApiError)
      }

      const userStore = useAuthentication()

      // refresh access token
      if (
        error.response &&
        error.response.status === 401 &&
        error.config &&
        error.config.url !== '/user/actions/access-token' &&
        userStore.state.value.refreshToken
      ) {
        if (typeof refreshTokenPromise == 'undefined') {
          refreshTokenPromise = useAuthentication()
            .refreshAccessToken({ value: userStore.state.value.refreshToken })
            .then(() => {
              refreshTokenPromise = undefined
            })
        }

        return refreshTokenPromise.then(() => {
          if (error.config) {
            return axios.request(error.config).catch((error: ApiError) => {
              //  Avoid loop if the newly retrieved token is still invalid
              if (error.code == 401) {
                // eslint-disable-next-line no-console
                console.error('Wrong access token got from backend. Logging out')
                useAuthentication().logout()
              }

              return Promise.reject(error)
            })
          }
        }) as Promise<ApiError>
      }

      return Promise.reject({
        code: error.response?.status,
        axiosError: error,
        isCancel: false
      } as ApiError)
    }
  )
}

export { axiosSetup }
