<template>
  <v-row>
    <template v-if="technicalDetails.length > 0">
      <v-col cols="12" md="" order="2" order-md="1">
        <div class="mb-1">
          <sf-heading v-if="showTitle || $vuetify.display.smAndDown">
            {{ $t('technicalData') }}
          </sf-heading>
        </div>
        <v-data-table
          ref="technical-details"
          :items="technicalDetails"
          :headers="technicalDataHeaders"
          :items-per-page="-1"
          :group-by="[{ key: 'group' }]"
        >
          <template #group-header="{ item, toggleGroup, isGroupOpen }">
            <tr
              :ref="
                (el) => {
                  if (!isGroupOpen(item)) toggleGroup(item)
                }
              "
              class="bg-grey-lighten-4"
            >
              <td colspan="3">
                <sf-heading>{{ item.value }}</sf-heading>
              </td>
            </tr>
          </template>
          <template #headers />
          <template #bottom />
        </v-data-table>
      </v-col>
    </template>

    <v-col v-if="productIdentifiers.length > 0" cols="12" md="auto" order="1" order-md="2">
      <template v-if="productIdentifiers.length > 0">
        <div class="mb-1">
          <sf-heading>{{ $t('productIdentifiers') }}</sf-heading>
        </div>

        <v-data-table
          ref="product-identifiers"
          :headers="identifiersHeaders"
          :items="productIdentifiers"
          :items-per-page="-1"
        >
          <template #[`item.name`]="{ item }">{{ $t(item.name) }}</template>
          <template #headers />
          <template #bottom />
        </v-data-table>
      </template>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import SfHeading from '@/components/text/SfHeading.vue'
import { TechnicalDetail } from '@/generatedTypes'
import { ProductIdentifier } from '@/modules/product/types'
import { DataTableHeader } from '@/types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ProductTechnicalDetails',
  components: { SfHeading },
  props: {
    technicalDetails: {
      type: Array as PropType<TechnicalDetail[]>,
      required: true
    },
    productIdentifiers: {
      type: Array as PropType<ProductIdentifier[]>,
      required: true
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const technicalDataHeaders: DataTableHeader<unknown>[] = [
      {
        key: 'data-table-group',
        width: '0px',
        headerProps: {
          class: 'pa-0'
        },
        cellProps: {
          class: 'pa-0'
        }
      },
      {
        value: 'name'
      },
      {
        value: 'value'
      }
    ]

    const identifiersHeaders: DataTableHeader<unknown>[] = [
      {
        value: 'name'
      },
      {
        value: 'value'
      }
    ]

    return {
      technicalDataHeaders,
      identifiersHeaders
    }
  }
})
</script>
