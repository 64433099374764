<template>
  <v-card class="pa-2 mb-2" :to="{ name: 'OrderDetails', params: { id: orderPreview.id } }">
    <div class="d-flex">
      <avatar-image ref="avatar" :user="orderPreview.initiatorUser" :size="30" class="mr-1" />
      <div class="d-flex flex-column flex-grow-1">
        <sf-heading ref="distributors" class="align-self-start">
          {{ formatDistributors(orderPreview.distributorNames) }}
        </sf-heading>
        <template v-if="orderPreview.distributorNames.length === 0">
          <sf-heading ref="orderNumberTitle">
            {{ orderPreview.orderNumber }}
          </sf-heading>
          <sf-text v-if="orderPreview.sentDate" ref="sent-date">
            {{ formatDate(orderPreview.sentDate) }}
          </sf-text>
        </template>
        <div v-else class="d-flex justify-space-between flex-wrap">
          <v-chip ref="orderNumberChip" size="small">
            {{ orderPreview.orderNumber }}
          </v-chip>
          <v-spacer />
          <sf-text v-if="orderPreview.sentDate" ref="sent-date">
            {{ formatDate(orderPreview.sentDate) }}
          </sf-text>
        </div>
      </div>
    </div>
    <v-divider class="my-1" />
    <template v-if="showStatus">
      <sf-color-text ref="status" :color="getStatusColor(orderPreview.orderStatus)">
        {{ $t('orderStatus.' + orderPreview.orderStatus) }}</sf-color-text
      >
      <v-divider class="my-1" />
    </template>
    <div>
      <orders-grid-card-item
        v-for="(item, index) in orderPreview.items"
        :key="index"
        :item="item"
      />
    </div>
    <v-divider class="my-1" />
    <div class="d-flex flex-column justify-space-between">
      <div ref="shippingCost" class="d-flex justify-space-between">
        <sf-text>{{ $t('shippingCostsShort') }}</sf-text>
        <span class="text-body-1 text-primary">
          {{ formatMoney(orderPreview.shippingCost, false, true) }}
        </span>
      </div>
      <div ref="netSum" class="d-flex justify-space-between">
        <sf-heading>∑</sf-heading>
        <span class="text-body-1 font-weight-bold text-primary">
          {{ formatMoney(orderSum, false, true) }}
        </span>
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import AvatarImage from '@/components/AvatarImage.vue'
import SfColorText from '@/components/text/SfColorText.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import OrdersGridCardItem from '@/modules/order/components/OrdersGridCardItem.vue'
import { formatDistributors, getStatusColor } from '@/modules/order/helpers'
import { OrderPreview } from '@/generatedTypes'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import { formatMoney } from '@/modules/product/helpers'
import { formatDate } from '@/utils/timeFormatHelpers'
import { computed, defineComponent, PropType } from 'vue'
import { storeToRefs } from 'pinia'

export default defineComponent({
  name: 'OrdersGridCard',
  components: {
    OrdersGridCardItem,
    AvatarImage,
    SfHeading,
    SfColorText,
    SfText
  },
  props: {
    orderPreview: {
      type: Object as PropType<OrderPreview>,
      required: true
    },
    showStatus: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { portalSettings } = storeToRefs(usePortalSettings())

    const orderSum = computed(() => {
      return portalSettings.value.grossPortal
        ? props.orderPreview.orderSumInformation.grossSum
        : props.orderPreview.orderSumInformation.netSum
    })

    return {
      getStatusColor,
      formatDistributors,
      formatMoney,
      formatDate,
      orderSum
    }
  }
})
</script>
