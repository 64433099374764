<template>
  <v-row>
    <v-col cols="12">
      <list-header :title="$t('cto.selectBaseModel')" />

      <list-content>
        <v-col cols="12">
          <!-- Loading -->
          <template v-if="loading">
            <v-row dense>
              <v-col cols="12">
                <v-skeleton-loader type="heading" />
              </v-col>
              <v-col v-for="index in 3" :key="index" cols="12" sm="6" md="4" xl="3">
                <v-skeleton-loader type="card" />
              </v-col>
            </v-row>
          </template>
          <!-- Model Groups -->
          <template v-else>
            <v-row
              v-for="modelGroupResource in modelGroups.filter(
                (group) => group.baseModels.length > 0
              )"
              :key="modelGroupResource.modelGroup.modelGroupId"
              dense
            >
              <v-col cols="12">
                <sf-heading>
                  {{ modelGroupResource.modelGroup.modelGroupDescription }}
                </sf-heading>
              </v-col>
              <v-col
                v-for="baseModel in modelGroupResource.baseModels"
                :key="baseModel.baseModel.modelId"
                cols="12"
                sm="6"
                md="4"
                xl="3"
              >
                <base-model-card :base-model="baseModel" />
              </v-col>
            </v-row>
          </template>
        </v-col>
      </list-content>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import ListContent from '@/components/list/ListContent.vue'
import ListHeader from '@/components/list/ListHeader.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import BaseModelCard from '@/modules/ctoFinder/components/BaseModelCard.vue'
import useCtoFinder from '@/modules/ctoFinder/useCtoFinder'
import router from '@/router'
import { useTitle } from '@/helpers'
import { storeToRefs } from 'pinia'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'CtoModelGroupPage',
  components: { BaseModelCard, ListContent, ListHeader, SfHeading },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('cto.appleCtoFinder')}`))
    useCtoFinder().getModelGroups(router.currentRoute.value.params.id as string)

    const { modelGroups, loading } = storeToRefs(useCtoFinder())

    return {
      modelGroups,
      loading
    }
  }
})
</script>
