import { Event } from '@/modules/tracking/types'
import AddToCartButtonEvents from '@/modules/tracking/events/helper/addToCartButton'

export default class ProductComparisonEvent extends Event {
  static readonly HighlightDifferences = class {
    static readonly on = new ProductComparisonEvent('Unterschiede hervorheben an')
    static readonly off = new ProductComparisonEvent('Unterschiede hervorheben aus')
  }
  static readonly AddToCart: AddToCartButtonEvents = {
    addToCart: new ProductComparisonEvent('In den Warenkorb gelegt'),
    request: new ProductComparisonEvent('Produkt angefragt')
  }
  static readonly RemoveProduct = new ProductComparisonEvent('Produkt entfernt')

  constructor(action: string, label?: string) {
    super('Produktvergleich', action, label ?? '')
  }
}
