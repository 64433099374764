<template>
  <div class="expandable-group">
    <div class="d-flex align-center bg-grey-lighten-4 pa-1">
      <sf-icon-button
        :icon="isExpanded ? mdiChevronDown : mdiChevronRight"
        @click="isExpanded = !isExpanded"
      />
      <sf-heading class="ml-1">{{ title }}</sf-heading>
    </div>

    <v-expand-transition>
      <div v-show="isExpanded">
        <v-divider />
        <slot />
      </div>
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import SfIconButton from '@/components/button/SfIconButton.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import { mdiChevronDown, mdiChevronRight } from '@mdi/js'

export default defineComponent({
  name: 'ExpandableGroup',
  components: { SfIconButton, SfHeading },
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup() {
    const isExpanded = ref(true)

    return {
      isExpanded,
      mdiChevronDown,
      mdiChevronRight
    }
  }
})
</script>

<style lang="scss" scoped>
.expandable-group:not(:last-child) {
  border-bottom: thin solid rgba(0, 0, 0, 0.1);
}
</style>
