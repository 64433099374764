import { handleApiError } from '@/handleApiError'
import api from '@/modules/portalSettings/api'
import { PortalSettings } from '@/modules/portalSettings/types'
import { defineStore } from 'pinia'

export interface PortalSettingsState {
  portalSettings: PortalSettings
  loading: boolean
}

const initialState = (): PortalSettingsState => {
  return {
    portalSettings: {
      addressPermissions: {
        canCreate: false,
        canSetDefaultBillingAddress: false,
        canSetDefaultDeliveryAddress: false
      },
      clearBrowserSession: false,
      grossPortal: false,
      isUserSpecificCartEnabled: false,
      isUserSpecificCollectionsEnabled: false,
      supplierComment: {
        field1: {
          required: false
        }
      },
      sellerComments: {},
      hideColleaguesPermissions: false,
      showLogout: true,
      showPaymentMethodSelection: false,
      showProductIds: false,
      hideProfile: false,
      showSuppliers: false,
      useAppleCtoFinder: false,
      useOwnDeliveryAddress: false,
      usePromotionalProducts: false,
      useQuotes: false,
      useCollections: true,
      useOrders: true,
      vatRatePercent: 0,
      showOrderLimitFunctionality: true,
      isCustomOrderNumberEnabled: true,
      shippingCostsOnlyApplyOncePerCart: false,
      minimumOrderSurchargesOnlyApplyOncePerCart: false
    },
    loading: false
  }
}

const usePortalSettings = defineStore('portalSettings', {
  state: () => initialState(),
  actions: {
    async getPortalSettings() {
      this.loading = true

      return api
        .getPortalSettings()
        .then(({ data }) => {
          this.portalSettings = data
        })
        .catch(handleApiError)
        .finally(() => {
          this.loading = false
        })
    }
  }
})

export default usePortalSettings
