<template>
  <v-card
    variant="flat"
    :loading="loading ? 'primary' : false"
    class="d-flex flex-row flex-grow-0 align-center justify-space-between transparent-card pa-2 mb-2"
  >
    <sf-text ref="orderStatus" color="primary" class="flex-grow-1">
      {{ $t('orderStatus.' + orderStatus) }}
    </sf-text>
    <v-badge v-if="!loading" ref="countBadge" inline :content="count.toString()" color="primary" />
  </v-card>
</template>

<script lang="ts">
import SfText from '@/components/text/SfText.vue'
import { defineComponent, PropType } from 'vue'
import { OrderStatus } from '@/generatedTypes'

export default defineComponent({
  name: 'OrdersGridColumnHeader',
  components: { SfText },
  props: {
    orderStatus: {
      type: String as PropType<OrderStatus>,
      required: true
    },
    count: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  }
})
</script>
