import { Event } from '@/modules/tracking/types'

type FilterContextText = 'Sammlungen' | 'Bestellungen' | 'Kategorien' | 'Produktsuche' | 'Angebote'

type FilterContext = 'categories' | 'collections' | 'orders' | 'productSearch' | 'quotes'
export type FilterType = 'Attribute' | 'Default'

export default class FilterEvent extends Event {
  constructor(category: string, action: string, label?: string) {
    super(category, action, label ?? '')
  }
  static GetFor(context: FilterContext): (type: FilterType) => (filterName: string) => FilterEvent {
    const category = contextToCategory(context)
    return getFor(category)
  }
}
function getFor(
  category: FilterContextText
): (filterType: FilterType) => (filterName: string) => FilterEvent {
  return function (filterType: FilterType): (filterName: string) => FilterEvent {
    const filterTypeName = filterTypeToName(filterType)

    return (filterName: string) =>
      new FilterEvent(category, filterTypeName + ' geändert', filterName)
  }
}
function filterTypeToName(type: FilterType): 'Attributfilter' | 'Filter' {
  return type === 'Attribute' ? 'Attributfilter' : 'Filter'
}

function contextToCategory(context: FilterContext): FilterContextText {
  switch (context) {
    case 'categories':
      return 'Kategorien'
    case 'collections':
      return 'Sammlungen'
    case 'orders':
      return 'Bestellungen'
    case 'productSearch':
      return 'Produktsuche'
    case 'quotes':
      return 'Angebote'
  }
}
