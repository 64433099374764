import { ComponentPublicInstance, computed, onMounted, onUnmounted, onUpdated, ref, Ref } from 'vue'

type ContainerProperties = { offset: Ref<number>; bottomMargin: number }
type ElementWithUid = HTMLElement & { dataset: { componentUid: string } }

const intersectionObservers = new Map<number, IntersectionObserver>()
const containerProperties: Map<number, ContainerProperties> = new Map<number, ContainerProperties>()
let uid = 0

function calculateHeight(element: Element, bottomMargin: number) {
  if (!element) {
    return bottomMargin
  }
  const rect = element.getBoundingClientRect()
  return rect.top + bottomMargin
}

const intersectionObserverCallback = (entries: IntersectionObserverEntry[]) => {
  for (const entry of entries) {
    if (entry.intersectionRatio > 0 && entry.intersectionRatio < 1) {
      const componentUid = parseInt((entry.target as ElementWithUid).dataset.componentUid)
      const properties = containerProperties.get(componentUid)
      if (properties) {
        properties.offset.value = calculateHeight(entry.target, properties.bottomMargin)
      }
    }
  }
}

export function containerHeight(
  elementOrComponentInstance:
    | Ref<HTMLElement | undefined>
    | Ref<ComponentPublicInstance<HTMLElement> | undefined>,
  bottomMargin = 16
): { offset: Ref<number> } {
  const myUid = uid++
  const offset = ref<number>(bottomMargin)
  containerProperties.set(myUid, { offset, bottomMargin })
  const element = computed<HTMLElement | undefined>(
    () =>
      (elementOrComponentInstance.value as ComponentPublicInstance<HTMLElement>)?.$el ||
      elementOrComponentInstance.value
  )

  let intersectionObserver = intersectionObservers.get(bottomMargin)

  // The check for window.IntersectionObsever should always be true in production but is needed for tests
  if (!intersectionObserver && window.IntersectionObserver) {
    const intersectionObserverOptions: IntersectionObserverInit = {
      // Using document.body instead of only document because Safari does not support document: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#browser_compatibility
      root: document.body,
      threshold: 0,
      rootMargin: `0px 0px -${bottomMargin}px 0px`
    }
    intersectionObserver = new IntersectionObserver(
      intersectionObserverCallback,
      intersectionObserverOptions
    )

    intersectionObservers.set(bottomMargin, intersectionObserver)
  }

  onMounted(() => {
    if (element.value) {
      ;(element.value as ElementWithUid).dataset.componentUid = myUid.toString()
      offset.value = calculateHeight(element.value, bottomMargin)
      intersectionObserver?.observe(element.value)
    }
  })
  onUpdated(() => {
    if (element.value) {
      offset.value = calculateHeight(element.value, bottomMargin)
    }
  })

  onUnmounted(() => {
    if (element.value) {
      containerProperties.delete(myUid)
      intersectionObserver?.unobserve(element.value)
      if (intersectionObserver?.takeRecords().length === 0) {
        intersectionObservers.delete(bottomMargin)
        intersectionObserver.disconnect()
      }
    }
  })

  return { offset }
}
