<template>
  <v-card id="permissions" class="pa-2">
    <v-row dense>
      <v-col cols="12">
        <sf-heading class="mb-1">
          {{ $t('user.permissions.permissions') }}
        </sf-heading>
      </v-col>
      <!-- order right -->
      <v-col cols="12" md="6" lg="4">
        <div id="submitOrder" class="d-flex flex-row align-center mb-1">
          <sf-sub-heading class="mr-2">
            {{ $t('user.permissions.submitOrder') }}
          </sf-sub-heading>
          <v-switch
            ref="submitOrder"
            v-model="userPermissions.submitOrder"
            class="mt-0 pt-0"
            inset
            color="green"
            hide-details
            :disabled="!isShopAdmin"
            @update:model-value="sendChange('order')"
          />
        </div>
        <div class="text-justify">
          <sf-text>
            {{ $t('user.permissions.submitOrderDescription') }}
          </sf-text>
        </div>
      </v-col>
      <!-- admin right -->
      <v-col cols="12" md="6" lg="4">
        <div id="shopAdmin" class="d-flex flex-row align-center mb-1">
          <sf-sub-heading class="mr-2">
            {{ $t('user.permissions.shopAdmin') }}
          </sf-sub-heading>
          <v-switch
            ref="shopAdmin"
            v-model="userPermissions.shopAdmin"
            class="mt-0 pt-0"
            inset
            color="green"
            hide-details
            :disabled="!isShopAdmin"
            @update:model-value="sendChange('admin')"
          />
        </div>
        <div class="text-justify">
          <sf-text>
            {{ $t('user.permissions.shopAdminDescription') }}
          </sf-text>
        </div>
      </v-col>
      <!-- change subsidiary right -->
      <v-col v-if="user.permission.changeSubsidiary != undefined" cols="12" md="6" lg="4">
        <div id="changeSubsidiary" class="d-flex flex-row align-center mb-1">
          <sf-sub-heading class="mr-2">
            {{ $t('user.permissions.changeSubsidiary') }}
          </sf-sub-heading>
          <v-switch
            ref="changeSubsidiary"
            v-model="userPermissions.changeSubsidiary"
            class="mt-0 pt-0"
            inset
            color="green"
            hide-details
            :disabled="!isShopAdmin"
            @update:model-value="sendChange('subsidiary')"
          />
        </div>
        <div class="text-justify">
          <sf-text>
            {{ $t('user.permissions.changeSubsidiaryDescription') }}
          </sf-text>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import SfHeading from '@/components/text/SfHeading.vue'
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { trackingForUser } from '@/modules/tracking/events/helper/userTrackingHelper'
import { trackEvent } from '@/modules/tracking/useTracking'
import { User } from '@/generatedTypes'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'UserPermissions',
  components: { SfHeading, SfSubHeading, SfText },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    },
    isShopAdmin: {
      type: Boolean,
      default: false
    }
  },
  emits: ['permissionsChanged'],
  setup(props, { emit }) {
    const userPermissions = computed(() => {
      return {
        shopAdmin: props.user.permission.shopAdmin,
        submitOrder: props.user.permission.submitOrder,
        changeSubsidiary: props.user.permission.changeSubsidiary
      }
    })

    const sendChange = (permission?: 'admin' | 'order' | 'subsidiary') => {
      if (permission) {
        let event
        switch (permission) {
          case 'admin':
            event = trackingForUser(props.user.id).adminPermission()
            break
          case 'order':
            event = trackingForUser(props.user.id).orderPermission()
            break
          case 'subsidiary':
            event = trackingForUser(props.user.id).subsidiaryPermission()
            break
        }
        trackEvent(event)
      }

      emit('permissionsChanged', userPermissions.value)
    }

    return {
      userPermissions,
      sendChange
    }
  }
})
</script>
