<template>
  <v-btn :color="$attrs.disabled ? '' : 'primary'" v-bind="$attrs" :elevation="0">
    <slot />
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SfButton',
  inheritAttrs: false
})
</script>
