<template>
  <div v-if="branding">
    <login-wrapper :branding="branding" :title="title" :loading="authState.loading">
      <login-form
        class="mt-2"
        :branding="branding"
        :login-error="authState.loginError"
        @login="login"
        @reset="resetPassword"
      />
    </login-wrapper>
  </div>
</template>

<script lang="ts">
import { useTitle } from '@/helpers'
import LoginForm from '@/modules/authentication/components/LoginForm.vue'
import LoginWrapper from '@/modules/authentication/components/LoginWrapper.vue'
import { LoginError } from '@/modules/authentication/types'
import useAuthentication from '@/modules/authentication/useAuthentication'
import usePasswordReset from '@/modules/authentication/usePasswordReset'
import useBranding from '@/modules/portalSettings/useBranding'
import { useStateManager } from '@/store'
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'LoginPage',
  components: { LoginForm, LoginWrapper },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('windowTitle.Login')}`))
    useStateManager().reset(false)
    const currentRoute = useRoute()
    const authError = currentRoute.query['auth_error']
    if (authError === '0') {
      useAuthentication().setLoginError(LoginError.UNKNOWN_ERROR)
    } else if (authError === '1') {
      useAuthentication().setLoginError(LoginError.USER_DEACTIVATED)
    }

    const authState = useAuthentication().state
    const login = useAuthentication().login
    const { branding } = useBranding()

    const title = computed(() => {
      return branding.value?.isQuotesPortal
        ? t('loginQuotesPortal')
        : window.location.hostname.endsWith('systemhaus.shop')
          ? t('loginPortal')
          : t('login')
    })

    const resetPassword = (mail: string) => {
      usePasswordReset().resetPassword({
        mail: mail
      })
    }

    return {
      branding,
      authState,
      title,
      login,
      resetPassword
    }
  }
})
</script>
