import { ApiError, handleApiError } from '@/handleApiError'
import useAuthentication from '@/modules/authentication/useAuthentication'
import useBranding from '@/modules/portalSettings/useBranding'
import { defineStore } from 'pinia'
import api from '@/modules/subsidiary/api'
import { ImpersonationStatus, Relation, Subsidiary } from '@/generatedTypes'

export interface SubsidiaryState {
  subsidiaries: Subsidiary[]
  impersonationStatus: ImpersonationStatus
  loading: boolean
}

const initialState = (): SubsidiaryState => {
  return {
    subsidiaries: [],
    impersonationStatus: { relation: Relation.NONE, customer: '' },
    loading: false
  }
}

const useSubsidiary = defineStore('subsidiary', {
  state: () => initialState(),
  actions: {
    async loadSubsidiaries() {
      this.loading = true

      return api
        .subsidiaries()
        .then(({ data }) => {
          this.subsidiaries = data
        })
        .catch((error: ApiError) =>
          handleApiError(error, undefined, {
            errorCode: 403,
            logError: false,
            appearance: 'NONE'
          })
        )
        .finally(() => {
          this.loading = false
        })
    },
    async getImpersonationStatus() {
      return api
        .impersonationStatus()
        .then(({ data }) => {
          this.impersonationStatus = data
        })
        .catch((error: ApiError) =>
          handleApiError(error, undefined, {
            errorCode: 403,
            logError: false,
            appearance: 'NONE'
          })
        )
        .finally(() => {
          const { branding } = useBranding()
          useAuthentication().setImpersonateMode(
            this.impersonationStatus.relation != Relation.NONE &&
              branding.value != undefined &&
              !branding.value.ssoSettings
          )
        })
    },
    async switchToSubsidiary(subsidiaryId: string, redirect?: string) {
      return api.switchToSubsidiary(subsidiaryId).then(({ data }) => {
        window.location.assign(
          '/subsidiarylogin?token=' + data + (redirect ? '&redirect=' + redirect : '')
        )
      })
    }
  }
})

export default useSubsidiary
