<template>
  <div>
    <sf-alert-error v-if="tokenError" class="mb-4">
      {{ $t('error.resetTokenError') }}
    </sf-alert-error>
    <v-form v-if="!tokenError" ref="form" v-model:model-value="valid">
      <sf-password-field
        ref="password-field"
        v-model="password"
        color="primary"
        :label="$t('password')"
        :rules="[passwordLengthRule, passwordRule]"
        class="mt-1"
        variant="outlined"
        required
        autocomplete="new-password"
      />
      <sf-password-field
        ref="repeat-field"
        v-model="repeat"
        color="primary"
        :label="$t('passwordRepeat')"
        :rules="[passwordMatchRule(password)]"
        class="mb-4"
        variant="outlined"
        required
        autocomplete="new-password"
      />
      <sf-large-button :disabled="!valid" block :loading="sending" @click="changePassword"
        >{{ $t('resetPassword') }}
      </sf-large-button>
    </v-form>
    <login-link />
  </div>
</template>

<script lang="ts">
import SfAlertError from '@/components/alerts/SfAlertError.vue'
import SfLargeButton from '@/components/button/SfLargeButton.vue'
import SfPasswordField from '@/components/input/SfPasswordField.vue'
import LoginLink from '@/modules/authentication/components/LoginLink.vue'
import { passwordLengthRule, passwordMatchRule, passwordRule } from '@/rules'
import { VForm } from '@/types'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ResetPasswordForm',
  components: { SfLargeButton, SfAlertError, SfPasswordField, LoginLink },
  props: {
    sending: {
      type: Boolean,
      required: true
    },
    tokenError: {
      type: Boolean,
      required: true
    }
  },
  emits: ['changePassword'],
  setup(_, { emit }) {
    const form = ref<VForm>()
    const password = ref('')
    const repeat = ref('')
    const valid = ref(false)

    const changePassword = () => {
      if (form.value && form.value.validate()) {
        emit('changePassword', password.value)
      }
    }

    return {
      form,
      password,
      repeat,
      valid,
      passwordRule,
      passwordLengthRule,
      passwordMatchRule,
      changePassword
    }
  }
})
</script>
