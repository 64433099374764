<template>
  <v-row>
    <v-col cols="12">
      <order-loading v-if="loading" :show-tabs="false" />

      <template v-else-if="cart">
        <list-header
          :title="
            isSupplierCart(cart)
              ? $t('order.toOrderFrom', [cart.supplierName])
              : $t('order.toOrder')
          "
          hide-show-categories-button
        />

        <list-empty
          v-if="
            (isSupplierCart(cart) && cart.cartItems.length === 0) ||
            (!isSupplierCart(cart) && cart.supplierCarts.length === 0)
          "
          :message="$t('emptyCart')"
          :icon="mdiCart"
        />
        <template v-else-if="checkout && preparedOrder">
          <v-form ref="form" v-model="validForm">
            <checkout-header
              :billing-events="CheckoutEvent.Address.Billing"
              :comment-events="CheckoutEvent.Comment.Checkout"
              :order-number-events="CheckoutEvent.OrderNumbers"
              :shipping-events="CheckoutEvent.Address.Shipping"
            />
          </v-form>
          <v-divider class="my-2" />
          <v-row>
            <v-col>
              <template v-if="!isSupplierCart(cart)">
                <supplier-cart
                  v-for="supplierCart in cart.supplierCarts"
                  :key="supplierCart.id"
                  :supplier-cart="supplierCart"
                  read-only
                  validate-comments-immediately
                  :update-checkout-cart-fn="useCheckout().loadCart"
                  :hide-infos="!portalSettings.showSuppliers"
                />
              </template>

              <template v-else>
                <cart-row
                  v-for="cartItem in cart.cartItems"
                  :key="cartItem.id"
                  :cart-item="cartItem"
                  :show-stepper="false"
                  :max-length="cart.maxPositionCommentLength"
                  validate-comments-immediately
                  @update-seller-comment="updateSellerComment"
                  @update-supplier-comment="updateSupplierComment"
                />
              </template>

              <v-divider class="my-2" />

              <cart-messages v-if="cart.messages.length > 0" :cart-messages="cart.messages" />

              <v-row dense>
                <v-col cols="12">
                  <template v-if="cart?.cartInfo">
                    <order-limit-warning
                      v-if="checkout.orderLimitExceedance != OrderLimitExceedance.KEPT"
                      :approvers="checkout.orderLimitApprovers"
                      :no-order-right="
                        checkout.orderLimitExceedance == OrderLimitExceedance.NO_ORDER_RIGHT
                      "
                      :costs="cart.cartInfo.totalCost"
                      :order-limit-value="checkout.orderLimitValue"
                      :loading="sending"
                      :disabled="orderDisabled"
                      @request-order="requestOrder"
                    >
                      <cart-info
                        :cart-info="cart.cartInfo"
                        :gross-portal="portalSettings.grossPortal"
                        :is-supplier-cart="false"
                        big-sum
                      />
                    </order-limit-warning>
                    <cart-info
                      v-else
                      :cart-info="cart.cartInfo"
                      :gross-portal="portalSettings.grossPortal"
                      :is-supplier-cart="false"
                      big-sum
                    >
                      <template #button>
                        <div class="d-flex ga-1 align-center flex-wrap">
                          <sf-large-button
                            id="order-button"
                            :loading="sending"
                            :block="$vuetify.display.xs"
                            :disabled="orderDisabled"
                            @click="sendOrder()"
                          >
                            {{ sendOrderCaption }}
                          </sf-large-button>
                          <div
                            v-if="canUseTopi"
                            class="d-flex flex-row flex-wrap flex-md-nowrap align-center ga-1"
                          >
                            <x-topi-checkout-button
                              class="topi"
                              checkout-mode="cart"
                              @click="checkoutWithTopi"
                            />
                            <x-topi-cart-rental-summary-label ref="topi" mode="cart" />
                          </div>
                        </div>
                      </template>
                      <template v-if="checkoutTerms" #terms>
                        <div class="pt-3">
                          <sf-text v-html="checkoutTerms" />
                        </div>
                      </template>
                    </cart-info>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </template>
    </v-col>
    <sf-refresh-dialog
      v-if="cartOutdated"
      :title="$t('cartOutdated.title')"
      :description="$t('cartOutdated.description')"
      :loading="cartLoading"
      @refresh="refreshCart"
    />
    <checkout-expired-dialog
      v-else-if="sessionExpired"
      :loading="cartLoading"
      @refresh="refreshCart"
    />
  </v-row>
</template>

<script setup lang="ts">
import OrderLimitWarning from '@/components/OrderLimitWarning.vue'
import SfLargeButton from '@/components/button/SfLargeButton.vue'
import SfRefreshDialog from '@/components/dialog/SfRefreshDialog.vue'
import ListEmpty from '@/components/list/ListEmpty.vue'
import ListHeader from '@/components/list/ListHeader.vue'
import SfText from '@/components/text/SfText.vue'
import { CanOrder, OrderLimitExceedance, SellerComments } from '@/generatedTypes'
import { useTitle } from '@/helpers'
import useAddressValidation from '@/modules/address/useAddressValidation'
import CartInfo from '@/modules/cart/components/CartInfo.vue'
import CartMessages from '@/modules/cart/components/CartMessages.vue'
import CartRow from '@/modules/cart/components/CartRow.vue'
import SupplierCart from '@/modules/cart/components/SupplierCart.vue'
import { isSupplierCart } from '@/modules/cart/helpers'
import useCart from '@/modules/cart/useCart'
import CheckoutExpiredDialog from '@/modules/order/components/CheckoutExpiredDialog.vue'
import CheckoutHeader from '@/modules/order/components/CheckoutHeader.vue'
import OrderLoading from '@/modules/order/components/OrderLoading.vue'
import useCheckout from '@/modules/order/useCheckout'
import useOrderingConditions from '@/modules/portalSettings/useOrderingConditions'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import CartEvent from '@/modules/tracking/events/cart'
import CheckoutEvent from '@/modules/tracking/events/checkout'
import OrderEvent from '@/modules/tracking/events/order'
import { trackEvent } from '@/modules/tracking/useTracking'
import { mdiCart } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { t } = useI18n()
useTitle(ref(`${t('order.toOrder')}`))

const currentRoute = useRoute()
const supplierCartId = computed<string | undefined>(() => {
  if (currentRoute.params.id == undefined || currentRoute.params.id == '') {
    return undefined
  } else {
    return currentRoute.params.id as string
  }
})

useCheckout().loadCheckout(supplierCartId.value)

const form = ref<HTMLFormElement>()
const validForm = ref(false)

watch(form, () => {
  form.value?.validate()
})

const { portalSettings } = storeToRefs(usePortalSettings())
const { orderingConditions } = storeToRefs(useOrderingConditions())
const {
  loading,
  cartLoading,
  sending,
  preparedOrder,
  checkout,
  cartOutdated,
  allCommentsValid,
  checkoutTerms,
  cart,
  sessionExpired
} = storeToRefs(useCheckout())
const { delivery, billing } = storeToRefs(useAddressValidation())

const addressValid = computed(() => {
  return delivery.value.valid && billing.value.valid
})

const sendOrderCaption = computed(() => {
  return orderingConditions.value.isSofortPaymentEnabled
    ? t('order.klarnaCheckoutCaption')
    : t('order.sendOrder')
})

const updateSupplierComment = ({ id, comment }: { id: string; comment: string }) => {
  if (cart.value) {
    useCart().updateSupplierComment(
      id,
      comment,
      cart.value.timestamp,
      useCheckout().loadCart,
      supplierCartId.value
    )
    trackEvent(CartEvent.Comment.ToDistributor)
  }
}

const updateSellerComment = ({
  id,
  comment,
  fieldIndex
}: {
  id: string
  comment: string
  fieldIndex: keyof SellerComments
}) => {
  if (cart.value) {
    useCart().updateSellerComment(
      id,
      comment,
      fieldIndex,
      cart.value.timestamp,
      useCheckout().loadCart,
      supplierCartId.value
    )
    trackEvent(CartEvent.Comment.ToPortalOwner)
  }
}

const sendOrder = () => {
  if (form.value && form.value.validate()) {
    if (orderingConditions.value.isSofortPaymentEnabled) {
      useCheckout().sendOrderAndPay(supplierCartId.value)
      trackEvent(OrderEvent.SendAndPay)
    } else {
      useCheckout().sendOrder(supplierCartId.value)
      trackEvent(CheckoutEvent.SendOrder)
    }
  }
}

const requestOrder = (commentToApprovers: string) => {
  if (preparedOrder.value && form.value && form.value.validate()) {
    useCheckout().requestOrder(commentToApprovers, currentRoute.params.id as string)
  }
}

const refreshCart = (): void => {
  useCheckout().loadCart(supplierCartId.value, true)
}

const orderDisabled = computed(() => {
  return (
    (cart.value && cart.value.canOrder == CanOrder.DISABLED) ||
    !validForm.value ||
    !addressValid.value ||
    !allCommentsValid.value
  )
})

const canUseTopi = computed(() => {
  if (cart.value) {
    if (isSupplierCart(cart.value)) {
      return cart.value.cartItems.some((item) => item.price?.canBeRented)
    } else {
      return cart.value.supplierCarts
        .flatMap((supplierCart) => supplierCart.cartItems)
        .some((item) => item.price?.canBeRented)
    }
  } else {
    return false
  }
})

const checkoutWithTopi = () => {
  useCheckout().sendOrderTopi(supplierCartId.value)
  trackEvent(OrderEvent.SendTopi)
}
</script>

<style lang="scss">
.topi > button {
  height: 52px;
}
</style>
