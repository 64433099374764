<template>
  <v-row>
    <v-col cols="12">
      <list-header :title="$t('cto.selectProductClass')" />

      <list-content>
        <!-- Loading -->
        <template v-if="loading">
          <v-col v-for="index in 8" :key="index" cols="12" sm="6" md="4" lg="3">
            <v-skeleton-loader type="card" />
          </v-col>
        </template>
        <!-- Product classes -->
        <template v-else>
          <v-col
            v-for="productClass in productClasses"
            :key="productClass.productClassId"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <product-class-card :product-class="productClass" />
          </v-col>
        </template>
      </list-content>
      <!-- Empty list -->
      <list-empty
        v-if="!loading && productClasses.length === 0"
        :message="$t('noAppleProductsAvailable')"
        :icon="mdiApple"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import ListContent from '@/components/list/ListContent.vue'
import ListEmpty from '@/components/list/ListEmpty.vue'
import ListHeader from '@/components/list/ListHeader.vue'
import { useTitle } from '@/helpers'
import ProductClassCard from '@/modules/ctoFinder/components/ProductClassCard.vue'
import useCtoFinder from '@/modules/ctoFinder/useCtoFinder'
import { mdiApple } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'CtoProductClassPage',
  components: { ProductClassCard, ListContent, ListHeader, ListEmpty },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('cto.appleCtoFinder')}`))
    useCtoFinder().getProductClasses()

    const { productClasses, loading } = storeToRefs(useCtoFinder())

    return {
      productClasses,
      loading,
      mdiApple
    }
  }
})
</script>
