<template>
  <v-row>
    <v-col cols>
      <list-header :title="$t('promotionalProducts')" />
      <list-loading v-if="loading" />

      <list-empty
        v-else-if="promotionalProducts.length === 0"
        :message="$t('noPromotionalProductsAvailable')"
        :icon="mdiTagArrowDownOutline"
      />

      <list-content v-else ref="listContent">
        <v-col cols="12">
          <promotional-product-row
            v-for="promotionalProduct in promotionalProducts"
            :key="promotionalProduct.product.productId"
            :promotional-product="promotionalProduct"
          />
        </v-col>
      </list-content>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import ListContent from '@/components/list/ListContent.vue'
import ListEmpty from '@/components/list/ListEmpty.vue'
import ListHeader from '@/components/list/ListHeader.vue'
import ListLoading from '@/components/list/ListLoading.vue'
import PromotionalProductRow from '@/modules/promotionalProducts/components/PromotionalProductRow.vue'
import usePromotionalProducts from '@/modules/promotionalProducts/usePromotionalProducts'
import { storeToRefs } from 'pinia'
import { defineComponent, ref } from 'vue'
import { useTitle } from '@/helpers'
import { useI18n } from 'vue-i18n'
import { mdiTagArrowDownOutline } from '@mdi/js'

export default defineComponent({
  name: 'PromotionalProductsPage',
  components: {
    ListContent,
    ListEmpty,
    ListHeader,
    ListLoading,
    PromotionalProductRow
  },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('promotionalProducts')}`))
    usePromotionalProducts().loadPromotionalProducts()

    const { loading, promotionalProducts } = storeToRefs(usePromotionalProducts())

    return {
      promotionalProducts,
      loading,
      mdiTagArrowDownOutline
    }
  }
})
</script>
