import { defineStore } from 'pinia'
import api from '@/modules/product/api'
import helpers from '@/modules/message/helpers'
import useCart from '@/modules/cart/useCart'

interface ProductRequestState {
  sending: boolean
}

const initialState = (): ProductRequestState => {
  return {
    sending: false
  }
}

const useProductRequest = defineStore('productRequest', {
  state: () => initialState(),
  actions: {
    async getProductRequest(productId: number, supplierRowId?: number) {
      useCart().loading.supplierRowId = supplierRowId
      useCart().loading.add = true

      return api
        .getProductRequest(productId)
        .then(({ data }) => {
          return data
        })
        .catch(() => {
          helpers.reportError('error.productRequest')
        })
        .finally(() => {
          useCart().loading.add = false
          useCart().loading.supplierRowId = undefined
        })
    },
    async sendProductRequest(id: number) {
      this.sending = true

      return api
        .sendProductRequest(id)
        .then(() => helpers.reportSuccess('success.productRequested'))
        .catch(() => {
          helpers.reportError('error.productRequest')
        })
        .finally(() => {
          this.sending = false
        })
    }
  }
})

export default useProductRequest
